import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { DeleteOutlined } from '@ant-design/icons';

function Delete(props) {
  const title = props.title === 'button.delete' ? props.intl.formatMessage({ id: props.title }) : props.title
  return (
    <Button
      {...props}
      title={title}
      danger
    >
      {title}
    </Button>
  )
}

Delete.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.object,
  title: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
}

Delete.defaultProps = {
  type: "primary",
  icon: <DeleteOutlined />,
  title: "button.delete",
  onClick: () => { },
  id: "btn-delete"
}

export default injectIntl(Delete);
