import OneSignal from 'react-onesignal'

const init = ({ appId, userId, setInizialized }) => {
  OneSignal.init({
    appId,
    allowLocalhostAsSecureOrigin: true,
    notificationClickHandlerMatch: 'exact',
    notificationClickHandlerAction: 'navigate',
    promptOptions: {
      slidedown: {
        enabled: true,
        autoPrompt: true,
        timeDelay: 5,
        pageViews: 1,
      },
    },
  }).then(() => {
    setInizialized()
    // OneSignal.showSlidedownPrompt().then(() => {

    // })
    if (userId) {
      OneSignal.setExternalUserId(userId.toString())
    }

    OneSignal.isPushNotificationsEnabled((isEnabled) => {
      if (isEnabled) console.log('Push notifications are enabled!')
      else console.log('Push notifications are not enabled yet.')
    })

    OneSignal.on('notificationDisplay', (event) => {
      console.warn('OneSignal notification displayed:', event)
    })

    OneSignal.on('notificationDismiss', (event) => {
      console.warn('OneSignal notification dismissed:', event)
    })

    // OneSignal.addListenerForNotificationOpened((data) => {
    //   console.log('Received NotificationOpened:')
    //   onOpenNotification(data)
    //   console.log(data)
    // })
  })

  return OneSignal
}

export default { init }
