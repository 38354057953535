import React from 'react'
import { Button, Space } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'

import { checkPermission } from 'redux/user/selectors'
import ProfileMenu from './ProfileMenu'
import LanguageSelector from './LanguageSelector'
import CompanySelector from './CompanySelector'
import Actions from './Actions'
import Timelines from './Timelines'
import ThemeColors from "./ThemeColors";

import './style.scss'

const CustomTopBar = ({
  intl,
  translation,
  invoicePermissions,
  purchaseInvoicePermissions,
  expensePermissions,
  isMobileView,
  companies,
  role_id,
  onChangeCompany,
  push,
  darkMode,
}) => {
  const isHasAccessAudit = useSelector((state) => checkPermission(state, 'audits'))

  return (
    <div
      className={`topbar ${darkMode ? 'bg-dark text-light' : 'bg-light'}`}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: darkMode ? '1px solid #1f1f1f' : '1px solid #e4e9f0',
      }}
    >
      {!isMobileView && (
        <Space>
          {invoicePermissions.isCanAdd && (
            <Button
              href="/#/sales/invoices/add"
              icon={<FontAwesomeIcon icon="shopping-basket" className="icon-main-btn" />}
            >
              {intl.formatMessage({ id: 'button.sell' })}
            </Button>
          )}
          {purchaseInvoicePermissions.isCanAdd && (
            <Button
              href="/#/purchases/purchaseinvoices/add"
              icon={<FontAwesomeIcon icon="shopping-bag" className="icon-main-btn" />}
            >
              {intl.formatMessage({ id: 'button.buy' })}
            </Button>
          )}
          {expensePermissions.isCanAdd && (
            <Button
              href="/#/expenses/add"
              icon={<FontAwesomeIcon icon="credit-card" className="icon-main-btn" />}
            >
              {translation.menu
                ? translation.menu.expenses
                : intl.formatMessage({ id: 'financeExpenses.financeExpenses' })}
            </Button>
          )}
          &nbsp;
        </Space>
      )}
      <Space>
        {(companies.companies.length > 1 || role_id === 1) && (
          <div className="mr-4">
            <CompanySelector companies={companies} onChangeCompany={onChangeCompany} push={push} />
          </div>
        )}
        <div className="mr-4">
          <LanguageSelector />
        </div>
        {isHasAccessAudit && (
          <div className="mr-4">
            <Timelines />
          </div>
        )}
        <div className="mr-4">
          <Actions />
        </div>
        <div className="mr-4">
          <ThemeColors />
        </div>
        <ProfileMenu />
      </Space>
    </div>
  )
}

export default CustomTopBar
