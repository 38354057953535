import React from 'react'
import { injectIntl } from 'react-intl'
import { Select } from 'antd'
import { EditorState, Modifier } from 'draft-js'
import { connect } from 'react-redux'

import { getTerm } from 'utils/hooks/useTerm'

const { Option, OptGroup } = Select

class TagsOption extends React.Component {
  componentDidMount() {
    if (this.props.emailId) {
      this.props.getTagsHandler(this.props.emailId)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.emailId !== prevProps.emailId && this.props.emailId) {
      this.props.getTagsHandler(this.props.emailId)
    }
  }

  changeHandler = (value) => {
    const { editorState, onChange } = this.props
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      `[${value}]`,
      editorState.getCurrentInlineStyle(),
    )
    const newEditorState = EditorState.push(editorState, contentState)

    onChange(EditorState.forceSelection(newEditorState, newEditorState.getSelection()))
  }

  renderOptionGroups() {
    const { tags } = this.props
    const options = []

    Object.keys(tags).forEach((field) => {
      options.push(
        <OptGroup key={field} label={field}>
          {tags[field].map((row) => {
            return (
              <Option key={row.name} value={row.name}>
                {row.title}
              </Option>
            )
          })}
        </OptGroup>,
      )
    })

    return options
  }

  render() {
    const { loading, intl, term, locale } = this.props
    const _termPlural = getTerm({ term, intl, plural: locale === 'en-US' })
    return (
      <div className="rdw-tags-wrapper">
        <Select
          loading={loading}
          value={undefined}
          showSearch
          placeholder={intl.formatMessage(
            { id: 'emails.insert_tagsment_with_title' },
            {
              tag: _termPlural.tag,
            },
          )}
          onChange={this.changeHandler}
        >
          {this.renderOptionGroups()}
        </Select>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    term: state.translation.term,
    locale: state.settings.locale,
  }
}

export default connect(mapStateToProps)(injectIntl(TagsOption))
