import React, { createRef, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Form, message, Modal, Popover, Skeleton, Space, Switch } from 'antd'
import { injectIntl } from 'react-intl'
import _ from 'lodash'
import styled from 'styled-components'
import { push, replace } from 'connected-react-router'
import { stringify } from 'query-string'
import { connect, useDispatch, useSelector } from 'react-redux'

import { Cancel, Export, Filter as ButtonFilter, LinkTransType, ShowColumn } from 'components/UI'
import { currency, formatDate, qtyToCurrency } from 'utils/formatting'
import { useProductStockMovements } from 'utils/queries/product'
import { cleanBlankValue } from 'utils/helper'
import { desktopLayoutSelector, financeConfigSelector, locationSelector } from 'redux/selectors'
import { dbFormat, transType } from 'utils/static'
import { exportFinanceProductStockMovements } from 'utils/apis'
import { useTerm } from 'utils/hooks'
import { editOptions } from 'redux/options/actions'
import { ParseURIToParams } from 'utils/parseURIToParams'
import DataTable from './DataTable'
import FilterForm from './FilterFormTransaction'
import { useProductDetailContext } from './Context'

const prefixQuery = `stock_movements`

const TABLE_NAME = 'stock-movement'

const DEFAULT_COLUMNS = 'trans_date,trans_type,reference,desc,price,avg_price,qty_movement,qty'

const HPPSkeleton = styled(Skeleton)`
  .ant-skeleton-content {
    display: flex;
    align-items: center;
  }

  .ant-skeleton-title {
    margin: 0;
  }
`
const filterParams = {
  date_from: null,
  date_to: null,
  tags: [],
  trans_type_ids: [],
}
const initialParams = {
  page: 1,
  sort_by: null,
  order_by: null,
  per_page: null,
  ...filterParams,
}

export const LoadingInvalidData = injectIntl(({ intl }) => {
  return (
    <Popover
      content={intl.formatMessage({ id: 'financeProduct.data_is_being_processed' })}
      className="d-flex"
    >
      {Array(3)
        .fill(undefined)
        .map((__, index) => (
          <HPPSkeleton
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            active
            title={{
              style: { borderRadius: 999999, width: 12, height: 12 },
            }}
            paragraph={{ rows: 0 }}
            className="mx-1 d-flex align-items-center"
          />
        ))}
    </Popover>
  )
})

const StockMovements = ({
  intl,
  permissions,
  unit,
  id,
  isMobileView,
  enabledQuery,
  editOption,
  showColumns = DEFAULT_COLUMNS,
  isPrint,
}) => {
  const dispatch = useDispatch()
  const filterFormRef = useRef()
  const showColumnFormRef = createRef()
  const location = useSelector(locationSelector)
  const [showExport, setShowExport] = useState(false)

  const [isHasInvalid, setIsHasInvalid] = useState(false)
  const [selectedTags, setSelectedTags] = useState(() => {
    let newTags = []
    if (!_.isEmpty(location.query?.stock_movements_tags)) {
      newTags = JSON.parse(decodeURIComponent(location.query?.stock_movements_tags))
    }
    return newTags
  })

  const {
    params: { [TABLE_NAME]: params },
    updateParams,
  } = useProductDetailContext()

  const transTypeFromRedux = useSelector(financeConfigSelector)?.trans_type ?? []

  const getTransType = useCallback(
    (idOnly) => {
      const transTypeIds = [
        transType.INVOICE,
        transType.RETURN,
        transType.DELIVERY,
        transType.PURCHASE_INVOICE,
        transType.PURCHASE_RETURN,
        transType.PURCHASE_DELIVERY,
        transType.PRODUCT_CONVERSION,
        transType.STOCK_ADJUSTMENT,
        transType.WAREHOUSE_TRANSFER,
      ]

      return transTypeFromRedux
        .filter((_transType) => transTypeIds.includes(_transType.id))
        .map((_transType) => {
          if (idOnly) {
            return _transType.id.toString()
          }

          return {
            value: _transType.id.toString(),
            label: _transType.name,
          }
        })
    },
    [transTypeFromRedux],
  )

  useEffect(() => {
    let newInitialParams = { ...initialParams }

    if (!_.isEmpty(location.query)) {
      const query = {}
      Object.entries(location.query).forEach(([key, value]) => {
        query[_.replace(key, `${prefixQuery}_`, '')] = decodeURIComponent(value)
      })

      const newParams = new ParseURIToParams(query)

      newParams.date('date_from')
      newParams.date('date_to')
      newParams.page()
      newParams.per_page()
      newParams.orderBy()
      newParams.string('sort_by', { validValues: ['trans_date'] })
      newParams.array('tags', {
        idOnly: true,
      })
      newParams.array('trans_type_ids')
      newParams.showColumns('trans_date,trans_type,desc,reference,price,avg_price,qty_movement,qty')

      newInitialParams = { ...newInitialParams, ...newParams.build() }
    }

    updateParams({
      tableName: TABLE_NAME,
      params: newInitialParams,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isDesktopView = useSelector(desktopLayoutSelector)

  const term = useTerm({ intl })

  const parseParams = useMemo(
    () =>
      cleanBlankValue({
        ...params,
        tag_ids: Array.isArray(params.tags) ? params.tags.join(',') : [],
        trans_type_ids: Array.isArray(params.trans_type_ids) ? params.trans_type_ids.join(',') : [],
        tags: null,
      }),
    [params],
  )

  const { data, isLoading: loading } = useProductStockMovements({
    payload: { params: parseParams, id },
    options: {
      enabled: enabledQuery,
      refetchInterval: isHasInvalid ? 10 * 1000 : false,
    },
    showColumns: showColumns || DEFAULT_COLUMNS,
  })

  const formattedData = useMemo(() => {
    if (!data) return { data: [] }

    return {
      ...data,
      data: (data.data || []).map((row, index) => ({ ...row, key: `${index + 1}` })),
    }
  }, [data])

  useEffect(() => {
    let _isHasInvalid = false

    // Check if data has invalid counting
    formattedData.data.forEach((item) => {
      if (!item.valid) _isHasInvalid = true
    })

    if (_isHasInvalid) {
      setIsHasInvalid(_isHasInvalid)
      // setEnabledRN(true)
    }
  }, [formattedData])

  const parseParamsToUri = useCallback(
    (newParams) => {
      const mergedParams = { ...params, ...newParams }
      if (!_.isEmpty(selectedTags)) {
        mergedParams.tags = Array.isArray(selectedTags) ? JSON.stringify(selectedTags) : null
      } else {
        mergedParams.tags = null
      }

      if (_.has(newParams, 'tags') && _.isEmpty(newParams.tags)) {
        mergedParams.tags = null
      }

      if (mergedParams.page === 1) mergedParams.page = null

      if (mergedParams.per_page === 10) mergedParams.per_page = null

      if (!_.isEmpty(mergedParams.trans_type_ids)) {
        mergedParams.trans_type_ids = JSON.stringify(mergedParams.trans_type_ids)
      }

      const newObject = {}
      Object.keys(mergedParams).forEach((key) => {
        newObject[`${prefixQuery}_${key}`] = mergedParams[key]
      })

      const otherQuery = {}
      if (!_.isEmpty(location.query)) {
        Object.keys(location.query).forEach((key) => {
          if (!_.includes(key, `${prefixQuery}_`)) {
            if (_.includes(key, 'page')) {
              otherQuery[key] = parseInt(location.query[key], 10)
            } else if (
              (_.includes(key, 'tags') || _.includes(key, 'trans_type_ids')) &&
              !_.isEmpty(location.query[key])
            ) {
              otherQuery[key] = decodeURIComponent(location.query[key])
            } else otherQuery[key] = location.query[key]
          }
        })
      }

      const payload = cleanBlankValue(newObject)
      const stockMovementQuery = !_.isEmpty(payload)
        ? stringify(payload, { arrayFormat: 'bracket' })
        : ''
      const joinQuery = !_.isEmpty(otherQuery)
        ? stringify(otherQuery, { arrayFormat: 'bracket' })
        : ''
      let query = ''
      if (!_.isEmpty(stockMovementQuery) || !_.isEmpty(joinQuery)) {
        query = '?'
        if (!_.isEmpty(stockMovementQuery)) {
          query = `${query}${stockMovementQuery}`
        }
        if (!_.isEmpty(joinQuery)) {
          query = `${query}&${joinQuery}`
        }
      }
      dispatch(replace(`${location.pathname}${query}`))
    },
    [dispatch, location.pathname, location.query, params, selectedTags],
  )

  const parseColumns = useMemo(() => {
    const columnsArray = showColumns.split(',')
    const columnChanges = {
      price: 'hpp',
      qty: 'quantity',
    }

    const updatedColumnsArray = columnsArray.map((columnName) => {
      if (columnChanges[columnName]) {
        return columnChanges[columnName]
      }
      return columnName
    })

    const newShowColumns = updatedColumnsArray.join(',')
    const columns = []

    if (newShowColumns.includes('trans_date')) {
      columns.push({
        title: intl.formatMessage({ id: 'financeProduct.date' }),
        dataIndex: 'trans_date',
        key: 'trans_date',
        render: (text) => formatDate(text),
        sorter: true,
      })
    }

    if (newShowColumns.includes('trans_type')) {
      columns.push({
        title: intl.formatMessage({ id: 'financeProduct.transaction' }),
        dataIndex: 'trans_type',
        key: 'trans_type',
        width: '10%',
        render: (text, row) => {
          if (isPrint) return text

          return (
            <LinkTransType
              title={text}
              transTypeId={row.trans_type_id}
              data={{ id: row.business_tran_id, warehouseId: row.warehouse_id }}
            />
          )
        },
      })
    }

    if (newShowColumns.includes('desc')) {
      columns.push({
        title: intl.formatMessage({ id: 'financeProduct.description' }),
        dataIndex: 'desc',
        key: 'desc',
        width: '15%',
        render: (text, row) => {
          if (isPrint) return text

          return (
            <LinkTransType
              title={text}
              transTypeId={row.trans_type_id}
              data={{ id: row.business_tran_id, warehouseId: row.warehouse_id }}
            />
          )
        },
      })
    }

    if (newShowColumns.includes('reference')) {
      columns.push({
        title: term.reference,
        dataIndex: 'reference',
        key: 'reference',
      })
    }

    if (permissions.product_show_hpp) {
      if (newShowColumns.includes('hpp')) {
        columns.push({
          title: intl.formatMessage({ id: 'financeProduct.price' }),
          dataIndex: 'price',
          key: 'price',
          render: (text) => currency(text, false, true),
          className: 'text-right',
        })
      }

      if (newShowColumns.includes('avg_price')) {
        columns.push({
          title: intl.formatMessage({ id: 'financeProduct.avg_base_price' }),
          dataIndex: 'avg_price',
          key: 'avg_price',
          render: (text, row) => {
            if (!row.valid) return <LoadingInvalidData />
            return currency(text, false, true)
          },
          className: 'text-right',
        })
      }
    }

    if (newShowColumns.includes('qty_movement')) {
      columns.push({
        title: intl.formatMessage({ id: 'financeProduct.stock_movement' }),
        dataIndex: 'qty_movement',
        key: 'qty_movement',
        className: 'text-right',
        render: (text) => {
          const formatText = qtyToCurrency(text)
          if (text < 0) {
            return <span style={{ color: '#CF2A28' }}>{formatText}</span>
          }

          if (text === 0) {
            return <span style={{ color: '#11A428' }}>{formatText}</span>
          }

          return <span style={{ color: '#11A428' }}>{`+${formatText}`}</span>
        },
      })
    }

    if (newShowColumns.includes('quantity')) {
      columns.push({
        title: intl.formatMessage({ id: 'financeProduct.stock' }),
        dataIndex: 'qty',
        key: 'qty',
        className: 'text-right',
        width: '15%',
        render: (text) => `${qtyToCurrency(text)} ${unit?.name}`,
      })
    }

    return columns
  }, [intl, isPrint, permissions.product_show_hpp, term, unit.name, showColumns])

  const showExportHandler = useCallback(() => {
    setShowExport(true)
  }, [])

  const hideExportHandler = useCallback(() => {
    setShowExport(false)
  }, [])

  const exportStockMovementHandler = useCallback(
    (e, payload) => {
      e.preventDefault()
      exportFinanceProductStockMovements(id, {
        ...params,
        ...payload,
        per_page: 1000,
      })
        .then((response) => {
          const downloadLink = document.createElement('a')
          const blob = new Blob([response.data], { type: response.headers['content-type'] })
          downloadLink.href = URL.createObjectURL(blob)

          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          const matches = filenameRegex.exec(response.headers['content-disposition'])
          if (matches?.[1]) {
            downloadLink.download = matches[1].replace(/['"]/g, '')
          }
          downloadLink.click()
        })
        .catch(() => {
          message.error(intl.formatMessage({ id: 'financeProduct.error_cannot_export' }))
        })
    },
    [id, intl, params],
  )

  const renderExportRow = useCallback(() => {
    const rows = []
    const total = data?.total || 0

    let length = total
    for (let i = 0; length > 0; i += 1) {
      length -= 1000

      const lastRow = length <= 0 ? total : (i + 1) * 1000
      const rowCount = `${i * 1000 + 1} - ${lastRow}`
      rows.push(
        <tr key={length} className="ant-table-row">
          <td>{`${intl.formatMessage({ id: 'financeProduct.row' })} ${rowCount}`}</td>
          <td>
            <a
              href="#"
              className="text-primary"
              onClick={(e) => exportStockMovementHandler(e, { export: 'xls', page: `${i + 1}` })}
            >
              Download xls
            </a>
          </td>
          <td>
            <a
              href="#"
              className="text-primary"
              onClick={(e) => exportStockMovementHandler(e, { export: 'csv', page: `${i + 1}` })}
            >
              Download csv
            </a>
          </td>
        </tr>,
      )
    }

    return rows
  }, [data, exportStockMovementHandler, intl])

  const isDirtyFilter = useMemo(() => {
    const currentParams = {
      tags: params.tags,
      date_from: params.date_from,
      date_to: params.date_to,
      trans_type_ids: params.trans_type_ids,
    }
    return !_.isEqual(filterParams, currentParams)
  }, [params])

  const paramsChangeHandler = useCallback(
    (newParams) => {
      updateParams({
        tableName: TABLE_NAME,
        params: {
          ...params,
          ...newParams,
        },
      })
      parseParamsToUri(newParams)
    },
    [params, parseParamsToUri, updateParams],
  )

  const filterVisibleChangeHandler = useCallback(
    (visible) => {
      if (!visible) {
        const form = filterFormRef.current
        form.validateFields().then((values) => {
          const newParams = {
            tags: values.tags || [],
            date_from: !_.isEmpty(values.date) ? values.date[0].format(dbFormat) : null,
            date_to: !_.isEmpty(values.date) ? values.date[1].format(dbFormat) : null,
            trans_type_ids: values.trans_type_ids || [],
          }

          const oldParams = {
            tags: params.tags,
            date_from: params.date_from,
            date_to: params.date_to,
            trans_type_ids: params.trans_type_ids,
          }

          if (!_.isEqual(oldParams, newParams)) {
            paramsChangeHandler({ page: 1, ...newParams })
          }
        })
      }
    },
    [params.date_from, params.date_to, params.tags, params.trans_type_ids, paramsChangeHandler],
  )

  const pageChangeHandler = useCallback(
    (page, pageSize) => {
      paramsChangeHandler({ page, per_page: pageSize })
    },
    [paramsChangeHandler],
  )

  const pageSizeChangeHandler = useCallback(
    (page, size) => {
      paramsChangeHandler({ per_page: size })
    },
    [paramsChangeHandler],
  )

  const tableChangeHandler = useCallback(
    (pagination, filters, sorter) => {
      let orderBy = ''
      if (sorter.order === 'ascend') {
        orderBy = 'asc'
      } else if (sorter.order === 'descend') {
        orderBy = 'desc'
      }
      paramsChangeHandler({ order_by: orderBy, sort_by: sorter.columnKey || '' })
    },
    [paramsChangeHandler],
  )

  const clearFilterHandler = useCallback(() => {
    if (!_.isEmpty(selectedTags)) {
      setSelectedTags([])
    }
    paramsChangeHandler(filterParams)

    if (filterFormRef.current) {
      filterFormRef.current.setFieldsValue({ tags: [], date: undefined, trans_type_ids: [] })
    }
  }, [paramsChangeHandler, selectedTags])

  const selectTagHandler = useCallback((value, options, selected) => {
    setSelectedTags((prevState) => {
      return [...prevState, selected]
    })
  }, [])

  const deselectTagHandler = useCallback((value) => {
    setSelectedTags((prevState) => {
      const newFilterdTags = prevState.filter((row) => row.id !== value)
      return [...newFilterdTags]
    })
  }, [])

  const clearTagHandler = useCallback(() => {
    setSelectedTags([])
  }, [])

  const renderFilter = useMemo(() => {
    const _currentParams = {
      date_from: params.date_from,
      date_to: params.date_to,
      tags: params.tags,
      trans_type_ids: params.trans_type_ids,
    }
    return (
      <FilterForm
        onSelectTag={selectTagHandler}
        onDeselectTag={deselectTagHandler}
        onClearTag={clearTagHandler}
        withTag
        withTransType
        ref={filterFormRef}
        params={_currentParams}
        selectedTags={selectedTags}
        transType={getTransType()}
      />
    )
  }, [
    getTransType,
    clearTagHandler,
    deselectTagHandler,
    params.date_from,
    params.date_to,
    params.tags,
    params.trans_type_ids,
    selectTagHandler,
    selectedTags,
  ])

  const onChangeShowColumns = useCallback(
    async (payload) => {
      if (showColumns !== payload) {
        await editOption(
          {
            show_columns_stock_movements: payload,
          },
          intl.formatMessage({ id: 'financeProduct.change_column_display' }),
          intl.formatMessage({ id: 'financeProduct.column_display_changed_successfully' }),
        )
      }
    },
    [editOption, intl, showColumns],
  )

  const renderShowColumns = () => {
    const columns = [
      { label: intl.formatMessage({ id: 'financeProduct.date' }), name: 'trans_date' },
      { label: intl.formatMessage({ id: 'financeProduct.transaction' }), name: 'trans_type' },
      { label: intl.formatMessage({ id: 'financeProduct.description' }), name: 'desc' },
      { label: term.reference, name: 'reference' },
      { label: intl.formatMessage({ id: 'financeProduct.price' }), name: 'price' },
      { label: intl.formatMessage({ id: 'financeProduct.avg_base_price' }), name: 'avg_price' },
      { label: intl.formatMessage({ id: 'financeProduct.stock_movement' }), name: 'qty_movement' },
      { label: intl.formatMessage({ id: 'financeProduct.stock' }), name: 'qty' },
    ]

    const initialValues = {}
    const defaultColumns = showColumns || DEFAULT_COLUMNS
    const _showColumns = defaultColumns ? showColumns.split(',') : []
    _showColumns.forEach((column) => {
      initialValues[column] = true
    })

    return (
      <Form ref={showColumnFormRef} initialValues={initialValues}>
        <b>{intl.formatMessage({ id: 'table.show_data' })}</b>
        {columns.map((col) => {
          return (
            <Space key={col.name} className="d-flex mt-2">
              <Form.Item noStyle name={col.name} valuePropName="checked">
                <Switch size="small" />
              </Form.Item>
              {col.label}
            </Space>
          )
        })}
      </Form>
    )
  }

  const showColumnsVisibleChangeHandler = (visible) => {
    if (!visible) {
      const form = showColumnFormRef.current
      form
        .validateFields()
        .then((values) => {
          const newCol = ['id']
          Object.keys(values).forEach((val) => {
            if (values[val]) {
              newCol.push(val)
            }
          })
          if (newCol.length === 1) {
            message.warning(intl.formatMessage({ id: 'table.min_one_column' }))
            form.resetFields()
            return
          }
          if (onChangeShowColumns) {
            onChangeShowColumns(newCol.join(','))
          }
        })
        .catch((info) => {
          if (info.errorFields) {
            form.scrollToField(info.errorFields[0].name)
          }
        })
    }
  }

  return (
    <>
      <div className="row mt-3 mb-3">
        <div className="col-md-6">
          <Popover
            style={{ alignItems: 'center' }}
            placement="bottomLeft"
            content={renderFilter}
            trigger="click"
            onOpenChange={filterVisibleChangeHandler}
          >
            <ButtonFilter showClearBtn={isDirtyFilter} onClear={clearFilterHandler} />
          </Popover>
          <Popover
            style={{ alignItems: 'center' }}
            placement="bottomLeft"
            content={renderShowColumns()}
            trigger="click"
            onOpenChange={showColumnsVisibleChangeHandler}
          >
            <ShowColumn style={{ marginLeft: 8 }} />
          </Popover>
        </div>
        <div className="col-md-6 d-flex justify-content-end">
          <Export onClick={showExportHandler} />
        </div>
      </div>
      <DataTable
        data={formattedData}
        columns={parseColumns}
        loading={loading}
        onPageChange={pageChangeHandler}
        isMobileView={isMobileView}
        onTableChange={tableChangeHandler}
        onPageSizeChange={pageSizeChangeHandler}
        scroll={parseColumns.length > 7 && isDesktopView ? { x: 1200 } : undefined}
        mobileTableByPassEmptyValue
      />
      {showExport && (
        <Modal
          title={`${intl.formatMessage({ id: 'button.export' })} ${intl.formatMessage({
            id: 'financeProduct.stock_movement',
          })}`}
          open={showExport}
          onCancel={hideExportHandler}
          maskTransitionName=""
          footer={[<Cancel key={0} onClick={hideExportHandler} />]}
          destroyOnClose
        >
          <table className="ant-table-custom w-100">
            <tbody className="ant-table-tbody-custom">{renderExportRow()}</tbody>
          </table>
        </Modal>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    showColumns: state.options.options.show_columns_stock_movements,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    push: (path) => dispatch(push(path)),
    editOption: (payload, loadingMessage, successMessage) =>
      dispatch(editOptions(payload, loadingMessage, successMessage)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(memo(injectIntl(StockMovements))))
