import React from 'react'
import { Button, Form, Input } from 'antd'
import { ChromePicker } from 'react-color'
import _ from 'lodash'
import { injectIntl } from 'react-intl'

import styles from './styles.module.scss'

class FinanceTagForm extends React.Component {
  state = {
    color: '#E4E9F0',
  }

  nameRef = React.createRef()

  componentDidMount() {
    const { innerRef } = this.props
    if (innerRef.current) {
      innerRef.current.resetFields()
    }
    if (this.nameRef.current) {
      setTimeout(() => this.nameRef.current.focus(), 200)
    }
    if (this.props.show && this.props.tag) {
      this.setState({ color: this.props.tag?.color ?? '#E4E9F0' })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show) {
      if (this.props.show) {
        const form = this.props.innerRef.current
        if (form) {
          form.resetFields()
        }
      }
    }
    if (this.props.show && !_.isEqual(this.props.tag, prevProps.tag)) {
      this.setState({ color: this.props.tag?.color ?? '#E4E9F0' })
    }
  }

  render() {
    const { tag, intl, innerRef } = this.props
    const { color, displayColorPicker } = this.state

    return (
      <Form
        id="tagForm"
        ref={innerRef}
        layout="vertical"
        initialValues={{
          name: tag?.name ?? null,
          color: tag?.color ?? color,
        }}
        onFinish={this.props.submitHandler}
      >
        <Form.Item
          label={intl.formatMessage({ id: 'financeTag.name' })}
          name="name"
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'financeTag.please_input_the_tag_name' }),
            },
            {
              max: 50,
              message: intl.formatMessage({ id: 'financeTag.max_characters' }, { value: 50 }),
            },
            {
              min: 2,
              message: intl.formatMessage({ id: 'financeTag.min_characters' }, { value: 2 }),
            },
            {
              pattern: /^[\w\s.]+$/,
              message: intl.formatMessage({ id: 'financeTag.only_alphanumeric_allowed' }),
            },
          ]}
        >
          <Input
            ref={this.nameRef}
            placeholder={intl.formatMessage({ id: 'financeTag.name' })}
            autoFocus
          />
        </Form.Item>
        <Form.Item name="color" label={intl.formatMessage({ id: 'tags.choose_a_color' })}>
          <Button
            htmlType="button"
            className={styles.buttonPickerColor}
            onClick={() =>
              this.setState((prevState) => ({
                ...prevState,
                displayColorPicker: !prevState.displayColorPicker,
              }))
            }
          >
            <div style={{ '--tag-color': color }} />
            <p className="mb-0 ml-2 mr-1">{color}</p>
          </Button>
          {displayColorPicker ? (
            <div className={styles.picker}>
              <button
                type="button"
                className={styles.overlay}
                onClick={() => this.setState({ displayColorPicker: false })}
              />
              <ChromePicker
                color={color}
                disableAlpha
                onChange={(newColor) => {
                  innerRef.current.setFieldValue('color', newColor.hex)
                  this.setState({ color: newColor.hex })
                }}
              />
            </div>
          ) : null}
        </Form.Item>
      </Form>
    )
  }
}

const Component = injectIntl(FinanceTagForm)

export default React.forwardRef((props, ref) => <Component innerRef={ref} {...props} />)
