import antdData from 'antd/lib/locale/id_ID'
import localeData from 'react-intl/locale-data/id'

const messages = {
  // Dynamic Translation
  'text.choose': 'Pilih {title}',
  'text.select': 'Pilih {title}',
  'text.please_choose': 'Silahkan pilih {title}',
  'button.add_with_title': 'Tambah {title}',
  'report.name_with_title': 'Nama {title}',
  'report.product_name_with_title': 'Nama {title}',
  'report.product_category_with_title': 'Kategori {title}',
  'report.total_quantity_product_with_title': 'Total kuantitas {title}',
  'report.finished_product_with_title': '{title} Jadi',
  'report.search_by_product_with_title': 'Cari {title}',
  'report.product_value_with_title': 'Nilai {title}',
  'report.product_profitability_stock_tracking_product_with_title': '{title} Lacak Stok',
  'report.product_profitability_non_stock_tracking_product_with_title': '{title} Tanpa Lacak Stok',
  'report.product_profitability_bundle_product_with_title': '{title} Paket',
  'sales.product_sales_with_title': 'Penjualan Per {title}',
  'sales.sales_contact_with_title': 'Penjualan Per {title}',
  'financeInvoices.product_type_with_title': 'Jenis {title}',
  'financeInvoices.select_product_with_title': 'Pilih {title}',
  'financeInvoices.please_select_tag_with_title': 'Silahkan pilih {title}',
  'financeWareHouses.total_products_with_title': 'Total {title}',
  'financeWareHouses.total_product_value_with_title': 'Total Nilai {title}',
  'financeWareHouses.ref_number_help_with_title':
    'Isikan {title} untuk catatan internal dan mempermudah pencarian.',
  'financeInvoices.used_to_create_transaction_categories_with_title':
    'Digunakan untuk membuat kategori transaksi. Anda juga bisa membuat laporan berdasarkan {title} ini.',
  'financeWareHouses.please_select_a_product_with_title': 'Silakan pilih {title}.',
  'financeWareHouses.header_column_hpp_help_with_title':
    'Ini adalah harga perolehan dari {title}, atau harga beli.',
  'financeInvoices.product_code_that_not_exist_will_be_inserted_as_a_new_product_with_title':
    'Kode SKU {product} yang belum ada di Kledo akan dimasukkan sebagai {product} baru.',
  'financeProduct.available_stock_product_with_title': '{product} Stok Tersedia',
  'financeProduct.almost_empty_stock_product_with_title': '{product} Stok Hampir Habis',
  'financeProduct.empty_stock_product_with_title': '{product} Stok Habis',
  'financeProduct.total_product_value_with_title': 'Total Nilai {product}',
  'financeProduct.total_product_type_with_title': 'Total Jenis {product}',
  'financeProduct.product_type_per_category_with_title': 'Jenis {product} per Kategori',
  'financeProduct.product_availability_with_title': 'Ketersediaan {product}',
  'financeProduct.product_image_with_title': 'Gambar {product}',
  'financeProduct.show_product_image_with_title': 'Tampilkan Gambar {product}',
  'financeProduct.hide_product_image_with_title': 'Sembunyikan Gambar {product}',
  'financeProduct.raw_material': 'Bahan baku:',
  'financePurchaseOrders.please_select_a_product_with_title': 'Silakan pilih {product}.',
  'financeProduct.package_product_items_with_title': 'Paket {product} terdiri dari :',
  'financeProduct.manufacture_product_items_with_title': '{product} manufaktur terdiri dari :',
  'priceRule.applies_to_the_following_customers_with_title': 'Berlaku Pada {customer} Berikut',
  'priceRule.applies_to_the_following_products_with_title': 'Berlaku Pada {product} Berikut',
  'priceRule.all_customer_with_title': 'Semua {customer}',
  'priceRule.customer_group_with_title': 'Grup {customer}',
  'priceRule.per_customer_with_title': 'Per {customer}',
  'priceRule.customer_groups_that_will_get_discount_with_title':
    'Grup {customer} yang akan mendapatkan diskon',
  'priceRule.customers_that_will_get_discount_with_title':
    '{customer} yang akan mendapatkan diskon',
  'priceRule.all_product_with_title': 'Semua {product}',
  'priceRule.product_category_with_title': 'Kategori {product}',
  'priceRule.per_product_with_title': 'Per {product}',
  'priceRule.discounted_product_category_with_title': 'Kategori {product} yang didiskon',
  'priceRule.discounted_product_with_title': '{product} yang didiskon',
  'posConnect.default_customer_with_title': '{customer} Default',
  'posConnect.please_select_default_customer_with_title': 'Silakan pilih {customer} Default',
  'posConnect.transaction_from_pos_inputed_as_this_customer_with_title':
    'Transaksi dari POS akan dicatatkan sebagai transaksi dari {customer} ini',
  'posConnect.service_charge_product_with_title': '{product} Service Charge',
  'posConnect.please_select_service_charge_product_with_title':
    'Silakan pilih {product} Service Charge',
  'posConnect.if_service_charge_inputes_as_this_product_with_title':
    'Jika terdapat service charge, akan dicatatkan sebagai pembelian pada {product} ini',
  'posConnect.custom_product_with_title': '{product} Custom',
  'posConnect.please_select_custom_product_with_title': 'Silakan pilih {product} Custom',
  'posConnect.if_custom_product_inputes_as_this_product_with_title':
    'Jika terdapat pembelian dengan {product} custom, akan dicatatkan pada {product} ini',
  'emails.insert_tagsment_with_title': 'Sisipkan {tag}',
  'layoutInvoice.show_product_with_title': 'Tampilkan {product}',
  'layoutInvoice.show_ref_code_product_with_title': 'Tampilkan SKU {product}',
  'layoutInvoice.show_product_image_with_title': 'Tampilkan Gambar {product}',
  'layoutInvoice.show_tags_with_title': 'Tampilkan {tags}',
  'layoutInvoice.show_reference_with_title': 'Tampilkan {reference}',
  'businessFlow.hide_products_that_are_out_of_stock_with_title':
    'Sembunyikan {products} yang stok habis',
  'businessFlow.hide_products_that_are_out_of_stock_description_with_title':
    'Apakah {products} yang stok habis disembunyikan saat menambah tagihan?',
  'businessFlow.product_price_dont_change_with_title': 'Harga {product} tidak bisa diubah',
  'businessFlow.wether_product_price_can_be_changed_when_add_invoice_with_title':
    'Jika aktif maka harga {product} saat menambah tagihan tidak dapat diubah.',
  'businessFlow.when_product_below_min_stock_email_notification_with_title':
    'Saat stok {product} di bawah stok minimal, akan dikirimkan email notifikasi',
  'bussinesFlow.desc_default_tracking_stock_with_title':
    'Secara default fitur lacak stock aktif saat membuat {product} baru',
  'businessFlow.use_fifo_method_desc_with_title':
    'Metode yang digunakan untuk menghitung HPP. Jika aktif akan menggunakan metode FIFO, jika tidak aktif akan menggunakan metode Averaging. Pengaturan ini tidak dapat diubah jika telah terdapat {product} yang aktif lacak stok nya. Pengaturan hanya tersedia di paket Elite.',
  'businessFlow.auto_update_product_buy_price_with_title': 'Auto update harga beli {product}',
  'businessFlow.auto_update_product_buy_price_desc_with_title':
    'Saat melakukan pembelian {product}, otomatis harga beli-nya dicatatkan ke produk sebagai harga beli default',
  'businessFlow.auto_update_product_sell_price_with_title': 'Auto update harga jual {product}',
  'businessFlow.auto_update_product_sell_price_desc_with_title':
    'Saat melakukan penjualan {product}, otomatis harga jual-nya dicatatkan ke produk sebagai harga jual default',
  'businessFlow.decline_product_conv_if_stock_not_enough_with_title':
    'Tolak konversi {product} jika stok kurang',
  'businessFlow.wether_decline_add_product_conv_if_stock_not_enough_with_title':
    'Apakah tolak penambahan konversi {product} jika stok bahan baku tidak mencukupi?',
  'businessFlow.show_tag_with_title': 'Tampilkan {tag}',
  'businessFlow.show_tag_desc_with_title': 'Apakah input {tag} ditampilkan saat membuat invoice?',
  'businessFlow.input_tag_is_required_with_title': 'Input {tag} wajib diisi',
  'businessFlow.input_tag_is_required_desc_with_title':
    'Apakah {tag} wajib diisi saat menambahkan transaksi penjualan?',
  'businessFlow.manual_journal_input_tag_is_required_desc_with_title':
    'Apakah {tag} wajib diiisi saat menambahkan transaksi jurnal umum?',
  'businessFlow.purchase_input_tag_is_required_desc_with_title':
    'Apakah {tag} wajib diiisi saat menambahkan transaksi tagihan pembelian?',
  'businessFlow.expense_input_tag_is_required_desc_with_title':
    'Apakah {tag} wajib diiisi saat menambahkan transaksi biaya?',
  'businessFlow.bank_input_tag_is_required_desc_with_title':
    'Apakah {tag} wajib diiisi saat menambahkan transaksi bank?',
  'businessFlow.product_conversion_input_tag_is_required_desc_with_title':
    'Apakah {tag} wajib diiisi saat menambahkan transaksi produk konversi?',
  'businessFlow.show_reference_with_title': 'Tampilkan {reference}',
  'businessFlow.show_reference_desc_with_title':
    'Apakah input {reference} ditampilkan saat membuat invoice?',
  'businessFlow.reference_multiline_with_title': 'Multiline {reference}',
  'businessFlow.reference_multiline_desc_with_title': 'Apakah {reference} bisa banyak baris?',
  'financeProduct.bundle_type_id_with_title': 'Tipe {product}',
  'salesPerson.show_sales_person_with_title': 'Tampilkan {sales_person}',
  'salesPerson.hide_sales_person_with_title': 'Sembunyikan {sales_person}',
  'salesPerson.select_sales_person_with_title': 'Pilih {sales_person}',
  'salesPerson.faq_answer_give_different_commission_with_title':
    'Anda dapat mengatur besaran komisi tiap {sales_person} melalui {here}. Selain itu komisi juga bisa diatur berbeda untuk masing-masing produk.',
  'businessFlow.wether_save_sales_person_to_sales_with_title':
    'Apakah mencatatkan {sales_person} pada penjualan?',
  'businessFlow.default_trans_date_follow_parent':
    'Default tanggal transaksi sama dengan tanggal transaksi asal',
  'businessFlow.default_trans_date_follow_parent_desc':
    'Jika ya, maka saat membuat tagihan dari pemesanan, tanggal transaksi defaultnya sama dengan tanggal pemesanan. Jika tidak, tanggal transaksi adalah tanggal hari ini.',

  'financeInvoices.with_credit_payment_with_title': 'Dengan {credit}',
  'financeInvoices.with_debit_payment_with_title': 'Dengan {debit}',
  'financePurchaseInvoices.pay_with_credit_memo_with_title': 'Dibayar dengan {credit}',
  'financePurchaseInvoices.please_select_with_credit_memo_with_title':
    'Silahkan pilih dibayar dengan {credit}',
  'financePurchaseInvoices.pay_with_debit_memo_with_title': 'Dibayar dengan {debit}',
  'financePurchaseInvoices.please_select_with_debit_memo_with_title':
    'Silahkan pilih dibayar dengan {debit}',
  'financeContacts.your_net_debt_with_title': 'Net {credit} Anda',
  'financeContacts.you_owe_with_title': 'Anda {credit}',
  'financeContacts.they_owe_with_title': 'Mereka {credit}',
  'financeContacts.your_due_debt_with_title': '{credit} Anda jatuh tempo',
  'financeContacts.their_due_debt_with_title': '{credit} mereka jatuh tempo',
  'financeContacts.debt_you_need_to_pay_with_title': '{credit} yang perlu Anda bayar',
  'financeContacts.max_payable_with_title': 'Maksimal {credit}',
  'financeContacts.total_payable_is_calculated_from_purchase_invoices_with_title':
    'Total {credit} dihitung dari tagihan pembelian, transaksi biaya dan kontak {credit}. Jika total {credit} melebihi maksimal, maka saat membuat tagihan pembelian baru akan ditolak.',
  'financeContacts.max_receivable_with_title': 'Maksimal {debit}',
  'financeContacts.total_accounts_receivable_is_calculated_from_sales_invoices_with_title':
    'Total {debit} dihitung dari tagihan penjualan dan kontak {debit}. Jika total {debit} melebihi maksimal, maka saat membuat tagihan penjualan baru akan ditolak.',
  'financeContacts.payable_account_with_title': 'Akun {credit}',
  'financeContacts.receivable_account_with_title': 'Akun {debit}',
  'financeContacts.awaiting_payment_with_title': '{debit} menunggu pembayaran',
  'creditMemo.add_credit_memo_with_title': 'Tambah {credit}',
  'creditMemo.detail_credit_memo_with_title': 'Detil {credit}',
  'creditMemo.detail_title_with_title': '{credit} {number}',
  'creditMemo.edit_credit_memo_with_title': 'Ubah {credit}',
  'debitMemo.add_debit_memo_with_title': 'Tambah {debit}',
  'debitMemo.detail_debit_memo_with_title': 'Detil {debit}',
  'debitMemo.detail_title_with_title': '{debit} {number}',
  'debitMemo.edit_debit_memo_with_title': 'Ubah {debit}',
  'financeContacts.import_contact_transactions_with_title': 'Import {credit} {debit}',
  'financeContacts.follow_these_steps_to_import_your_contact_transactions_with_title':
    'Ikuti langkah-langkah berikut untuk meng-import {credit} {debit}',
  'financeContacts.1_download_your_contact_transactions_template_file_with_title':
    '1. Download file template {credit} {debit}',
  'financeContacts.start_by_downloading_our_contact_transactions_csv_with_title':
    'Mulai dengan mengunduh file template dalam format Excel. File ini mempunyai kolom header yang yang dibutuhkan Kledo untuk mengimport detail dari {credit} {debit}-mu.',
  'financeContacts.2_copy_your_contact_transactions_into_the_template_with_title':
    '2. Salin detail {credit} {debit} ke dalam template',
  'financeContacts.import_contact_transactions_using_excel_or_another_spreadsheet_editor_with_title':
    'Menggunakan Excel atau software spreadsheeet lainnya, masukkan detail {credit} {debit} ke dalam file template dari Kledo. Pastikan detail {credit} {debit} sesuai dengan kolom header yang ada di template.',
  'financeInvoices.payment_with_credit_memo_with_title': 'Pembayaran dengan {credit}',
  'financeInvoices.payment_with_debit_memo_with_title': 'Pembayaran dengan {debit}',
  'salesCommission.you_can_manage_sales_person_in_this_page_with_title':
    'Anda dapat mengatur komisi penjualan masing-masing {sales_person} pada halaman ini. Jika sudah, {link} untuk melihat laporan komisi sales-nya.',
  'salesCommission.applies_to_the_following_sales_person_with_title':
    'Berlaku Pada {sales_person} Berikut',
  'financeManualJournal.tag_is_required_with_title': '{tag} wajib diisi',
  'financeTag.who_can_access_this_tag_with_title': 'Siapa yang bisa mengakses {tag} ini?',
  'financeProduct.who_can_access_this_product_with_title':
    'Siapa yang bisa mengakses {product} ini?',
  'financeInvoices.recurring_send_email_to_customer_with_title': 'Kirim Email ke {customer}',

  'text.and': 'dan',

  'topBar.changeLanguage': 'Ubah Bahasa',
  'topBar.latestKledoUpdate': 'Update Kledo Terbaru',
  'topBar.dataChangesTimeline': 'Timeline Perubahan Data',
  'topBar.issuesHistory': 'Issues History',
  'topBar.projectManagement': 'Project Management',
  'topBar.typeToSearch': 'Ketik untuk mencari...',
  'topBar.buyNow': 'Buy Now $24',
  'topBar.bitcoin': 'Bitcoin',
  'topBar.profileMenu.hello': 'Halo',
  'topBar.profileMenu.billingPlan': 'Billing Plan',
  'topBar.profileMenu.role': 'Role',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Telp',
  'topBar.profileMenu.logout': 'Keluar',
  'topBar.billing.plan': 'Paket',
  'topBar.billing.forever': 'Selamanya',
  'topBar.billing.active_until': 'Aktif s/d',

  'notification.updates': 'Updates',
  'notification.timeline': 'Timeline',
  'notification.server_error': 'Server Bermasalah',
  'notification.something_went_wrong':
    'Terjadi masalah di server, silahkan hubungi customer support kami.',
  'notification.install_kledo': 'Install Aplikasi Kledo',
  'notification.kledo_is_now_on_google_play': 'Kledo kini tersedia di Google Play',
  'notification.kledo_is_now_on_app_store': 'Kledo kini tersedia di App Store',
  'notification.install_now': 'Install Sekarang',
  'notification.later': 'Nanti Saja',
  'notification.disable': 'Matikan Notifikasi',
  'notification.enable': 'Nyalakan Notifikasi',
  'notification.low_stock': 'Notifikasi stok habis',
  'notification.marketplace_sync_done': 'Notifikasi sinkronisasi marketplace selesai',
  'notification.bank_sync_done': 'Notifikasi sinkronisasi bank selesai',
  'notification.monthly_financial_report_email': 'Email laporan keuangan bulanan',
  'notification.a_new_version_is_available': 'Versi terbaru tersedia!',
  'notification.please_wait_prepare_to_reload_page':
    'Silahkan tunggu, sistem akan melakukan pemasangan update dalam {countDown} detik. {errorAutoReloadMessage}',
  'notification.autoreload_failed': 'Pemasangan otomatis gagal.',
  'notification.reload_immediately': 'Pasangkan langsung',

  'date.today': 'Hari Ini',
  'date.yesterday': 'Kemarin',
  'date.last_week': 'Minggu Lalu',
  'date.this_month': 'Bulan Ini',
  'date.last_month': 'Bulan Lalu',
  'date.last_7_days': '7 Hari Lalu',
  'date.last_30_days': '30 Hari Lalu',
  'date.last_365_days': '365 Hari Lalu',
  'date.this_year': 'Tahun Ini',
  'date.last_year': 'Tahun Lalu',
  'date.daily': 'Harian',
  'date.monthly': 'Bulanan',
  'date.yearly': 'Tahunan',
  'date.day': 'Hari',
  'date.month': 'Bulan',
  'date.months': 'Bulan',
  'date.year': 'Tahun',
  'date.years': 'Tahun',
  'date.same_date_last_month': 'Tanggal sama bulan lalu',
  'date.same_date_last_year': 'Tanggal sama tahun lalu',

  'button.page': 'Halaman',
  'button.next': 'Lanjut',
  'button.prev': 'Sebelumnya',
  'button.renew': 'Perpanjang',
  'button.revert': 'Kembalikan',
  'button.upgrade': 'Upgrade',
  'button.delete': 'Hapus',
  'button.archive': 'Arsipkan',
  'button.unarchive': 'Batalkan Arsip',
  'button.add': 'Tambah',
  'button.edit': 'Ubah',
  'button.back': 'Kembali',
  'button.search': 'Cari',
  'button.save': 'Simpan',
  'button.save_add_new': 'Simpan & Tambah Baru',
  'button.cancel': 'Batal',
  'button.print': 'Print',
  'button.filter': 'Filter',
  'button.clear_filter': 'Hapus Filter',
  'button.guide': 'Panduan',
  'button.tour': 'Tur',
  'button.video': 'Video',
  'button.article': 'Artikel',
  'button.year': 'Tahun',
  'button.month': 'Bulan',
  'button.close': 'Tutup',
  'button.error_data_': 'Error data!',
  'button.create_something': 'Buat {title}',
  'input.placeholder.search': 'Cari',
  'button.return': 'Retur',
  'button.hello_can_i_help': 'Halo, ada yang bisa saya bantu?',
  'button.schedule_now': 'Jadwalkan Sekarang',
  'popconfirm.title.delete': 'Apakah Anda yakin untuk menghapus?',
  'popconfirm.title.archive': 'Apakah Anda yakin untuk mengarsipkan?',
  'popconfirm.title.unarchive': 'Apakah Anda yakin untuk batalkan arsip?',
  'popconfirm.title.reset': 'Apakah Anda yakin untuk mengatur ulang?',
  'popconfirm.title.void': 'Apakah Anda yakin untuk melakukan void transaksi ini?',
  'popconfirm.title.unvoid': 'Apakah Anda yakin untuk membatalkan void transaksi ini?',
  'breadcrumb.sales': 'Penjualan',
  'breadcrumb.purchases': 'Pembelian',
  'breadcrumb.conversionbalances': 'Saldo Awal',
  'breadcrumb.conversiondate': 'Tanggal Konversi',
  'breadcrumb.add': 'Tambah',
  'breadcrumb.edit': 'Edit',
  'breadcrumb.detail': 'Detil',
  'suggestion.not_found': 'Data tidak ditemukan',
  'spinner.loading': 'Pemuatan...',
  'button.yes': 'Ya',
  'button.no': 'Tidak',
  'button.pdf': 'PDF',
  'button.import': 'Import',
  'button.export': 'Ekspor',
  'button.view_journal_entries': 'Lihat Entri Jurnal',
  'button.add_more': 'Tambah baris',
  'button.print_pdf': 'Cetak PDF',
  'button.print_label': 'Cetak Label',
  'button.print_payment': 'Cetak Rekap Pembayaran',
  'button.print_tax': 'Cetak Faktur Pajak',
  'button.print_partial': 'Cetak per Termin',
  'button.copy_link': 'Salin Link',
  'button.copy': 'Salin',
  'button.send_email': 'Kirim Email',
  'button.send_wa': 'Kirim Whatsapp',
  'button.send_sms': 'Kirim SMS',
  'button.send_email_payment': 'Email Rekap Pembayaran',
  'button.send_wa_payment': 'Whatsapp Rekap Pembayaran',
  'button.send_sms_payment': 'SMS Rekap Pembayaran',
  'button.download_word': 'Download MsWord',
  'button.download_xls': 'Download XLS',
  'button.download_csv': 'Download CSV',
  'button.download_now': 'Download Sekarang',
  'button.send': 'Kirim',
  'button.share': 'Bagikan',
  'button.duplicate': 'Duplikat',
  'button.change_layout': 'Ganti Layout',
  'button.reset': 'Kembali pengaturan awal',
  'button.copy_to_clipboard': 'Salin Link ke Clipboard',
  'button.select_all': 'Pilih Semua',
  'button.deselect_all': 'Hapus Semua',
  'button.change_order': 'Ubah Urutan',
  'button.sell': 'Jual',
  'button.buy': 'Beli',
  'message.cannot_update_data': 'Data tidak dapat diubah.',
  'message.cannot_return_data': 'Data tidak dapat di kembalikan.',
  'message.link_has_been_copied': 'Link berhasil disalin.',
  'message.on': 'di',
  'link.show_more': 'Tampilkan selengkapnya',
  'link.show_less': 'Sembunyikan',
  'link.payment': 'Pembayaran {payment}',
  'link.more': 'selengkapnya',
  'link.add_email': 'Tambah email',
  'link.add_shipping_address': 'Tambah Alamat Pengiriman',

  'companies.company_list': 'Daftar Perusahaan',
  'companies.add_company': 'Tambah Perusahaan',
  'companies.created_on': 'Dibuat',
  'companies.as': 'Sebagai',
  'companies.are_you_sure_to_delete_this_company':
    'Apakah Anda yakin untuk menghapus perusahaan ini?\nSetelah dihapus, data tidak dapat dikembalikan',
  'companies.choose_package': 'Pilih Paket',
  'companies.full_name': 'Nama Lengkap',
  'companies.please_input_full_name': 'Silakan masukkan Nama Lengkap',
  'companies.company_name': 'Nama Perusahaan',
  'companies.please_input_company_name': 'Silakan masukkan Nama Perusahaan',
  'companies.role_on_company': 'Jabatan di Perusahaan Sebagai',
  'companies.please_input_role_on_company': 'Silakan masukkan Jabatan di Perusahaan',
  'companies.number_of_employee': 'Jumlah Karyawan',
  'companies.please_input_number_of_employee': 'Silakan masukkan Jumlah Karyawan',
  'companies.please_input_email': 'Silakan masukkan email',
  'companies.please_input_phone_number': 'Silakan masukkan nomor telepon',
  'companies.phone_number_must_08': 'Nomor telepon harus berawalan 08',
  'companies.next_per_month': 'Selanjutnya {price}/bln',
  'companies.free_forever': 'Gratis Selamanya',

  'table.number': 'No',
  'table.show_data': 'Tampilkan Data',
  'table.rows_selected': '{count} baris dipilih',
  'table.min_one_column': 'Minimal tampil satu kolom',
  'table.data_empty': 'Data Kosong',
  'table.data_x_empty': 'Data {name} Kosong',
  'table.lets_create_your_first_x': 'Yuk mulai buat {name} pertamamu!',

  'nps.how_likely_you_recommend':
    'Seberapa besar kemungkinan Anda akan merekomendasikan Kledo kepada teman atau rekan kerja?',
  'nps.not_likely': 'Sama sekali tidak mungkin',
  'nps.very_likely': 'Sangat mungkin',
  'nps.please_mention_another':
    'Mohon sebutkan hal lain yang ingin Anda sampaikan melalui kotak komentar di bawah',
  'nps.cannot_hide_nps': 'Tidak dapat menutup NPS',
  'nps.cannot_send_nps': 'Tidak dapat mengirim NPS',
  'nps.thank_you_your_input':
    'Terima kasih, masukan Anda sangat berarti. Kami mempelajari setiap masukan yang masuk.',

  'sms.cannot_send_wa': 'Tidak dapat mengirim Whatsapp.',
  'sms.cannot_send_sms': 'Tidak dapat mengirim SMS.',
  'sms.destination_number': 'Nomor Tujuan',
  'sms.sms_has_been_sent_to': 'SMS telah berhasil terkirim ke',
  'sms.sms_content': 'Isi SMS',
  'sms.wa_content': 'Isi Whatsapp',
  'sms.please_input_content': 'Silakan masukkan isi SMS.',
  'sms.please_input_wa_content': 'Silakan masukkan isi Whatsapp.',
  'sms.please_input_phone': 'Silakan masukkan nomor tujuan.',

  'email.to': 'Kepada',
  'email.from': 'Dari',
  'email.subject': 'Judul',
  'email.message': 'Pesan',
  'email.please_input_subject': 'Silakan masukkan judul.',
  'email.please_input_message': 'Silakan masukkan pesan.',
  'email.cannot_send_email': 'Tidak dapat mengirim email.',
  'email.email_has_been_sent_to': 'Email telah berhasil terkirim ke',
  'email.please_confirm_email':
    'Silakan mengkonfirmasi email Anda {email} terlebih dahulu sebelum bisa mengirimkan email.',
  'email.send_confirmation_email': 'Kirim Email Konfirmasi',
  'email.resend_confirmation_email': 'Kirim Ulang Email Konfirmasi',
  'email.we_have_sent_confirmation_email':
    'Kami telah mengirim email konfirmasi ke {email}. Mohon cek kotak masuk dan konfirmasi email Anda untuk melanjutkan.',
  'email.email_confirmation': 'Konfirmasi Email',
  'email.email_has_been_verified': 'Email {email} telah berhasil diverifikasi.',
  'email.cannot_verify_email': 'Tidak dapat memverifikasi email.',
  'email.email_verification_failed': 'Email {email} gagal diverifikasi.',
  'email.email_template': 'Template Email',
  'emails.edit_email_template': 'Ubah Template Email',
  'emails.are_you_sure_revert_to_default': 'Apakah Anda yakin akan mengembalikan ke default?',
  'emails.revert_to_default': 'Kembalikan ke Default',
  'emails.template_name': 'Nama Templat',
  'emails.please_input_the_email_template_name': 'Silakan masukkan nama template email.',
  'emails.max_100_characters': 'Maks 100 karakter.',
  'emails.email_template_name': 'Nama Template Email',
  'emails.subject': 'Judul',
  'emails.please_input_the_email_template_subject': 'Silakan masukkan judul template email.',
  'emails.email_successfully_updated': 'Email berhasil diperbarui.',
  'emails.error_cannot_update_email_template': 'Gagal memperbarui template email.',
  'emails.email_successfully_reverted_to_default': 'Email berhasil dikembalikan ke default.',
  'emails.error_cannot_revert_email_template': 'Gagal mengembalikan template email.',
  'emails.reply_email_address_successfully_updated': 'Alamat email balasan berhasil diperbarui.',
  'emails.error_cannot_update_reply_email_address': 'Gagal memperbarui alamat email balasan.',
  'emails.email_templates': 'Template Email',
  'emails.reply_email_address': 'Alamat Email Pengirim',
  'emails.body': 'Tubuh',
  'emails.rdw_tags_wrapper': 'rdw-tags-wrapper',
  'emails.insert_tagsment': 'Sisipkan Tag',
  'emails.when_an_agent_or_guest_replies_to_an_email,_this_is_the_email_their_reply_will_be_sent_to':
    'Saat Anda mengirimkan email, email pengirim akan menggunakan alamat email berikut:.',
  'emails.display_name': 'Nama tampilan',
  'emails.please_input_the_display_name': 'Silakan masukkan nama tampilan.',
  'emails.max_20_characters': 'Maks 20 karakter.',
  'emails.only_alphanumeric_allowed': 'Hanya alfanumerik yang diizinkan.',
  'emails.email_address': 'Alamat email',
  'emails.please_input_the_email_address': 'Silakan masukkan alamat email.',
  'emails.please_input_unique_email_address': 'Email {email} telah terdaftar.',
  'emails.invalid_email_address_format': 'Format alamat email tidak valid.',
  'email.you_can_resend_an_email_after':
    'Ada dapat mengirimkan konfirmasi email kembali setelah {countDown}.',
  'emails.send_attachment_from_related_transaction': 'Kirim attachment dari transaksi terkait',
  'emails.send_attachment_from_related_transaction_help':
    'Apabila transaksi terkait mempunyai attachment, apakah ikut dikirimkan pada email ini?',

  'wa.wa_template': 'Template Whatsapp',
  'wa.edit_wa_template': 'Ubah Template Whatsapp',
  'wa.change_template': 'Ubah Template',
  'wa.error_cannot_update_template': 'Tidak dapat mengubah template Whatsapp',
  'wa.error_cannot_revert_template': 'Tidak dapat mengembalikan template Whatsapp',

  'multiCurrency.multi_currency': 'Multi Mata Uang',
  'multiCurrency.base_currency_cannot_changed_after_enabled':
    'Mata uang dasar tidak dapat diubah setelah Anda mengaktifkan multi mata uang',
  'multiCurrency.activate_multi_currency': 'Aktifkan multi mata uang',
  'multiCurrency.set_rate_source': 'Atur sumber kurs',
  'multiCurrency.change_daily_rate': 'Ubah kurs harian',
  'multiCurrency.add_currency': 'Tambah mata uang',
  'multiCurrency.in': 'Dalam',
  'multiCurrency.rate': 'Kurs',
  'multiCurrency.source': 'Sumber',
  'multiCurrency.base_currency': 'Mata uang dasar',
  'multiCurrency.currency': 'Mata uang',
  'multiCurrency.select_currency': 'Pilih mata uang',
  'multiCurrency.rate_source': 'Sumber kurs',
  'multiCurrency.select_rate_source': 'Pilih sumber kurs',
  'multiCurrency.edit_rate_for_date': 'Ubah kurs untuk tanggal',
  'multiCurrency.input_date': 'Masukkan tanggal',
  'multiCurrency.rate_value': 'Nilai kurs',
  'multiCurrency.rate_value_must_more_0': 'Nilai kurs harus lebih dari 0',
  'multiCurrency.select_rate_value': 'Masukkan nilai kurs',
  'multiCurrency.rate_reference': 'Kurs referensi',
  'multiCurrency.please_select_base_currency_to_enable_multi':
    'Silakan pilih mata uang dasar untuk mengaktifkan multi mata uang',
  'multiCurrency.please_select_currency_first': 'Pilih mata uang terlebih dahulu',
  'multiCurrency.change_rate_value': 'Ubah nilai kurs',
  'multiCurrency.transaction_currency': 'Mata uang transaksi',
  'multiCurrency.reset_rate': 'Reset kurs',
  'multiCurrency.rate_not_found': 'Kurs tidak ditemukan',

  'referral.referral': 'Referal',
  'referral.read_more': 'Baca selengkapnya >>',
  'referral.referral_history': 'Riwayat Referal',
  'referral.your_referral_code': 'Kode Referalmu',
  'referral.company': 'Perusahaan',
  'referral.code': 'Kode',
  'referral.copy_code': 'Salin Kode',
  'referral.add_code': 'Tambah Kode',
  'referral.register': 'Daftar',
  'referral.paid': 'Dibayar',
  'referral.transfered': 'Ditransfer',
  'referral.approved': 'Disetujui',
  'referral.lead': 'Lead',
  'referral.cannot_get_referral': 'Tidak dapat mengambil data referal',
  'referral.cannot_add_referral': 'Tidak dapat menambah referal',
  'referral.cannot_delete_referral': 'Tidak dapat menghapus referal',
  'referral.invite_your_friends': 'Ajak Temanmu Menggunakan Kledo',
  'referral.get_millions_per_month': 'Dapatkan jutaan rupiah per bulan',
  'referral.share_your_referral_code': 'Bagikan kode referalmu',
  'referral.share_via_sosmed_or_anything':
    'Bagikan via sosmed, email, whatsapp, atau apapun ke kolegamu',
  'referral.discount_for_your_friends': 'Diskon {discount}% untuk temanmu',
  'referral.your_friends_will_get_discount':
    'Temanmu akan mendapatkan diskon {discount}% jika mendaftar untuk 1 tahun',
  'referral.reward_per_referral': 'Reward Rp {rewardAmount}rb per referal',
  'referral.get_reward_per_referral':
    'Dapatkan reward sebesar Rp {rewardAmount}rb rupiah tiap referal',

  'customColumn.you_can_add_custom_column_on_invoice':
    'Anda bisa menambahkan custom kolom pada data transaksi tagihan penjualan',
  'customColumn.name': 'Nama',
  'customColumn.please_input_name': 'Silakan Masukkan Nama',
  'customColumn.data_type': 'Tipe Data',
  'customColumn.please_input_data_type': 'Silakan Masukkan Tipe Data',
  'customColumn.transaction_field': 'Field Transaksi',
  'customColumn.item_field': 'Field Item',
  'customColumn.option': 'Pilihan',
  'customColumn.default_selected': 'Default Terpilih',
  'customColumn.please_input_option': 'Silakan Masukkan Pilihan',
  'customColumn.min_1_option': 'Minimal 1 pilihan',
  'customColumn.add_more': 'Tambah baris',
  'customColumn.what_difference_of_transaction_and_item_field':
    'Apa beda field transaksi dan field item? Klik di sini.',
  'customColumn.please_select_custom_column': 'Silakan pilih custom field',
  'customColumn.show_custom_column': 'Tampilkan Custom Field',
  'customColumn.hide_custom_column': 'Sembunyikan Custom Field',
  'customColumn.with_custom_fields_you_can_add_data_to_the_bill':
    'Dengan custom fields, Anda bisa menambah data pada tagihan, ataupun juga menambah kolom pada item tagihan sesuai dengan kebutuhan perusahaan Anda.',

  'marketplaceConnect.status': 'Status',
  'marketplaceConnect.marketplace': 'Marketplace',
  'marketplaceConnect.store': 'Toko',
  'marketplaceConnect.store_url': 'Url Toko',
  'marketplaceConnect.data_integration_schedule': 'Jadwal Tarik Data',
  'marketplaceConnect.created_at': 'Dibuat',
  'marketplaceConnect.last_data_integrated': 'Terakhir Tarik Data',
  'marketplaceConnect.action': 'Aksi',
  'marketplaceConnect.choose_marketplace_to_connect_to_kledo':
    'Pilih marketplace yang akan dihubungkan ke Kledo',
  'marketplaceConnect.marketplace_not_supported_yet': 'Marketplace ini belum didukung',
  'marketplaceConnect.setting': 'Setting',
  'marketplaceConnect.store_name': 'Nama Toko',
  'marketplaceConnect.delete_connection': 'Hapus Koneksi',
  'marketplaceConnect.sync_now': 'Tarik Data Sekarang',
  'marketplaceConnect.sync_interval': 'Jadwal Tarik Data',
  'marketplaceConnect.please_select_sync_interval': 'Silakan Pilih Jadwal Tarik Data',
  'marketplaceConnect.payment_bank_account': 'Akun Bank Pembayaran',
  'marketplaceConnect.please_select_payment_bank_account': 'Silakan Pilih Akun Bank Pembayaran',
  'marketplaceConnect.shipping_cost_account': 'Akun Biaya Pengiriman',
  'marketplaceConnect.shipping_cost_account_tooltip':
    'Setiap transaksi yang biaya pengirimannya dibayarkan pelanggan ke marketplace, akan dibuatkan transaksi biaya secara otomatis.',
  'marketplaceConnect.start_pulling_data': 'Mulai tarik data',
  'marketplaceConnect.start_pulling_data_tooltip':
    'Kapan tanggal mulai tarik data dari marketplace ke Kledo? Tarik data maksimal 1 minggu dari tanggal sekarang.',
  'marketplaceConnect.start_pulling_data_minimum_date_message':
    'Minimal tanggal mulai penarikan data adalah sekarang.',
  'marketplaceConnect.start_pulling_data_maximum_date_message':
    'Maximal tanggal mulai penarikan data adalah sekarang.',
  'marketplaceConnect.please_select_shipping_cost_account': 'Silakan Pilih Akun Biaya Pengiriman',
  'marketplaceConnect.admin_cost_account': 'Akun Biaya Admin',
  'marketplaceConnect.admin_cost_account_tooltip':
    'Setiap transaksi yang dipotong biaya admin oleh marketplace, akan dibuatkan transaksi biaya secara otomatis.',
  'marketplaceConnect.please_select_admin_cost_account': 'Silakan Pilih Akun Biaya Admin',
  'marketplaceConnect.warehouse': 'Gudang',
  'marketplaceConnect.please_select_warehouse': 'Silakan Pilih Gudang',
  'marketplaceConnect.tag': 'Tag',
  'marketplaceConnect.record_shipping_cost': 'Catat Biaya Pengiriman',
  'marketplaceConnect.is_shipping_cost_will_be_recorded_on_kledo':
    'Apakah biaya pengiriman akan dicatatkan ke Kledo?',
  'marketplaceConnect.every_hour': 'Setiap {hour} jam',
  'marketplaceConnect.please_input_shop_name': 'Silakan masukkan url toko',
  'marketplaceConnect.yeah_successful': 'Hore berhasil!',
  'marketplaceConnect.next_activation_step_will_be_contacted':
    'Untuk langkah aktifasi berikutnya, Anda akan dihubungi oleh tim customer success kami pada hari dan jam kerja, melalui whatsapp dan email. Mohon ditunggu ya.',
  'marketplaceConnect.if_product_not_track_empty_warehouse':
    'Jika produk Anda tidak lacak stok, silakan kosongkan bagian gudang ini',
  'marketplaceConnect.using_marketplace_connect_shop_transaction_automatic_on_kledo':
    'Dengan menggunakan marketplace connect, transaksi tokomu di marketplace akan otomatis masuk ke data Kledo, asik kan? Yuk hubungkan marketplacemu dengan Kledo.',
  'marketplaceConnect.tutorial_video': 'Video Tutorial',
  'marketplaceConnect.record_transaction': 'Catat Transaksi',
  'marketplaceConnect.please_select_record_transaction': 'Silakan masukkan Catat Transaksi',
  'marketplaceConnect.on_transaction_done': 'Saat transaksi selesai',
  'marketplaceConnect.start_on_item_sent': 'Mulai saat barang dikirim',
  'marketplaceConnect.record_transaction_help':
    'Jika dicatat saat barang dikirim, maka saat barang status sedang kirim di kledo akan dicatatkan transaksi pemesanan dan pengirimannya. Dan saat transaksi selesai, akan dibuatkan transaksi tagihan penjualan dan pembayarannya. Jika dicatat saat transaksi selesai, maka transaksi dimasukkan ke kledo hanya saat status transaksi di marketplace selesai.',

  'posConnect.choose_pos_to_connect_to_kledo': 'Pilih POS yang akan dihubungkan ke Kledo',
  'posConnect.please_input_client_id': 'Silakan masukkan Client ID',
  'posConnect.please_input_client_secret': 'Silakan masukkan Client Secret',
  'posConnect.click_here_to_get_client_id_secret':
    'Klik {link} untuk mendapatkan Client ID dan Secret',
  'posConnect.payment_transaction_input_this_coa_account':
    'Transaksi pembayaran akan dicatatkan pada akun COA ini',
  'posConnect.default_customer': 'Pelanggan Default',
  'posConnect.please_select_default_customer': 'Silakan pilih Pelanggan Default',
  'posConnect.transaction_from_pos_inputed_as_this_customer':
    'Transaksi dari POS akan dicatatkan sebagai transaksi dari pelanggan ini',
  'posConnect.service_charge_product': 'Produk Service Charge',
  'posConnect.please_select_service_charge_product': 'Silakan pilih Produk Service Charge',
  'posConnect.if_service_charge_inputes_as_this_product':
    'Jika terdapat service charge, akan dicatatkan sebagai pembelian pada produk ini',
  'posConnect.custom_product': 'Produk Custom',
  'posConnect.please_select_custom_product': 'Silakan pilih Produk Custom',
  'posConnect.if_custom_product_inputes_as_this_product':
    'Jika terdapat pembelian dengan produk custom, akan dicatatkan pada produk ini',
  'posConnect.your_pos_has_been_connected':
    'POS Anda sudah terkoneksi dengan Kledo. Data dari POS akan otomatis masuk ke Kledo secara berkala',

  'consolidation.you_must_have_more_than_one_companies':
    'Untuk dapat menggunakan fitur konsolidasi, Anda terlebih dahulu harus mempunyai lebih dari satu perusahaan dengan hak akses Administrator.',
  'consolidation.if_you_have_more_companies_you_may_activate':
    'Jika Anda memiliki lebih dari satu perusahaan, Anda dapat mengaktifkan fitur konsolidasi. Fitur konsolidasi akan secara otomatis mengkompilasi laporan dari masing-masing perusahaan kedalam satu laporan konsolidasi.',
  'consolidation.you_must_have_admin_each_companies':
    'Anda harus memilik hak akses sebagai administrator dimasing-masing perusahaan yang akan dikompilasi.',
  'consolidation.please_select_companies_that_will_be_compiled':
    'Silakan pilih perusahaan yang akan dikompilasi ke dalam laporan konsolidasi',
  'consolidation.please_select_coa_accounts':
    'Silakan pilih akun COA untuk dikompilasi kedalam laporan konsolidasi',
  'consolidation.please_select_all_companies_that_will_be_compiled':
    'Silakan pilih semua perusahaan yang akan dikompilasi ke dalam laporan konsolidasi',
  'consolidation.must_enable_2_companies': 'Anda harus mengaktifkan setidaknya 2 perusahaan',
  'consolidation.cannot_select_company': 'Tidak dapat memilih perusahaan',
  'consolidation.add_company': 'Tambah Perusahaan',
  'consolidation.automatic_compilation': 'Kompilasi Otomatis',
  'consolidation.compile': 'Kompilasi',
  'consolidation.cannot_compile': 'Tidak dapat mengkompilasi',
  'consolidation.code': 'Kode',
  'consolidation.name': 'Nama',
  'consolidation.category': 'Kategori',
  'consolidation.account': 'Akun',
  'consolidation.account_total': 'Jumlah Akun',
  'consolidation.activate_consolidation_feature': 'Aktifkan fitur konsolidasi',
  'consolidation.cannot_activate_consolidation': 'Tidak dapat mengaktifkan fitur konsolidasi',
  'consolidation.compilation_saved':
    'Kompilasi akun berhasil, klik tombol di bawah untuk melihat laporan',
  'consolidation.see_report': 'Lihat Laporan',
  'consolidation.confirm_auto_compile':
    'Kompilasi otomatis akan secara otomatis mengkompilasi akun dengan kode yang sama ke dalam akun konsolidasi. Apakah Anda yakin akan mengkompilasi otomatis?',
  'consolidation.code_already_exist': 'Kode sudah ada, silakan ganti dengan yang lain',

  'translation.translation': 'Translasi',
  'translation.title.reset': 'Apakah Anda yakin untuk reset?',
  'translation.reset': 'Reset Translasi',
  'translation.error_cannot_edit': 'TIdak bisa mengubah Translasi',

  'audit.audit': 'Audit',
  'audit.show_audit': 'Lihat Audit',
  'audit.date': 'Tanggal',
  'audit.description': 'Deskripsi',
  'audit.ip': 'IP',
  'audit.by': ' oleh ',
  'audit.user': 'User',
  'audit.item': 'Item',
  'audit.all_item': 'Semua item',
  'audit.filter': 'Filter Audit',

  'appSetting.apps': 'Aplikasi',
  'appSetting.date_created': 'Tanggal Dibuat',
  'appSetting.app_name': 'Nama Aplikasi',
  'appSetting.client_id': 'Client ID',
  'appSetting.client_id_has_been_copied': 'Client ID berhasil disalin',
  'appSetting.secret_has_been_copied': 'Secret berhasil disalin',
  'appSetting.endpoint_has_been_copied': 'API Endpoint URL berhasil disalin',
  'appSetting.documentation_has_been_copied': 'Dokumentasi API berhasil disalin',
  'appSetting.api_documentation_endpoint': 'Dokumentasi API Endpoint',
  'appSetting.please_input_name': 'Silakan masukkan nama aplikasi',
  'appSetting.please_redirect_uri': 'Silakan masukkan redirect URI',
  'appSetting.please_valid_uri': 'Silakan masukkan URL yang valid',
  'appSetting.error_cannot_get': 'Tidak dapat mengambil data aplikasi',
  'appSetting.error_cannot_create': 'Tidak dapat membuat aplikasi',
  'appSetting.error_cannot_edit': 'Tidak dapat mengubah aplikasi',
  'appSetting.error_cannot_delete': 'Tidak dapat menghapus aplikasi',
  'appSetting.app_has_been_created_make_sure_save_secret':
    'Aplikasi baru berhasil dibuat, silakan pastikan simpan kode secret di bawah sebelum berpindah ke halaman lain.',
  'appSetting.secret_code_just_shown_once':
    'Kode secret hanya tampil sekali. Simpan kode ini, karena setelah ini Anda tidak dapat melihat kode secret ini lagi.',
  'appSetting.integrate_your_app_with_kledo': 'Integrasikan aplikasimu dengan Kledo',
  'appSetting.now_app_can_be_integrated_with_kledo':
    'Sekarang aplikasi, website, maupun software-mu bisa diintegrasikan dengan Kledo.',
  'appSetting.for_more_details_lets_see_the_video': 'Untuk lebih jelasnya, yuk lihat videonya',
  'appSetting.watch_video': 'Lihat video',

  'settings.shipping_comps': 'Atur perusahaan ekspedisi',
  'settings.businessflow': 'Atur alur bisnismu',
  'settings.company': 'Data dan logo perusahaan',
  'settings.taxes': 'Atur pajak',
  'settings.terms': 'Atur data termin pembayaran',
  'settings.billing': 'Upgrade dan perpanjangan paket Kledo',
  'settings.automatic_sequencing': 'Atur penomoran otomatis invoice',
  'settings.lock_date': 'Kunci data lama untuk tutup buku',
  'settings.layout_invoice': 'Atur tampilan print invoice',
  'settings.layout_reporting': 'Atur tampilan laporan keuangan',
  'settings.users': 'Undang staff dan kolegamu menggunakan Kledo',
  'settings.roles': 'Atur hak akses pengguna',
  'settings.my_profile': 'Atur profil dan ubah password',
  'settings.setup': 'Hapus data demo dan mulai dari awal',
  'settings.translation': 'Ubah bahasa pada menu Kledo',
  'settings.audits': 'Pantau log perubahan data',
  'settings.units': 'Atur satuan produk',
  'settings.consolidation': 'Atur perusahaan dan akun untuk laporan konsolidasi',
  'settings.tags': 'Atur tag untuk pemisahan laporan cabang/departemen/divisi',
  'settings.email_template': 'Atur tampilan email',
  'settings.apps': 'Koneksikan Apps mu dengan API Kledo',
  'settings.account_mappings': 'Pemetaan akun CoA untuk transaksi di Kledo',
  'settings.marketplace_connect': 'Otomatis sinkron Shopee, Tokopedia, dan Lazada ke Kledo',
  'settings.whatsapp_template': 'Atur template default pengiriman invoice via Whatsapp',
  'settings.notification': 'Atur notifikasi yang dikirim ke emailmu',
  'settings.notification_title': 'Notifikasi',
  'settings.custom_columns': 'Custom data pada invoicemu',
  'settings.woocommerce': 'Otomatis sinkron Wordpress & Woocommerce ke Kledo',
  'settings.pos_connect': 'Otomatis sinkron POS ke Kledo',
  'settings.multi_currency': 'Atur penggunaan banyak mata uang',
  'settings.sales_commission': 'Atur komisi penjualan tiap tim sales',

  'billing.error_cannot_upgrade': 'Tidak dapat upgrade',
  'billing.error_cannot_renew': 'Tidak dapat perpanjangan',
  'billing.error_cannot_delete': 'Tidak dapat menghapus',
  'billing.error_cannot_get_addon': 'Tidak dapat mengambil addon',
  'billing.error_cannot_add_addon': 'Tidak dapat menambah addon',
  'billing.account_expired':
    'Akun Anda telah habis masa aktifnya, silakan order perpanjangan untuk kembali dapat mengakses akun Anda',
  'billing.expired': 'Akun Anda telah habis masa aktifnya, {setup} untuk order perpanjangan',
  'billing.current_expired_date': 'Tanggal expired saat ini',
  'billing.1.expiration_date_after_renewal': 'Tanggal expired setelah perpanjangan',
  'billing.2.expiration_date_after_renewal':
    'Tanggal expired setelah perpanjangan dihitung dari tanggal expired saat ini, bukan dihitung dari tanggal pembayaran.',
  'billing.expired_link': 'klik disini',
  'billing.billing': 'Billing',
  'billing.thankyou': 'Terimakasih',
  'billing.upgrade': 'Upgrade',
  'billing.renew': 'Perpanjang',
  'billing.addon': 'Addon',
  'billing.show_addon': 'Tambah Addon',
  'billing.hide_addon': 'Sembunyikan Addon',
  'billing.upgrade_now': 'Upgrade Sekarang',
  'billing.renew_now': 'Perpanjang Sekarang',
  'billing.order_now': 'Pesan Sekarang',
  'billing.upgrade_plan': 'Upgrade Paket',
  'billing.renew_plan': 'Perpanjang Paket',
  'billing.subscription_plan': 'Paket Berlangganan',
  'billing.plan': 'Paket',
  'billing.forever': 'Selamanya',
  'billing.payment_history': 'Riwayat Pembayaran',
  'billing.active_until': 'Aktif sampai',
  'billing.order_date': 'Tanggal Order',
  'billing.description': 'Deskripsi',
  'billing.period': 'Periode',
  'billing.payment_date': 'Tanggal Bayar',
  'billing.total': 'Total',
  'billing.status': 'Status',
  'billing.select_plan': 'Silakan pilih paket yang Anda inginkan',
  'billing.select_contract_duration': 'Silakan pilih lama kontrak',
  'billing.total_bill': 'Total tagihan',
  'billing.please_add_an_addon': 'Silakan tambah salah satu addon',
  'billing.please_select_a_plan': 'Silakan pilih paket',
  'billing.please_select_the_contract_duration': 'Silakan pilih lama kontrak',
  'billing.get_x_discount_for_1_year_payment_and_x_discount_for_6_months':
    'Diskon {discountFor1Year} untuk pembayaran 1 tahun dan diskon {discountFor6Months} untuk pembayaran 6 bulan',
  'billing.bill_email_has_been_sent_to_your_email':
    'Email tagihan telah dikirimkan ke alamat email Anda.',
  'billing.please_make_a_payment_of': 'Silakan melakukan pembayaran sebesar',
  'billing.by_click_button_below': 'dengan klik tombol berikut',
  'billing.pay_now': 'Bayar Sekarang',
  'billing.pay': 'Bayar',
  'billing.payment_failed': 'Pembayaran Gagal',
  'billing.please_contact_kledo_if_difficulity_on_payment':
    'Silakan menghubungi tim Hebat Kledo jika mengalami kesulitan dalam pembayaran.',
  'billing.payment_success': 'Pembayaran Berhasil',
  'billing.your_account_activated_thanks': 'Akun Anda telah diaktifkan. Terima kasih.',
  'billing.payment_pending': 'Menunggu Pembayaran',
  'billing.your_account_wiil_be_active_after_payment':
    'Akun Anda akan aktif setelah dilakukan pembayaran. Jika Anda telah melakukan pembayaran, silakan klik tombol berikut. Terimakasih.',
  'billing.to': 'ke',
  'billing.bank': 'Bank',
  'billing.account_number': 'Nomor Rekening',
  'billing.after_make_a_payment_please_confirm_via_whatsapp':
    'Setelah melakukan pembayaran, mohon konfirmasi melalui whatsapp di',
  'billing.back_to_billing': 'Kembali ke Billing',
  'billing.feature_available_in_pro_plan': 'Fitur {feature} tersedia di paket PRO',
  'billing.feature_can_only_be_use_on_pro_plan': '{feature} hanya bisa diakses di paket PRO',
  'billing.pro_plan_features': 'Fitur Paket Pro',
  'billing.feature_available_in_elite_plan': 'Fitur {feature} tersedia di paket Elite',
  'billing.feature_can_only_be_use_on_elite_plan': '{feature} hanya bisa diakses di paket Elite',
  'billing.elite_plan_features': 'Fitur Paket Elite',
  'billing.feature_available_in_champion_plan': 'Fitur {feature} tersedia di paket Champion',
  'billing.feature_can_only_be_use_on_champion_plan':
    '{feature} hanya bisa diakses di paket Champion',
  'billing.champion_plan_features': 'Fitur Paket Champion',
  'billing.quota_is_exhausted_add_an_addon': 'Kuota {feature} Anda Habis, Silahkan Tambah Addon',
  'billing.marketplace_connect_available_in_elite':
    '{feature} Tersedia di Paket Elite, Silahkan Upgrade',
  'billing.paid_plan_features': 'Fitur Paket Berbayar',
  'billing.feature_can_only_be_accessed_on_paid_plan':
    'Fitur {feature} hanya bisa diakses di paket berbayar',
  'billing.warehouse_and_inventory': 'Gudang & Inventori',
  'billing.track_product': 'Lacak produk',
  'billing.attachment': 'Attachment',
  'billing.dashboard_layout_setting': 'Pengaturan tampilan dashboard',
  'billing.feature_locked': 'Fitur Dikunci',
  'billing.your_free_trial_active': 'Akun Free Trial Anda sudah aktif.',
  'billing.account_will_active_until': 'Akun akan aktif sampai dengan',
  'billing.token_required_to_make_payment': 'Token diperlukan untuk melakukan pembayaran',

  'dashboard.hello_name': 'Halo {name},',
  'dashboard.schedule_a_demo_and_consult':
    'Yuk jadwalkan demo dan konsultasi online secara gratis bersama tim hebat Kledo.',
  'dashboard.demo_online_with_zoom':
    'Demo dilakukan online menggunakan Zoom secara 1 on 1 selama 45 menit.',
  'dashboards.dashboard': 'Dashboard',
  'dashboards.select_company': 'Pilih Perusahaan: ',
  'dashboards.gross_profit': 'Laba kotor',
  'dashboards.net_profit': 'Laba bersih',
  'dashboards.net_profit_for_this_year': 'Laba bersih tahun ini',
  'dashboards.sales_value_this_year': 'Nilai penjualan bulan ini',
  'dashboards.sales': 'Penjualan',
  'dashboards.awaiting_payment': 'Menunggu pembayaran',
  'dashboards.overdue': 'Jatuh tempo',
  'dashboards.sales_this_day': 'Penjualan hari ini',
  'dashboards.sales_this_month': 'Penjualan bulan ini',
  'dashboards.sales_this_year': 'Penjualan tahun ini',
  'dashboards.this_month': 'Bulan ini',
  'dashboards.ytd': 'Tahun ini',
  'dashboards.total': 'Total',
  'dashboards.are_you_sure_delete': 'Apakah Anda yakin untuk menghapus?',
  'dashboards.modif': 'Ubah',
  'dashboards.exit': 'Keluar',
  'dashboards.select_account': 'Pilih akun',
  'dashboards.please_select_account': 'Silakan pilih akun!',
  'dashboards.in': 'In',
  'dashboards.out': 'Out',
  'dashboards.net': 'Net',
  'dashboards.edit': 'Ubah Dashboard',
  'dashboards.other': 'Lainnya',
  'dashboards.the_first_date_end_higger_than_second_date_start':
    'Tanggal mulai kedua harus berupa tanggal setelah tanggal pertama berakhir',

  'payments.add_a_payment_method': 'Tambah Metode Pembayaran',
  'payments.delete_a_payment_method': 'Hapus Metode Pembayaran ',
  'payments.are_you_sure_delete': 'Anda yakin menghapus?',
  'payments.payment_metod': 'Metode pembayaran',
  'payments.please_select_a_payment_to_delete': 'Silakan pilih pembayaran untuk dihapus',
  'payments.select_a_payment_method': 'Pilih metode pembayaran',
  'payments.edit_payment_method': 'Ubah Metode Pembayaran',
  'payments.payment_method': 'Metode pembayaran',
  'payments.please_input_the_payment_name': 'Silakan masukkan nama pembayaran.',
  'payments.max_20_characters': 'Maks 20 karakter.',
  'payments.only_alphanumeric_allowed': 'Hanya alfanumerik yang diizinkan.',
  'payments.name': 'Nama',
  'payments.payment': 'Pembayaran',
  'payments.payment_methods': 'Metode pembayaran',
  'payments.successfully_added': 'berhasil ditambahkan.',
  'payments.error_cannot_add': 'Tidak bisa menambahkan pembayaran.',
  'payments.successfully_deleted': 'berhasil dihapus.',
  'payments.error_cannot_delete': 'Gagal menghapus pambayaran.',
  'payments.payment_successfully_updated': 'Pembayaran berhasil diperbarui.',
  'payments.error_cannot_update_payment': 'Gagal memperbarui pembayaran.',
  'payments.payment_moved_successfully': 'Pembayaran berhasil dipindahkan.',
  'payments.error_cannot_move_the_payment': 'Gagal memindahkan pembayaran.',
  'payments.change_status_payment_successfully': 'Ubah pembayaran status berhasil.',
  'payments.error_cannot_change_status_the_payment': 'Gagal mengubah status pembayaran. ',
  'payments.must_have_active_payment': 'Harus ada minimal satu pembayaran aktif.',

  'profile.profile': 'Profil',
  'profile.image_profile': 'Gambar Profil',
  'profile.my_profile': 'Profilku',
  'profile.old_password': 'Password lama',
  'profile.please_input_the_old_password': 'Silakan masukkan password lama.',
  'profile.new_password': 'Password baru',
  'profile.please_input_the_new_password': 'Silakan masukkan password baru.',
  'profile.max_100_characters': 'Maks 100 karakter.',
  'profile.confirm_new_password': 'Ulangi password baru',
  'profile.please_input_the_confirm_new_password':
    'Silakan masukkan password baru yang dikonfirmasi! ',
  'profile.email_or_username': 'Email',
  'profile.please_input_the_email/username': 'Silakan masukkan email.',
  'profile.the_input_is_not_valid-e-mail': 'Silakan masukkan email yang valid',
  'profile.full_name': 'Nama lengkap',
  'profile.please_input_the_full_name': 'Silakan masukkan nama lengkap.',
  'profile.0nly_alphanumeric_allowed': 'Hanya alfanumerik yang diizinkan.',
  'profile.phone_number': 'Nomor telepon',
  'profile.please_input_the_phone_number': 'Silakan masukkan nomor telepon.',
  'profile.max_20_characters': 'Maks 20 karakter.',
  'profile.only_number_allowed': 'Hanya angka yang diizinkan.',
  'profile.user_profile_successfully_updated': 'Profil berhasil diperbarui.',
  'profile.error_cannot_update_user_profile': 'Gagal memperbarui profil.',
  'profile.user_password_successfully_updated': 'Password berhasil diperbarui.',
  'profile.error_cannot_update_user_password': 'Gagal memperbarui password.',
  'profile.change_password': 'Ganti password',
  'profile.two_passwords_that_you_enter_is_inconsistent_':
    'Dua password yang Anda masukkan tidak konsisten.',
  'profile.password_atleast_8_characters_atleast_1_uppercase_and_atleast_1_digit':
    'Password setidaknya 8 karakter, setidaknya 1 huruf besar dan setidaknya 1 digit.',
  'profile.password_atleast_6_characters': 'Password setidaknya 6 karakter.',
  'profile.password_atleast_8_characters': 'Password setidaknya 8 karakter.',
  'profile.password_pattern_minimum_1_capital_and_1_lowercase_letter':
    'Minimal 1 huruf kapital dan 1 huruf kecil.',
  'profile.password_pattern_minimum_1_number': 'Minimal 1 angka.',
  'profile.logout_from_all_device': 'Logout dari semua perangkat',
  'profile.cannot_logout_from_all_device': 'Tidak dapat logout dari semua perangkat',

  'roles.add_role': 'Tambah Peran',
  'roles.delete_role': 'Hapus Peran',
  'roles.select_role_to_delete': 'Pilih Peran untuk Dihapus',
  'roles.please_select_a_role': 'Silakan pilih peran.',
  'roles.select_role': 'Pilih peran',
  'roles.edit_role': 'Ubah Peran',
  'roles.role': 'Peran',
  'roles.roles': 'Peran',
  'roles.please_input_the_role_name': 'Silakan masukkan nama peran.',
  'roles.max_100_characters': 'Maks 100 karakter.',
  'roles.superadmin': 'Superadmin',
  'roles.error_cannot_get_roles': 'Tidak bisa mendapatkan Peran.',
  'roles.successfully_added': 'berhasil ditambahkan.',
  'roles.error_cannot_add': 'Tidak bisa menambahkan',
  'roles.role_successfully_updated': 'Peran berhasil diperbarui.',
  'roles.error_cannot_update_role': 'Gagal memperbarui peran.',
  'roles.successfully_deleted': 'berhasil dihapus.',
  'roles.error_cannot_delete': 'Gagal menghapus',
  'roles.role_moved_successfully': 'Peran berhasil dipindahkan.',
  'roles.error_cannot_move_the_channel': 'Gagal memindahkan channel.',
  'roles.search_by_role_name': 'Cari berdasarkan nama peran',
  'roles.name': 'Nama peran',
  'roles.invoices_payment': 'Pembayaran',
  'roles.orders_payment': 'Pembayaran',
  'roles.purchase_invoices_payment': 'Pembayaran',
  'roles.purchase_orders_payment': 'Pembayaran',
  'roles.product_show_purchase_price': 'Tampilkan Harga Beli',
  'roles.product_show_sale_price': 'Tampilkan Harga Jual',
  'roles.product_show_hpp': 'Tampilkan HPP',
  'roles.inventory_show_hpp': 'Tampilkan Harga',
  'roles.reports_finance': 'Finansial',
  'roles.reports_accounting': 'Akuntansi',
  'roles.reports_sales': 'Penjualan',
  'roles.reports_purchases': 'Pembelian',
  'roles.reports_expenses': 'Biaya',
  'roles.reports_taxes': 'Pajak',
  'roles.reports_inventory': 'Inventori',
  'roles.reports_fixed_assets': 'Aset Tetap',
  'roles.reports_budget': 'Anggaran',
  'roles.reports_consolidation': 'Konsolidasi',
  'roles.apikeys': 'API Keys',
  'roles.sales_other_user_access': 'Akses Milik User Lain',
  'roles.purchase_other_user_access': 'Akses Milik User Lain',
  'roles.expenses_other_user_access': 'Akses Milik User Lain',
  'roles.product_show_manufacture_raw': 'Tampilkan Bahan Baku Manufaktur',
  'report.view_price': 'Lihat Harga',

  'roles.show_purchase_price': 'Tampilkan Harga Beli',
  'roles.show_sales_price': 'Tampilkan Harga Jual',
  'roles.show_cogs': 'Tampilkan HPP',
  'roles.show_manufaktur_materials': 'Tampilkan Bahan Baku Manufaktur',
  'roles.users_with_this_role': 'Pengguna dengan peran ini',
  'roles.click_here_to_set_users': '{clickHere} untuk mengatur pengguna.',
  'roles.role_not_found': 'Peran dengan id {id} tidak ditemukan',

  'shipComp.shipping_company': 'Ekspedisi',
  'shipComp.name': 'Nama',
  'shipComp.status': 'Status',
  'shipComp.error_cannot_move': 'Tidak dapat menambah ekspedisi.',
  'shipComp.error_cannot_add': 'Tidak dapat menambah ekspedisi.',
  'shipComp.error_cannot_update': 'Tidak dapat mengubah ekspedisi.',
  'shipComp.error_cannot_delete': 'Tidak dapat menghapus ekspedisi.',
  'shipComp.error_cannot_change_status': 'Tidak dapat mengubah status ekspedisi.',
  'shipComp.please_select_to_delete': 'Silakan pilih Ekspedisi untuk dihapus',
  'shipComp.select': 'Pilih Ekspedisi',
  'shipComp.please_input_the_name': 'Silakan masukkan nama ekspedisi.',
  'shipComp.must_have_active_shipcomp': 'Harus ada minimal satu ekspedisi aktif.',
  'report.sales_per_period': 'Penjualan Per Periode',
  'report.purchases_per_period': 'Pembelian Per Periode',
  'report.product_sales_per_customer': 'Penjualan Produk per Pelanggan',

  'report.processing_the_data': 'Memproses data',
  'report.access_denied': 'Akses Ditolak',
  'report.profitability_per_invoice': 'Profitabilitas per Tagihan',
  'report.show_account': 'Tampilkan Akun',
  'report.show_zero_account_balance': 'Tampilkan akun bersaldo kosong',
  'report.account_not_compiled':
    'Terdapat akun yang belum dikompilasi, silahkan {link} untuk mengkompilasi',
  'report.month': 'Bulan',
  'report.date': 'Tanggal',
  'report.expense_claim_customer': 'Kontak',
  'report.general_ledger_reporting': 'Laporan Buku Besar',
  'report.account': 'Akun',
  'report.account_list': 'Daftar Akun',
  'report.general_ledger': 'Buku Besar',
  'report.source': 'Sumber',
  'report.please_select_source': 'Silahkan Pilih Sumber',
  'report.description': 'Deskripsi',
  'report.reference': 'Referensi',
  'report.debit': 'Debit',
  'report.credit': 'Kredit',
  'report.ytd_debit': 'Debit Tahun Ini',
  'report.ytd_credit': 'Kredit Tahun Ini',
  'report.running_balance': 'Saldo Berjalan',
  'report.opening_balance': 'Saldo Awal',
  'report.closing_balance': 'Saldo Akhir',
  'report.total': 'Total',
  'report.total_this_page': 'Total Halaman Ini',
  'report.total_all_page': 'Total Seluruh Halaman',
  'report.posted_on': 'Dibuat pada',
  'report.no_data': 'No Data',
  'report.journal_reporting': 'Jurnal',
  'report.journal_report': 'Jurnal',
  'report.journal': 'Jurnal',
  'report.account_transaction_reporting': 'Transaksi Akun',
  'report.bank_summary': 'Ringkasan Bank',
  'report.bank_account': 'Akun Bank',
  'report.cash_received': 'Uang Diterima',
  'report.cash_spent': 'Uang Dibelanjakan',
  'report.sales_tax': 'Pajak Penjualan',
  'report.net': 'Net',
  'report.tax': 'Pajak',
  'report.customer_invoice': 'Tagihan Pelanggan',
  'report.invoice_number': 'Nomor Tagihan',
  'report.to': 'Sampai',
  'report.from': 'Dari',
  'report.due_date': 'Tanggal Jatuh Tempo',
  'report.date_and_time': 'Tanggal & waktu',
  'report.invoice_date': 'Tanggal Tagihan',
  'report.invoice_total': 'Total Tagihan',
  'report.paid': 'Dibayar',
  'report.due': 'Jatuh Tempo',
  'report.all': 'Semua',
  'report.status': 'Status',
  'report.income_per_customer': 'Pendapatan Per Pelanggan',
  'report.customer': 'Pelanggan',
  'report.income': 'Pendapatan',
  'report.sales_per_product': 'Penjualan Per Produk',
  'report.order_per_product': 'Pemesanan Per Produk',
  'report.product_name': 'Nama Produk',
  'report.current_unit_price': 'Harga Saat Ini',
  'report.quantity_sold': 'Jumlah Terjual',
  'report.product_quantity_sold_not_sent_yet': 'Jumlah Produk Belum Dikirim',
  'report.quantity_bought': 'Jumlah Dibeli',
  'report.average': 'Rata-rata',
  'report.purchases': 'Pembelian',
  'report.purchases_per_vendor': 'Pembelian Per Vendor',
  'report.purchases_per_product': 'Pembelian Per Produk',
  'report.expense_per_contact': 'Biaya per Kontak',
  'report.supplier': 'Vendor',
  'report.supplier_invoice': 'Tagihan Vendor',
  'report.purchase_value': 'Nilai Pembelian',
  'report.aged_receivable': 'Umur Piutang',
  'report.aged_receivable_not_yet_due': 'Belum Jatuh Tempo',
  'report.aged_receivable_aged_trans': 'Umur dari Transaksi',
  'report.aged_receivable_aged_due': 'Umur dari Jatuh Tempo',
  'report.aged_receivable_detail': 'Detil Umur Piutang',
  'report.aged_payable': 'Umur Hutang',
  'report.aged_payable_detail': 'Detil Umur Hutang',
  'report.expense_claim': 'Klaim Biaya',
  'report.expense_claim_detail': 'Detil Klaim Biaya',
  'report.trial_balance': 'Trial Balance',
  'report.profit_and_loss': 'Laba Rugi',
  'report.sales': 'Penjualan',
  'report.other_revenue': 'Penghasilan lain',
  'report.cost_of_sales': 'Beban Pokok Penjualan',
  'report.total_cost_of_sales': 'Total Beban Pokok Penjualan',
  'report.expenses': 'Biaya',
  'report.operating_expenses': 'Biaya Operasional',
  'report.total_operating_expenses': 'Total Biaya Operasional',
  'report.other_expenses': 'Biaya Lain-Lain',
  'report.gross_profit': 'Laba Kotor',
  'report.trading_income': 'Pendapatan',
  'report.total_trading_income': 'Total Pendapatan',
  'report.profitloss_comprehensive_income': 'Laba/Rugi Pendapatan Komprehensif',
  'report.profitloss_all_encompassing': 'Mencakup semua',
  'report.profitloss_one_of': 'Salah satu',
  'report.profitloss_filter_by_tags': 'Filter berdasarkan tag',
  'report.profitloss_filter_currency': 'Pilih Mata Uang',
  'report.profitloss_currency': 'Mata Uang',
  'report.total_comprehensive_income_this_period': 'Total Pendapatan Komprehensif Periode ini',
  'report.reports': 'Laporan',
  'report.financial': 'Finansial',
  'report.balance_sheet': 'Neraca',
  'report.compare': 'Bandingkan',
  'report.period': 'Periode',
  'report.cash_summary': 'Ringkasan Uang Tunai',
  'report.executive_summary': 'Ringkasan Eksekutif',
  'report.sales_tax_report': 'Pajak Penjualan',
  'report.accounting': 'Akuntansi',
  'report.aged_receivables': 'Umur Piutang',
  'report.customer_invoice_report': 'Tagihan Pelanggan',
  'report.aged_payables': 'Umur Hutang',
  'report.supplier_invoice_report': 'Tagihan Vendor',
  'report.purchases_per_supplier': 'Pembelian per Vendor',
  'report.cash_flow': 'Arus Kas',
  'report.net_cash_flows_from_': 'Arus kas bersih dari ',
  'report.direct_method': 'Metode Langsung',
  'report.indirect_method': 'Metode Tak Langsung',
  'report.please_select': 'Silakan Pilih',
  'report.cash_and_cash_equivalent': 'Kas dan Setara Kas',
  'report.cash_and_cash_equivalent_at_beginning_period': 'Kas dan setara kas di awal periode',
  'report.cash_and_cash_equivalent_at_end_period': 'Kas dan setara kas di akhir periode',
  'report.net_change_in_cash_for_period': 'Perubahan kas untuk periode',
  'report.net_cash_flows': 'Arus kas bersih',
  'report.equity_movement': 'Pergerakan Ekuitas',
  'report.beginning': 'Awal',
  'report.ending': 'Akhir',
  'report.net_movement': 'Pergerakan Bersih',
  'report.spend': 'Pengeluaran',
  'report.to_customer': 'Ke',
  'report.account_transactions': 'Transaksi Akun',
  'report.account_transactions_title': 'Transaksi {account}',
  'report.fixed_asset': 'Aset Tetap',
  'report.fixed_asset_detail': 'Detil Aset Tetap',
  'report.fixed_asset_summary': 'Ringkasan Aset Tetap',
  'report.asset_name': 'Nama Aset',
  'report.number': 'Nomor',
  'report.purchase_date': 'Tanggal Pembelian',
  'report.purchase_price': 'Harga Pembelian',
  'report.depreciation': 'Penyusutan',
  'report.book_value': 'Nilai Buku',
  'report.witholding_tax': 'Pajak Pemotongan',
  'report.inventory': 'Inventori',
  'report.inventory_summary': 'Ringkasan Inventori',
  'report.inventory_stock_movement': 'Pergerakan Stok Inventori',
  'report.warehouse_stock_summary': 'Ringkasan Stok Gudang',
  'report.warehouse_stock_movement': 'Pergerakan Stok Gudang',
  'report.warehouse': 'Gudang',
  'report.category': 'Kategori',
  'report.select_category': 'Pilih Kategori',
  'report.code': 'Kode',
  'report.qty': 'Kuantitas',
  'report.opening_qty': 'Kuantitas Awal',
  'report.qty_movement': 'Pergerakan Kuantitas',
  'report.closing_qty': 'Kuantitas Akhir',
  'report.opening_value': 'Nilai Awal',
  'report.value_movement': 'Pergerakan Nilai',
  'report.value': 'Nilai',
  'report.closing_value': 'Nilai Akhir',
  'report.avg_cost': 'Harga Rata-rata',
  'report.avg_cost2': 'Harga Rata-Rata',
  'report.unit_price': 'Harga Satuan',
  'report.product_value': 'Nilai Produk',
  'report.product_stock': 'Stok Produk',
  'report.product': 'Produk',
  'report.until': 's/d',
  'report.budget': 'Anggaran',
  'report.actual': 'Aktual',
  'report.movement': 'Pergerakan',
  'report.price': 'Harga',
  'report.unpaid': 'Belum Dibayar',
  'report.grand_total': 'Grand Total',
  'report.delivery': 'Pengiriman',
  'report.please_input_name': 'Silakan masukkan nama',
  'report.start_period': 'Periode Mulai',
  'report.please_input_start_period': 'Silakan masukkan periode mulai',
  'report.no_of_period': 'Jumlah Periode',
  'report.last_period': 'Periode Sebelumnya',
  'report.last_n_period': '{count} Periode Terakhir',
  'report.cannot_save_budget': 'Tidak dapat menyimpan anggaran',
  'report.cannot_delete_budget': 'Tidak dapat menghapus anggaran',
  'report.interval': 'Interval',
  'report.apply': 'Terapkan',
  'report.delete_figures_this_column': 'Hapus semua angka dari kolom ini',
  'report.apply_figures_to_following_month': 'Terapkan angka dari bulan ini untuk bulan berikutnya',
  'report.adjust_figures_each_month_amount':
    'Sesuaikan angka tiap bulan berikutnya dengan jumlah ini',
  'report.adjust_figures_each_month_percent':
    'Sesuaikan angka tiap bulan berikutnya dengan persentase ini',
  'report.max_n_period_to_print': 'Maksimal {count} periode untuk dapat melakukan print',
  'report.total_income': 'Total Pendapatan',
  'report.operating_costs': 'Biaya Operasional',
  'report.net_profit': 'Laba Bersih',
  'report.search_by_product': 'Cari produk',
  'report.shipping_amount': 'Jumlah Pengiriman',
  'report.shipping_cost_total': 'Total Ongkos Kirim',
  'report.shipping_cost': 'Ongkos Kirim',
  'report.shipping_cost_per_expedition': 'Ongkos Kirim per Ekspedisi',
  'report.sales_per_sales_person': 'Penjualan per Sales Person',
  'report.production_report': 'Laporan Produksi',
  'report.finished_product': 'Produk Jadi',
  'report.transaction_total': 'Total Transaksi',
  'report.receivable_payable_contact': 'Hutang Piutang per Kontak',
  'report.sort_by': 'Urutkan',
  'report.invoice_payment': 'Pelunasan Pembayaran Tagihan',
  'report.purchase_invoice_payment': 'Pelunasan Pembayaran Tagihan Pembelian',
  'report.first_payment_date': 'Tanggal Pembayaran Pertama',
  'report.paid_date': 'Tanggal Pelunasan',
  'report.product_profitability': 'Profitabilitas Produk',
  'report.total_profit': 'Total Profit',
  'report.profit_margin': 'Profit Margin',
  'report.avg_sell_price': 'Harga Jual Rata-Rata',
  'report.hpp_per_unit': 'HPP Per Unit',
  'report.avg_cogs': 'HPP Rata - Rata',
  'report.stock_in': 'Stok Masuk',
  'report.stock_out': 'Stok Keluar',
  'report.product_purchase_per_supplier': 'Pembelian Product per Vendor',
  'report.total_quantity_product': 'Total kuantitas Produk',
  'report.total_price': 'Total Harga',
  'report.product_purchases_per_vendor': 'Pembelian Produk per Supplier',
  'report.vendor': 'Supplier',
  'report.include_unit_conversion': 'Tampilkan konversi unit',
  'report.hide_zero_qty': 'Sembunyikan qty yang kosong',
  'report.sales_per_product_category': 'Penjualan Per Kategori Produk',
  'report.favorite': 'Favorit',
  'report.remove_favorite': 'Hilangkan dari menu favorit',
  'report.add_favorite': 'Tambahkan menu ke menu favorit',

  'trialBalance.show_balance': 'Tampilkan Saldo',

  'attachment.file_name': 'Nama File',
  'attachment.search_by_file_name': 'Cari berdasarkan nama file',
  'reportsImport.amount_of_success_import': 'Jumlah impor yang berhasil',
  'reportsImport.detail': 'Detail Import',
  'reportsImport.status': 'Status Import',
  'reportsImport.failed_to_import': 'Gagal Import',
  'reportsImport.successful_import': 'Import sukses',
  'reportsImport.import_type': 'Tipe Import',
  'reportsImport.import_time': 'Waktu Import',
  'reportsImport.transaction_status': 'Status Transaksi',
  'reportsImport.download_file_import': 'Unduh berkas import',
  'reportsImport.download_file_error': 'Unduh berkas error',
  'reportsImport.sub_account_code': 'Kode Sub Akun (Parent)',
  'reportsImport.sub_account_name': 'Nama Sub Akun (Parent)',
  'reportsImport.imported_by': 'Diimpor oleh',

  'setup.demo_data_successfully_cleared': 'Data demo berhasil dihapus.',
  'setup.error_demo_data_failure_to_cleared_': 'Gagal hapus data demo.',
  'setup.demo_data_successfully_finished': 'Setup berhasil diselesaikan.',
  'setup.error_demo_data_failure_to_finished': 'Gagal menyelesaikan setup.',
  'setup.setup_clear_demo_data': 'Setup Hapus Demo Data',
  'setup.you_are_using_a_demo_of_kledo_at_the_moment':
    'Saat ini Anda sedang menggunakan demo kledo.',
  'setup.click_button_below_to_clear_the_test_data_and_start_setting_up_your_property':
    'Klik tombol di bawah untuk menghapus data demo dan mulai setup perusahaan Anda ',
  'setup.are_you_sure_that_you_want_to_clear_demo_': 'Anda yakin ingin menghapus demo?',
  'setup.this_will_remove_all_rooms_room_types_rates_and_bookings':
    'Ini akan menghapus semua data, dan tidak dapat dikembalikan.',
  'setup.clear_test_data_and_setup': 'Hapus data demo dan lakukan setup',
  'setup.clear_demo_data': 'Hapus data demo',
  'setup.delete_all_data_now': 'Hapus seluruh data sekarang',
  'setup.continue_deleting_data': 'Lanjut hapus data',
  'setup.1_waring':
    'Halaman setup ini akan hanya tampil pada admin dengan hak akses Administrator.',
  'setup.2_waring':
    'Halaman ini akan hilang secara otomatis 1 bulan setelah tanggal pendaftaran di Kledo.',
  'setup.finish': 'Selesai',
  'setup.finish_setup': 'Selesaikan setup',
  'setup.demo':
    'Data yang tampil saat ini adalah data dummy. Setelah Anda siap, {setup} untuk mengosongkan data.',
  'setup.demo_link': 'klik disini',
  'setup.trial': 'Akses free trial Anda akan berakhir {days} hari lagi. {setup}.',
  'setup.trial_one_day': 'Akses free trial Anda akan berakhir dalam {hour} jam lagi. {setup}.',
  'setup.trial_zero_days': 'Akses free trial Anda telah berakhir. {setup}.',
  'setup.subscription': 'Paket berlangganan Anda akan habis dalam {days} hari lagi. {setup}.',
  'setup.subscription_one_day':
    'Paket berlangganan Anda akan berakhir dalam {hour} jam lagi. {setup}.',
  'setup.subscription_zero_days': 'Paket berlangganan Anda telah berakhir. {setup}.',
  'setup.go_to_dashboard': 'Pergi ke dashboard',
  'setup.setup_complete':
    'Data demo telah dihapus dan setup selesai, sekarang kamu bisa mulai menggunakan Kledo.',
  'setup.input_your_cash_and_account_bank_name':
    'Masukkan nama kas & rekening bank perusahaan Anda',
  'setup.input_your_company_name': 'Masukkan data perusahaan Anda',
  'setup.company_logo': 'Logo Perusahaan',
  'setup.cash': 'Kas',
  'setup.bca_account': 'Rekening BCA',
  'setup.mandiri_account': 'Rekening Mandiri',
  'setup.please_input_bank_account_name_or_delete_this_field':
    'Isi nama akun bank atau hapus baris ini.',

  'accountMapping.account_mappinng': 'Pemetaan Akun',

  'users.hello_please_select_company_to_proceed':
    'Halo {name}, silakan pilih perusahaan untuk melanjutkan',
  'users.please_select_company_to_proceed': 'silakan pilih perusahaan untuk melanjutkan',
  'users.hello': 'Halo',
  'users.you_are_invited_to_join': 'Anda diundang untuk bergabung di',
  'users.do_you_agree_to_join': 'Apakah Anda bersedia bergabung di',
  'users.all_users': 'Semua user',
  'users.select_user': 'Pilih pengguna',
  'users.yes': 'Ya',
  'users.no': 'Tidak',
  'users.add_user': 'Tambah pengguna',
  'users.delete_user': 'Hapus pengguna',
  'users.only_displays_users_other_than_you': 'Hanya menampilkan pengguna selain Anda',
  'users.please_select_a_user': 'Silakan pilih pengguna.',
  'users.select_user_to_delete': 'Pilih Pengguna untuk Dihapus',
  'users.edit_user': 'Ubah Pengguna',
  'users.name': 'Nama Lengkap',
  'users.email': 'Email',
  'users.role': 'Peran di perusahaan sebagai',
  'users.roles': 'Peran',
  'users.resending': 'Kirim Ulang',
  'users.resending_help':
    'Untuk mengkonfirmasi, silahkan buka inbox email, dan klik link yang dikirim oleh Kledo.',
  'users.success_resending': 'Sukses Mengirim Undangan',
  'users.success_resending_desc':
    'Undangan berhasil dikirim ulang ke email {email}. Silahkan buka inbox email Anda, dan klik link yang telah dikirm oleh Kledo.',
  'users.waiting_for_confirmation': 'Menunggu Konfirmasi',
  'users.please_input_the_email_address': 'Silakan masukkan alamat email.',
  'users.max_100_characters': 'Maks 100 karakter.',
  'users.invalid_email_address_format': 'Format alamat email tidak valid.',
  'users.please_select_a_role': 'Silakan pilih peran.',
  'users.select_roles': 'Pilih peran',
  'users.pending_invitation': '(Undangan tertunda)',
  'users.user': 'Pengguna',
  'users.successfully_added': 'berhasil ditambahkan.',
  'users.error_cannot_add': 'Tidak bisa menambahkan',
  'users.user_successfully_updated': 'Pengguna berhasil diperbarui.',
  'users.error_cannot_update_user': 'Gagal memperbarui pengguna.',
  'users.successfully_deleted': 'berhasil dihapus.',
  'users.error_cannot_delete': 'Gagal menghapus',
  'users.search_by_name_or_email': 'Cari berdasarkan nama atau email',
  'users.restore_password': 'Pulihkan Password',
  'users.enter_the_email_address_associated_with_your_account_and_well_email_you_a_link_to_reset_your_password':
    'Masukkan alamat email yang dikaitkan dengan akun Anda, dan kami akan mengirimi Anda email tautan untuk mereset password Anda ',
  'users.back_to_login': 'Kembali untuk Login',
  'users.send_reset_link': 'Kirim Reset Link',
  'users.please_check_your_email_inbox_and_follow_the_instruction_to_reset_your_password_if_email_hasnt_received_please_check_the_bulk_and_spam_folder':
    'Silakan periksa kotak masuk email Anda dan ikuti instruksi untuk mereset password Anda. Jika email belum diterima, silakan periksa folder bulk dan spam ',
  'users.please_input_your_email': 'Silakan masukkan email Anda',
  'users.the_input_is_not_valid_email_': 'Silakan masukkan email yang valid.',
  'users.welcome_to_kledo': 'SELAMAT DATANG DI KLEDO ',
  'users.welcome_to2': 'Selamat datang di ',
  'users.phone': 'Telepon',
  'users.password': 'Password',
  'users.confirm_new_password': 'Konfirmasi password baru',
  'users.activate': 'Mengaktifkan',
  'users.invalid_invitation_token': 'Token undangan tidak valid',
  'users.your_invitation_token_is_invalid_please_check_it_again_and_reload_the_page':
    'Silakan periksa lagi dan muat ulang halaman',
  'users.please_fill_the_register_form': 'Silakan isi formulir pendaftaran',
  'users.please_input_your_email_address': 'Silakan masukkan alamat email Anda',
  'users.please_input_your_name': 'Silakan masukkan nama Anda',
  'users.please_input_your_phone': 'Silakan masukkan no ponsel Anda',
  'users.only_number_allowed': 'Hanya angka yang diizinkan.',
  'users.please_input_new_password': 'Silakan masukkan Password Baru',
  'users.min_8_characters': 'Minimal 8 karakter',
  'users.password_atleast_8_characters_atleast_1_uppercase_and_atleast_1_digit':
    'Password setidaknya 8 karakter, setidaknya 1 huruf besar dan setidaknya 1 digit.',
  'users.two_passwords_that_you_enter_is_inconsistent_':
    'Dua password yang Anda masukkan tidak konsisten.',
  'users.welcome_to': 'SELAMAT DATANG DI',
  'users.please_log_in': 'Silakan masuk',
  'users.you_have_logged_out_automatically':
    'Anda sudah terlogout secara otomatis, silahkan login kembali untuk melanjutkan.',
  'users.remember_me': 'Ingat saya',
  'users.forgot_password_': 'lupa password?',
  'users.login': 'Masuk',
  'users.login_with_google': 'Sign in dengan Google',
  'users.login_failed': 'Login Gagal',
  'users.go_back_to_home_page': 'Kembali ke halaman utama',
  'users.failed_to_connect_to_kledo': 'Gagal terhubung ke server Kledo',
  'users.please_check_your_internet_connection_please_contact_wa':
    'Mohon periksa koneksi internet Anda. Jika masih mengalami kendala, silakan menghubungi via Whatsapp ke 62-896-5884-7000',
  'users.please_input_your_password': 'Silakan masukkan password Anda',
  'users.profile': 'Profil',
  'users.reset_password': 'Setel Ulang Password',
  'users.request_new_password_reset': 'Minta reset password lagi',
  'users.new_password': 'Password baru',
  'users.invalid_password_reset_token': 'Token tidak valid',
  'users.your_password_reset_token_is_invalid_please_check_it_again_and_reload_the_page':
    'Token atur ulang password Anda tidak valid, periksa lagi dan muat ulang halaman',
  'users.users': 'Pengguna',
  'users.information': 'Informasi',
  'users.information_invitation_email':
    'Email sudah dikirim ke {email}. Silakan minta kolega Anda untuk membuka email dan mengikuti instruksi berikutnya untuk mengaktifkan akun. Jika email tidak diterima, silahkan cek folder bulk dan folder spam.',
  'users.create_kledo_accounts': 'Buat Akun Kledo',
  'users.congratulation_': 'Selamat!',
  'users.registration_failed': 'Registrasi gagal',
  'users.your_kledo_account_successfully_created': 'Akun Kledo Anda berhasil dibuat.',
  'users.your_username_and_password_already_sent_to_your_email':
    'Nama pengguna dan kata sandi Anda sudah dikirim ke email Anda',
  'users.kledo_app_url': 'Alamat subdomain yang kamu inginkan',
  'users.kledo_app_url_is_required': 'Alamat subdomain Aplikasi Kledo diperlukan',
  'users.kledo_app_arl_must_be_at_least_3_characters':
    'Alamat subdomain Aplikasi Kledo minimal 3 karakter',
  'users.kledo_app_url_cannot_be_longer_than_20_characters':
    'Url Aplikasi Kledo tidak boleh lebih dari 20 karakter',
  'users.name_is_required': 'Nama harus diisi',
  'users.name_must_be_at_least_5_characters': 'Nama harus minimal 5 karakter',
  'users.name_cannot_be_longer_than_20_characters': 'Nama tidak boleh lebih dari 20 karakter',
  'users.property_name': 'Nama perusahaan',
  'users.property_name_is_required': 'Nama perusahaan wajib diisi',
  'users.property_name_must_be_at_least_5_characters': 'Nama perusahaan minimal 5 karakter',
  'users.property_name_cannot_be_longer_than_20_characters':
    'Nama perusahaan tidak boleh lebih dari 20 karakter',
  'users.role_is_required': 'Jabatan harus diisi',
  'users.role_must_be_at_least_3_characters': 'Jabatan minimal 3 karakter',
  'users.role_cannot_be_longer_than_20_characters': 'Jabatan tidak boleh lebih dari 20 karakter',
  'users.phone_number': 'Nomor telepon',
  'users.phone_number_is_required': 'Nomor Telepon harus diisi',
  'users.no_phone_number_worng': 'Nomor telepon salah',
  'users.phone_number_must_be_at_least_5_characters': 'Nomor Telepon minimal 5 karakter',
  'users.phone_number_cannot_be_longer_than_20_characters':
    'Nomor Telepon tidak boleh lebih dari 20 karakter',
  'users.email_is_required': 'Email harus diisi',
  'users.email_is_not_a_valid_email': 'Email bukan email yang valid',
  'users.register_now': 'Daftar sekarang',
  'users.login_now': 'Masuk sekarang',
  'users.domain_value_app_kledo_com_already_used_please_use_another_name':
    'Domain {value}.app.kledo.com sudah digunakan, silakan gunakan nama lain',
  'users.please_contact_hello_kledo_com_for_more_info':
    'Silakan hubungi hello@kledo.com untuk info lebih lanjut.',
  'users.registration_completed': 'Pendaftaran berhasil!',
  'users.password_has_been_sent_to_email_sms':
    'Password telah dikirim ke email dan sms mu, silakan cek ya',
  'users.click_here_to_set_roles': '{clickHere} untuk mengatur peran.',

  'report.point_of_sale': 'Point Of Sale',
  'report.net_sales_per_product_category': 'Net Penjualan per Kategori Produk',
  'report.product_category': 'Kategori Produk',
  'report.volume_per_product_category': 'Volume per KategoriProduk',
  'report.gross_sales': 'Gross Penjualan',
  'report.net_sales': 'Net Penjualan',
  'report.discount': 'Discount',
  'report.gratuity': 'Gratuity',
  'report.collected': 'collected',
  'report.total_collected': 'Total Collected',
  'report.all_outlets': 'Semua',
  'report.payment': 'Pembayaran',
  'report.day_of_the_week_sales': 'Day Of The Week Penjualan',
  'report.sales_summary': 'Penjalan Summary',
  'report.invoice': 'Invoice',
  'report.transactions': 'Transaksi',
  'report.transactions_type': 'Tipe Transaksi',
  'report.choose_transactions_type': 'Pilih tipe transaksi',
  'report.buy_sell_price': 'Harga Jual/Beli',
  'report.avg_per_transaction': 'Avg. Net Sale Per Transactions',
  'report.name': 'Nama',
  'report.volume': 'Volume',
  'report.top_products': 'Top Produk',
  'report.pos': 'Pos',
  'report.pos_reports': 'Pos Reports',
  'report.outlets': 'Outlets',

  'financeAccounts.add_account': 'Tambah Akun',
  'financeAccounts.financeAccounts': 'Akun',
  'financeAccounts.all_accounts': 'Semua akun',
  'financeAccounts.select_category': 'Pilih kategori',
  'financeAccounts.category': 'Kategori',
  'financeAccounts.please_input_the_code': 'Silakan masukkan kode.',
  'financeAccounts.successfully_added': 'berhasil ditambahkan.',
  'financeAccounts.error_cannot_add': 'Tidak dapat menambah',
  'financeAccounts.error_cannot_update': 'Tidak dapat mengubah akun.',
  'financeAccounts.error_cannot_move': 'Tidak dapat memindah akun.',
  'financeAccounts.error_cannot_change_status': 'Tidak dapat mengubah status akun.',
  'financeAccounts.edit_account': 'Ubah Akun',
  'financeAccounts.please_input_the_account_name': 'Silakan masukkan nama akun.',
  'financeAccounts.account_name': 'Nama',
  'financeAccounts.code': 'Kode',
  'financeAccounts.desc': 'Deskripsi',
  'financeAccounts.please_select_a_category': 'Silakan pilih kategori.',
  'financeAccounts.max_20_characters': 'Maks 20 karakter.',
  'financeAccounts.max_100_characters': 'Maks 100 karakter.',
  'financeAccounts.min_2_characters': 'Min 2 karakter.',
  'financeAccounts.balance': 'Saldo',
  'financeAccounts.only_alphanumeric_allowed': 'Hanya huruf dan angka yang diperkenankan.',
  'financeAccounts.select_account': 'Pilih akun',
  'financeAccounts.set_conversion_balances': 'Saldo Awal',
  'financeAccounts.error_get_conversion_balances': 'Terjadi masalah cek konversi saldo',
  'financeAccounts.error_cannot_load_data': 'Tidak dapat mengambil data akun.',
  'financeAccounts.all_category': 'Semua kategori',
  'financeAccounts.error_cannot_delete': 'Tidak dapat menghapus akun.',
  'financeAccounts.error_cannot_search': 'Tidak dapat mencari akun.',
  'financeAccounts.set_manual_journal': 'Jurnal Umum',
  'financeAccounts.follow_these_steps_to_import_your_account':
    'Ikuti langkah-langkah berikut untuk meng-import akun',
  'financeAccounts.1_download_your_account_template_file': '1. Download file template akun',
  'financeAccounts.start_by_downloading_our_account_csv':
    'Mulai dengan mengunduh file template Excel. File ini mempunyai kolom header yang yang dibutuhkan Kledo untuk mengimport detail dari akun-mu.',
  'financeAccounts.download_template_file': 'Download File Template',
  'financeAccounts.download_template_data_example_file': 'Download File Contoh',
  'financeAccounts.import': 'Import Akun',
  'financeAccounts.2_copy_your_account_into_the_template': '2. Salin detail akun ke dalam template',
  'financeAccounts.using_excel_or_another_spreadsheet_editor':
    'Menggunakan Excel atau software spreadsheeet lainnya, masukkan detail akun ke dalam file template dari Kledo. Pastikan detail akun sesuai dengan kolom header yang ada di template.',
  'financeAccounts.important': 'PENTING',
  'financeAccounts.3_upload_the_updated_template_file_here':
    '3. Upload template yang sudah diupdate ke sini',
  'financeAccounts.upload': 'Upload',
  'financeAccounts.save_your_updated_template_as_.csv_or_.txt_and_you_are_ready_to_go':
    'Simpan file template yang sudah diupdate',
  'financeAccounts.a_maximum_of_500_account_can_be_imported_at_a_time':
    'Maksimal 1.000 akun dapat diimport dalam satu waktu.',
  'financeAccounts.import_actions_only_insert_new_account_cannot_update_or_delete':
    'Saat meng-import, Kledo hanya akan memasukkan data baru, tidak ada data yang dihapus atau diupdate.',
  'financeAccounts.dont_change_the_column_heading_in_the_template_file':
    'Jangan mengubah header kolom di dalam template. Ini dibutuhkan agar import dapat berjalan.',
  'financeAccounts.account_successfully_imported': '{rowCount} baris akun berhasil diimport.',
  'financeAccounts.go_to_account_list': 'Ke halaman daftar akun',
  'financeAccounts.sub_account_from': 'Sub Akun dari',

  'financeClosing.closing': 'Tutup Buku',
  'financeClosing.period': 'Periode',
  'financeClosing.note': 'Catatan',
  'financeClosing.profit_loss': 'Laba/Rugi',
  'financeClosing.choose_period': 'Pilih Periode',
  'financeClosing.set_worksheet': 'Set Kertas Kerja',
  'financeClosing.worksheet': 'Kertas Kerja',
  'financeClosing.done': 'Selesai',
  'financeClosing.please_select_period': 'Silakan pilih periode tutup buku',
  'financeClosing.please_select_date': 'Silakan pilih tanggal',
  'financeClosing.please_select_account': 'Silakan pilih akun',
  'financeClosing.closing_report': 'Laporan Tutup Buku',
  'financeClosing.closing_period_to_has_been_saved':
    'Tutup buku periode {from} s/d {to} berhasil disimpan',
  'financeClosing.period_from_to': 'Periode: {from} - {to}',
  'financeClosing.see_closing_report': 'Lihat Laporan Tutup Buku',
  'financeClosing.error_get_start_date': 'Tidak dapat mengambil tanggal awal',
  'financeClosing.error_get_closing_data': 'Tidak dapat mengambil data tutup buku',
  'financeClosing.error_create_closing_data': 'Tidak dapat membuat data tutup buku',
  'financeClosing.error_delete_closing_data': 'Tidak dapat menghapus data tutup buku',
  'financeClosing.debit': 'Debit',
  'financeClosing.credit': 'Kredit',
  'financeClosing.net_profit_loss': 'Laba Bersih',
  'financeClosing.tax_expense': 'Pajak Beban',
  'financeClosing.tax_expense_account': 'Akun Pajak Beban',
  'financeClosing.tax_payable_account': 'Akun Hutang Pajak',
  'financeClosing.tax_amount': 'Jumlah Pajak',
  'financeClosing.tax_percent': 'Persen Pajak',
  'financeClosing.net_profit_loss_after_tax': 'Laba Bersih Setelah Pajak',
  'financeClosing.retained_earnings_account': 'Akun Pendapatan Tertahan',
  'financeClosing.early_period': 'Periode Awal',

  'financeContacts.paid_status': 'Status Dibayar',
  'financeContacts.transaction_number': 'Nomor Transaksi',
  'financeContacts.payment_note': 'Catatan Pembayaran',
  'financeContacts.payment_account_code': 'Kode Akun Pembarayan',
  'financeContacts.photo': 'Foto',
  'financeContacts.show_photo': 'Tampilkan Foto',
  'financeContacts.hide_photo': 'Sembunyikan Foto',
  'financeContacts.add_contact': 'Tambah Kontak',
  'financeContacts.financeContacts': 'Kontak',
  'financeContacts.contact_type': 'Tipe Kontak',
  'financeContacts.select_contact_type': 'Pilih tipe kontak',
  'financeContacts.successfully_added': 'berhasil ditambahkan.',
  'financeContacts.error_cannot_add': 'Tidak dapat menambah kontak.',
  'financeContacts.error_cannot_update': 'Tidak dapat mengubah kontak.',
  'financeContacts.edit_contact': 'Ubah Kontak',
  'financeContacts.please_input_the_contact_name': 'Silakan masukkan nama kontak.',
  'financeContacts.please_select_a_contact_type': 'Silakan pilih tipe kontak.',
  'financeContacts.please_select_a_required_contact_type': 'Tipe kontak {contacts} wajib dipilih.',
  'financeContacts.please_select_a_contact_type_with_minimal':
    'Silakan pilih tipe kontak, minimal 1 tipe kontak.',
  'financeContacts.contact_group': 'Grup Kontak',
  'financeContacts.please_select_a_contact_group': 'Silakan pilih grup kontak.',
  'financeContacts.contact_name': 'Nama',
  'financeContacts.all_contact_type': 'Semua tipe kontak',
  'financeContacts.max_characters': 'Maks {value} karakter.',
  'financeContacts.min_characters': 'Min {value} karakter.',
  'financeContacts.balance': 'Saldo',
  'financeContacts.address': 'Alamat',
  'financeContacts.email': 'Email',
  'financeContacts.add_email': 'Tambah Email',
  'financeContacts.npwp': 'NPWP',
  'financeContacts.invalid_email_address_format': 'Format email tidak valid.',
  'financeContacts.only_alphanumeric_allowed': 'Hanya huruf dan angka yang diperkenankan.',
  'financeContacts.company': 'Perusahaan',
  'financeContacts.alternative_email': 'Email Alternatif',
  'financeContacts.email_cannot_be_the_same': 'Email tidak boleh sama',
  'financeContacts.phone': 'Telepon',
  'financeContacts.secondary_phone': 'Telepon sekunder',
  'financeContacts.only_number_allowed': 'Hanya angka yang diperkenankan.',
  'financeContacts.error_cannot_search': 'Tidak dapat mencari kontak.',
  'financeContacts.group': 'Grup',
  'financeContacts.all_group': 'Semua Grup',
  'financeContacts.add_group': 'Tambah Grup',
  'financeContacts.edit_group': 'Ubah Grup',
  'financeContacts.delete_group': 'Hapus Group',
  'financeContacts.only_group_that_not_assigned_in_contact_could_be_deleted':
    'Catatan: Hanya grup yang belum mempunyai kontak yang dapat dihapus.',
  'financeContacts.billing_address': 'Alamat Penagihan',
  'financeContacts.shipping_address': 'Alamat Pengiriman',
  'financeContacts.account_mapping': 'Pemetaan Akun',
  'financeContacts.show_phone_detail': 'Tampilkan telepon detail',
  'financeContacts.hide_phone_detail': 'Sembunyikan telepon detail',
  'financeContacts.show_account_mapping': 'Tampilkan pemetaan akun',
  'financeContacts.hide_account_mapping': 'Sembunyikan pemetaan akun',
  'financeContacts.payable_account': 'Akun Hutang',
  'financeContacts.receivable_account': 'Akun Piutang',
  'financeContacts.transactions': 'Transaksi',
  'financeContacts.successfully_deleted': 'berhasil dihapus.',
  'financeContacts.error_cannot_delete': 'Tidak dapat menghapus grup kontak.',
  'financeContacts.error_cannot_add_group': 'Tidak dapat menambah grup kontak.',
  'financeContacts.error_cannot_update_group': 'Tidak dapat mengubah grup kontak.',
  'financeContacts.error_cannot_move': 'Tidak dapat memindah grup kontak.',
  'financeContacts.error_cannot_change_status': 'Tidak dapat mengubah status grup kontak.',
  'financeContacts.error_cannot_load_can_be_deleted_group':
    'Tidak dapat mengambil data grup kontak.',
  'financeContacts.follow_these_steps_to_import_your_contact':
    'Ikuti langkah-langkah berikut untuk meng-import kontak',
  'financeContacts.1_download_your_contact_template_file': '1. Download file template kontak',
  'financeContacts.start_by_downloading_our_contact_csv':
    'Mulai dengan mengunduh file template dalam format Excel. File ini mempunyai kolom header yang yang dibutuhkan Kledo untuk mengimport detail dari kontak-mu.',
  'financeContacts.download_template_file': 'Download File Template',
  'financeContacts.download_template_data_example_file': 'Download File Contoh',
  'financeContacts.import': 'Import Kontak',
  'financeContacts.2_copy_your_contact_into_the_template':
    '2. Salin detail kontak ke dalam template',
  'financeContacts.using_excel_or_another_spreadsheet_editor':
    'Menggunakan Excel atau software spreadsheeet lainnya, masukkan detail kontak ke dalam file template dari Kledo. Pastikan detail kontak sesuai dengan kolom header yang ada di template.',
  'financeContacts.important': 'PENTING',
  'financeContacts.3_upload_the_updated_template_file_here':
    '3. Upload template yang sudah diupdate ke sini',
  'financeContacts.upload': 'Upload',
  'financeContacts.save_your_updated_template_as_.csv_or_.txt_and_you_are_ready_to_go':
    'Simpan file template yang sudah diupdate',

  'financeContacts.dont_change_the_column_heading_in_the_template_file':
    'Jangan mengubah header kolom di dalam template. Ini dibutuhkan agar import dapat berjalan.',
  'financeContacts.a_maximum_of_1000_contact_can_be_imported_at_a_time':
    'Maksimal 1.000 kontak dapat diimport dalam satu waktu.',
  'financeContacts.import_benefit_with_update':
    'Kamu juga dapat menggunakan file yang sudah diimport untuk mengupdate semua kontakmu, menghemat waktu dan tenaga daripada mengupdate kontak satu persatu melalui browser.',
  'financeContacts.warning_update_contact_using_import':
    'Proses import akan menggunakan Nama Kontak, Perusahaan, dan Tipe kontak untuk menentukan apakah suatu kontak sudah ada di Kledo atau belum, jika kontak sudah ada maka kontak akan diupdate, sedangkan jika kontak belum ada maka akan dibuat kontak baru. Kontak tidak akan dihapus, sehingga kamu bisa dengan aman mengupdate sebagian kontakmu saja tanpa khawatir akan kehilangan data dikontak yang lain.',

  'financeContacts.contact_successfully_imported': '{rowCount} baris kontak berhasil diimport.',
  'financeContacts.go_to_contact_list': 'Ke halaman daftar kontak',
  'financeContacts.mass_delete_info':
    '**Kontak  yang telah mempunyai transaksi tidak dapat dihapus, hanya bisa diarsipkan.',
  'financeContacts.want_to_use_this_contact': 'Apakah Anda akan menggunakan kontak ini ?',
  'financeContacts.are_you_sure_to_archive': 'Apakah Anda yakin untuk mengarsipkan kontak ini?',
  'financeContacts.are_you_sure_to_unarchive': 'Apakah Anda yakin untuk batalkan arsip kontak ini?',
  'financeContacts.your_net_debt': 'Net hutang Anda',
  'financeContacts.you_owe': 'Anda hutang',
  'financeContacts.they_owe': 'Mereka Hutang',
  'financeContacts.payment_received': 'Pembayaran diterima',
  'financeContacts.your_due_debt': 'Hutang Anda jatuh tempo',
  'financeContacts.their_due_debt': 'Hutang mereka jatuh tempo',
  'financeContacts.payment_sent': 'Pembayaran dikirim',
  'financeContacts.awaiting_payment': 'Piutang menunggu pembayaran',
  'financeContacts.debt_you_need_to_pay': 'Hutang yang perlu Anda bayar',
  'financeContacts.money_in_out': 'Keluar Masuk Uang',
  'financeContacts.money_in': 'Uang Masuk',
  'financeContacts.money_out': 'Uang keluar',
  'financeContacts.bank_accounts': 'Rekening Bank',
  'financeContacts.bank': 'Bank',
  'financeContacts.branch': 'Cabang',
  'financeContacts.holder_name': 'Nama Pemilik',
  'financeContacts.account_number': 'Nomor Rekening',
  'financeContacts.required': 'Harus diisi',
  'financeContacts.select_shipping_address': 'Pilih Alamat Pengiriman',
  'financeContacts.revert_to_default_address': 'Kembalikan ke alamat default',
  'financeContacts.max_payable': 'Maksimal Hutang',
  'financeContacts.total_payable_is_calculated_from_purchase_invoices':
    'Total hutang dihitung dari tagihan pembelian, transaksi biaya dan kontak hutang. Jika total hutang melebihi maksimal, maka saat membuat tagihan pembelian baru akan ditolak.',
  'financeContacts.max_receivable': 'Maksimal Piutang',
  'financeContacts.total_accounts_receivable_is_calculated_from_sales_invoices':
    'Total piutang dihitung dari tagihan penjualan dan kontak piutang. Jika total piutang melebihi maksimal, maka saat membuat tagihan penjualan baru akan ditolak.',
  'financeContacts.country': 'Negara',
  'financeContacts.province': 'Provinsi',
  'financeContacts.city': 'Kota',
  'financeContacts.district': 'Kecamatan',
  'financeContacts.village': 'Kelurahan',
  'financeContacts.id_card_type': 'Tipe Kartu Identitas',
  'financeContacts.id_card_number': 'ID Kartu Identitas',
  'financeContacts.please_select_id_card_type': 'Silahkan pilih tipe kartu identitas',

  'financeExpenseTerms.delete_expenseTerm': 'Hapus Termin',
  'financeExpenseTerms.are_you_sure_delete': 'Apakah Anda yakin untuk menghapus?',
  'financeExpenseTerms.select_expenseTerm': 'Pilih Termin',
  'financeExpenseTerms.please_select_expenseTerm_to_delete': 'Silakan pilih Termin untuk dihapus',
  'financeExpenseTerms.add_expenseTerm': 'Tambah Termin',
  'financeExpenseTerms.financeExpenseTerms': 'Termin',
  'financeExpenseTerms.successfully_deleted': 'berhasil dihapus.',
  'financeExpenseTerms.successfully_added': 'berhasil ditambahkan.',
  'financeExpenseTerms.error_cannot_delete': 'Tidak dapat menghapus termin.',
  'financeExpenseTerms.error_cannot_add': 'Tidak dapat menambah termin.',
  'financeExpenseTerms.error_cannot_update': 'Tidak dapat mengubah termin.',
  'financeExpenseTerms.error_cannot_move': 'Tidak dapat memindah termin.',
  'financeExpenseTerms.error_cannot_change_status': 'Tidak dapat mengubah status termin.',
  'financeExpenseTerms.edit_expenseTerm': 'Ubah Termin',
  'financeExpenseTerms.please_input_the_expenseTerm_name': 'Silakan masukkan nama termin.',
  'financeExpenseTerms.please_input_the_days': 'Silakan masukkan jumlah hari.',
  'financeExpenseTerms.expenseTerm_name': 'Nama',
  'financeExpenseTerms.length': 'Lama',
  'financeExpenseTerms.days': 'Hari',
  'financeExpenseTerms.max_characters': 'Maks {value} karakter.',
  'financeExpenseTerms.min_characters': 'Min {value} karakter.',
  'financeExpenseTerms.only_alphanumeric_allowed': 'Hanya huruf dan angka yang diperkenankan.',
  'financeExpenseTerms.only_number_allowed': 'Hanya angka yang diperkenankan.',
  'financeExpenseTerms.only_0_-_1000_allowed': 'Hanya 0 - 1000 diperkenankan',
  'financeExpenseTerms.only_0_-_10000_allowed': 'Hanya 0 - 10.000 diperkenankan',
  'financeExpenseTerms.must_have_active_termin': 'Harus ada minimal satu termin aktif.',

  'financeTaxes.add_tax': 'Tambah Pajak',
  'financeTaxes.financeTaxes': 'Pajak',
  'financeTaxes.successfully_added': 'berhasil ditambahkan.',
  'financeTaxes.error_cannot_add': 'Tidak dapat menambah pajak.',
  'financeTaxes.error_cannot_update': 'Tidak dapat mengubah pajak.',
  'financeTaxes.error_cannot_move': 'Tidak dapat memindah pajak.',
  'financeTaxes.error_cannot_change_status': 'Tidak dapat mengubah status pajak.',
  'financeTaxes.edit_tax': 'Ubah Pajak',
  'financeTaxes.please_input_the_tax_name': 'Silakan masukkan nama pajak.',
  'financeTaxes.please_input_the_effective_rates': 'Silakan masukkan persentase efektif.',
  'financeTaxes.please_select_a_sell_tax_account': 'Silakan pilih akun pajak penjualan.',
  'financeTaxes.please_select_a_buy_tax_account': 'Silakan pilih akun pajak pembelian.',
  'financeTaxes.select_buy_tax_account': 'Pilih akun pajak pembelian',
  'financeTaxes.select_sell_tax_account': 'Pilih akun pajak penjualan',
  'financeTaxes.tax_name': 'Nama',
  'financeTaxes.effective_rates': 'Persentase efektif',
  'financeTaxes.is_withholding': 'Pemotongan',
  'financeTaxes.buy_tax_account': 'Akun Pajak Pembelian',
  'financeTaxes.sell_tax_account': 'Akun Pajak Penjualan',
  'financeTaxes.max_characters': 'Maks {value} karakter.',
  'financeTaxes.min_characters': 'Min {value} karakter.',
  'financeTaxes.only_alphanumeric_allowed': 'Hanya huruf dan angka yang diperkenankan.',
  'financeTaxes.only_number_allowed': 'Hanya angka yang diperkenankan.',
  'financeTaxes.effective_rate_%': 'Persentase Efektif %',
  'financeTaxes.must_have_active_tax': 'Harus ada minimal satu pajak aktif.',
  'financeTaxes.witholding': 'Pemotongan',
  'financeTaxes.error_cannot_load_data_detail': 'Tidak dapat memuat data detail pajak.',
  'financeTaxes.delete_tax': 'Hapus Pajak',
  'financeTaxes.are_you_sure_delete': 'Apakah Anda yakin untuk menghapus?',
  'financeTaxes.select_tax': 'Pilih Pajak',
  'financeTaxes.please_select_tax_to_delete': 'Silakan pilih Pajak untuk dihapus',
  'financeTaxes.successfully_deleted': 'berhasil dihapus.',
  'financeTaxes.error_cannot_delete': 'Tidak dapat menghapus pajak.',
  'financeTaxes.manually_edit_tax_amount_allowed': 'Besaran rupiah pajak bisa diubah secara manual',
  'financeTaxes.allow_manual': 'Allow manual',
  'financeTaxes.compound': 'Majemuk',
  'financeTaxes.compound_help_text':
    'Pajak dilipatgandakan akan diterapkan terhadap total setelah pajak sebelumnya diterapkan. Dengan urutan pajak.',
  'financeTaxes.tax_type': 'Tipe Pajak',
  'financeTaxes.one': 'Satu',
  'financeTaxes.consist_of': 'Terdiri dari',
  'financeTaxes.consist_of_is_required': 'Minimal 1 pajak terpilih.',
  'financeTaxes.effective_percentage': 'Persentase Efektif',
  'financeTaxes.error_effective_percentage': 'Tidak dapat mendapatkan persentase efektif',
  'financeTaxes.tax_group': 'Grup Pajak',

  'financeUnits.error_cannot_move': 'Tidak dapat memindah satuan',
  'financeUnits.name': 'Nama',
  'financeUnits.max_characters': 'Maks {value} karakter.',
  'financeUnits.min_characters': 'Min {value} karakter.',
  'financeUnits.only_alphanumeric_allowed': 'Hanya huruf dan angka yang diperkenankan.',
  'financeUnits.please_input_the_unit_name': 'Silakan masukkan nama satuan.',
  'financeUnits.error_cannot_add': 'Tidak dapat menambah satuan.',
  'financeUnits.error_cannot_update': 'Tidak dapat mengubah satuan.',
  'financeUnits.error_cannot_delete': 'Tidak dapat menghapus satuan.',
  'financeUnits.please_select_unit_to_delete': 'Silakan pilih Satuan untuk dihapus',
  'financeUnits.select_unit': 'Pilih Satuan',
  'financeUnits.unit': 'Satuan',

  'financeExpenses.add_expense': 'Tambah Biaya',
  'financeExpenses.financeExpenses': 'Biaya',
  'financeExpenses.successfully_added': 'berhasil ditambahkan.',
  'financeExpenses.error_cannot_add': 'Tidak dapat menambah biaya.',
  'financeExpenses.error_cannot_update': 'Tidak dapat mengubah biaya.',
  'financeExpenses.error_cannot_move': 'Tidak dapat memindah biaya.',
  'financeExpenses.error_cannot_change_status': 'Tidak dapat mengubah status biaya.',
  'financeExpenses.edit_expense': 'Ubah Biaya',
  'financeExpenses.please_input_the_expense_name': 'Silakan masukkan nama biaya.',
  'financeExpenses.please_input_the_effective_rates': 'Silakan masukkan persentase efektif.',
  'financeExpenses.please_select_a_sell_expense_account': 'Silakan pilih akun biaya penjualan.',
  'financeExpenses.please_select_a_buy_expense_account': 'Silakan pilih akun biaya pembelian.',
  'financeExpenses.select_buy_expense_account': 'Pilih akun biaya pembelian',
  'financeExpenses.select_sell_expense_account': 'Pilih akun biaya penjualan',
  'financeExpenses.expense_name': 'Nama',
  'financeExpenses.effective_rates': 'Persentase Efektif',
  'financeExpenses.is_withholding': 'Pemotongan',
  'financeExpenses.buy_expense_account': 'Akun biaya pembelian',
  'financeExpenses.sell_expense_account': 'Akun biaya penjualan',
  'financeExpenses.max_characters': 'Maks {value} karakter.',
  'financeExpenses.min_characters': 'Min {value} karakter.',
  'financeExpenses.only_alphanumeric_allowed': 'Hanya huruf dan angka yang diperkenankan.',
  'financeExpenses.only_number_allowed': 'Hanya angka yang diperkenankan.',
  'financeExpenses.expense_account': 'Akun Biaya',
  'financeExpenses.select_expense_account': 'Pilih akun',
  'financeExpenses.description': 'Deskripsi',
  'financeExpenses.tax': 'Pajak',
  'financeExpenses.select_tax': 'Pilih pajak',
  'financeExpenses.amount': 'Total',
  'financeExpenses.pay_from': 'Dibayar Dari',
  'financeExpenses.please_select_a_pay_from': 'Silakan pilih dibayar dari',
  'financeExpenses.select_pay_from': 'Pilih dibayar dari',
  'financeExpenses.pay_later': 'Bayar Nanti',
  'financeExpenses.benefeciary': 'Penerima',
  'financeExpenses.please_select_a_benefeciary': 'Silakan pilih penerima',
  'financeExpenses.select_benefeciary': 'Pilih penerima',
  'financeExpenses.add_contact': 'Tambah kontak',
  'financeExpenses.all_contacts': 'Semua kontak',
  'financeExpenses.transaction_date': 'Tgl Transaksi',
  'financeExpenses.please_select_transaction_date': 'Silakan pilih tanggal transaksi',
  'financeExpenses.due_date': 'Tanggal Jatuh Tempo',
  'financeExpenses.please_select_due_date': 'Silakan pilih tanggal jatuh tempo',
  'financeExpenses.term': 'Termin',
  'financeExpenses.select_term': 'Pilih termin',
  'financeExpenses.add_term': 'Tambah termin',
  'financeExpenses.price_include_tax': 'Harga termasuk pajak',
  'financeExpenses.add_more': 'Tambah baris',
  'financeExpenses.payment_method': 'Metode Pembayaran',
  'financeExpenses.please_select_a_payment_method': 'Silakan pilih metode pembayaran',
  'financeExpenses.select_payment_method': 'Pilih metode pembayaran',
  'financeExpenses.number': 'Nomor',
  'financeExpenses.please_input_the_number': 'Silakan isikan nomor.',
  'financeExpenses.memo': 'Reference',
  'financeExpenses.date': 'Tanggal',
  'financeExpenses.category': 'Kategori',
  'financeExpenses.status': 'Status',
  'financeExpenses.balance_due': 'Sisa Tagihan',
  'financeExpenses.total': 'Total',
  'financeExpenses.please_select_a_term': 'Silakan pilih termin.',
  'financeExpenses.search_by_memo': 'Cari',
  'financeExpenses.the_due_date_max_higger_than_transaction_date':
    'Tanggal jatuh tempo harus sama atau setelah tanggal transaksi',
  'financeExpenses.detail_title': 'Biaya - {status}',
  'financeExpenses.pay_bill': 'Bayar Tagihan',
  'financeExpenses.detail_expense': 'Detil Biaya',
  'financeExpenses.total_amount_must_greater_than_total_payment':
    'Total transaksi harus lebih besar atau sama dengan total pembayaran.',
  'financeExpenses.all': 'Semua',
  'financeExpenses.unpaid': 'Belum Dibayar',
  'financeExpenses.partial': 'Bayar Sebagian',
  'financeExpenses.closed': 'Closed',
  'financeExpenses.follow_these_steps_to_import_your_expenses':
    'Ikuti langkah berikut untuk mengimport biayamu',
  'financeExpenses.1_download_your_expenses_template_file': '1. Download file template biaya',
  'financeExpenses.start_by_downloading_our_expenses_csv':
    'Mulai dengan mengunduh file template Excel. File ini mempunyai kolom header yang yang dibutuhkan Kledo untuk mengimport detail dari biaya-biayamu.',
  'financeExpenses.download_template_file': 'Download File Template',
  'financeExpenses.download_template_data_example_file': 'Download File Contoh',
  'financeExpenses.import': 'Import Biaya',
  'financeExpenses.2_copy_your_expenses_into_the_template':
    '2. Salin detail biaya ke dalam template',
  'financeExpenses.using_excel_or_another_spreadsheet_editor':
    'Menggunakan Excel atau software spreadsheeet lainnya, masukkan detail biaya ke dalam file template dari Kledo. Pastikan detail biaya sesuai dengan kolom header yang ada di template.',
  'financeExpenses.important': 'PENTING',
  'financeExpenses.3_upload_the_updated_template_file_here':
    '3. Upload template yang sudah diupdate ke sini',
  'financeExpenses.upload': 'Upload',
  'financeExpenses.save_your_updated_template_as_.csv_or_.txt_and_you_are_ready_to_go':
    'Simpan file template yang sudah diupdate',
  'financeExpenses.a_maximum_of_500_expenses_can_be_imported_at_a_time':
    'Maksimal 1.000 biaya dapat diimport dalam satu waktu.',
  'financeExpenses.contact_name_that_not_exist_will_be_inserted_as_a_new_contact':
    'Nama kontak yang yang belum ada di Kledo akan dimasukkan sebagai kontak baru.',
  'financeExpenses.import_actions_only_insert_new_expenses_cannot_update_or_delete':
    'Saat meng-import, Kledo hanya akan memasukkan data baru, tidak ada data yang dihapus atau diupdate.',
  'financeExpenses.dont_change_the_column_heading_in_the_template_file':
    'Jangan mengubah header kolom di dalam template. Ini dibutuhkan agar import dapat berjalan.',
  'financeExpenses.you_can_only_upload_csv_file':
    'Anda hanya dapat mengunggah file csv atau excel.',
  'financeExpenses.upload_file': 'Upload File',
  'financeExpenses.check_the_imported_data': 'Cek Data',
  'financeExpenses.please_check_the_imported_data': 'Silakan cek data yang diimport',
  'financeExpenses.import_expenses_done': 'Import Berhasil',
  'financeExpenses.error_upload_file': 'Gagal mengunggah file.',
  'financeExpenses.file_not_found': 'File tidak ditemukan.',
  'financeExpenses.if_you_are_happy_to_continue_with_the_import_please_click_the_import_now_button': `Jika data yang diimport sesuai dengan yang kamu harapkan, silakan klik tombol "Import Sekarang".`,
  'financeExpenses.contact_name': 'Nama Kontak',
  'financeExpenses.email': 'Email',
  'financeExpenses.address': 'Alamat',
  'financeExpenses.phone': 'Phone',
  'financeExpenses.expense_date': 'Tanggal Biaya',
  'financeExpenses.expense_number': 'Nomor Biaya',
  'financeExpenses.reference': 'Reference',
  'financeExpenses.account_code': 'Kode Akun',
  'financeExpenses.tax_inclusive': 'Termasuk Pajak',
  'financeExpenses.paid': 'Lunas',
  'financeExpenses.paid_account': 'Akun Pembayaran',
  'financeExpenses.row_imported': '{imported} dari {total} baris diproses',
  'financeExpenses.expenses_successfully_imported': '{rowCount} biaya berhasil diimport.',
  'financeExpenses.view_detail': 'Tampilkan detil',
  'financeExpenses.import_another_file': 'Import file lain',
  'financeExpenses.go_to_expenses_list': 'Ke halaman daftar biaya',
  'financeExpenses.success': 'Berhasil',
  'financeExpenses.failed': 'Gagal',
  'financeExpenses.error_cannot_import': 'Gagal import.',
  'financeExpenses.error': 'Error',
  'financeExpenses.import_expenses_in_progress': 'Import in progress',
  'financeExpenses.import_expenses_is_still_in_progress': 'Import is still in progress',
  'financeExpenses.its_ok_to_close_this_page': `It's OK to close this page or import another file. When import done, you will be notified via email.`,

  'financePurchaseOrders.message': 'Pesan',
  'financePurchaseOrders.customer': 'Pelanggan',
  'financePurchaseOrders.show_message': 'Tampilkan Pesan',
  'financePurchaseOrders.hide_message': 'Sembunyikan Pesan',
  'financePurchaseOrders.show_attachment': 'Tampilkan Lampiran',
  'financePurchaseOrders.hide_attachment': 'Sembunyikan Lampiran',
  'financePurchaseOrders.account': 'Akun',
  'financePurchaseOrders.debit': 'Debit',
  'financePurchaseOrders.credit': 'Kredit',
  'financePurchaseOrders.journal_report': 'Laporan Jurnal',
  'financePurchaseOrders.add_purchaseorder': 'Tambah Pesanan Pembelian',
  'financePurchaseOrders.financePurchaseOrders': 'Pesanan Pembelian',
  'financePurchaseOrders.successfully_added': 'berhasil ditambahkan.',
  'financePurchaseOrders.error_cannot_add': 'Tidak dapat menambah pesanan pembelian.',
  'financePurchaseOrders.error_cannot_update': 'Tidak dapat mengubah pesanan pembelian.',
  'financePurchaseOrders.error_cannot_move': 'Tidak dapat memindah pesanan pembelian.',
  'financePurchaseOrders.error_cannot_print_pdf': 'Tidak dapat membuat pdf pesanan pembelian.',
  'financePurchaseOrders.error_cannot_change_status':
    'Tidak dapat mengubah status pesanan pembelian.',
  'financePurchaseOrders.edit_purchaseorder': 'Ubah Pesanan Pembelian',
  'financePurchaseOrders.max_characters': 'Maks {value} karakter.',
  'financePurchaseOrders.min_characters': 'Min {value} karakter.',
  'financePurchaseOrders.only_number_allowed': 'Hanya angka yang diperkenankan.',
  'financePurchaseOrders.description': 'Deskripsi',
  'financePurchaseOrders.tax': 'Pajak',
  'financePurchaseOrders.select_tax': 'Pilih pajak',
  'financePurchaseOrders.amount': 'Total',
  'financePurchaseOrders.please_select_a_contact': 'Silakan pilih kontak',
  'financePurchaseOrders.select_contact': 'Pilih kontak',
  'financePurchaseOrders.add_contact': 'Tambah kontak',
  'financePurchaseOrders.all_contacts': 'Semua kontak',
  'financePurchaseOrders.transaction_date': 'Tgl Transaksi',
  'financePurchaseOrders.please_select_transaction_date': 'Silakan pilih tanggal transaksi',
  'financePurchaseOrders.due_date': 'Tanggal Jatuh Tempo',
  'financePurchaseOrders.please_select_due_date': 'Silakan pilih tanggal jatuh tempo',
  'financePurchaseOrders.term': 'Termin',
  'financePurchaseOrders.select_term': 'Pilih termin',
  'financePurchaseOrders.add_term': 'Tambah termin',
  'financePurchaseOrders.price_include_tax': 'Harga termasuk pajak',
  'financePurchaseOrders.add_more': 'Tambah baris',
  'financePurchaseOrders.number': 'Nomor',
  'financePurchaseOrders.please_input_the_number': 'Silakan isikan nomor.',
  'financePurchaseOrders.memo': 'Reference',
  'financePurchaseOrders.date': 'Tanggal',
  'financePurchaseOrders.status': 'Status',
  'financePurchaseOrders.balance_due': 'Sisa Tagihan',
  'financePurchaseOrders.total': 'Total',
  'financePurchaseOrders.please_select_a_term': 'Silakan pilih termin.',
  'financePurchaseOrders.search_by_memo': 'Cari',
  'financePurchaseOrders.the_due_date_max_higger_than_transaction_date':
    'Tanggal jatuh tempo harus sama atau setelah tanggal transaksi',
  'financePurchaseOrders.detail_title': 'Pesanan Pembelian {number}',
  'financePurchaseOrders.vendor': 'Vendor',
  'financePurchaseOrders.reference': 'Reference',
  'financePurchaseOrders.product': 'Produk',
  'financePurchaseOrders.qty': 'Kuantitas',
  'financePurchaseOrders.unit': 'Satuan',
  'financePurchaseOrders.price': 'Harga',
  'financePurchaseOrders.add_product': 'Tambah Produk',
  'financePurchaseOrders.select_product': 'Pilih produk',
  'financePurchaseOrders.attachments': 'Attachment',
  'financePurchaseOrders.save_&_approve': 'Simpan & setujui',
  'financePurchaseOrders.save_&_approve_add_new': 'Simpan & setujui & Tambah Baru',
  'financePurchaseOrders.save_as_draft': 'Simpan sbg draft',
  'financePurchaseOrders.save_as_draft_add_new': 'Simpan sbg draft & Tambah Baru',
  'financePurchaseOrders.save_&_submit_for_approval': 'Simpan & ajukan approval',
  'financePurchaseOrders.save_&_submit_for_approval_add_new':
    'Simpan & ajukan approval & Tambah Baru',
  'financePurchaseOrders.please_select_a_product': 'Silakan pilih produk.',
  'financePurchaseOrders.please_input_the_qty': 'Silakan masukkan kuantitas.',
  'financePurchaseOrders.please_input_the_price': 'Silakan masukkan harga.',
  'financePurchaseOrders.please_input_the_amount': 'Silakan masukkan total.',
  'financePurchaseOrders.approve': 'Setujui',
  'financePurchaseOrders.are_you_sure_to_approve': 'Apakah Anda yakin untuk menyutujui?',
  'financePurchaseOrders.submit_for_approval': 'Ajukan approval',
  'financePurchaseOrders.are_you_sure_to_submit_for_approval':
    'Apakah Anda yakin untuk mengajukan approval?',
  'financePurchaseOrders.create_invoice': 'Buat Tagihan',
  'financePurchaseOrders.must_greater_than_0': 'Harus lebih besar dari 0.',
  'financeProduct.product': 'Produk',
  'financeProduct.sell_or_purchase': 'Jual atau Beli',
  'financeProduct.for_sale': 'Dijual',
  'financeProduct.bought': 'Dibeli',
  'financeProduct.track_stock': 'Lacak Stok',
  'financeProduct.not_track_stock': 'Tidak Lacak Stok',
  'financePurchaseOrders.must_be_smaller_than_bill':
    'Total harus lebih kecil atau sama dengan total transaksi.',
  'financePurchaseInvoices.pay_with_credit_memo': 'Dibayar dengan Hutang',
  'financePurchaseInvoices.please_select_with_credit_memo': 'Silahkan pilih dibayar dengan hutang',
  'financePurchaseInvoices.pay_with_debit_memo': 'Dibayar dengan Piutang',
  'financePurchaseInvoices.please_select_with_debit_memo': 'Silahkan pilih dibayar dengan piutang',

  'financePurchaseOrders.follow_these_steps_to_import_your_purchase_orders':
    'Ikuti langkah berikut untuk mengimport pesanan pembelianmu',
  'financePurchaseOrders.1_download_your_purchase_orders_template_file':
    '1. Download file template pesanan pembelian',
  'financePurchaseOrders.start_by_downloading_our_purchase_orders_csv':
    'Mulai dengan mengunduh file template Excel. File ini mempunyai kolom header yang yang dibutuhkan Kledo untuk mengimport detail dari pesanan pembelian-pesanan pembelianmu.',
  'financePurchaseOrders.download_template_file': 'Download File Template',
  'financePurchaseOrders.download_template_data_example_file': 'Download File Contoh',
  'financePurchaseOrders.import': 'Import Pesanan Pembelian',
  'financePurchaseOrders.2_copy_your_purchase_orders_into_the_template':
    '2. Salin detail pesanan pembelian ke dalam template',
  'financePurchaseOrders.using_excel_or_another_spreadsheet_editor':
    'Menggunakan Excel atau software spreadsheeet lainnya, masukkan detail pesanan pembelian ke dalam file template dari Kledo. Pastikan detail pesanan pembelian sesuai dengan kolom header yang ada di template.',
  'financePurchaseOrders.important': 'PENTING',
  'financePurchaseOrders.3_upload_the_updated_template_file_here':
    '3. Upload template yang sudah diupdate ke sini',
  'financePurchaseOrders.upload': 'Upload',
  'financePurchaseOrders.save_your_updated_template_as_.csv_or_.txt_and_you_are_ready_to_go':
    'Simpan file template yang sudah diupdate',
  'financePurchaseOrders.a_maximum_of_500_purchase_orders_can_be_imported_at_a_time':
    'Maksimal 1.000 pesanan pembelian dapat diimport dalam satu waktu.',
  'financePurchaseOrders.contact_name_that_not_exist_will_be_inserted_as_a_new_contact':
    'Nama kontak yang yang belum ada di Kledo akan dimasukkan sebagai kontak baru.',
  'financePurchaseOrders.import_actions_only_insert_new_purchase_orders_cannot_update_or_delete':
    'Saat meng-import, Kledo hanya akan memasukkan data baru, tidak ada data yang dihapus atau diupdate.',
  'financePurchaseOrders.dont_change_the_column_heading_in_the_template_file':
    'Jangan mengubah header kolom di dalam template. Ini dibutuhkan agar import dapat berjalan.',
  'financePurchaseOrders.you_can_only_upload_csv_file':
    'Anda hanya dapat mengunggah file csv atau excel',
  'financePurchaseOrders.upload_file': 'Upload File',
  'financePurchaseOrders.check_the_imported_data': 'Cek Data',
  'financePurchaseOrders.please_check_the_imported_data': 'Silakan cek data yang diimport',
  'financePurchaseOrders.import_purchase_orders_done': 'Import Berhasil',
  'financePurchaseOrders.error_upload_file': 'Gagal mengunggah file.',
  'financePurchaseOrders.file_not_found': 'File tidak ditemukan.',
  'financePurchaseOrders.if_you_are_happy_to_continue_with_the_import_please_click_the_import_now_button': `Jika data yang diimport sesuai dengan yang kamu harapkan, silakan klik tombol "Import Sekarang".`,
  'financePurchaseOrders.valid': 'Valid',
  'financePurchaseOrders.invalid': 'Invalid',
  'financePurchaseOrders.import_now': 'Import Sekarang',
  'financePurchaseOrders.contact_name': 'Nama Kontak',
  'financePurchaseOrders.email': 'Email',
  'financePurchaseOrders.address': 'Alamat',
  'financePurchaseOrders.phone': 'Phone',
  'financePurchaseOrders.purchase_order_date': 'Tanggal Tagihan Pembelian',
  'financePurchaseOrders.purchase_order_number': 'Nomor Tagihan Pembelian',
  'financePurchaseOrders.product_name': 'Nama Produk',
  'financePurchaseOrders.code_sku': 'Kode/SKU',
  'financePurchaseOrders.tax_inclusive': 'Termasuk Pajak',

  'financePurchaseOrders.inventory_account': 'Akun Penyimpanan',
  'financePurchaseOrders.purchase_orders_successfully_imported':
    '{rowCount} pesanan pembelian berhasil diimport.',
  'financePurchaseOrders.view_detail': 'Tampilkan detil',
  'financePurchaseOrders.import_another_file': 'Import file lain',
  'financePurchaseOrders.go_to_purchase_order_list': 'Ke halaman daftar pesanan pembelian',
  'financePurchaseOrders.success': 'Berhasil',
  'financePurchaseOrders.failed': 'Gagal',
  'financePurchaseOrders.error_cannot_import': 'Gagal import.',
  'financePurchaseOrders.error': 'Error',
  'financePurchaseOrders.tax_manual': 'Pajak Manual',
  'financePurchaseOrders.tax_manual_must_less_than_amount':
    'Pajak harus kurang dari nilai transaksi.',
  'financePurchaseOrders.discount': 'Discount',
  'financePurchaseOrders.additional_discount': 'Tambahan Diskon',
  'financePurchaseOrders.discount_per_item': 'Diskon per item',
  'financePurchaseOrders.total_discount': 'Total Diskon',
  'financePurchaseOrders.total_cannot_be_negative': 'Nilai sisa tagihan tidak boleh minus.',
  'financePurchaseOrders.product_not_found': 'Produk tidak ditemukan',
  'financePurchaseOrders.product_has_been_added': 'Produk berhasil ditambahkan',
  'financePurchaseOrders.discount_per_quantity': 'Diskon per kuantitas',

  'salesCommission.sales_commission': 'Komisi Penjualan',
  'salesCommission.commission': 'Komisi',
  'salesCommission.commission_required': 'Komisi harus diisi',
  'salesCommission.commission_name': 'Nama Komisi',
  'salesCommission.commission_name_required': 'Nama komisi wajib diisi',
  'salesCommission.commission_amount_by': 'Besaran Komisi Bertingkat Berdasar',
  'salesCommission.commission_amount': 'Besaran Komisi Bertingkat',
  'salesCommission.min_transaction_per_month': 'Minimal transaksi per bulan',
  'salesCommission.min_transaction_per_month_required': 'Minimal transaksi harus diisi',
  'salesCommission.cannot_get_commission_type': 'Tidak dapat mengambil tipe komisi',
  'salesCommission.cannot_get_commission': 'Tidak dapat mengambil komisi',
  'salesCommission.sales_person_required': 'Sales person wajib diisi',
  'salesCommission.all_sales': 'Semua Sales',
  'salesCommission.not_due_only':
    'Komisi hanya untuk transaksi yang pelunasannya tidak melewati jatuh tempo',
  'salesCommission.not_due_only_desc':
    'Transaksi penjualan yang jatuh tempo tidak akan diperhitungkan sebagai komisi',
  'salesCommission.invalid_format_commission_amount': 'Jumlah Komisi format tidak valid.',
  'salesCommission.commission_is_only_for_transactions_that_have_been_paid':
    'Komisi hanya untuk transaksi yang telah dibayar',
  'salesCommission.commission_is_only_for_transactions_that_have_been_paid_desc':
    'Tagihan yang belum dibayar tidak diperhitungkan sebagai komisi.',
  'salesCommission.commission_type_id_help_text': `
    Jika berdasarkan "{transactionsPerMonth}", maka komisi dihitung berdasarkan jumlah transaksi penjualan tiap bulannya. Misalnya salah seorang sales membuat 5 invoice. Maka sales tersebut terhitung telah melakukan 5 transaksi.
    <br />
    <br />
    Jika berdasarkan "{salesAmountPerMonth}", maka komisi dihitung berdasarkan nominal uang penjualan dalam rupiah. Misalnya seorang sales membuat 5 invoice, dengan total tagihan keseluruhan Rp 5 juta. Maka sales tersebut terhitung telah melakukan nominal penjualan Rp 5 jt.
    <br />
    <br />
    Jika berdasarkan "{numberOfProductsSoldPerMonth}", maka komisi dihitung berdasarkan jumlah kuantitas produk yang terjual. Misalnya seorang sales membuat 5 invoice, dimana masing-masing invoice menjual 100pcs produk. Maka sales tersebut terhitung telah menjualn sebanyak 500pcs produk.
  `,

  'priceRule.price_rule': 'Aturan Harga',
  'priceRule.rule_name': 'Nama Aturan',
  'priceRule.rule_name_required': 'Nama aturan wajib diisi',
  'priceRule.dicsount': 'Diskon',
  'priceRule.discount_required': 'Diskon wajib diisi',
  'priceRule.min_buy': 'Berlaku jika pelanggan membeli dengan:',
  'priceRule.min_qty': 'Minimal Kuantitas',
  'priceRule.min_qty_required': 'Minimal pembelian wajib diisi',
  'priceRule.min_qty_tooltip':
    'Berapa jumlah minimal produk yang dibeli pelanggan agar diskon berlaku?',
  'priceRule.min_amount': 'Minimal Pembelian',
  'priceRule.min_amount_tooltip':
    'Berapa rupiah minimal pembelian pelanggan agar diskon berlaku? ' +
    'Dihitung dari total pembelian seluruh produk dalam satu invoice',
  'priceRule.effective_date': 'Tanggal Berlaku',
  'priceRule.forever': 'Selamanya',
  'priceRule.date': 'Tanggal',
  'priceRule.date_start': 'Tanggal Mulai',
  'priceRule.date_end': 'Tanggal Selesai',
  'priceRule.date_is_required': 'Tanggal wajib diisi',
  'priceRule.until': 'sampai',
  'priceRule.applies_to_the_following_customers': 'Berlaku Pada Pelanggan Berikut',
  'priceRule.customer': 'Pelanggan',
  'priceRule.all_customer': 'Semua Pelanggan',
  'priceRule.customer_group': 'Grup Pelanggan',
  'priceRule.customer_groups_that_will_get_discount': 'Grup pelanggan yang akan mendapatkan diskon',
  'priceRule.add_group': 'Tambah grup',
  'priceRule.select_group': 'Pilih grup',
  'priceRule.per_customer': 'Per Pelanggan',
  'priceRule.customers_that_will_get_discount': 'Pelanggan yang akan mendapatkan diskon',
  'priceRule.add_customer': 'Tambah pelanggan',
  'priceRule.name': 'Nama',
  'priceRule.company': 'Perusahaan',
  'priceRule.applies_to_the_following_products': 'Berlaku Pada Produk Berikut',
  'priceRule.product': 'Produk',
  'priceRule.all_product': 'Semua Produk',
  'priceRule.product_category': 'Kategori Produk',
  'priceRule.discounted_product_category': 'Kategori produk yang didiskon',
  'priceRule.add_category': 'Tambah kategori',
  'priceRule.per_product': 'Per Produk',
  'priceRule.discounted_product': 'Produk yang didiskon',
  'priceRule.add_product': 'Tambah produk',
  'priceRule.status': 'Status',
  'priceRule.active': 'Aktif',
  'priceRule.inactive': 'Tidak Aktif',
  'priceRule.select_price_rule': 'Pilih aturan harga',
  'priceRule.to_use_the_pricing_rules_please_first_activate_the_sales_discount_option':
    'Untuk menggunakan aturan harga, silakan aktifkan terlebih dahulu opsi Diskon Penjualan dari menu Pengaturan Alur Bisnis.',

  'financeProduct.cannot_delete_current_photo': 'Tidak bisa menghapus foto ini.',
  'financeProduct.search_in': 'Cari di',
  'financeProduct.available': 'Tersedia',
  'financeProduct.almost_empty': 'Hampir Habis',
  'financeProduct.empty': 'Habis',
  'financeProduct.stock_per_category': 'Stok per Kategori',
  'financeProduct.warehouse_location': 'Lokasi Gudang',
  'financeProduct.total_stock': 'Total Stok',
  'financeProduct.total_avg_base_price': 'Total HPP',
  'financeProduct.total_sales': 'Total Penjualan',
  'financeProduct.total_purchase': 'Total Pembelian',
  'financeProduct.detail_product': 'Detil Produk',
  'financeProduct.code_sku_tooltip':
    'Kode SKU (Stock Keeping Unit) adalah kode unik tiap produk. Kode akan tergenerate secara otomatis, atau Anda bisa juga mengubahnya.',
  'financeProduct.row': 'Baris',
  'financeProduct.conversion_detail_title': 'Konversi Produk {number}',
  'financeProduct.category': 'Kategori',
  'financeProduct.avg_base_price': 'HPP',
  'financeProduct.all_stock': 'Semua stok',
  'financeProduct.all_products': 'Semua produk',
  'financeProduct.product_name': 'Nama Produk',
  'financeProduct.code_sku': 'Kode/SKU',
  'financeProduct.cost_price': 'Harga Beli',
  'financeProduct.sale_price': 'Harga Jual',
  'financeProduct.quantity': 'Kuantitas',
  'financeProduct.products_and_services': 'Produk dan Layanan',
  'financeProduct.add_category': 'Tambah Kategori',
  'financeProduct.name_category_required': 'Nama kategori wajib diisi',
  'financeProduct.name_category_must_be_at_least_2_characters': 'nama kategori minimal 2 karakter',
  'financeProduct.edit_category': 'Ubah Kategori',
  'financeProduct.edit_product': 'Ubah Produk',
  'financeProduct.image': 'Gambar',
  'financeProduct.choose_category': 'Pilih Kategori',
  'financeProduct.choose_category_required': 'Silakan pilih kategori',
  'financeProduct.product_name_required': 'Nama produk wajib diisi',
  'financeProduct.product_name_must_be_at_least_2_characters': 'Nama produk minimal 2 karakter',
  'financeProduct.product_name_max_150_characters': 'Nama produk maksimal 150 karakter',
  'financeProduct.code_sku_must_be_at_least_2_characters': 'Kode/SKU minimal 2 karakter',
  'financeProduct.i_purchase_this_item': 'Saya membeli item ini',
  'financeProduct.price': 'Harga',
  'financeProduct.price_required': 'Harga wajib diisi',
  'financeProduct.extra_price_must_greather_than_ziro': 'Harga harus lebih besar dari 0.',
  'financeProduct.account': 'Akun',
  'financeProduct.select_account': 'Pilih Akun',
  'financeProduct.tax': 'Pajak',
  'financeProduct.choose_tax_required': 'Pajak harus diisi',
  'financeProduct.select_tax_rate': 'Pilih Pajak',
  'financeProduct.i_sell_this_item': 'Saya menjual item ini',
  'financeProduct.i_track_this_item': 'Saya melacak inventori item ini',
  'financeProduct.this_treats_your_item_as_a_tracked_inventory':
    'Ini akan mencatat stok item Anda.',
  'financeProduct.date': 'Tanggal',
  'financeProduct.add_adjustment': 'Tambah Penyesuaian',
  'financeProduct.quantity_on_hand': 'Stok di tangan',
  'financeProduct.total_value': 'Nilai Total',
  'financeProduct.purchase': 'Pembelian',
  'financeProduct.tax_rate': 'Pajak',
  'financeProduct.sales': 'Penjualan',
  'financeProduct.inventory': 'Inventori',
  'financeProduct.inventory_asset_account': 'Akun Aset Inventori',
  'financeProduct.total': 'Total',
  'financeProduct.decrease_quantity': 'Kurangi stok',
  'financeProduct.revolution': 'Revolusi',
  'financeProduct.new_adjustment': 'Penyesuaian Stok',
  'financeProduct.adjustment_type': 'Tipe Penyesuaian',
  'financeProduct.adjustment_type_required': 'tipe penyesuaian wajib diisi',
  'financeProduct.select_adjustment_type': 'Pilih Penyesuaian Tipe',
  'financeProduct.date_required': 'tanggal wajib diisi',
  'financeProduct.current_qty': 'Jumlah stok sekarang',
  'financeProduct.qty_required': 'jumlah stok wajib diisi',
  'financeProduct.avg_cost': 'Harga rata-rata',
  'financeProduct.avg_cost_required': 'harga rata-rata wajib diisi',
  'financeProduct.total_value_required': 'nilai total wajib diisi',
  'financeProduct.adjustment': 'Penyesuaian',
  'financeProduct.adjustment_required': 'penyesuaian wajib diisi',
  'financeProduct.cost_price_required': 'harga wajib diisi',
  'financeProduct.new_qty_on_hand': 'Stok ditangan baru',
  'financeProduct.new_qty_on_hand_required': 'stok ditangan baru wajib diisi',
  'financeProduct.choose_account_required': 'akun wajib diisi',
  'financeProduct.note': 'Catatan',
  'financeProduct.note_required': 'catatan wajib diisi',
  'financePurchaseOrders.detail_purchaseorder': 'Detil Pesanan Pembelian',
  'financeProduct.error_cannot_add': 'Tidak dapat menambahkan produk.',
  'financeProduct.error_cannot_export': 'Tidak dapat ekspor produk',
  'financeProduct.error_cannot_add_categorie': 'Tidak dapat menambahkan kategori produk.',
  'financeProduct.recent_transactions': 'Transaksi Terkini',

  'financeProduct.manufacture': 'Manufaktur',
  'financeProduct.package': 'Paket',
  'financeProduct.conversion': 'Konversi',
  'financeProduct.conversion_description': 'Keterangan',
  'financeProduct.follow_these_steps_to_import_your_products':
    'Ikuti langkah berikut untuk mengimport produk-produkmu',
  'financeProduct.1_download_your_products_template_file': '1. Download file template produk',
  'financeProduct.start_by_downloading_our_products_csv':
    'Mulai dengan mendownload file template produk dalam format Excel. Format ini mempunyai format header yang dibutuhkankledo untuk mengimport detail produkmu.',
  'financeProduct.download_template_file': 'Download File Template',
  'financeProduct.download_template_data_example_file': 'Download File Contoh Data',
  'financeProduct.import': 'Import Produk',
  'financeProduct.2_copy_your_products_into_the_template': '2. Salin produkmu ke dalam template',
  'financeProduct.using_excel_or_another_spreadsheet_editor':
    'Menggunakan Excel atau program spreadsheet lainnya, masukkan detail produkmu ke dalam file template. Pastikan detail produk sesuai dengan header kolom yang ada di template.',
  'financeProduct.important': 'PENTING',
  'financeProduct.3_upload_the_updated_template_file_here':
    '3. Upload file template yang telah diupdate di sini',
  'financeProduct.upload': 'Upload',
  'financeProduct.save_your_updated_template_as_.csv_or_.txt_and_you_are_ready_to_go':
    'Simpan template yang sudah diupdate',
  'financeProduct.a_maximum_of_1000_products_can_be_imported_at_a_time':
    'Maksimum 200 produk dapat diimpot dalam satu waktu.',
  'financeProduct.dont_change_the_column_heading_in_the_template_file':
    'Jangan mengubah header kolom dalam file template. Ini dibutuhkan Kledo untuk proses import pada langkah berikutnya. Kamu juga dapat menggunakan file yang sudah diimport untuk mengupdate semua produk-produkmu, menghemat waktu dan tenaga daripada mengupdate produk satu persatu melalui browser. Proses import akan menggunakan Kode/SKU produk untuk menentukan apakah suatu produk sudah ada di Kledo atau belum, jika produk sudah ada maka produk akan diupdate, sedangkan jika produk belum ada maka akan dibuat produk baru. Produk tidak akan dihapus, sehingga kamu bisa dengan aman mengupdate sebagian produkmu saja tanpa khawatir akan kehilangan data diproduk yang lain.',
  'financeProduct.you_can_only_upload_csv_file': 'Anda hanya dapat mengunggah file csv atau excel',
  'financeProduct.upload_file': 'Upload File',
  'financeProduct.check_the_imported_data': 'Cek Data',
  'financeProduct.please_check_the_imported_data': 'Silakan cek data yang diimport',
  'financeProduct.import_product_done': 'Import Berhasil',
  'financeProduct.error_upload_file': 'Gagal mengunggah file.',
  'financeProduct.file_not_found': 'File tidak ditemukan.',
  'financeProduct.if_you_are_happy_to_continue_with_the_import_please_click_the_import_now_button': `Jika data yang kamu upload sudah sesuai dengan yang diinginkan, silakan klik tombol "Import Sekarang".`,
  'financeProduct.valid': 'Valid',
  'financeProduct.invalid': 'Invalid',
  'financeProduct.import_now': 'Import Sekarang',
  'financeProduct.status': 'Status',
  'financeProduct.name': 'Nama',
  'financeProduct.purchase_price': 'Harga Beli',
  'financeProduct.purchase_account': 'Akun Pembelian',
  'financeProduct.purchase_tax': 'Pajak Pembelian',
  'financeProduct.sell_price': 'Harga Jual',
  'financeProduct.sell_account': 'Akun Penjualan',
  'financeProduct.sell_tax': 'Pajak Penjualan',
  'financeProduct.track_account': 'Akun Pelacakan',
  'financeProduct.product_bundle': 'Produk Bundel',
  'financeProduct.product_bundle_qty': 'Kuantitas Produk Bundel',
  'financeProduct.product_bundle_account': 'Akun Produk Bundel',
  'financeProduct.product_bundle_account_cost': 'Harga Akun Produk Bundel',
  'financeProduct.account_cost': 'Harga Akun',
  'financeProduct.inventory_account': 'Akun Penyimpanan',
  'financeProduct.products_successfully_imported': '{rowCount} produk berhasil diimport.',
  'financeProduct.view_detail': 'Tampilkan detil',
  'financeProduct.import_another_file': 'Import file lain',
  'financeProduct.go_to_product_list': 'Ke halaman daftar produk',
  'financeProduct.success': 'Berhasil',
  'financeProduct.failed': 'Gagal',
  'financeProduct.error_cannot_import': 'Gagal import.',
  'financeProduct.error': 'Error',
  'financeProduct.please_input_the_code_sku': 'Kode/SKU wajib diisi.',
  'financeProduct.code_sku_maximal_150_characters': 'Kode/SKU maksimal 150 karakter.',
  'financeProduct.please_choose_at_least_a_product_action':
    'Silakan pilih minimal satu aksi dari product (Beli/Jual/Inventori).',
  'financeProduct.name_category_already_exist':
    'Nama kategori sudah digunakan, silakan pilih nama kategori yang lain',
  'financeProduct.please_choose_this_action':
    'Beli produk harus aktif jika lacak inventori diaktifkan!',
  'financeProduct.please_choose_the_unit': 'Silakan pilih satuan.',
  'financeProduct.show_qty_per_warehouse': 'Tampilkan stok per gudang',
  'financeProduct.select_category': 'Pilih kategori',
  'financeProduct.qty': 'Qty',
  'financeProduct.min_qty': 'Kuantitas Minimal',
  'financeProduct.min_qty_must_less': 'Kuantitas harus lebih kecil dari kuantitas selanjutnya',
  'financeProduct.min_qty_must_greater': 'Kuantitas harus lebih besar dari kuantitas sebelumnya',
  'financeProduct.wholesale_price': 'Harga Grosir',
  'financeProduct.wholesale_qty': 'Kuantitas Grosir',
  'financeProduct.show_wholesale_price': 'Tampilkan Harga Grosir',
  'financeProduct.hide_wholesale_price': 'Sembunyikan Harga Grosir',
  'financeProduct.warehouses': 'Gudang',
  'financeProduct.warehouse': 'Gudang',
  'financeProduct.transaction': 'Transaksi',
  'financeProduct.description': 'Deskripsi',
  'financeProduct.type': 'Jenis',
  'financeProduct.stock_movement': 'Pergerakan Stok',
  'financeProduct.warehouse_movement': 'Pergerakan Gudang',
  'financeProduct.stock': 'Stok',
  'financeProduct.select_stock': 'Pilih Stok',
  'financeProduct.error_load_stock': 'Gagal load stok.',
  'financeProduct.show_accounts_and_taxes_setting': 'Tampilkan pengaturan akun dan pajak',
  'financeProduct.hide_accounts_and_taxes_setting': 'Sembunyikan pengaturan akun dan pajak',
  'financeProduct.categories': 'Kategori',
  'financeProduct.error_cannot_move': 'Tidak dapat memindah kategori.',
  'financeProduct.please_select_a_category_to_delete':
    'Silahkan pilih salah satu kategori untuk menghapus.',
  'financeProduct.delete_a_product_category': 'Hapus Kategori Produk',
  'financeProduct.error_cannot_delete_category': 'Tidak dapat menghapus kategori produk.',
  'financeProduct.error_cannot_update_category': 'Tidak dapat mengubah kategori produk.',
  'financeProduct.error_cannot_update': 'Tidak dapat mengubah produk.',
  'financeProduct.error_cannot_load_can_be_deleted_category':
    'Tidak dapat mengambil data kategori produk.',
  'financeProduct.only_category_that_not_assigned_in_product_could_be_deleted':
    'Catatan: Hanya kategori yang belum mempunyai produk yang dapat dihapus.',
  'financeProduct.reference': 'Reference',
  'financeProduct.error_load_product': 'Tidak dapat menampilkan data produk.',
  'financeProduct.add_product': 'Tambah {title}',
  'financeProduct.duplicate_product_package': 'Duplikat {title} Paket',
  'financeProduct.duplicate_product_manufacture': 'Duplikat {title} Manufaktur',
  'financeProduct.add_product_package': 'Tambah {title} Paket',
  'financeProduct.add_product_manufacture': 'Tambah {title} Manufaktur',
  'financeProduct.edit_product_package': 'Ubah {title} Paket',
  'financeProduct.edit_product_manufacture': 'Ubah {title} Manufaktur',
  'financeProduct.package_product_items': 'Paket produk terdiri dari',
  'financeProduct.manufacture_product_items': 'Produk manufaktur terdiri dari',
  'financeProduct.error_load_product_detail':
    'Terjadi masalah di sistem saat mengambil data detail produk.',
  'financeProduct.cost_of_production': 'Biaya produksi terdiri dari',
  'financeProduct.product_conversion': 'Konversi Produk',
  'financeProduct.total_cost': 'Biaya total',
  'financeProduct.total_cost_conversion': 'Biaya total konversi',
  'financeProduct.cost_per_unit': 'Biaya per unit',
  'financeProduct.error_cannot_conversion_product':
    'Terjadi masalah server saat mengkonversi produk',
  'financeProduct.conversion_title': 'Konversi {title} {name}',
  'financeProduct.amount': 'Jumlah',
  'financeProduct.conversion_date': 'Tanggal Konversi',
  'financeProduct.error_load_conversion_product_detail':
    'Terjadi masalah di server saat mengambil konversi produk',
  'financeProduct.error_cannot_delete_conversion_product':
    'Terjadi masalah di server saat menghapus konversi produk',
  'financeProduct.multiplier': 'Pengali',
  'financeProduct.please_input_the_multiplier': 'Silahkan isikan pengali',
  'financeProduct.per_pcs': 'Per Pcs',
  'financeProduct.unit_conversion': 'Konversi Satuan',
  'financeProduct.unit': 'Satuan',
  'financeProduct.base_unit': 'Satuan Dasar',
  'financeProduct.add_unit_conversion': 'Tambah konversi satuan',
  'financeProduct.edit_unit_conversion': 'Ubah konversi satuan',
  'financeProduct.please_input_unit': 'Silakan masukkan satuan',
  'financeProduct.please_input_unit_conv': 'Silakan masukkan konversi satuan',
  'financeProduct.unit_cannot_same': 'Satuan tidak boleh sama',
  'financeProduct.unit_cannot_same_as_conversion': 'Satuan tidak boleh sama dengan konversi satuan',
  'financeProduct.cannot_edit_unit_conversion': 'Tidak dapat mengubah konversi satuan',
  'financeProduct.mass_delete_info':
    '**Produk yang telah mempunyai transaksi tidak dapat dihapus, hanya bisa diarsipkan.',
  'financeProduct.archive': 'Arsipkan',
  'financeProduct.show_archive': 'Tampilkan Arsip',
  'financeProduct.unarchive': 'Batalkan Arsip',
  'financeProduct.archived': 'Diarsipkan',
  'financeProduct.without_archive': 'Tanpa arsip',
  'financeProduct.with_archive': 'Dengan arsip',
  'financeProduct.archive_only': 'Arsip saja',
  'financeProduct.are_you_sure_to_archive': 'Apakah Anda yakin untuk mengarsipkan produk ini?',
  'financeProduct.are_you_sure_to_unarchive': 'Apakah Anda yakin untuk batalkan arsip produk ini?',
  'financeProduct.future_transactions': 'Transaksi yang akan datang ',
  'financeProduct.all_transactions': 'Semua transaksi',
  'financeProduct.current_transaction_will_not_be_modified':
    'Tidak akan mempengaruhi transaksi yang sudah dibuat.',
  'financeProduct.current_transaction_and_future_will_be_modified':
    'Akan mempengaruhi jurnal entri dari transaksi yang sudah dibuat.',
  'financeProduct.purchase_account_cannot_be_modified_for_future_transactions':
    'Akun pembelian tidak dapat diubah pada transaksi yang akan datang, karena produk ini telah mempunyai transaksi sebelumnya.',
  'financeProduct.purchase_account_cannot_be_modified_for_all_transactions':
    'Akun pembelian tidak dapat diubah untuk semua transaksi, karena ada transaksi pada produk ini sebelum tanggal penguncian, atau tanggal tutup buku.',
  'financeProduct.sell_account_cannot_be_modified': 'Akun penjualan tidak dapat diubah.',
  'financeProduct.sell_account_cannot_be_modified_for_all_transactions':
    'Akun penjualan tidak dapat diubah untuk semua transaksi, karena ada transaksi pada produk ini sebelum tanggal penguncian, atau tanggal tutup buku.',
  'financeProduct.track_account_cannot_be_modified':
    'Akun persediaan tidak dapat diubah karena ada transaksi pada produk ini sebelum tanggal penguncian, atau tanggal tutup buku.',
  'financeProduct.please_choose_account_update_past':
    'Pengaturan edit akun yang akan datang belum dipilih!',
  'financeProduct.same_as_business_flow': 'Sesuai pengaturan alur bisnis',
  'financeProduct.please_input_buffer_qty': 'Silakan masukkan jumlah stok minimal',
  'financeProduct.data_is_being_processed': 'Data sedang diproses',

  'financePurchaseInvoices.message': 'Pesan',
  'financePurchaseInvoices.customer': 'Pelanggan',
  'financePurchaseInvoices.add_purchaseInvoice': 'Tambah Tagihan Pembelian',
  'financePurchaseInvoices.financePurchaseInvoices': 'Tagihan Pembelian',
  'financePurchaseInvoices.successfully_added': 'berhasil ditambah.',
  'financePurchaseInvoices.error_cannot_print_pdf': 'Tidak dapat membuat pdf tagihan pembelian.',

  'financePurchaseInvoices.error_cannot_add': 'Tidak dapat menambah tagihan pembelian.',
  'financePurchaseInvoices.error_cannot_update': 'Tidak dapat mengubah tagihan pembelian.',
  'financePurchaseInvoices.error_cannot_move': 'Tidak dapat memindah tagihan pembelian.',
  'financePurchaseInvoices.error_cannot_change_status':
    'Tidak dapat mengubah status tagihan pembelian.',
  'financePurchaseInvoices.edit_purchaseInvoice': 'Ubah Tagihan Pembelian',
  'financePurchaseInvoices.max_characters': 'Maks {value}.',
  'financePurchaseInvoices.min_characters': 'Min {value} karakter.',
  'financePurchaseInvoices.only_number_allowed': 'Hanya angka yang diperkenankan.',
  'financePurchaseInvoices.description': 'Deskripsi',
  'financePurchaseInvoices.tax': 'Pajak',
  'financePurchaseInvoices.select_tax': 'Pilih pajak',
  'financePurchaseInvoices.please_select_a_contact': 'Silakan pilih kontak',
  'financePurchaseInvoices.select_contact': 'Pilih kontak',
  'financePurchaseInvoices.add_contact': 'Tambah kontak',
  'financePurchaseInvoices.all_contacts': 'Semua kontak',
  'financePurchaseInvoices.transaction_date': 'Tgl Transaksi',
  'financePurchaseInvoices.please_select_transaction_date': 'Silakan pilih tanggal transaksi',
  'financePurchaseInvoices.due_date': 'Tanggal Jatuh Tempo',
  'financePurchaseInvoices.please_select_due_date': 'Silakan pilih tanggal jatuh tempo',
  'financePurchaseInvoices.term': 'Termin',
  'financePurchaseInvoices.select_term': 'Pilih termin',
  'financePurchaseInvoices.add_term': 'Tambah termin',
  'financePurchaseInvoices.price_include_tax': 'Harga termasuk pajak',
  'financePurchaseInvoices.add_more': 'Tambah baris',
  'financePurchaseInvoices.number': 'Nomor',
  'financePurchaseInvoices.please_input_the_number': 'Silakan isikan nomor.',
  'financePurchaseInvoices.memo': 'Reference',
  'financePurchaseInvoices.date': 'Tanggal',
  'financePurchaseInvoices.status': 'Status',
  'financePurchaseInvoices.balance_due': 'Sisa Tagihan',
  'financePurchaseInvoices.total': 'Total',
  'financePurchaseInvoices.please_select_a_term': 'Silakan pilih termin.',
  'financePurchaseInvoices.search_by_memo': 'Cari',
  'financePurchaseInvoices.the_due_date_max_higger_than_transaction_date':
    'Tanggal jatuh tempo harus sama atau setelah tanggal transaksi',
  'financePurchaseInvoices.the_payment_date_max_higger_than_transaction_date':
    'Tanggal pembayaran harus sama atau setelah tanggal transaksi',
  'financePurchaseInvoices.detail_title': 'Tagihan Pembelian {number}',
  'financePurchaseInvoices.vendor': 'Vendor',
  'financePurchaseInvoices.reference': 'Reference',
  'financePurchaseInvoices.product': 'Produk',
  'financePurchaseInvoices.qty': 'Kuantitas',
  'financePurchaseInvoices.price': 'Harga',
  'financePurchaseInvoices.add_product': 'Tambah Produk',
  'financePurchaseInvoices.select_product': 'Pilih produk',
  'financePurchaseInvoices.attachments': 'Attachment',
  'financePurchaseInvoices.save_&_approve': 'Simpan & setujui',
  'financePurchaseInvoices.save_as_draft': 'Simpan sbg draft',
  'financePurchaseInvoices.save_&_submit_for_approval': 'Simpan & ajukan approval',
  'financePurchaseInvoices.please_select_a_product': 'Silakan pilih produk.',
  'financePurchaseInvoices.please_input_the_qty': 'Silakan masukkan kuantitas.',
  'financePurchaseInvoices.please_input_the_price': 'Silakan masukkan harga.',
  'financePurchaseInvoices.please_input_the_amount': 'Silakan masukkan total.',
  'financePurchaseInvoices.approve': 'Setujui',
  'financePurchaseInvoices.are_you_sure_to_approve': 'Apakah Anda yakin untuk menyutujui?',
  'financePurchaseInvoices.submit_for_approval': 'Ajukan approval',
  'financePurchaseInvoices.are_you_sure_to_submit_for_approval':
    'Apakah Anda yakin untuk mengajukan approval?',
  'financePurchaseInvoices.create_invoice': 'Buat Tagihan',
  'financePurchaseInvoices.amount_paid': 'Total Dibayar',
  'financePurchaseInvoices.paid_from': 'Dibayar Dari',
  'financePurchaseInvoices.add_payment': 'Tambah Pembayaran',
  'financePurchaseInvoices.select_paid_from': 'Pilih dibayar dari',
  'financePurchaseInvoices.please_select_a_paid_from': 'Silakan pilih dibayar dari.',
  'financePurchaseInvoices.error_cannot_add_payment': 'Tidak bisa menambah pembayaran.',
  'financePurchaseInvoices.payment': 'Pembayaran',
  'financePurchaseInvoices.detail_purchaseInvoice': 'Detil Tagihan Pembelian',
  'financePurchaseInvoices.number_po': 'Nomor PO',
  'financePurchaseInvoices.amount': 'Jumlah',
  'financePurchaseInvoices.paid_to': 'Dibayar Ke',
  'financePurchaseInvoices.select_paid_to': 'Pilih dibayar ke',
  'financePurchaseInvoices.please_select_a_paid_to': 'Silakan pilih dibayar ke.',

  'financePurchaseInvoices.follow_these_steps_to_import_your_purchase_invoices':
    'Ikuti langkah berikut untuk mengimport tagihan pembelianmu',
  'financePurchaseInvoices.1_download_your_purchase_invoices_template_file':
    '1. Download file template tagihan pembelian',
  'financePurchaseInvoices.start_by_downloading_our_purchase_invoices_csv':
    'Mulai dengan mengunduh file template Excel. File ini mempunyai kolom header yang yang dibutuhkan Kledo untuk mengimport detail dari tagihan pembelian-tagihan pembelianmu.',
  'financePurchaseInvoices.download_template_file': 'Download File Template',
  'financePurchaseInvoices.download_template_data_example_file': 'Download File Contoh',
  'financePurchaseInvoices.import': 'Import Tagihan pembelian',
  'financePurchaseInvoices.2_copy_your_purchase_invoices_into_the_template':
    '2. Salin detail tagihan pembelian ke dalam template',
  'financePurchaseInvoices.using_excel_or_another_spreadsheet_editor':
    'Menggunakan Excel atau software spreadsheeet lainnya, masukkan detail tagihan pembelian ke dalam file template dari Kledo. Pastikan detail tagihan pembelian sesuai dengan kolom header yang ada di template.',
  'financePurchaseInvoices.important': 'PENTING',
  'financePurchaseInvoices.3_upload_the_updated_template_file_here':
    '3. Upload template yang sudah diupdate ke sini',
  'financePurchaseInvoices.upload': 'Upload',
  'financePurchaseInvoices.save_your_updated_template_as_.csv_or_.txt_and_you_are_ready_to_go':
    'Simpan file template yang sudah diupdate',
  'financePurchaseInvoices.a_maximum_of_1000_purchase_invoices_can_be_imported_at_a_time':
    'Maksimal 1.000 tagihan pembelian dapat diimport dalam satu waktu.',
  'financePurchaseInvoices.contact_name_that_not_exist_will_be_inserted_as_a_new_contact':
    'Nama kontak yang yang belum ada di Kledo akan dimasukkan sebagai kontak baru.',
  'financePurchaseInvoices.import_actions_only_insert_new_purchase_invoices_cannot_update_or_delete':
    'Saat meng-import, Kledo hanya akan memasukkan data baru, tidak ada data yang dihapus atau diupdate.',
  'financePurchaseInvoices.dont_change_the_column_heading_in_the_template_file':
    'Jangan mengubah header kolom di dalam template. Ini dibutuhkan agar import dapat berjalan.',
  'financePurchaseInvoices.you_can_only_upload_csv_file':
    'Anda hanya dapat mengunggah file csv atau excel',
  'financePurchaseInvoices.upload_file': 'Upload File',
  'financePurchaseInvoices.check_the_imported_data': 'Cek Data',
  'financePurchaseInvoices.please_check_the_imported_data': 'Silakan cek data yang diimport',
  'financePurchaseInvoices.import_purchase_invoices_done': 'Import Berhasil',
  'financePurchaseInvoices.error_upload_file': 'Gagal mengunggah file.',
  'financePurchaseInvoices.file_not_found': 'File tidak ditemukan.',
  'financePurchaseInvoices.if_you_are_happy_to_continue_with_the_import_please_click_the_import_now_button': `Jika data yang diimport sesuai dengan yang kamu harapkan, silakan klik tombol "Import Sekarang".`,
  'financePurchaseInvoices.valid': 'Valid',
  'financePurchaseInvoices.invalid': 'Invalid',
  'financePurchaseInvoices.import_now': 'Import Sekarang',
  'financePurchaseInvoices.contact_name': 'Nama Kontak',
  'financePurchaseInvoices.email': 'Email',
  'financePurchaseInvoices.address': 'Alamat',
  'financePurchaseInvoices.phone': 'Phone',
  'financePurchaseInvoices.invoice_date': 'Tanggal Tagihan',
  'financePurchaseInvoices.invoice_number': 'Nomor Invoice',
  'financePurchaseInvoices.product_name': 'Nama Produk',
  'financePurchaseInvoices.code_sku': 'Kode/SKU',
  'financePurchaseInvoices.tax_inclusive': 'Termasuk Pajak',
  'financePurchaseInvoices.paid': 'Lunas',
  'financePurchaseInvoices.paid_account': 'Akun Pembayaran',
  'financePurchaseInvoices.copy_link': 'Salin Link Faktur Pembelian',
  'financePurchaseInvoices.send_email': 'Kirim Email Faktur Pembelian',
  'financeInvoices.on': 'pada',
  'financeInvoices.last_printed_by': 'Terakhir dicetak oleh',
  'financeInvoices.last_modified_by': 'Terakhir diubah oleh',
  'financeInvoices.preorder_number': 'Nomor PO',
  'financeInvoices.total_before_tax': 'Total Sebelum Pajak',
  'financeInvoices.printStatus': 'Status Print',
  'financeInvoices.import_new_invoice': 'Import Tagihan Baru',
  'financeInvoices.import_invoice_status': 'Import Status Tagihan',
  'financeInvoices.import_recurring_invoice': 'Import Tagihan Berulang',
  'financeInvoices.witholding': 'Pemotongan',
  'financeInvoices.select_holding_from': 'Pilih dipotong dari',
  'financeInvoices.please_select_a_holding_from': 'Silahkan pilih dipotong dari',
  'financeInvoices.print_partial': 'Faktur Per Termin',
  'financeInvoices.print_delivery_label': 'Label Pengiriman',
  'financeInvoices.print_delivery': 'Surat Jalan',
  'financeInvoices.print_payment': 'Rekap Pembayaran',
  'financeInvoices.print_tax': 'Faktur Pajak',
  'financeInvoices.download_word': 'Faktur MsWord',
  'financeInvoices.default_label_term': 'Tagihan Termin I',
  'financeInvoices.here': 'di sini',
  'financeInvoices.please_input_label': 'Silakan masukkan label',
  'financeInvoices.insufficient_stock_confirm':
    'Terdapat produk yang  stok nya kurang, Apakah akan tetap membuat tagihan ini ?',
  'financeInvoices.insufficient_stock_warning':
    'Tidak dapat menambah tagihan, karena terdapat produk yang stok nya kurang.',
  'financeInvoices.insufficient_stock': 'Stok tidak mencukupi',
  'financeInvoices.builtin_notes_for_easy_searching':
    'Catatan internal untuk mempermudah pencarian.',
  'financeInvoices.used_to_create_transaction_categories':
    'Digunakan untuk membuat kategori transaksi. Anda juga bisa membuat laporan berdasarkan tag ini.',
  'financeInvoices.the_number_will_be_automatically_generated_by_the_system':
    'Nomor akan otomatis terbuat oleh sistem, tapi Anda juga bisa mengubahnya disini. Anda bisa mengatur format nomor ini {link}.',
  'financeInvoices.amount_before_tax': 'Total Sblm Pajak',
  'financeInvoices.amount_after_discount': 'Total setelah diskon',

  'financePurchaseInvoices.import_purchase_invoice_status': 'Import Status Tagihan Pembelian',
  'financePurchaseInvoices.inventory_account': 'Akun Penyimpanan',
  'financePurchaseInvoices.purchase_invoices_successfully_imported':
    '{rowCount} tagihan pembelian berhasil diimport.',
  'financePurchaseInvoices.view_detail': 'Tampilkan detil',
  'financePurchaseInvoices.import_another_file': 'Import file lain',
  'financePurchaseInvoices.go_to_purchase_invoice_list': 'Ke halaman daftar tagihan pembelian',
  'financePurchaseInvoices.success': 'Berhasil',
  'financePurchaseInvoices.failed': 'Gagal',
  'financePurchaseInvoices.error_cannot_import': 'Gagal import.',
  'financePurchaseInvoices.error': 'Error',
  'financePurchaseInvoices.make_a_payment': 'Kirim pembayaran',

  'financeQuotes.message': 'Pesan',
  'financeQuotes.error_cannot_print_pdf': 'Tidak dapat membuat pdf penawaran.',
  'financeQuotes.add_quote': 'Tambah Penawaran',
  'financeQuotes.financeQuotes': 'Penawaran',
  'financeQuotes.quote_accepted': 'Penawaran disetujui',
  'financeQuotes.quote_open': 'Penawaran belum selesai',
  'financeQuotes.successfully_added': 'berhasil ditambahkan.',
  'financeQuotes.error_cannot_add': 'Tidak dapat menambah penawaran.',
  'financeQuotes.error_cannot_update': 'Tidak dapat mengubah penawaran.',
  'financeQuotes.error_cannot_move': 'Tidak dapat memindah penawaran.',
  'financeQuotes.error_cannot_change_status': 'Tidak dapat mengubah status penawaran.',
  'financeQuotes.edit_quote': 'Ubah Penawaran',
  'financeQuotes.max_characters': 'Maks {value} karakter.',
  'financeQuotes.min_characters': 'Min {value} karakter.',
  'financeQuotes.only_number_allowed': 'Hanya angka yang diperkenankan.',
  'financeQuotes.description': 'Deskripsi',
  'financeQuotes.tax': 'Pajak',
  'financeQuotes.select_tax': 'Pilih pajak',
  'financeQuotes.amount': 'Total',
  'financeQuotes.please_select_a_contact': 'Silakan pilih kontak',
  'financeQuotes.select_contact': 'Pilih kontak',
  'financeQuotes.add_contact': 'Tambah kontak',
  'financeQuotes.all_contacts': 'Semua kontak',
  'financeQuotes.transaction_date': 'Tgl Transaksi',
  'financeQuotes.please_select_transaction_date': 'Silakan pilih tanggal transaksi',
  'financeQuotes.due_date': 'Kadaluarsa',
  'financeQuotes.please_select_due_date': 'Silakan pilih tanggal kadaluarsa',
  'financeQuotes.term': 'Termin',
  'financeQuotes.select_term': 'Pilih termin',
  'financeQuotes.add_term': 'Tambah termin',
  'financeQuotes.price_include_tax': 'Harga termasuk pajak',
  'financeQuotes.add_more': 'Tambah baris',
  'financeQuotes.number': 'Nomor',
  'financeQuotes.please_input_the_number': 'Silakan isikan nomor.',
  'financeQuotes.memo': 'Reference',
  'financeQuotes.date': 'Tanggal',
  'financeQuotes.status': 'Status',
  'financeQuotes.balance_due': 'Sisa Tagihan',
  'financeQuotes.please_select_a_term': 'Silakan pilih termin.',
  'financeQuotes.search_by_memo': 'Cari',
  'financeQuotes.the_due_date_max_higger_than_transaction_date':
    'Tanggal kadaluarsa harus sama atau setelah tanggal transaksi',
  'financeQuotes.detail_title': 'Penawaran {number}',
  'financeQuotes.customer': 'Pelanggan',
  'financeQuotes.reference': 'Reference',
  'financeQuotes.product': 'Produk',
  'financeQuotes.qty': 'Kuantitas',
  'financeQuotes.price': 'Harga',
  'financeQuotes.add_product': 'Tambah Produk',
  'financeQuotes.select_product': 'Pilih produk',
  'financeQuotes.attachments': 'Attachment',
  'financeQuotes.save_as_draft': 'Simpan sbg draft',
  'financeQuotes.save_as_draft_add_new': 'Simpan sbg draft & Tambah Baru',
  'financeQuotes.save_&_submit_for_approval': 'Simpan & ajukan approval',
  'financeQuotes.please_select_a_product': 'Silakan pilih produk.',
  'financeQuotes.please_input_the_qty': 'Silakan masukkan kuantitas.',
  'financeQuotes.please_input_the_price': 'Silakan masukkan harga.',
  'financeQuotes.please_input_the_amount': 'Silakan masukkan total.',
  'financeQuotes.mark_as_sent': 'Tandai sbg terkirim',
  'financeQuotes.are_you_sure_to_mark_as_sent': 'Apakah Anda yakin menandai sebagai terkirim?',
  'financeQuotes.mark_as_accepted': 'Tandai sbg diterima',
  'financeQuotes.are_you_sure_to_mark_as_accepted': 'Apakah Anda yakin menandai sebagai diterima?',
  'financeQuotes.mark_as_declined': 'Tandai sbg ditolak',
  'financeQuotes.are_you_sure_to_mark_as_declined': 'Apakah Anda yakin menandai sebagai ditolak?',
  'financeQuotes.create_invoice': 'Buat Tagihan',
  'financeQuotes.send': 'Kirim',
  'financeQuotes.send_add_new': 'Kirim & Tambah Baru',
  'financeQuotes.detail_quote': 'Detil Penawaran',
  'financeQuotes.create_order': 'Buat Pemesanan',

  'financeInvoices.sales': 'Penjualan',
  'financeInvoices.print_receipt': 'Print Kuitansi Tagihan',
  'financeInvoices.download': 'Unduh',
  'financeInvoices.download_sales_report': 'Unduh Laporan Penjualan',
  'financeInvoices.my_order': 'Pesanan Saya',
  'financeInvoices.all': 'Semua',
  'financeInvoices.export': 'Export',
  'financeInvoices.orders': 'Pesanan',
  'financeInvoices.order_review': 'Tinjauan Pesanan',
  'financeInvoices.manage_order': 'Kelola Pesanan',
  'financeInvoices.download_order': 'Unduh Pesanan',
  'financeInvoices.export_all_order': 'Ekspor Semua Pesanan',
  'financeInvoices.delivery_list': 'Daftar Pengiriman',
  'financeInvoices.done': 'Done',
  'financeInvoices.export_invoice': 'Ekspor E-Faktur',
  'financeInvoices.choose_template': 'Pilih Template',
  'financeInvoices.import_with_kledo_template': 'Import dengan format template Excel dari Kledo',
  'financeInvoices.marketplace_connect_sync_automatic_from_marketplace_without_import':
    'Marketplace Connect: Otomatis sinkronisasi data dari marketplace ke Kledo, tanpa perlu import',
  'financeInvoices.import_with_marketplace_template':
    'Import dengan format template Excel dari Marketplace',
  'financeInvoices.export_sales_data_from_tokopedia': '1. Export Data Penjualan dari Tokopedia',
  'financeInvoices.export_sales_data_from_shopee': '1. Export Data Penjualan dari Shopee',
  'financeInvoices.export_sales_data_from_bukalapak': '1. Export Data Penjualan dari Bukalapak',
  'financeInvoices.export_sales_data_from_lazada': '1. Export Data Penjualan dari Lazada',
  'financeInvoices.export_sales_data_from_tiktok': '1. Export Data Penjualan dari Tiktok Shop',
  'financeInvoices.export_sales_data_from_olsera': '1. Export data penjualan dari dashboard Olsera',
  'financeInvoices.export_sales_data_record_shipping_cost_to_bill':
    'catatkan biaya kirim ke tagihan',
  'financeInvoices.upload_excel_from_tokopedia': '2. Upload File Excel dari Tokopedia di sini',
  'financeInvoices.upload_excel_from_shopee': '2. Upload File Excel dari Shopee di sini',
  'financeInvoices.upload_excel_from_bukalapak': '2. Upload File Excel dari Bukalapak di sini',
  'financeInvoices.upload_excel_from_lazada': '2. Upload File Excel dari Lazada di sini',
  'financeInvoices.upload_excel_from_tiktok': '2. Upload File Excel dari Tiktok Shop di sini',
  'financeInvoices.upload_excel_from_olsera': '2. Upload File Excel dari Olsera di sini',
  'financeInvoices.set_import_option': '3. Atur Opsi Import',
  'financeInvoices.1a_tokopedia': 'a. Pada halaman Home, klik tab {sales}',
  'financeInvoices.1b_tokopedia': 'b. Klik tombol {download}',
  'financeInvoices.1c_tokopedia':
    'c. Filter rentang waktu laporan penjualan yang ingin diunduh, lalu klik tombol {download}. Simpan file Excel yang baru saja di unduh.',
  'financeInvoices.1a_shopee':
    'a. Login pada aplikasi shopee seller center, lalu klik menu {myOrder}',
  'financeInvoices.1b_shopee': 'b. Klik tab {all}',
  'financeInvoices.1c_shopee':
    'c. Filter rentang waktu laporan penjualan yang ingin diunduh pada kolom Periode Data, lalu klik tombol {export}. Simpan file Excel yang baru saja di unduh.',
  'financeInvoices.1a_bukalapak': 'a. Pada halaman Home, klik tab {transaction}',
  'financeInvoices.1b_bukalapak': 'b. Klik tombol {downloadTransaction}',
  'financeInvoices.1c_bukalapak':
    'c. Filter rentang waktu dan transaksi laporan penjualan yang ingin di unduh, lalu klik tombol {downloadTransaction}. Simpan file Excel yang baru saja di unduh.',
  'financeInvoices.1a_lazada':
    'a. Pada halaman Home, klik tab {orders}, lalu klik tab {orderReview}',
  'financeInvoices.1b_lazada': 'b. Klik tombol {export}, lalu klik {exportAllOrder}',
  'financeInvoices.1c_lazada':
    'c. Untuk laporan penjualan yang ingin diunduh, klik tombol {download}, lalu klik {done}. Simpan file Excel yang baru saja di unduh.',
  'financeInvoices.1a_tiktok': 'a. Klik menu {order} - {manageOrder}',
  'financeInvoices.1b_tiktok': 'b. Klik tab {done}',
  'financeInvoices.1c_tiktok': 'c. Klik menu {downloadOrder}',
  'financeInvoices.1d_tiktok': 'd. Pilih format unduh Excel',
  'financeInvoices.1e_tiktok': 'e. Centang {deliveryList} pada {chooseTemplate}',
  'financeInvoices.1f_tiktok': 'f. Klik tombol {download}',
  'financeInvoices.1a_olsera': 'a. Klik menu Laporan',
  'financeInvoices.1b_olsera': 'b. Klik menu Laporan Penjualan',
  'financeInvoices.1c_olsera': 'c. Klik menu Item Penjualan Per Tanggal',
  'financeInvoices.1d_olsera': 'd. Pilih status pembayaran telah dibayar',
  'financeInvoices.1e_olsera': 'e. Klik tombol Excel. Simpan file Excel yang baru saja di unduh.',
  'financeInvoices.this_import_will_change_status':
    'Fasilitas import ini akan mengubah status tagihan yang belum lunas menjadi lunas.',
  'financeInvoices.data_will_not_change_even_if_different':
    'Data tagihan tidak akan diubah pada import ini, meskipun data pada sistem kledo dengan data pada file excel berbeda.',
  'financeQuotes.follow_these_steps_to_import_your_quotes':
    'Ikuti langkah berikut untuk mengimport penawaranmu',
  'financeQuotes.1_download_your_quotes_template_file': '1. Download file template penawaran',
  'financeQuotes.start_by_downloading_our_quotes_csv':
    'Mulai dengan mengunduh file template Excel penawaran. File ini mempunyai kolom header yang yang dibutuhkan Kledo untuk mengimport detail dari penawaran-penawaranmu.',
  'financeQuotes.download_template_file': 'Download File Template',
  'financeQuotes.download_template_data_example_file': 'Download File Contoh',
  'financeQuotes.import': 'Import Penawaran',
  'financeQuotes.2_copy_your_quotes_into_the_template':
    '2. Salin detail penawaran ke dalam template',
  'financeQuotes.using_excel_or_another_spreadsheet_editor':
    'Menggunakan Excel atau software spreadsheeet lainnya, masukkan detail penawaran ke dalam file template dari Kledo. Pastikan detail penawaran sesuai dengan kolom header yang ada di template.',
  'financeQuotes.important': 'PENTING',
  'financeQuotes.3_upload_the_updated_template_file_here':
    '3. Upload template yang sudah diupdate ke sini',
  'financeQuotes.upload': 'Upload',
  'financeQuotes.save_your_updated_template_as_.csv_or_.txt_and_you_are_ready_to_go':
    'Simpan file template yang sudah diupdate',
  'financeQuotes.a_maximum_of_500_quotes_can_be_imported_at_a_time':
    'Maksimal 1.000 penawaran dapat diimport dalam satu waktu.',
  'financeQuotes.contact_name_that_not_exist_will_be_inserted_as_a_new_contact':
    'Nama kontak yang yang belum ada di Kledo akan dimasukkan sebagai kontak baru.',
  'financeQuotes.import_actions_only_insert_new_quotes_cannot_update_or_delete':
    'Saat meng-import, Kledo hanya akan memasukkan data baru, tidak ada data yang dihapus atau diupdate.',
  'financeQuotes.dont_change_the_column_heading_in_the_template_file':
    'Jangan mengubah header kolom di dalam template. Ini dibutuhkan agar import dapat berjalan.',
  'financeQuotes.you_can_only_upload_csv_file': 'Anda hanya dapat mengunggah file csv atau excel',
  'financeQuotes.upload_file': 'Upload File',
  'financeQuotes.check_the_imported_data': 'Cek Data',
  'financeQuotes.please_check_the_imported_data': 'Silakan cek data yang diimport',
  'financeQuotes.import_quotes_done': 'Import Berhasil',
  'financeQuotes.error_upload_file': 'Gagal mengunggah file.',
  'financeQuotes.file_not_found': 'File tidak ditemukan.',
  'financeQuotes.if_you_are_happy_to_continue_with_the_import_please_click_the_import_now_button': `Jika data yang diimport sesuai dengan yang kamu harapkan, silakan klik tombol "Import Sekarang".`,
  'financeQuotes.valid': 'Valid',
  'financeQuotes.invalid': 'Invalid',
  'financeQuotes.total': 'Total',
  'financeQuotes.import_now': 'Import Sekarang',
  'financeQuotes.contact_name': 'Nama Kontak',
  'financeQuotes.email': 'Email',
  'financeQuotes.address': 'Alamat',
  'financeQuotes.phone': 'Phone',
  'financeQuotes.quote_date': 'Tanggal Penawaran',
  'financeQuotes.quote_number': 'Nomor Penawaran',
  'financeQuotes.product_name': 'Nama Produk',
  'financeQuotes.code_sku': 'Kode/SKU',
  'financeQuotes.tax_inclusive': 'Termasuk Pajak',
  'financeQuotes.copy_link': 'Salin Link Penawaran',
  'financeQuotes.send_email': 'Kirim Email Penawaran',
  'financeQuotes.send_sms': 'Kirim SMS Penawaran',
  'financeQuotes.send_wa': 'Kirim Whatsapp Penawaran',

  'financeQuotes.inventory_account': 'Akun Penyimpanan',
  'financeQuotes.quotes_successfully_imported': '{rowCount} quote berhasil diimport.',
  'financeQuotes.view_detail': 'Tampilkan detil',
  'financeQuotes.import_another_file': 'Import file lain',
  'financeQuotes.go_to_quote_list': 'Ke halaman daftar penawaran',
  'financeQuotes.success': 'Berhasil',
  'financeQuotes.failed': 'Gagal',
  'financeQuotes.error_cannot_import': 'Gagal import.',
  'financeQuotes.error': 'Error',
  'financeQuotes.print_quote': 'Cetak Penawaran',

  'pos.pos': 'POS',
  'pos.pos_activity': 'POS Activity',
  'pos.transfer_to_room': 'Transfer to Room',
  'pos.please_choose_a_table': 'please choose a table!',
  'pos.failed_list_item_null': 'failed list item null!',
  'pos.harga_item': 'Harga item',
  'pos.activity': 'Activity',
  'pos.saved_bill': 'Saved Bill',
  'pos.items': 'Items',
  'pos.total_harga_item': 'Total harga item',
  'pos.discount': 'Discount',
  'pos.tax': 'Pajak',
  'pos.total': 'Total',
  'pos.pay': 'Pay',
  'pos.save_bill': 'Save Bill',
  'pos.list_save_bill': 'List Save Bill',
  'pos.table': 'Table',
  'pos.select_table': 'select table',
  'pos.receipt_number': 'Receipt Nomor',
  'pos.details': 'Detils',
  'pos.payment': 'Pembayaran',
  'pos.date_range': 'Tanggal Range',
  'pos.unpaid': 'unpaid',
  'pos.paid_cashier': 'paid cashier',
  'pos.transfer_to_room_': 'transfer to room',
  'pos.please_select_date_range': 'please select tanggal range',
  'pos.select': 'Select',
  'pos.room': 'Room',
  'pos.name': 'Nama',
  'pos.checking': 'Checking',
  'pos.transfer': 'Transfer',
  'pos.new_sale': 'New Sale',
  'pos.transfer_to': 'Transfer To',
  'pos.guest_name': 'Guest Name',
  'pos.pos_outlets': 'POS Outlets',
  'pos.category': 'Kategori',
  'pos.variant_name': 'Variant Name',
  'pos.modify_variant': 'Modify Variant',
  'pos.variant_of': 'Variant of',
  'pos.add_variant_category': 'Tambah Variant Kategori',
  'pos.add_variant': 'Tambah Variant',
  'pos.product_name': 'Nama Produk',
  'pos.add_table': 'Tambah Table',
  'pos.enable_table_for_this_outlet': 'Enable table for this outlet',
  'pos.add_product': 'Tambah Produk',
  'pos.add_category': 'Tambah Kategori',
  'pos.product': 'Produk',
  'pos.settings': 'Settings',
  'pos.name_category_required': 'Nama kategori wajib diisi',
  'pos.edit_category': 'Ubah Kategori',
  'pos.category_name': 'Kategori Name',
  'pos.change_multi_required': 'change multi wajib diisi',
  'pos.one_variant_per_product_sale': 'One Variant per produk sale',
  'pos.multiple_variant_per_product_sale': 'Multiple Variant per produk sale',
  'pos.edit_variant_category': 'Ubah Variant Kategori',
  'pos.add_tables': 'Tambah Tables',
  'pos.name_table_required': 'name table wajib diisi',
  'pos.tables': 'Tables',
  'pos.edit_tables': 'Ubah Tables',
  'pos.name_required': 'name wajib diisi',
  'pos.tax_required': 'tax wajib diisi',
  'pos.service_charge': 'Service Charge',
  'pos.service_charge_required': 'service charge wajib diisi',
  'pos.product_image': 'Gambar Produk',
  'pos.product_image_required': 'Gambar produk wajib diisi',
  'pos.choose_category_required': 'choose kategori wajib diisi',
  'pos.choose_category': 'Pilih Kategori',
  'pos.product_name_required': 'nama produk wajib diisi',
  'pos.price': 'Harga',
  'pos.price_required': 'price wajib diisi',
  'pos.extra_price_must_greather_than_0': 'Harga harus lebih besar dari 0.',
  'pos.base_price': 'Base Harga',
  'pos.base_price_required': 'Base Harga wajib diisi',
  'pos.description': 'Deskripsi',
  'pos.description_required': 'Description wajib diisi',
  'pos.edit_product': 'Ubah Produk',
  'pos.variant': 'Variant',
  'pos.variant_name_required': 'variant name wajib diisi',
  'pos.edit_variant': 'Ubah Variant',

  'financeInvoices.message': 'Pesan',
  'financeInvoices.order_payment': 'Pembayaran Pemesanan',
  'financeInvoices.add_invoice': 'Tambah Tagihan',
  'financeInvoices.financeInvoices': 'Tagihan',
  'financeInvoices.error_cannot_print_pdf': 'Tidak dapat membuat pdf tagihan.',
  'financeInvoices.error_cannot_export_receipt': 'Tidak dapat ekspor kwitansi',
  'financeInvoices.successfully_added': 'berhasil ditambahkan.',
  'financeInvoices.error_cannot_add': 'Tidak dapat menambah tagihan.',
  'financeInvoices.error_cannot_update': 'Tidak dapat mengubah tagihan.',
  'financeInvoices.error_cannot_move': 'Tidak dapat memindah tagihan.',
  'financeInvoices.error_cannot_change_status': 'Gagal mengubah status tagihan.',
  'financeInvoices.edit_invoice': 'Ubah tagihan',
  'financeInvoices.max_characters': 'Maks {value} karakter.',
  'financeInvoices.min_characters': 'Min {value} karakter.',
  'financeInvoices.only_number_allowed': 'Hanya angka yang diperkenankan.',
  'financeInvoices.description': 'Deskripsi',
  'financeInvoices.tax': 'Pajak',
  'financeInvoices.select_tax': 'Pilih pajak',
  'financeInvoices.please_select_a_contact': 'Silakan pilih kontak',
  'financeInvoices.select_contact': 'Pilih kontak',
  'financeInvoices.add_contact': 'Tambah kontak',
  'financeInvoices.all_contacts': 'Semua kontak',
  'financeInvoices.transaction_date': 'Tgl Transaksi',
  'financeInvoices.please_select_transaction_date': 'Silakan pilih tanggal transaksi',
  'financeInvoices.due_date': 'Tanggal Jatuh Tempo',
  'financeInvoices.please_select_due_date': 'Silakan pilih tanggal jatuh tempo',
  'financeInvoices.term': 'Termin',
  'financeInvoices.select_term': 'Pilih termin',
  'financeInvoices.add_term': 'Tambah termin',
  'financeInvoices.price_include_tax': 'Harga termasuk pajak',
  'financeInvoices.add_more': 'Tambah baris',
  'financeInvoices.number': 'Nomor',
  'financeInvoices.please_input_the_number': 'Silakan isikan nomor.',
  'financeInvoices.memo': 'Reference',
  'financeInvoices.date': 'Tanggal',
  'financeInvoices.payment_date': 'Tanggal Pembayaran',
  'financeInvoices.paid_date': 'Tanggal Pelunasan',
  'financeInvoices.show_payment_amount': 'Tampilkan jumlah pelunasan',
  'financeInvoices.hide_payment_amount': 'Sembunyikan jumlah pelunasan',
  'financeInvoices.status': 'Status',
  'financeInvoices.payment_status': 'Status Pembayaran',
  'financeInvoices.balance_due': 'Sisa Tagihan',
  'financeInvoices.please_select_a_term': 'Silakan pilih termin.',
  'financeInvoices.search_by_memo': 'Cari',
  'financeInvoices.the_due_date_max_higger_than_transaction_date':
    'Tanggal jatuh tempo harus sama atau setelah tanggal transaksi',
  'financeInvoices.detail_title': ' Tagihan {number}',
  'financeInvoices.customer': 'Pelanggan',
  'financeInvoices.reference': 'Reference',
  'financeInvoices.product': 'Produk',
  'financeInvoices.qty': 'Kuantitas',
  'financeInvoices.price': 'Harga',
  'financeInvoices.add_product': 'Tambah Produk',
  'financeInvoices.select_product': 'Pilih produk',
  'financeInvoices.attachments': 'Attachment',
  'financeInvoices.save_&_approve': 'Simpan & setujui',
  'financeInvoices.save_as_draft': 'Simpan sbg draft',
  'financeInvoices.save_&_submit_for_approval': 'Simpan & ajukan approval',
  'financeInvoices.please_select_a_product': 'Silakan pilih produk.',
  'financeInvoices.please_input_the_qty': 'Silakan masukkan kuantitas.',
  'financeInvoices.please_input_the_price': 'Silakan masukkan harga.',
  'financeInvoices.please_input_the_amount': 'Silakan masukkan total.',
  'financeInvoices.approve': 'Setujui',
  'financeInvoices.are_you_sure_to_approve': 'Apakah Anda yakin untuk menyutujui?',
  'financeInvoices.submit_for_approval': 'Ajukan approval',
  'financeInvoices.are_you_sure_to_submit_for_approval':
    'Apakah Anda yakin untuk mengajukan approval?',
  'financeInvoices.create_invoice': 'Buat Tagihan',
  'financeInvoices.amount_paid': 'Total Dibayar',
  'financeInvoices.paid_from': 'Terima pembayaran ke',
  'financeInvoices.add_payment': 'Terima Pembayaran',
  'financeInvoices.receive_a_payment': 'Terima pembayaran',
  'financeInvoices.error_cannot_add_payment': 'Tidak bisa menambah pembayaran.',
  'financeInvoices.payment': 'Pembayaran',
  'financeInvoices.detail_invoice': 'Detil Tagihan',
  'financeInvoices.number_quotes': 'Nomor Penawaran',
  'financeInvoices.amount': 'Jumlah',
  'financeInvoices.tax_code': 'Kode dan Nomor Seri Faktur',
  'financeInvoices.please_input_the_tax_code': 'Silakan masukkan kode dan nomor seri faktur.',
  'financeInvoices.error_cannot_change_tax_code':
    'Tidak dapat mengubah kode dan nomor seri faktur.',
  'financeInvoices.order': 'Nomor Pemesanan',
  'financeInvoices.quote': 'Nomor Penawaran',
  'financeInvoices.delivery': 'Nomor Pengiriman',
  'financeInvoices.please_select_deliveries': 'Pilih minimal satu nomor pengiriman.',
  'financeInvoices.cannot_greather_than_order': 'Tidak dapat melebihi order.',
  'financeInvoices.down_payment': 'Uang muka',
  'financeInvoices.already_paid': 'Sudah dibayar',
  'financeInvoices.transaction': 'Transaksi',
  'financeInvoices.print_invoice': 'Faktur',
  'financeInvoices.whoever_have_this_link_can_see_pdf':
    'Siapapun yang memiliki link ini dapat melihat invoicenya seperti tampilan format PDF.',
  'financeInvoices.copy_link': 'Salin Link Faktur',
  'financeInvoices.send_email': 'Kirim Email Faktur',
  'financeInvoices.send_sms': 'Kirim SMS Faktur',
  'financeInvoices.send_wa': 'Kirim Whatsapp Faktur',
  'financeInvoices.sales_person': 'Sales Person',
  'financeInvoices.set_paid': 'Set Lunas',
  'financeInvoices.mass_payment': 'Pembayaran Massal',
  'financeInvoices.cannot_mass_payment': 'Tidak dapat pembayaran massal',
  'financeInvoices.can_be_paid': 'Bisa dilunaskan',
  'financeInvoices.cannot_be_paid': 'Tidak bisa dilunaskan',
  'financeInvoices.data_progress_paid': '{count} data dari total {total} telah dilunaskan',
  'financeInvoices.data_has_been_paid': 'Total {count} data telah berhasil dilunaskan',
  'financeInvoices.cash_payment': 'Bayar Tunai',
  'financeInvoices.with_credit_payment': 'Dengan Hutang',
  'financeInvoices.with_debit_payment': 'Dengan Piutang',
  'financeInvoices.payment_with_credit_memo': 'Pembayaran dengan Hutang',
  'financeInvoices.payment_with_debit_memo': 'Pembayaran dengan Piutang',
  'financeInvoices.follow_these_steps_to_import_your_invoices':
    'Ikuti langkah berikut untuk mengimport tagihanmu',
  'financeInvoices.create_mass_trans': 'Buat {title} Massal',
  'financeInvoices.can_be_created_to': 'Bisa dibuat {title}',
  'financeInvoices.cannot_be_created_to': 'Tidak bisa dibuat {title}',
  'financeInvoices.data_progress_create_trans': '{count} {title} dari total {total} telah dibuat',
  'financeInvoices.data_has_been_create_trans': 'Total {count} {title} telah berhasil dibuat',

  'financeInvoices.1_download_your_invoices_template_file': '1. Download file template tagihan',
  'financeInvoices.start_by_downloading_our_invoices_csv':
    'Mulai dengan mengunduh file template Excel tagihan. File ini mempunyai kolom header yang yang dibutuhkan Kledo untuk mengimport detail dari tagihan-tagihanmu.',
  'financeInvoices.download_template_file': 'Download File Template',
  'financeInvoices.download_template_data_example_file': 'Download File Contoh',
  'financeInvoices.import': 'Import Tagihan',
  'financeInvoices.2_copy_your_invoices_into_the_template':
    '2. Salin detail tagihan ke dalam template',
  'financeInvoices.using_excel_or_another_spreadsheet_editor':
    'Menggunakan Excel atau software spreadsheeet lainnya, masukkan detail tagihan ke dalam file template dari Kledo. Pastikan detail tagihan sesuai dengan kolom header yang ada di template.',
  'financeInvoices.important': 'PENTING',
  'financeInvoices.3_upload_the_updated_template_file_here':
    '3. Upload template yang sudah diupdate ke sini',
  'financeInvoices.upload': 'Upload',
  'financeInvoices.save_your_updated_template_as_.csv_or_.txt_and_you_are_ready_to_go':
    'Simpan file template yang sudah diupdate',
  'financeInvoices.a_maximum_of_500_invoices_can_be_imported_at_a_time':
    'Maksimal 1.000 tagihan dapat diimport dalam satu waktu.',
  'financeInvoices.contact_name_that_not_exist_will_be_inserted_as_a_new_contact':
    'Nama kontak yang yang belum ada di Kledo akan dimasukkan sebagai kontak baru.',
  'financeInvoices.import_actions_only_insert_new_invoices_cannot_update_or_delete':
    'Saat meng-import, Kledo hanya akan memasukkan data baru, tidak ada data yang dihapus atau diupdate.',
  'financeInvoices.dont_change_the_column_heading_in_the_template_file':
    'Jangan mengubah header kolom di dalam template. Ini dibutuhkan agar import dapat berjalan.',
  'financeInvoices.you_can_only_upload_csv_file': 'Anda hanya dapat mengunggah file csv atau excel',
  'financeInvoices.upload_file': 'Upload File',
  'financeInvoices.check_the_imported_data': 'Cek Data',
  'financeInvoices.please_check_the_imported_data': 'Silakan cek data yang diimport',
  'financeInvoices.import_invoices_done': 'Import Berhasil',
  'financeInvoices.error_upload_file': 'Gagal mengunggah file.',
  'financeInvoices.file_not_found': 'File tidak ditemukan.',
  'financeInvoices.if_you_are_happy_to_continue_with_the_import_please_click_the_import_now_button': `Jika data yang diimport sesuai dengan yang kamu harapkan, silakan klik tombol "Import Sekarang".`,
  'financeInvoices.valid': 'Valid',
  'financeInvoices.invalid': 'Invalid',
  'financeInvoices.total': 'Total',
  'financeInvoices.import_now': 'Import Sekarang',
  'financeInvoices.contact_name': 'Nama Kontak',
  'financeInvoices.email': 'Email',
  'financeInvoices.address': 'Alamat',
  'financeInvoices.phone': 'Phone',
  'financeInvoices.invoice_date': 'Tanggal Tagihan',
  'financeInvoices.date_same_as_the_original_transaction':
    'Tanggalnya disamakan dengan transaksi asal',
  'financeInvoices.invoice_number': 'Nomor Invoice',
  'financeInvoices.product_name': 'Nama Produk',
  'financeInvoices.code_sku': 'Kode/SKU',
  'financeInvoices.tax_inclusive': 'Termasuk Pajak',
  'financeInvoices.paid': 'Lunas',
  'financeInvoices.paid_account': 'Akun Pembayaran',
  'financeInvoices.row_imported': '{imported} dari {total} baris diproses',

  'financeInvoices.inventory_account': 'Akun Penyimpanan',
  'financeInvoices.invoices_successfully_imported': '{rowCount} invoice berhasil diimport.',
  'financeInvoices.view_detail': 'Tampilkan detil',
  'financeInvoices.import_another_file': 'Import file lain',
  'financeInvoices.go_to_invoice_list': 'Ke halaman daftar invoice',
  'financeInvoices.success': 'Berhasil',
  'financeInvoices.failed': 'Gagal',
  'financeInvoices.error_cannot_import': 'Gagal import.',
  'financeInvoices.error': 'Error',
  'financeInvoices.import_invoices_in_progress': 'Import sedang berlangsung',
  'financeInvoices.import_invoices_is_still_in_progress': 'Import masih berjalan',
  'financeInvoices.import_warehouse_help_text':
    'Apabila saat melakukan import data dari marketplace diisikan kolom gudang-nya, maka otomatis produk yang terbentuk akibat dari transaksi yang diimport akan "lacak stok". Namun jika produk tidak diisi gudang, otomatis produknya akan "tidak lacak stok".',
  'financeInvoices.its_ok_to_close_this_page':
    'Tidak apa-apa untuk menutup halaman ini atau mengimport file lain. Ketika import selesai, Anda akan diberitahu melalui email.',
  'financeInvoices.duplicate_to_trans': 'Duplikat ke transaksi',

  'financeInvoices.you_can_only_upload_up_to': `Anda hanya dapat mengupload maksimum sebanyak {count} file`,
  'financeInvoices.please_select_tag': 'Silahkan pilih tag',
  'financeInvoices.recurring_invoice': 'Tagihan Berulang',
  'financeInvoices.1_download_your_invoices_recurring_template_file':
    '1. Download file template tagihan berulang',
  'financeInvoices.start_by_downloading_our_invoices_recurring_csv':
    'Mulai dengan mengunduh file template Excel tagihan berulang. File ini mempunyai kolom header yang yang dibutuhkan Kledo untuk mengimport detail dari tagihan-tagihan berulangmu.',
  'financeInvoices.2_copy_your_invoices_recurring_into_the_template':
    '2. Salin detail tagihan berulang ke dalam template',
  'financeInvoices.invoice_recurring_using_excel_or_another_spreadsheet_editor':
    'Menggunakan Excel atau software spreadsheeet lainnya, masukkan detail tagihan berulang ke dalam file template dari Kledo. Pastikan detail tagihan berulang sesuai dengan kolom header yang ada di template.',
  'financeInvoices.cannot_upload_this_photo': 'Tidak bisa mengunggah foto ini.',

  'financeInvoices.import_invoice_return': 'Import Tagihan Retur',
  'financeInvoices.invoice_return_number': 'Nomor Tagihan Retur',
  'financeInvoices.import_return_product_name_or_code': 'Nama {product} atau Kode/SKU',
  'financeInvoices.1_download_your_invoices_return_template_file':
    '1. Download file template tagihan retur',
  'financeInvoices.start_by_downloading_our_invoices_return_csv':
    'Mulai dengan mengunduh file template Excel tagihan retur. File ini mempunyai kolom header yang yang dibutuhkan Kledo untuk mengimport detail dari tagihan-tagihan returmu.',
  'financeInvoices.2_copy_your_invoices_return_into_the_template':
    '2. Salin detail tagihan retur ke dalam template',
  'financeInvoices.invoice_return_using_excel_or_another_spreadsheet_editor':
    'Menggunakan Excel atau software spreadsheeet lainnya, masukkan detail tagihan retur ke dalam file template dari Kledo. Pastikan detail tagihan retur sesuai dengan kolom header yang ada di template.',
  'financeInvoices.follow_these_steps_to_import_your_invoices_return':
    'Ikuti langkah berikut untuk mengimport tagihan returmu',

  'financeBanks.edit_bank_statement': 'Edit Bank Statement',
  'financeBanks.error_load_data': 'Terjadi masalah saat mengambil data transaksi bank',
  'financeBanks.are_you_sure_to_revert': 'Apakah Anda yakin untuk melanjutkan ?',
  'financeBanks.error_cannot_revert': 'Tidak dapat melakukan revert',
  'financeBanks.add_bank': 'Tambah Bank',
  'financeBanks.financeBanks': 'Akun Kas & Bank',
  'financeBanks.manage_account': 'Atur Akun',
  'financeBanks.statement_balance': 'Saldo di bank',
  'financeBanks.balance_in_kledo': 'Saldo di kledo',
  'financeBanks.successfully_added': 'berhasil ditambahkan.',
  'financeBanks.error_cannot_add': 'Tidak dapat menambah bank.',
  'financeBanks.error_cannot_update': 'Tidak dapat mengubah bank.',
  'financeBanks.error_cannot_move': 'Tidak dapat memindah bank.',
  'financeBanks.error_cannot_change_status': 'Tidak dapat mengubah status bank.',
  'financeBanks.edit_bank': 'Ubah bank',
  'financeBanks.max_characters': 'Maks {value} karakter.',
  'financeBanks.min_characters': 'Min {value} karakter.',
  'financeBanks.only_number_allowed': 'Hanya angka yang diperkenankan.',
  'financeBanks.description': 'Deskripsi',
  'financeBanks.tax': 'Pajak',
  'financeBanks.select_tax': 'Pilih pajak',
  'financeBanks.amount': 'Total',
  'financeBanks.please_select_a_contact': 'Silakan pilih kontak',
  'financeBanks.select_contact': 'Pilih kontak',
  'financeBanks.add_contact': 'Tambah kontak',
  'financeBanks.all_contacts': 'Semua kontak',
  'financeBanks.contact': 'Kontak',
  'financeBanks.transaction_date': 'Tanggal Transaksi',
  'financeBanks.please_select_transaction_date': 'Silakan pilih tanggal transaksi',
  'financeBanks.due_date': 'Tanggal Jatuh Tempo',
  'financeBanks.please_select_due_date': 'Silakan pilih tanggal jatuh tempo',
  'financeBanks.term': 'Termin',
  'financeBanks.select_term': 'Pilih termin',
  'financeBanks.add_term': 'Tambah termin',
  'financeBanks.price_include_tax': 'Harga termasuk pajak',
  'financeBanks.add_more': 'Tambah baris',
  'financeBanks.number': 'Nomor',
  'financeBanks.please_input_the_number': 'Silakan isikan nomor.',
  'financeBanks.memo': 'Reference',
  'financeBanks.date': 'Tanggal',
  'financeBanks.status': 'Status',
  'financeBanks.balance_due': 'Sisa Tagihan',
  'financeBanks.please_select_a_account': 'Silakan pilih akun.',
  'financeBanks.search_by_memo': 'Cari',
  'financeBanks.the_due_date_max_higger_than_transaction_date':
    'Tanggal jatuh tempo harus sama atau setelah tanggal transaksi',
  'financeBanks.detail_title': ' Transaksi: {transTypeName}',
  'financeBanks.customer': 'Pelanggan',
  'financeBanks.reference': 'Reference',
  'financeBanks.attachments': 'Attachment',
  'financeBanks.save_&_approve': 'Simpan & setujui',
  'financeBanks.save_as_draft': 'Simpan sbg draft',
  'financeBanks.save_&_submit_for_approval': 'Simpan & ajukan approval',
  'financeBanks.please_input_the_amount': 'Silakan masukkan total.',
  'financeBanks.approve': 'Setujui',
  'financeBanks.are_you_sure_to_approve': 'Apakah Anda yakin untuk menyutujui?',
  'financeBanks.submit_for_approval': 'Ajukan approval',
  'financeBanks.are_you_sure_to_submit_for_approval':
    'Apakah Anda yakin untuk mengajukan approval?',
  'financeBanks.create_bank': 'Buat Bank',
  'financeBanks.amount_paid': 'Total Dibayar',
  'financeBanks.paid_from': 'Dibayar Dari',
  'financeBanks.add_payment': 'Tambah Pembayaran',
  'financeBanks.make_a_payment': 'Buat pembayaran',
  'financeBanks.select_paid_from': 'Pilih dibayar dari',
  'financeBanks.please_select_a_paid_from': 'Silakan pilih dibayar dari.',
  'financeBanks.error_cannot_add_payment': 'Tidak bisa menambah pembayaran.',
  'financeBanks.payment': 'Pembayaran',
  'financeBanks.detail_bank': 'Detil  Bank',
  'financeBanks.spent': 'Kirim',
  'financeBanks.received': 'Terima',
  'financeBanks.balance': 'Saldo',
  'financeBanks.transfer_money': 'Transfer Dana',
  'financeBanks.spend_money': 'Kirim Dana',
  'financeBanks.receive_money': 'Terima Dana',
  'financeBanks.auto_import_bank_statement': 'Import Transaksi Bank Otomatis',
  'financeBanks.manually_import_bank_statement': 'Import Transaksi Bank Manual',
  'financeBanks.from': 'Dari',
  'financeBanks.to': 'Ke',
  'financeBanks.bank_account': 'Akun Bank',
  'financeBanks.account_transactions': 'Transaksi di Kledo',
  'financeBanks.bank_statements': 'Transaksi di Bank',
  'financeBanks.reconcile': 'Rekonsiliasi',
  'financeBanks.reconcile_mass_reconcile_title': 'Rekonsiliasi Massal',
  'financeBanks.reconcile_mass_can_be_reconcile': 'Bisa direkonsiliasi',
  'financeBanks.reconcile_mass_cannot_be_reconcile': 'Tidak bisa di rekonsiliasi',
  'financeBanks.reconcile_mass_reconcile_are_you_sure':
    'Apakah Anda yakin untuk merekonsiliasi {count} data transaksi ini?',
  'financeBanks.reconcile_data_progress_deleted':
    '{count} data dari total {total} telah terekonsilasi',
  'financeBanks.search_by_reference': 'Cari',
  'financeBanks.please_select_a_from_account': 'Silakan pilih akun asal',
  'financeBanks.please_select_a_to_account': 'Silakan pilih akun tujuan',
  'financeBanks.transfer': 'Transfer',
  'financeBanks.edit_account_transaction': 'Ubah Transaksi Akun',
  'financeBanks.error_cannot_transfer_money': 'Gagal transfer dana',
  'financeBanks.the_to_account_cannot_be_same_with_from_account':
    'Akun tujuan tidak boleh sama dengan akun asal.',
  'financeBanks.the_from_account_cannot_be_same_with_to_account':
    'Akun tujuan tidak boleh sama dengan akun asal.',
  'financeBanks.new_transaction': 'Transaksi Baru',
  'financeBanks.source': 'Sumber',
  'financeBanks.confirm_delete_bank_statement_p1':
    'Tindakan ini akan mengubah saldo rekening Anda di Kledo.',
  'financeBanks.confirm_delete_bank_statement_p2':
    'Pada umumnya tindakan ini hanya dilakukan untuk menghapus transaksi rekening duplikat.',
  'financeBanks.follow_these_steps_to_import_your_transactions':
    'Ikuti langkah-langkah berikut untuk meng-import transaksi',
  'financeBanks.import_bank_transactions': 'Import Transaksi Bank',
  'financeBanks.you_can_only_upload_csv_file': 'Anda hanya dapat mengunggah file csv atau excel',
  'financeBanks.upload_file': 'Upload',
  'financeBanks.set_import_options': 'Opsi Import',
  'financeBanks.import_statement_done': 'Selesai',
  'financeBanks.error_upload_file': 'Gagal mengunggah file.',
  'financeBanks.file_not_found': 'File tidak ditemukan.',
  'financeBanks.statement_heading': 'Header Statement',
  'financeBanks.statement_value': 'Isi Statement',
  'financeBanks.assign_to': 'Assign Ke',
  'financeBanks.please_select_a_assign_to': 'Silakan pilih assign ke.',
  'financeBanks.select_assign_to': 'Pilih assign ke',
  'financeBanks.rows_uploaded_set_the_import_options':
    '{rowCount} baris berhasil diunggah. Lakukan setting opsi import di bawah dan klik tombol Import Sekarang untuk melanjutkan.',
  'financeBanks.please_select_a_date_format': 'Silakan pilih format tanggal.',
  'financeBanks.select_date_format': 'Pilih format tanggal',
  'financeBanks.dont_import_the_first_line':
    'Jangan import baris pertama, karena baris pertama merupakan header.',
  'financeBanks.import_now': 'Import Sekarang',
  'financeBanks.date_format': 'Format Tanggal',
  'financeBanks.error_cannot_import': 'Gagal import.',
  'financeBanks.1_in_a_new_window':
    '1. Bukan tab browser baru, buka dan login ke internet banking Anda.',
  'financeBanks.2_download_your_bank_statement': '2. Download mutasi bank Anda.',
  'financeBanks.file_type_must_be_csv': 'File harus berupa CSV',
  'financeBanks.3_upload_the_bank_statement_here': '3. Unggah mutasi bank disini.',
  'financeBanks.download_our_csv_template': 'Download template import dalam csv',
  'financeBanks.to_create_your_own_bank_statement_file': ' untuk membuat mutasi bank sendiri.',
  'financeBanks.import_maximum_of_1000_bank_statement_line_at_a_time':
    'Import maksimal 1000 baris mutasi dalam satu waktu.',
  'financeBanks.drop_the_file_to_upload': 'Drag file di sini untuk upload',
  'financeBanks.transaction_lines_successfully_imported':
    '{rowCount} baris mutasi berhasil diimport.',
  'financeBanks.view_detail': 'Lihat detil',
  'financeBanks.import_another_file': 'Import file yang lain lagi',
  'financeBanks.go_to_reconciliation': 'Rekonsiliasi sekarang',
  'financeBanks.success': 'Sukses',
  'financeBanks.failed': 'Gagal',
  'financeBanks.invalid_date': 'Tanggal Tidak Valid',
  'financeBanks.invalid_transaction_type': 'Tipe Transaksi Tidak Valid',
  'financeBanks.invalid_amount': 'Total Transaksi Tidak Valid',
  'financeBanks.total': 'Total',
  'financeBanks.review_your_bank_statement': 'Review mutasi bank mu...',
  'financeBanks.then_match_with_your_transactions_in_kledo':
    '...kemudian cocokkan dengan transaksi di Kledo',
  'financeBanks.bank_statement': 'Transaksi di bank',
  'financeBanks.go': 'Go',
  'financeBanks.show_received_item': 'Lihat transaksi masuk',
  'financeBanks.find_&_match': 'Cari & Cocokkan',
  'financeBanks.kledo_transaction': 'Transaksi Kledo',
  'financeBanks.subtotal': 'Subtotal',
  'financeBanks.must_match': 'Harus Sesuai',
  'financeBanks.difference': 'Selisih',
  'financeBanks.find_&_select_matching_transactions': '1. Cari dan temukan transaksi yang sesuai',
  'financeBanks.error_cannot_reconcile': 'Gagal tidak dapat merekonsiliasi.',
  'financeBanks.are_you_sure_to_reconcile': 'Apakah Anda yakin akan merekonsiliasi transaksi ini?',
  'financeBanks.difference_must_be_zero': 'Selisih harus 0.',
  'financeBanks.view_your_selected_transactions':
    '2. Lihat transaksi yang sudah dipilih, tambah transaksi baru sesuai kebutuhan.',
  'financeBanks.the_sum_of_your_selected_transactions_must_match_the_money_spent':
    '3. Jumlah total transaksi yang dipilih harus sesuai dengan mutasi bank. Lakukan penyesuaian sesuai kebutuhan.',
  'financeBanks.show_spent_item': 'Lihat transaksi keluar',
  'financeBanks.to_reconcile': 'Sampai',
  'financeBanks.transactions': 'Transaksi',
  'financeBanks.minimal_value': 'Minimal {value}',
  'financeBanks.maximal_value': 'Maksimal {value}',
  'financeBanks.error_cannot_print_pdf': 'Tidak dapat membuat pdf bank.',
  'financeBanks.print_receipt': 'Cetak Kuitansi',
  'financeBanks.print_remittance_advice': 'Cetak Bukti Kas Keluar',
  'financeBanks.print_cash_receipt': 'Cetak Bukti Kas Masuk',
  'financeBanks.print_expense_slip': 'Cetak Slip Pengeluaran',
  'financeBanks.print_purchase_payment': 'Cetak Pemberitahuan Pembayaran',
  'financeBanks.print_transfer': 'Cetak Bukti Transfer',
  'financeBanks.copy_link_receipt': 'Salin Link Kuitansi',
  'financeBanks.copy_link_remittance_advice': 'Salin Link Bukti Kas Keluar',
  'financeBanks.copy_link_cash_receipt': 'Salin Link Bukti Kas Masuk',
  'financeBanks.copy_link_expense_slip': 'Salin Link Slip Pengeluaran',
  'financeBanks.copy_link_purchase_payment': 'Salin Link Pemberitahuan Pembayaran',
  'financeBanks.copy_link_transfer': 'Salin Link Bukti Transfer',
  'financeBanks.send_email_receipt': 'Kirim Email Kuitansi',
  'financeBanks.send_email_remittance_advice': 'Kirim Email Bukti Kas Keluar',
  'financeBanks.send_email_cash_receipt': 'Kirim Email Bukti Kas Masuk',
  'financeBanks.send_email_expense_slip': 'Kirim Email Slip Pengeluaran',
  'financeBanks.send_email_purchase_payment': 'Kirim Email Pemberitahuan Pembayaran',
  'financeBanks.send_sms_receipt': 'Kirim SMS Kuitansi',
  'financeBanks.send_sms_remittance_advice': 'Kirim SMS Bukti Kas Keluar',
  'financeBanks.send_sms_cash_receipt': 'Kirim SMS Bukti Kas Masuk',
  'financeBanks.send_sms_expense_slip': 'Kirim SMS Slip Pengeluaran',
  'financeBanks.send_sms_purchase_payment': 'Kirim SMS Pemberitahuan Pembayaran',
  'financeBanks.send_wa_receipt': 'Kirim Whatsapp Kuitansi',
  'financeBanks.send_wa_remittance_advice': 'Kirim Whatsapp Bukti Kas Keluar',
  'financeBanks.send_wa_cash_receipt': 'Kirim Whatsapp Bukti Kas Masuk',
  'financeBanks.send_wa_expense_slip': 'Kirim Whatsapp Slip Pengeluaran',
  'financeBanks.send_wa_purchase_payment': 'Kirim Whatsapp Pemberitahuan Pembayaran',
  'financeBanks.1_download_your_bankStatement_template_file':
    '1. Download file template mutasi bank',
  'financeBanks.start_by_downloading_our_bankStatement_csv':
    'Mulai dengan mengunduh file template Excel mutasi bank. File ini mempunyai kolom header yang yang dibutuhkan Kledo untuk mengimport detail dari mutasi bank-mu.',
  'financeBanks.2_copy_your_bankStatement_into_the_template':
    '2. Salin detail mutasi bank ke dalam template',
  'financeBanks.a_maximum_of_500_bankStatement_can_be_imported_at_a_time':
    'Maksimal 1.000 mutasi bank dapat diimport dalam satu waktu.',
  'financeBanks.import_actions_only_insert_new_bankStatement_cannot_update_or_delete':
    'Saat meng-import, Kledo hanya akan memasukkan data baru, tidak ada data yang dihapus atau diupdate.',
  'financeBanks.bankStatement_successfully_imported':
    '{rowCount} baris mutasi bank berhasil diimport.',
  'financeBanks.reconciliation_detail': 'Detil Rekonsiliasi',
  'financeBanks.detail': 'Detil',
  'financeBanks.mutasi_bank': 'Mutasi Bank',
  'financeBanks.transactions_on_kledo': 'Transaksi di Kledo',
  'financeBanks.bank_connect': 'Bank Connect',
  'financeBanks.add_bank_connect': 'Tambah Bank Connect',
  'financeBanks.edit_bank_connect': 'Ubah Bank Connect',
  'financeBanks.choose_bank': 'Pilih bank yang akan dihubungkan ke Kledo',
  'financeBanks.bank_connected': 'Bank yang sudah terhubung ke Kledo',
  'financeBanks.account': 'Akun',
  'financeBanks.fill_out_the_form': 'Isi Formulir',
  'financeBanks.under_review': 'Dalam Peninjauan',
  'financeBanks.signature_and_send': 'Tanda Tangan & Kirim',
  'financeBanks.activation': 'Aktivasi',
  'financeBanks.submission': 'Pengajuan',
  'financeBanks.review': 'Review',
  'financeBanks.active': 'Aktif',
  'financeBanks.cannot_add_bank_connect': 'Tidak dapat menambah bank connect',
  'financeBanks.cannot_update_sort_order': 'Tidak dapat menyimpan pengurutan',
  'financeBanks.cannot_delete_bank_connect': 'Tidak dapat menghapus bank connect',
  'financeBanks.please_select_account': 'Silakan pilih akun',
  'financeBanks.send_submission': 'Kirim Pengajuan',
  'financeBanks.connect_to_account': 'Hubungkan ke Akun',
  'financeBanks.connect_company_desc_1':
    'Untuk dapat terhubung dengan Rekening BCA Bisnis, diperlukan pengaktifan fitur BCA API di rekening BCA Anda.',
  'financeBanks.connect_company_desc_2': 'Berikut langkah-langkah untuk pengaktifan BCA API:',
  'financeBanks.connect_company_desc_3': '- Masukkan data-data Anda pada form di halaman ini.',
  'financeBanks.connect_company_desc_4':
    '- Tim Hebat Kledo akan menghubungi Anda melalui WhatsApp untuk konfirmasi dan pengumpulan data lainnya.',
  'financeBanks.connect_company_desc_5':
    '- Form pengajuan pengaktifan BCA API akan dikirim Tim Hebat Kledo ke Bank BCA.',
  'financeBanks.connect_company_desc_6':
    '- BCA API aktif, dan mutasi rekening Anda akan otomatis terdownload ke Kledo.',
  'financeBanks.connect_company_desc_7': 'Beberapa hal yang perlu Anda ketahui:',
  'financeBanks.connect_company_desc_8':
    '- Untuk koneksi BCA API dikenakan biaya dari pihak BCA nya sebesar Rp50.000,- per bulan. Dari pihak Kledo tidak mengenakan biaya sama sekali',
  'financeBanks.connect_company_desc_9':
    '- Proses pengajuan pengaktifan dibutuhkan waktu sekitar 2-4 minggu.',
  'financeBanks.connect_company_desc_10':
    '- Koneksi BCA API yang dihubungkan dengan Kledo hanya mengambil data mutasi saja, tidak untuk melakukan transfer dana. Sehingga rekening Anda terjamin keamanannya.',
  'financeBanks.account_information': 'Informasi Rekening',
  'financeBanks.bank_account_type': 'Tipe Rekening Bank',
  'financeBanks.internet_banking_username': 'Username Internet Banking',
  'financeBanks.internet_banking_password': 'Password Internet Banking',
  'financeBanks.repeat_internet_banking_password': 'Ulangi Password Internet Banking',
  'financeBanks.please_input_ib_username': 'Silakan masukkan username internet banking',
  'financeBanks.please_input_ib_password': 'Silakan masukkan password internet banking',
  'financeBanks.last_get_data': 'Terakhir Ambil Data',
  'financeBanks.account_owner_name': 'Nama Pemilik Rekening',
  'financeBanks.account_number': 'Nomor Rekening',
  'financeBanks.please_input_account_owner_name': 'Silakan masukkan nama pemilik rekening',
  'financeBanks.please_input_account_number': 'Silakan masukkan nomor rekening',
  'financeBanks.scan_bank_book': 'Scan/Foto Buku Tabungan',
  'financeBanks.please_input_scan_bank_book': 'Silakan masukkan scan/foto buku tabungan',
  'financeBanks.identity_card_information': 'Informasi KTP',
  'financeBanks.company_information': 'Informasi Perusahaan',
  'financeBanks.trade_name': 'Nama Dagang',
  'financeBanks.please_input_trade_name': 'Silakan masukkan Nama Dagang',
  'financeBanks.website': 'Website',
  'financeBanks.please_input_website': 'Silakan masukkan Website',
  'financeBanks.company_email': 'Email Perusahaan',
  'financeBanks.please_input_company_email': 'Silakan masukkan Email Perusahaan',
  'financeBanks.please_input_a_valid_format_email': 'Silakan masukkan format Email yang valid',
  'financeBanks.company_tax_number': 'Nomor NPWP Perusahaan',
  'financeBanks.please_input_company_tax_number': 'Silakan masukkan Nomor NPWP Perusahaan',
  'financeBanks.company_account_number': 'Nomor Rekening Perusahaan',
  'financeBanks.please_input_company_account_number': 'Silakan masukkan Nomor Rekening Perusahaan',
  'financeBanks.office_address': 'Alamat Kantor',
  'financeBanks.please_input_office_address': 'Silakan masukkan Alamat Kantor',
  'financeBanks.corp_id': 'ID Perusahaan',
  'financeBanks.please_input_corp_id': 'Silakan masukkan ID Perusahaan',
  'financeBanks.main_pic': 'PIC Utama',
  'financeBanks.secondary_pic': 'PIC Kedua',
  'financeBanks.position': 'Posisi',
  'financeBanks.please_input_position': 'Silakan masukkan Posisi',
  'financeBanks.id_number': 'NIK',
  'financeBanks.please_input_id_number': 'Silakan masukkan NIK',
  'financeBanks.please_input_email': 'Silakan masukkan Email',
  'financeBanks.phone_number': 'Nomor Telepon',
  'financeBanks.please_input_phone_number': 'Silakan masukkan Nomor Telepon',
  'financeBanks.address_according_to_id_card': 'Alamat sesuai KTP',
  'financeBanks.please_input_address': 'Silakan masukkan Alamat',
  'financeBanks.name_on_bank_account_owner_card': 'Nama Sesuai KTP Pemilik Rekening',
  'financeBanks.identity_card_number': 'Nomor Induk Kependudukan (NIK)',
  'financeBanks.scan_identity_card': 'Scan/Foto KTP',
  'financeBanks.please_input_name_on_bank_account_owner_card': 'Silakan masukkan nama',
  'financeBanks.please_input_identity_card_number': 'Silakan masukkan NIK',
  'financeBanks.please_input_scan_identity_card': 'Silakan masukkan scan/foto KTP',
  'financeBanks.note': 'Catatan',
  'financeBanks.follow_these_steps_to_import_your_bankTrans':
    'Ikuti langkah-langkah berikut untuk meng-import transaksi bank',
  'financeBanks.1_download_your_bankTrans_template_file':
    '1. Download file template transaksi bank',
  'financeBanks.start_by_downloading_our_bankTrans_csv':
    'Mulai dengan mengunduh file template dalam format Excel. File ini mempunyai kolom header yang yang dibutuhkan Kledo untuk mengimport detail dari transaksi bank-mu.',
  'financeBanks.download_template_file': 'Download File Template',
  'financeBanks.download_template_data_example_file': 'Download File Contoh',
  'financeBanks.import_bankTrans': 'Import Transaksi Bank',
  'financeBanks.2_copy_your_bankTrans_into_the_template':
    '2. Salin detail transaksi bank ke dalam template',
  'financeBanks.using_excel_or_another_spreadsheet_editor':
    'Menggunakan Excel atau software spreadsheeet lainnya, masukkan detail transaksi bank ke dalam file template dari Kledo. Pastikan detail transaksi bank sesuai dengan kolom header yang ada di template.',
  'financeBanks.important': 'PENTING',
  'financeBanks.3_upload_the_updated_template_file_here':
    '3. Upload template yang sudah diupdate ke sini',
  'financeBanks.upload': 'Upload',
  'financeBanks.save_your_updated_template_as_.csv_or_.txt_and_you_are_ready_to_go':
    'Simpan file template yang sudah diupdate',
  'financeBanks.a_maximum_of_500_bankTrans_can_be_imported_at_a_time':
    'Maksimal 1.000 transaksi bank dapat diimport dalam satu waktu.',
  'financeBanks.import_actions_only_insert_new_bankTrans_cannot_update_or_delete':
    'Saat meng-import, Kledo hanya akan memasukkan data baru, tidak ada data yang dihapus atau diupdate.',
  'financeBanks.dont_change_the_column_heading_in_the_template_file':
    'Jangan mengubah header kolom di dalam template. Ini dibutuhkan agar import dapat berjalan.',
  'financeBanks.bankTrans_successfully_imported':
    '{rowCount} baris transaksi bank berhasil diimport.',
  'financeBanks.go_to_bankTrans_list': 'Ke halaman daftar transaksi bank',
  'financeBanks.revert_reconciliation': 'Batalkan Rekonsiliasi',
  'financeBanks.view_reconciliation': 'Lihat Rekonsiliasi',
  'financeBanks.import_with_bank_template': 'Import dengan format template dari Bank',
  'financeBanks.login_bca_business': '1. Login ke internet banking BCA Bisnis Anda',
  'financeBanks.click_bank_info_mutation_bca': '2. Klik menu Informasi Rekening - Mutasi Rekening',
  'financeBanks.select_format_download_csv':
    '3. Pilih format download CSV, dan klik tombol download',
  'financeBanks.upload_downloaded_csv_on_form_below':
    '4. Unggah file CSV yang baru saja didownload pada form dibawah',
  'financeBanks.login_mandiri_business': '1. Login ke internet banking Mandiri Bisnis Anda',
  'financeBanks.click_bank_info_mutation_mandiri':
    '2. Klik menu Informasi Rekening - Mutasi Rekening',
  'financeBanks.select_excel_click_download': '3. Pilih jenis file Excel, dan klik tombol Unduh',
  'financeBanks.select_download_check_file_download':
    '4. Klik menu Unduh Laporan. Centang file yang akan di unduh, kemudian klik tombol Unduh',
  'financeBanks.upload_downloaded_excel_on_form_below':
    '5. Unggah file Excel yang baru saja didownload pada form dibawah',
  'financeBanks.the_one_of_accounts_must_be_base_currency': 'Salah satu akun harus mata uang dasar',
  'financeBanks.data_has_been_reconciled': 'Data berhasil di rekonsiliasi.',
  'financeBanks.empty_bank_reconcile_data_message':
    'Untuk merekonsiliasi transaksi bank, silahkan melakukan<br/>import transaksi bank terlebih dahulu.',
  'financeBanks.bank_reconcile_click_here_to_import': 'Klik disini untuk import transaksi bank!',
  'financeBanks.search_bank_statement_at_kledo':
    'Cari mutasi bank yang sesuai dengan transaksi di Kledo',
  'financeBanks.your_selected_transactions':
    'Transaksi yang sudah dipilih, tambah transaksi baru sesuai kebutuhan.',
  'financeBanks.total_transaction_on_kledo_and_bank_mutation_must_be_same':
    'Total transaksi di Kledo harus sama dengan total mutasi',
  'financeBanks.total_transaction_on_kledo': 'Total transaksi di Kledo',
  'financeBanks.total_mutations': 'Total mutasi',

  'purchases.purchases': 'Ringkasan Pembelian',
  'purchases.purchases_invoices': 'Tagihan Pembelian',
  'purchases.awaiting_payment': 'Menunggu Pembayaran',
  'purchases.overdue': 'Jatuh Tempo',
  'purchases.purchases_total_last_30_days': 'Total Pembelian 30 Hari Terakhir',
  'purchases.purchases_total': 'Total Pembelian',
  'purchases.product': 'Produk',
  'purchases.qty': 'Jml',
  'purchases.value': 'Nilai',
  'purchases.payment_made': 'Pembayaran',
  'purchases.purchases_orders': 'Pesanan Pembelian',
  'purchases.draft': 'Draft',
  'purchases.awaiting_approval': 'Menunggu Approval',
  'purchases.approved': 'Disetujui',

  'sales.sales': 'Ringkasan Penjualan',
  'sales.invoices': 'Tagihan',
  'sales.awaiting_payment': 'Menunggu Pembayaran',
  'sales.overdue': 'Jatuh Tempo',
  'sales.purchases_total_last_30_days': 'Penjualan 30 Hari Terakhir',
  'sales.product_sales_last_30_days': 'Penjualan Per Produk 30 Hari Terakhir',
  'sales.product_sales': 'Penjualan Per Produk',
  'sales.sales_contact': 'Penjualan Per Pelanggan',
  'sales.purchases_vendor': 'Pembelian Per Vendor',
  'sales.payments.received': 'Pembayaran Diterima',
  'sales.quotes': 'Penawaran',
  'sales.draft': 'Draft',
  'sales.sent': 'Terkirim',
  'sales.accepted': 'Disetujui',

  'financeNumbers.automatic_sequencing': 'Penomoran Otomatis',
  'financeNumbers.automatic_sequencing_billing': 'Penomoran {title}',
  'financeNumbers.define_the_number_to_be_used':
    'Tentukan nomor yang digunakan untuk membuat penomoran tagihan. Nomor dibawah akan otomatis ditambah setiap dokumen baru dibuat.',
  'financeNumbers.prefix': 'Awalan',
  'financeNumbers.suffix': 'Akhiran',
  'financeNumbers.sequence': 'Nomor saat ini',
  'financeNumbers.please_input_the_next_number': 'Silakan isi nomor saat ini',
  'financeNumbers.sequence_error': 'Nomor saat ini tidak berhasil dibuat.',
  'financeNumbers.error_edit': 'Tidak dapat edit nomor.',
  'financeNumbers.error_check_exist': 'Tidak dapat memeriksa nomor.',
  'financeNumbers.number_is_used': 'Nomor telah di pakai, {link} untuk mendapatkan nomor baru.',
  'financeNumbers.number_format': 'Format Penomoran',
  'financeNumbers.automatic_numbering_output_example': 'Contoh Output Penomoran Otomatis',
  'financeNumbers.reset_number_every': 'Reset Nomor Setiap',
  'financeNumbers.never_reset': 'Tidak pernah reset',
  'financeNumbers.every_month': 'Setiap bulan',
  'financeNumbers.every_year': 'Setiap tahun',
  'financeNumbers.on_date': 'Pada tanggal',
  'financeNumbers.add_numbering_code': 'Tambah Kode Penomoran',
  'financeNumbers.please_input_format': 'Silakan isi format nomor',
  'financeNumbers.please_input_date': 'Silakan isi tanggal',
  'financeNumbers.please_input_month': 'Silakan isi bulan',
  'financeNumbers.trans_number_same_as_original_trans':
    'Nomor transaksi disamakan dengan transaksi asal',
  'financeNumbers.trans_number_same_as_original_trans_desc':
    'Jika aktif, maka saat membuat tagihan dari pemesanan, nomornya akan disamakan dengan pemesanan. Begitu juga untuk transaksi lainnya.',
  'financeNumbers.auto_numbering_lock': 'Nomor transaksi dikunci supaya tidak dapat diubah',
  'financeNumbers.auto_numbering_lock_desc':
    'Jika aktif, maka nomor transaksi akan mengikuti sistem Kledo, tidak dapat diubah oleh pengguna.',
  'financeNumbers.default_settings': 'Pengaturan Default',
  'financeNumbers.add_setting': 'Tambah Pengaturan',
  'financeNumbers.setting_name': 'Nama Pengaturan',
  'financeNumbers.setting_name_is_required': 'Nama Pengaturan wajib diisi!',
  'financeNumbers.valid_for_bank_accounts': 'Berlaku untuk akun bank',
  'financeNumbers.bank_account_is_required': 'Akun bank wajib diisi!',
  'financeNumbers.select_bank_account': 'Pilih Akun Bank',
  'financeNumbers.last_reset_number_log':
    'Terakhir reset nomor pada {date}, [{clickHere}] untuk lihat log',
  'financeNumbers.reset_number_automatic': 'Reset Nomor Otomatis',

  'financeConversion.your_opening_balances_will_be_set_as_at':
    'Saldo awal akan di set per tanggal {date}',
  'financeConversion.conversion_date': 'Tanggal Konversi',
  'financeConversion.show_zero_balances': 'Tampilkan balance yang nol',
  'financeConversion.conversion_balances': 'Saldo Awal',
  'financeConversion.add_comparative_balances': 'Saldo Pembanding',
  'financeConversion.error_cannot_add_comparative_balances':
    'Tidak dapat menambah saldo pembanding',
  'financeConversion.error_cannot_save_comparative_balances':
    'Tidak dapat menyimpan saldo pembanding',
  'financeConversion.are_you_sure': 'Apakah Anda yakin?',
  'financeConversion.warning': 'Perhatian',
  'financeConversion.reversal_detail': 'Detail Reversal',
  'financeConversion.opening_balance': 'Opening Balance',
  'financeConversion.error_cannot_delete_comparative_balances': 'Gagal menghapus saldo awal',
  'financeConversion.confirm_balances': 'Konfirmasi saldo akun per {endDate}',
  'financeConversion.your_debit_and_credit_total_must_be_balanced':
    'Total debit dan kredi harus sama. Jika kamu tetap ingin menyimpannya, selisih sebesar {diff} akan disimpan sebagai Ekuitas saldo awal.',
  'financeConversion.enter_the_date_that_you_began_processing':
    'Masukkan tanggal Anda memulai memproses transaksi menggunakan Kledo. Akan lebih mudah jika Anda set tanggal konversi sama dengan awal dari periode pajak.',
  'financeConversion.error_cannot_save_conversion_date': 'Tidak dapat menyimpan tanggal konversi',
  'financeConversion.cannot_set_conversion_date':
    'Tidak dapat set tanggal konversi, karena telah dilakukan tutup buku pada bulan ini.',
  'financeConversion.back_to_account_list': 'Back to account list',
  'financeConversion.follow_these_steps_to_import_your_conversion_balance':
    'Ikuti langkah-langkah berikut untuk meng-import saldo awal',
  'financeConversion.1_download_your_conversion_balance_template_file':
    '1. Download file template saldo awal',
  'financeConversion.start_by_downloading_our_conversion_balance_csv':
    'Mulai dengan mengunduh file template Excel saldo awal. File ini mempunyai kolom header yang yang dibutuhkan Kledo untuk mengimport detail dari saldo awal-mu.',
  'financeConversion.download_template_file': 'Download File Template',
  'financeConversion.download_template_data_example_file': 'Download File Contoh',
  'financeConversion.import': 'Import Saldo Awal',
  'financeConversion.2_copy_your_conversion_balance_into_the_template':
    '2. Salin detail saldo awal ke dalam template',
  'financeConversion.using_excel_or_another_spreadsheet_editor':
    'Menggunakan Excel atau software spreadsheeet lainnya, masukkan detail saldo awal ke dalam file template dari Kledo. Pastikan detail saldo awal sesuai dengan kolom header yang ada di template.',
  'financeConversion.important': 'PENTING',
  'financeConversion.3_upload_the_updated_template_file_here':
    '3. Upload template yang sudah diupdate ke sini',
  'financeConversion.upload': 'Upload',
  'financeConversion.save_your_updated_template_as_.csv_or_.txt_and_you_are_ready_to_go':
    'Simpan file template yang sudah diupdate',
  'financeConversion.a_maximum_of_500_conversion_balance_can_be_imported_at_a_time':
    'Maksimal 1.000 saldo awal dapat diimport dalam satu waktu.',
  'financeConversion.import_actions_only_insert_new_conversion_balance_cannot_update_or_delete':
    'Saat meng-import, Kledo hanya akan memasukkan data baru, tidak ada data yang dihapus atau diupdate.',
  'financeConversion.dont_change_the_column_heading_in_the_template_file':
    'Jangan mengubah header kolom di dalam template. Ini dibutuhkan agar import dapat berjalan.',
  'financeConversion.conversion_balance_successfully_imported':
    '{rowCount} baris saldo awal berhasil diimport.',
  'financeConversion.go_to_conversion_balance_list': 'Ke halaman daftar saldo awal',

  'financeManualJournal.add_manual_journal': 'Tambah Jurnal Umum',
  'financeManualJournal.financeManualJournal': 'Jurnal Umum',
  'financeManualJournal.successfully_added': 'berhasil ditambahkan',
  'financeManualJournal.error_cannot_add': 'Tidak dapat menambah jurnal umum',
  'financeManualJournal.error_cannot_update': 'Jurnal umum gagal diperbarui',
  'financeManualJournal.edit_manual_journal': 'Ubah Jurnal Umum',
  'financeManualJournal.max_characters': 'Maks. {value} karakter',
  'financeManualJournal.min_characters': 'Min. {value} karakter',
  'financeManualJournal.only_number_allowed': 'Hanya angka yang diperbolehkan',
  'financeManualJournal.description': 'Deskripsi',
  'financeManualJournal.tax': 'Pajak',
  'financeManualJournal.select_tax': 'Pilih pajak',
  'financeManualJournal.amount': 'Jumlah',
  'financeManualJournal.transaction_date': 'Tanggal Transaksi',
  'financeManualJournal.please_select_transaction_date': 'Silakan pilih tanggal transaksi',
  'financeManualJournal.due_date': 'Jatuh tempo',
  'financeManualJournal.please_select_due_date': 'Silakan isi tanggal jatuh tempo',
  'financeManualJournal.price_include_tax': 'Harga termasuk pajak',
  'financeManualJournal.add_more': 'Tambah baris',
  'financeManualJournal.number': 'Nomor',
  'financeManualJournal.please_input_the_number': 'Silakan masukkan nomor.',
  'financeManualJournal.memo': 'Reference',
  'financeManualJournal.date': 'Tanggal',
  'financeManualJournal.detail_title': 'Jurnal Umum {number}',
  'financeManualJournal.reference': 'Reference',
  'financeManualJournal.attachments': 'Attachment',
  'financeManualJournal.please_input_the_amount': 'Silakan masukkan jumlah',
  'financeManualJournal.follow_these_steps_to_import_your_manualJournal':
    'Ikuti langkah-langkah berikut untuk meng-import jurnal umum',
  'financeManualJournal.1_download_your_manualJournal_template_file':
    '1. Download file template jurnal umum',
  'financeManualJournal.start_by_downloading_our_manualJournal_csv':
    'Mulai dengan mengunduh file template Excel jurnal umum. File ini mempunyai kolom header yang yang dibutuhkan Kledo untuk mengimport detail dari jurnal umum-mu.',
  'financeManualJournal.download_template_file': 'Download File Template',
  'financeManualJournal.download_template_data_example_file': 'Download File Contoh',
  'financeManualJournal.import': 'Import Jurnal Umum',
  'financeManualJournal.2_copy_your_manualJournal_into_the_template':
    '2. Salin detail jurnal umum ke dalam template',
  'financeManualJournal.using_excel_or_another_spreadsheet_editor':
    'Menggunakan Excel atau software spreadsheeet lainnya, masukkan detail jurnal umum ke dalam file template dari Kledo. Pastikan detail jurnal umum sesuai dengan kolom header yang ada di template.',
  'financeManualJournal.important': 'PENTING',
  'financeManualJournal.3_upload_the_updated_template_file_here':
    '3. Upload template yang sudah diupdate ke sini',
  'financeManualJournal.upload': 'Upload',
  'financeManualJournal.save_your_updated_template_as_.csv_or_.txt_and_you_are_ready_to_go':
    'Simpan file template yang sudah diupdate',
  'financeManualJournal.a_maximum_of_500_manualJournal_can_be_imported_at_a_time':
    'Maksimal 1.000 jurnal umum dapat diimport dalam satu waktu.',
  'financeManualJournal.import_actions_only_insert_new_manualJournal_cannot_update_or_delete':
    'Saat meng-import, Kledo hanya akan memasukkan data baru, tidak ada data yang dihapus atau diupdate.',
  'financeManualJournal.dont_change_the_column_heading_in_the_template_file':
    'Jangan mengubah header kolom di dalam template. Ini dibutuhkan agar import dapat berjalan.',
  'financeManualJournal.manualJournal_successfully_imported':
    '{rowCount} baris jurnal umum berhasil diimport.',
  'financeManualJournal.go_to_manualJournal_list': 'Ke halaman daftar jurnal umum',

  'financeManualJournal.status': 'Status',
  'financeManualJournal.balance_due': 'Sisa Tagihan',
  'financeManualJournal.total': 'Total',
  'financeManualJournal.search_by_memo': 'Cari',
  'financeManualJournal.the_due_date_max_higger_than_transaction_date':
    'Tanggal kadaluarsa harus sama atau setelah tanggal transaksi',
  'financeManualJournal.customer': 'Pelanggan',
  'financeManualJournal.detail_manual_journal': 'Detil Jurnal Umum',
  'financeManualJournal.account': 'Akun',
  'financeManualJournal.debit': 'Debit',
  'financeManualJournal.credit': 'Kredit',
  'financeManualJournal.select_account': 'Pilih akun',
  'financeManualJournal.diff': 'Kredit dan debit terdapat selisih sebesar {diff}',
  'financeManualJournal.total_debits_must_equal_total_credits':
    'Total pemasukan harus sama dengan total pengeluaran.',
  'financeManualJournal.debit_or_credit_must_greater_than_0':
    'Debit atau credit harus lebih besar dari 0.',
  'financeManualJournal.total_debit': 'Total Debit',
  'financeManualJournal.total_credit': 'Total Kredit',

  'financeFixedAssets.register': 'Daftarkan',
  'financeFixedAssets.fixed_assets': 'Aset Tetap',
  'financeFixedAssets.add_fixed_asset': 'Tambah Aset Tetap',
  'financeFixedAssets.asset_name': 'Nama Aset',
  'financeFixedAssets.purchase_invoice': 'Tagihan Pembelian',
  'financeFixedAssets.purchase_date': 'Tanggal Pembelian',
  'financeFixedAssets.purchase_price': 'Harga Beli',
  'financeFixedAssets.book_value': 'Nilai Buku',
  'financeFixedAssets.init_cost': 'Biaya Awal',
  'financeFixedAssets.disposal_date': 'Tanggal Pelepasan',
  'financeFixedAssets.disposal_price': 'Harga Jual',
  'financeFixedAssets.gain': 'Untung/Rugi',
  'financeFixedAssets.number': 'Nomor',
  'financeFixedAssets.purchase_price_from': 'Dari',
  'financeFixedAssets.purchase_price_to': 'Sampai',
  'financeFixedAssets.details': 'Detil',
  'financeFixedAssets.please_input_the_asset_name': 'Silakan isikan nama aset',
  'financeFixedAssets.max_characters': 'Maks {value} karakter.',
  'financeFixedAssets.min_characters': 'Min {value} karakter.',
  'financeFixedAssets.please_select_purchase_date': 'Silakan masukkan tanggal pembelian',
  'financeFixedAssets.please_input_the_purchase_price': 'Silakan masukkan harga pembelian',
  'financeFixedAssets.fixed_asset_account': 'Akun Aset Tetap',
  'financeFixedAssets.please_select_a_fixed_asset_account': 'Silakan pilih akun aset tetap',
  'financeFixedAssets.select_fixed_asset_account': 'Pilih akun aset tetap',
  'financeFixedAssets.credited_from_account': 'Dikreditkan Dari Akun',
  'financeFixedAssets.please_select_a_credit_from_account': 'Silakan pilih dikreditkan dari akun',
  'financeFixedAssets.select_credit_from_account': 'Silakan pilih dikreditkan dari akun',
  'financeFixedAssets.description': 'Deskripsi',
  'financeFixedAssets.must_greater_than_0': 'Harus lebih besar dari 0',
  'financeFixedAssets.depreciation': 'Penyusutan',
  'financeFixedAssets.no_depreciation': 'Tanpa penyusutan',
  'financeFixedAssets.accumulated_depreciation_account': 'Akun Akumulasi Penyusutan',
  'financeFixedAssets.select_accumulated_depreciation_account':
    'Silakan pilih akun akumulasi penyusutan',
  'financeFixedAssets.depreciation_expense_account': 'Akun penyusutan',
  'financeFixedAssets.select_depreciation_expense_account': 'Silakan pilih akubn penyusutan',
  'financeFixedAssets.depreciation_method': 'Metode Penyusutan',
  'financeFixedAssets.select_depreciation_method': 'Silakan pilih metode penyusutan',
  'financeFixedAssets.rate': 'Nilai penyusutan per tahun',
  'financeFixedAssets.effective_life': 'Masa Manfaat (tahun)',
  'financeFixedAssets.show_more_options': 'Tampilkan opsi lainnya',
  'financeFixedAssets.depreciation_start_date': 'Tanggal Mulai Penyusutan',
  'financeFixedAssets.accumulated_depreciation': 'Akumulasi Penyusutan',
  'financeFixedAssets.cost_limit': 'Batas Biaya',
  'financeFixedAssets.residual_value': 'Nilai Residu',
  'financeFixedAssets.hide_more_options': 'Sembunyikan opsi lainnya',
  'financeFixedAssets.please_select_a_accumulated_depreciation_account':
    'Silakan pilih akun akumulasi penyusutan',
  'financeFixedAssets.please_select_a_depreciation_expense_account':
    'Silakan pilih akun penyusutan',
  'financeFixedAssets.please_select_a_depreciation_method': 'Silakan pilih metode penyusutan',
  'financeFixedAssets.must_equal_or_smaller_than_cost_limit_minus_residual_value':
    'Harus sama atau lebih kecil dari batas biaya dikurang nilai residu',
  'financeFixedAssets.must_equal_or_smaller_than_purchase_price':
    'Harus sama atau lebih kecil dari harga pembelian',
  'financeFixedAssets.must_equal_or_smaller_than_cost_limit':
    'Harus sama atau lebih kecil dari batas biaya',
  'financeFixedAssets.transactions': 'Transaksi',
  'financeFixedAssets.detail_fixed_asset': 'Detil Aset Tetap',
  'financeFixedAssets.detail_title': 'Aset Tetap {detail}',
  'financeFixedAssets.date': 'Tanggal',
  'financeFixedAssets.reference': 'Reference',
  'financeFixedAssets.debit': 'Debit',
  'financeFixedAssets.credit': 'Kredit',
  'financeFixedAssets.undo_dispose': 'Kembalikan Pelepasan',
  'financeFixedAssets.dispose': 'Lepas/Jual',
  'financeFixedAssets.rollback_depreciation': 'Kembalikan Penyusutan',
  'financeFixedAssets.error_cannot_add': 'Gagal menambah aset tetap.',
  'financeFixedAssets.error_cannot_update': 'Gagal mengubah aset tetap.',
  'financeFixedAssets.edit_title': 'Ubah Aset Tetap {detail}',
  'financeFixedAssets.dispose_fixed_asset': 'Lepas/Jual Aset Tetap',
  'financeFixedAssets.dispose_title': 'Lepas/Jual {title}',
  'financeFixedAssets.last_depreciation': 'Penyusutan Terakhir',
  'financeFixedAssets.sale_price': 'Harga Penjualan/Pelepasan',
  'financeFixedAssets.journal_entries': 'Entri Journal',
  'financeFixedAssets.name': 'Nama',
  'financeFixedAssets.account': 'Akun',
  'financeFixedAssets.acquisition_const': 'Biaya Akuisisi',
  'financeFixedAssets.gain_on_disposal': 'Untung pada pelepasan/penjualan',
  'financeFixedAssets.loss_on_disposal': 'Kerugian pada pelepasan/penjualan',
  'financeFixedAssets.please_select_disposal_date': 'Silakan pilih tanggal pelepasan',
  'financeFixedAssets.please_input_the_sale_price': 'Silakan isikan harga penjualan/pelepasan',
  'financeFixedAssets.deposit_to': 'Didepositkan ke',
  'financeFixedAssets.deposit_to_account': 'Deposit ke akun',
  'financeFixedAssets.please_select_a_deposit_to_account': 'Silakan pilih deposit ke akun.',
  'financeFixedAssets.must_equal_or_higher_than_purchase_date_and_last_depreciation_date':
    'Harus sama atau setelah tanggal pemnbelian dan tanggal penyusutan terakhir',
  'financeFixedAssets.must_equal_or_higher_than_purchase_date':
    'Harus sama atau setelah tanggal pemnbelian',
  'financeFixedAssets.register_fixed_asset': 'Daftarkan Aset Tetap',
  'financeFixedAssets.run_depreciation': 'Jalankan Penyusutan',
  'financeFixedAssets.period': 'Periode',
  'financeFixedAssets.method': 'Metode',
  'financeFixedAssets.depreciation_amount': 'Jumlah Penyusutan',
  'financeFixedAssets.error_cannot_run_depreciation': 'Gagal menjalankan penyusutan.',
  'financeFixedAssets.please_select_at_least_a_depreciation':
    'Silakan pilih paling tidak satu penyusutan.',
  'financeFixedAssets.depreciation_executed_successfully': 'Penyusutan berhasil dijalankan.',
  'financeFixedAssets.back_to_asset_list': 'Kembali ke daftar aset tetap',
  'financeFixedAssets.apply_depreciation': 'Jalankan Penyusutan',
  'financeFixedAssets.are_u_sure_to_undo_dispose':
    'Apakah kamu yakin akan membatalkan penjualan/pelepasan?',
  'financeFixedAssets.are_u_sure_to_rollback_depreciation':
    'Apakah kamu yakin akan membatalkan/mengembalikan penyusutan?',
  'financeFixedAssets.please_input_the_number': 'Silakan isikan nomor.',
  'financeFixedAssets.select_deposit_to_account': 'Pilih akun pengeluaran',
  'financeFixedAssets.edit_fixed_asset': 'Ubah Aset Tetap',
  'financeFixedAssets.error_cannot_rollback_depreciation':
    'Error tidak dapat mengembalikan penyusutan.',
  'financeFixedAssets.detail_fixed_asset_transaction': 'Detil Transaksi Aset Tetap',
  'financeFixedAssets.dispose_detail': 'Pelepasan/Penjualan',
  'financeFixedAssets.follow_these_steps_to_import_your_fixedAsset':
    'Ikuti langkah-langkah berikut untuk meng-import aset tetap',
  'financeFixedAssets.1_download_your_fixedAsset_template_file':
    '1. Download file template aset tetap',
  'financeFixedAssets.start_by_downloading_our_fixedAsset_csv':
    'Mulai dengan mengunduh file template Excel aset tetap. File ini mempunyai kolom header yang yang dibutuhkan Kledo untuk mengimport detail dari aset tetap-mu.',
  'financeFixedAssets.download_template_file': 'Download File Template',
  'financeFixedAssets.download_template_data_example_file': 'Download File Contoh',
  'financeFixedAssets.import': 'Import Aset Tetap',
  'financeFixedAssets.2_copy_your_fixedAsset_into_the_template':
    '2. Salin detail aset tetap ke dalam template',
  'financeFixedAssets.using_excel_or_another_spreadsheet_editor':
    'Menggunakan Excel atau software spreadsheeet lainnya, masukkan detail aset tetap ke dalam file template dari Kledo. Pastikan detail aset tetap sesuai dengan kolom header yang ada di template.',
  'financeFixedAssets.important': 'PENTING',
  'financeFixedAssets.3_upload_the_updated_template_file_here':
    '3. Upload template yang sudah diupdate ke sini',
  'financeFixedAssets.upload': 'Upload',
  'financeFixedAssets.save_your_updated_template_as_.csv_or_.txt_and_you_are_ready_to_go':
    'Simpan file template yang sudah diupdate',
  'financeFixedAssets.a_maximum_of_500_fixedAsset_can_be_imported_at_a_time':
    'Maksimal 1.000 aset tetap dapat diimport dalam satu waktu.',
  'financeFixedAssets.import_actions_only_insert_new_fixedAsset_cannot_update_or_delete':
    'Saat meng-import, Kledo hanya akan memasukkan data baru, tidak ada data yang dihapus atau diupdate.',
  'financeFixedAssets.dont_change_the_column_heading_in_the_template_file':
    'Jangan mengubah header kolom di dalam template. Ini dibutuhkan agar import dapat berjalan.',
  'financeFixedAssets.fixedAsset_successfully_imported':
    '{rowCount} baris aset tetap berhasil diimport.',
  'financeFixedAssets.go_to_fixedAsset_list': 'Ke halaman daftar aset tetap',
  'financeFixedAssets.the_date_this_asset_was_purchased_or_acquired':
    'Tanggal aset ini dibeli atau diperoleh.',
  'financeFixedAssets.the_total_cost_of_acquiring_this_asset':
    'Total biaya untuk memperoleh aset ini, tanpa pajak.',
  'financeFixedAssets.account_to_hold_the_balance_of_this_fixed_asset':
    'Akun untuk menampung saldo aset tetap ini.',
  'financeFixedAssets.account_for_crediting_the_purchase_of_fixed_asset':
    'Akun untuk mengkreditkan pembelian aset tetap. Contohnya Kas, Pinjaman atau Modal.',
  'financeFixedAssets.an_expense_account_to_record_monthly_depreciation':
    ' Akun biaya untuk merekam penyusutan setiap bulannya.',
  'financeFixedAssets.accounts_for_contra_assets_to_accommodate_accumulated_depreciation':
    'Akun untuk kontra aset untuk menampung akumulasi penyusutan.',
  'financeFixedAssets.percent_depreciation_value_each_year':
    'Nilai persen penyusutan setiap tahunnya.',
  'financeFixedAssets.the_longevity_of_the_asset_since_purchase_in_years':
    'Panjang usia aset sejak dibeli dalam tahun.',
  'financeFixedAssets.method_of_calculating_assets_each_months':
    'Metode perhitungan aset tiap bulannya{br}Straight Line = Penyusutan secara rata- rata sepanjang masa manfaat aset.{br}Sebagai contoh jika harga beli aset 1000 dan nilai penyusutan 20%, maka depresiasi per tahunnya adalah 1000 * 20% = 200{br}Declining Balance 100% = Penyusutan dengan tingkat penurunan lebih besar diawal usia aset.{br}Sebagai contoh jika harga beli aset 1000 dan nilai penyusutan 20%, maka{br}Penyusutan Tahun Pertama = 1000 x 20% = 200{br}Penyusutan Tahun Kedua = (1000 - 200) x 20% = 160<br/>Penyusutan Tahun Ketiga = (800 - 160) x 20% = 128{br}Declining Balance 150% menghitung penuyutan dengan nilai penyusutan dikali 150%{br}Declining Balance 200% menghitung penuyutan dengan nilai penyusutan dikali 200%',
  'financeFixedAssets.the_commencement_date_of_the_accumulated_depreciation_account_opening_balance':
    'Tanggal dimulainya saldo awal akun akumulasi penyusutan. Bisa diisi sama dengan tanggal pembelian aset, atau lebih baik menggunakan tanggal awal periode atau tanggal migrasi.',
  'financeFixedAssets.beginning_balance_for_accumulated_depreciation_accounts':
    'Saldo awal untuk akun akumulasi penyusutan dari pembukuan atau pencatatan yang lalu.',
  'financeFixedAssets.the_upper_limit_on_the_value_of_the_depreciated_assets':
    'Batas atas nilai aset yang disusutkan. Misalnya aset dibeli dengan harga Rp1000, kemudian diset batas biaya Rp600, maka hanya Rp600 saja yang disusutkan.',
  'financeFixedAssets.the_residual_value_is_the_minimum_amount_of_assets':
    'Nilai residu adalah batas minimal nilai aset yang sudah tidak dapat disusutkan kembali. Misalnya aset dibeli dengan harga Rp1000, kemudian nilai residu diset Rp200. Maka saat aset tersebut disusutkan tiap bulannya, dan nilai aset akhirnya tinggal Rp200, maka aset tersebut sudah tidak disusutkan kembali.',
  'financeFixedAssets.image': 'Gambar Aset Tetap',
  'financeFixedAssets.show_image': 'Tampilkan gambar aset tetap',
  'financeFixedAssets.hide_image': 'Sembunyikan gambar aset tetap',

  'financeLockDate.lock_date': 'Tanggal Penguncian',
  'financeLockDate.lock_date_will_stop_data_from_being_changed':
    'Dengan tanggal penguncian maka semua transaksi pada dan sebelum tanggal penguncian tidak dapat diubah, dihapus dan ditambah. Kamu bisa mengubah tanggal penguncian setiap saat.',
  'financeLockDate.stop_all_users_making_changes_on_and_before':
    'Cegah semua pengguna untuk membuat perubahan pada tanggal dan sebelum tanggal',
  'financeLockDate.please_select_a_date': 'Silakan pilih tanggal.',
  'financeLockDate.error_cannot_set_lock_date': 'Gagal mengubah tanggal penguncian',
  'financeLockDate.cannot_higher_than_now':
    'Tanggal penguncian harus sebelum atau sama dengan hari ini',
  'financeLockDate.cannot_select_before_lock_date':
    'Tidak dapat memilih tanggal sebelum tanggal penguncian',
  'financeLockDate.this_transaction_is_locked':
    'Transaksi ini dikunci. Silakan buka kunci dengan mengubah tanggal penguncian',
  'financeLockDate.click_button_below_to_remove_the_lock_date':
    'Klik tombol di bawah untuk menghapus tanggal penguncian',
  'financeLockDate.remove_lock_date': 'Hapus Tanggal Penguncian',
  'financeFixedAssets.error_cannot_delete': 'Gagal menghapus tanggal penguncian.',

  'property.property': 'Properti',
  'property.company': 'Perusahaan',
  'property.property_details': 'Detil Properti',
  'property.company_details': 'Data Perusahaan',
  'property.tax_details': 'Pengaturan Pajak',
  'property.system_settings': 'Pengaturan Sistem',
  'property.property_logo': 'Logo Properti',
  'property.company_logo': 'Logo Perusahaan',
  'property.error_cannot_update_tax_details': 'tipe Gagal',
  'property.property_details_successfully_updated': 'Detil properti berhasil diperbarui.',
  'property.company_details_successfully_updated': 'tipe Perusahaan',
  'property.error_cannot_update_property_details': 'Gagal memperbarui detail properti.',
  'property.error_cannot_update_company_details': 'Tidak dapat mengubah company details.',
  'property.system_details_successfully_updated': 'Detil sistem berhasil diperbarui.',
  'property.error_cannot_update_system_details': 'tipe Gagal',
  'property.tax_details_successfully_updated': 'Rincian pajak berhasil diperbarui.',
  'property.property_name': 'Nama',
  'property.company_name': 'Nama',
  'property.please_input_the_property_name': 'Silakan masukkan nama properti.',
  'property.please_input_the_company_name': 'Silakan masukkan company name.',
  'property.max_100_characters': 'Maks 100 karakter.',
  'property.emai_address': 'Alamat email',
  'property.invalid_email_address_format': 'Format alamat email tidak valid.',
  'property.phone_number': 'Nomor telepon',
  'property.max_20_characters': 'Nomor telepon',
  'property.only_number_allowed': 'Hanya angka yang diizinkan.',
  'property.address': 'Alamat Penagihan',
  'property.address_delivery': 'Alamat Pengiriman',
  'property.country': 'Negara',
  'property.tax_number': 'Nomor NPWP Perusahaan',
  'property.please_select_the_country': 'Silakan pilih negara.',
  'property.select_country': 'Pilih negara',
  'property.arrival_time': 'Jam kedatangan',
  'property.departure_time': 'Jam keberangkatan',
  'property.image_file_size_maximal': 'Ukuran file gambar maksimal',
  'property.language': 'Bahasa',
  'property.select_time_region': 'Pilih zona waktu',
  'property.timezone': 'Zona waktu',
  'property.please_select_the_language': 'Silakan pilih bahasanya.',
  'property.select_timezone': 'Pilih zona waktu',
  'property.please_select-the_timezone': 'Silakan pilih zona waktu.',
  'property.date_format': 'Format tanggal',
  'property.please_select_the_date_format': 'Silakan pilih format tanggal.',
  'property.select_date_format': 'Pilih format tanggal',
  'property.time_format': 'Format waktu',
  'property.please_select_the_time_format': 'Silakan pilih format waktu.',
  'property.select_time_format': 'Pilih format waktu',
  'property.Currency_Format': 'Format mata uang',
  'property.please_select_the_currency_format': 'Silakan pilih format mata uang.',
  'property.select_currency_format': 'Pilih format mata uang ',
  'property.country_tax_identifier_name': 'Nama Pengenal Pajak Negara',
  'property.only_alphanumeric_allowed': 'Hanya alfanumerik yang diizinkan.',
  'property.company_tax_number': 'Nomor Pajak Perusahaan',
  'property.tax_name': 'Nama Pajak',
  'property.tax_rate': 'Persentase pajak',
  'property.image_file_dimension_maximal': 'Dimensi file gambar maksimal',
  'property.click_or_drag_file_to_this_area_to_upload':
    'Klik atau seret file ke area ini untuk mengunggah',
  'property.preview': 'Pratinjau',
  'property.file_uploaded_successfully': 'berkas berhasil diunggah.',
  'property.file_upload_failed': 'gagal unggah file.',
  'property.you_can_only_upload_jpg_or_png_file_': 'Anda hanya dapat mengunggah file JPG / PNG.',
  'property.image_must_smaller_than': 'Gambar harus lebih kecil dari',
  'property.image_width_must_smaller_than': 'Lebar gambar harus lebih kecil dari',
  'property.image_height_must_smaller_than': 'Tinggi gambar harus lebih kecil dari ',
  'property.currency': 'Mata uang',
  'property.please_select_the_currency': 'Silakan pilih mata uang.',
  'property.select_currency': 'Pilih Mata Dana',
  'property.number_after_comma': 'Jumlah angka di belakang koma',
  'property.please_select_the_number_after_comma': 'Silakan pilih jumlah angka di belakang koma.',
  'property.select_number_after_comma': 'Pilih jumlah angka di belakang koma',

  'financeWareHouses.add_warehouse': 'Tambah Gudang',
  'financeWareHouses.financeWareHouses': 'Gudang',
  'financeWareHouses.warehouse_name': 'Nama Gudang',
  'financeWareHouses.successfully_added': 'berhasil ditambahkan.',
  'financeWareHouses.error_cannot_add': 'Tidak dapat menambah',
  'financeWareHouses.error_cannot_update': 'Tidak dapat mengubah gudang.',
  'financeWareHouses.error_cannot_move': 'Tidak dapat memindah gudang.',
  'financeWareHouses.error_cannot_change_status': 'Tidak dapat mengubah status gudang.',
  'financeWareHouses.error_cannot_load_warehouses': 'Tidak dapat memuat data gudang.',
  'financeWareHouses.edit_warehouse': 'Ubah Gudang',
  'financeWareHouses.please_input_the_name': 'Silakan masukkan nama.',
  'financeWareHouses.please_input_the_code': 'Silakan masukkan kode.',
  'financeWareHouses.name': 'Nama',
  'financeWareHouses.code': 'Kode',
  'financeWareHouses.max_characters': 'Maks {value} karakter.',
  'financeWareHouses.min_characters': 'Min {value} karakter.',
  'financeWareHouses.only_alphanumeric_allowed': 'Hanya huruf dan angka yang diperkenankan.',
  'financeWareHouses.desc': 'Deskripsi',
  'financeWareHouses.total_value': 'Total Nilai',
  'financeWareHouses.stock_adjustment': 'Penyesuaian Stok',
  'financeWareHouses.warehouse_transfer': 'Transfer Gudang',
  'financeWareHouses.import_warehouse_transfer': 'Import Transfer Gudang',
  'financeWareHouses.transfer_in': 'Transfer Masuk',
  'financeWareHouses.transfer_out': 'Transfer Keluar',
  'financeWareHouses.qty': 'Qty',
  'financeWareHouses.value': 'Nilai',
  'financeWareHouses.detail_warehouse': 'Detail Gudang',
  'financeWareHouses.show_transactions': 'Lihat Transaksi',
  'financeWareHouses.hide_transactions': 'Sembunyikan Transaksi',
  'financeWareHouses.purchase_&_sale': 'Pembelian & Penjualan',
  'financeWareHouses.transactions': 'Transaksi',
  'financeWareHouses.date': 'Tanggal',
  'financeWareHouses.type': 'Tipe',
  'financeWareHouses.number': 'Nomor',
  'financeWareHouses.debit': 'Debit',
  'financeWareHouses.credit': 'Kredit',
  'financeWareHouses.from': 'Dari',
  'financeWareHouses.to': 'Ke',
  'financeWareHouses.adjustment_type': 'Tipe penyesuaian stok',
  'financeWareHouses.please_select_date': 'Silakan pilih tanggal',
  'financeWareHouses.warehouse_transfer_out': 'Transfer Keluar Gudang',
  'financeWareHouses.warehouse_transfer_in': 'Transfer Masuk Gudang',
  'financeWareHouses.qty_before': 'Qty Sebelumnya',
  'financeWareHouses.qty_after': 'Qty Setelahnya',
  'financeWareHouses.transfer_qty': 'Transfer Qty',
  'financeWareHouses.add_more': 'Tambah baris',
  'financeWareHouses.attachments': 'Attachments',
  'financeWareHouses.select_warehouse': 'Pilih gudang',
  'financeWareHouses.please_input_the_transfer_qty': 'Silakan masukkan jumlah yang ditransfer.',
  'financeWareHouses.please_select_a_warehouse': 'Silakan pilih gudang.',
  'financeWareHouses.must_greater_than_0': 'Harus lebih dari 0.',
  'financeWareHouses.detail_transfer': 'Transfer Gudang {number}',
  'financeWareHouses.detail_transfer_title': 'Detail Transfer Gudang',
  'financeWareHouses.edit_warehouse_transfer': 'Ubah Transfer Gudang',
  'financeWareHouses.insufficient_stock': 'Stok tidak mencukupi',
  'financeWareHouses.detail_stockadjustment_title': 'Detail Penyesuaian Stok',
  'financeWareHouses.detail_stockadjustment': 'Penyesuaian Stok {number}',
  'financeWareHouses.difference': 'Selisih',
  'financeWareHouses.average_price': 'Harga Rata-rata',
  'financeWareHouses.actual_qty': 'Qty Aktual',
  'financeWareHouses.actual_qty_2': 'Kuantitas Aktual',
  'financeWareHouses.account': 'Akun',
  'financeWareHouses.add_stock_adjustment': 'Tambah Penyesuaian Stok',
  'financeWareHouses.import_stock_adjustment': 'Import Penyesuaian Stok',
  'financeWareHouses.download': 'Download',
  'financeWareHouses.please_select_adjustment_type': 'Silakan pilih tipe penyesuaian stok',
  'financeWareHouses.select_account': 'Pilih akun',
  'financeWareHouses.recorded_qty': 'Qty Tercatat',
  'financeWareHouses.please_input_the_actual_qty': 'Silakan isi kauntiti aktual',
  'financeWareHouses.please_input_the_difference_qty': 'Silakan isi perbedaan kuantiti',
  'financeWareHouses.please_input_the_average_price': 'Silakan isi harga rata-rata',
  'financeWareHouses.error_cannot_update_stock_adjustment': 'Tidak dapat mengubah penyesuaian stok',
  'financeWareHouses.error_cannot_add_stock_adjustment': 'Tidak dapat menambah penyesuaian stok',
  'financeWareHouses.edit_stock_adjustment': 'Ubah Penyesuaian Stok',
  'financeWareHouses.transfer': 'Transfer',
  'financeWareHouses.total_qty': 'Total Kuantitas',
  'financeWareHouses.cannot_less_than_0': 'Tidak boleh kurang dari 0.',
  'financeWareHouses.follow_these_steps_to_import_your_stockAdjustment':
    'Ikuti langkah-langkah berikut untuk menyensuaikan stok',
  'financeWareHouses.1_download_your_stockAdjustment_template_file':
    '1. Download file template penyesuaian stok',
  'financeWareHouses.start_by_downloading_our_stockAdjustment_csv':
    'Mulai dengan mengunduh file template Excel penyesuaian stok. File ini mempunyai kolom header yang yang dibutuhkan Kledo untuk mengimport detail dari penyesuaian stok-mu.',
  'financeWareHouses.download_template_file': 'Download File Template',
  'financeWareHouses.download_template_data_example_file': 'Download File Contoh',
  'financeWareHouses.import': 'Import Penyesuaian Stok',
  'financeWareHouses.2_copy_your_stockAdjustment_into_the_template':
    '2. Salin detail penyesuaian stok ke dalam template',
  'financeWareHouses.important': 'PENTING',
  'financeWareHouses.3_upload_the_updated_template_file_here':
    '3. Upload template yang sudah diupdate ke sini',
  'financeWareHouses.upload': 'Upload',
  'financeWareHouses.save_your_updated_template_as_.csv_or_.txt_and_you_are_ready_to_go':
    'Simpan file template yang sudah diupdate',
  'financeWareHouses.a_maximum_of_500_stockAdjustment_can_be_imported_at_a_time':
    'Maksimal 1.000 penyesuaian stok dapat diimport dalam satu waktu.',
  'financeWareHouses.import_actions_only_insert_new_stockAdjustment_cannot_update_or_delete':
    'Saat meng-import, Kledo hanya akan memasukkan data baru, tidak ada data yang dihapus atau diupdate.',
  'financeWareHouses.dont_change_the_column_heading_in_the_template_file':
    'Jangan mengubah header kolom di dalam template. Ini dibutuhkan agar import dapat berjalan.',
  'financeWareHouses.stockAdjustment_successfully_imported':
    '{rowCount} baris penyesuaian stok berhasil diimport.',
  'financeWareHouses.go_to_stockAdjustment_list': 'Ke halaman daftar penyesuaian stok',
  'financeWareHouses.account_id_help': 'Pilih akun penyesuaian stok untuk penjurnalan.',
  'financeWareHouses.count_stock_help':
    'Jika ingin melakukan stok opname, pilih tipe perhitungan stok. Dengan tipe ini, jumlah stok barang akan dikunci sehingga seluruh pergerakan stok sebelum dilakukannya penyesuaian stok ini akan diabaikan.',
  'financeWareHouses.count_stock_in_out_help':
    'Jika ingin menambah atau mengurangi stok dari suatu gudang, pilih tipe Stok Masuk / Keluar.',
  'financeWareHouses.warehouse_transfer_successfully_imported':
    '{rowCount} transfer gudang berhasil diimport.',
  'financeWareHouses.go_to_warehouse_page': 'Ke halaman gudang',
  'financeWareHouses.follow_these_steps_to_import_your_warehouse_transfer':
    'Ikuti langkah berikut untuk mengimport transfer gudangmu',
  'financeWareHouses.1_download_your_warehouse_transfer_template_file':
    '1. Download file template transfer gudang',
  'financeWareHouses.start_by_downloading_our_warehouse_transfer_csv':
    'Mulai dengan mengunduh file template Excel. File ini mempunyai kolom header yang yang dibutuhkan Kledo untuk mengimport detail dari transfer gudangmu.',
  'financeWareHouses.2_copy_your_warehouse_transfer_into_the_template':
    '2. Salin detail transfer gudang ke dalam template',
  'financeWareHouses.using_excel_or_another_spreadsheet_editor':
    'Menggunakan Excel atau software spreadsheeet lainnya, masukkan detail transfer gudang ke dalam file template dari Kledo. Pastikan detail transfer gudang sesuai dengan kolom header yang ada di template.',
  'financeWareHouses.a_maximum_of_500_warehouse_transfer_can_be_imported_at_a_time':
    'Maksimal 1.000 transfer gudang dapat diimport dalam satu waktu.',
  'financeWareHouses.change_access': 'Ubah Hak Akses',
  'financeWareHouses.who_can_access_this_warehouse': 'Siapa yang bisa mengakses gudang ini?',
  'financeWareHouses.all_user': 'Semua Pengguna',
  'financeWareHouses.access': 'Hak Akses',
  'financeWareHouses.recorded_amount': 'Jumlah Yang Tercatat',
  'financeWareHouses.actual_amount': 'Jumlah Sebenarnya',
  'financeWareHouses.difference_2': 'Perbedaan',

  'approvals.approve': 'Approve',
  'approvals.reject': 'Tolak',
  'approvals.waiting_for_approval': 'Menunggu Persetujuan',
  'approvals.waiting_for_my_approval': 'Menunggu Persetujuanku',
  'approvals.rejected': 'Ditolak',
  'approvals.wait': 'Menunggu',
  'approvals.are_you_sure_to_approve': 'Apakah Anda yakin approve transaksi ini?',
  'approvals.are_you_sure_to_reject': 'Apakah Anda yakin menolak transaksi ini?',
  'approvals.enable_approvals': 'Aktifkan Approval',
  'approvals.approval_levels': 'Berapa jumlah level approval?',
  'approvals.approval_authority': 'Peran yang mempunyai wewenang approval level {level}',
  'approvals.role_is_required': 'Silahkan pilih peran',
  'approvals.approval_progress': 'Progress persetujuan',
  'approvals.and_number_others': 'dan {value} lainnya',
  'approvals.cancel_approval': 'Batalkan persetujuan',
  'approvals.cancel_rejection': 'Batalkan penolakan',
  'approvals.are_you_sure_to_cancel_approval':
    'Apakah Anda yakin untuk batalkan approval di transaksi ini?',

  'layoutInvoice.layout_invoice': 'Layout Invoice',
  'layoutInvoice.invoice': 'Faktur Penjualan',
  'layoutInvoice.tax_invoice': 'Faktur Pajak',
  'layoutInvoice.delivery': 'Surat Jalan',
  'layoutInvoice.order': 'Pemesanan',
  'layoutInvoice.receipt': 'Kuitansi',
  'layoutInvoice.expense_slip': 'Slip Pengeluaran',
  'layoutInvoice.purchase_payment': 'Pemberitahuan Pembayaran',
  'layoutInvoice.send_receive_money': 'Kirim/Terima Dana',
  'layoutInvoice.remittance_advice': 'Bukti Kas Keluar',
  'layoutInvoice.cash_receipt': 'Bukti Kas Masuk',
  'layoutInvoice.account_code': 'Kode Akun',
  'layoutInvoice.account_name': 'Nama Akun',
  'layoutInvoice.error_cannot_load_data': 'Tidak berhasil mengambil data.',
  'layoutInvoice.error_cannot_save_data': 'Tidak berhasil menyimpan data.',
  'layoutInvoice.error_cannot_reset_data': 'Tidak berhasil mengatur ulang data.',
  'layoutInvoice.sale': 'Penjualan',
  'layoutInvoice.purchase': 'Pembelian',
  'layoutInvoice.company_logo': 'Company Logo',
  'layoutInvoice.signature_logo': 'Signature Logo',
  'layoutInvoice.upload_logo': 'Upload logo',
  'layoutInvoice.change_logo_size': 'Ubah ukuran logo',
  'layoutInvoice.upload_signature': 'Upload signature',
  'layoutInvoice.purchase_order': 'Pesanan Pembelian',
  'layoutInvoice.purchase_invoice': 'Faktur Pembelian',
  'layoutInvoice.purchase_return': 'Retur Pembelian',
  'layoutInvoice.purchase_quote': 'Penawaran Pembelian',
  'layoutInvoice.quote': 'Penawaran',
  'layoutInvoice.sell_invoice': 'Tagihan Penjualan',
  'layoutInvoice.show_discount': 'Tampilkan Diskon',
  'layoutInvoice.show_account': 'Tampilkan Rekening',
  'layoutInvoice.show_price': 'Tampilkan Harga',
  'layoutInvoice.show_tax_total': 'Tampilkan Total Pajak',
  'layoutInvoice.show_subtotal': 'Tampilkan Subtotal',
  'layoutInvoice.show_total': 'Tampilkan Total',
  'layoutInvoice.show_amount_column': 'Tampilkan Kolom Jumlah',
  'layoutInvoice.show_tax': 'Tampilkan Pajak',
  'layoutInvoice.show_product': 'Tampilkan Produk',
  'layoutInvoice.show_ref_code_product': 'Tampilkan SKU Produk',
  'layoutInvoice.show_product_image': 'Tampilkan Gambar Produk',
  'layoutInvoice.show_qty': 'Tampilkan Kuantitas',
  'layoutInvoice.show_total_qty': 'Tampilkan Total Kuantitas',
  'layoutInvoice.show_unit': 'Tampilkan Satuan',
  'layoutInvoice.show_description': 'Tampilkan Deskripsi',
  'layoutInvoice.show_tags': 'Tampilkan Tag',
  'layoutInvoice.show_term': 'Tampilkan Termin',
  'layoutInvoice.show_payment_detail': 'Tampilkan Detail Pembayaran',
  'layoutInvoice.show_sales_person': 'Tampilkan Sales Person',
  'layoutInvoice.show_paid_status': 'Tampilkan Status Lunas',
  'layoutInvoice.show_warehouse': 'Tampilkan Gudang',
  'layoutInvoice.show_amount_in_word': 'Tampilkan Jumlah dalam Kata',
  'layoutInvoice.show_contact_name': 'Tampilkan Nama Kontak',
  'layoutInvoice.show_attention': 'Tampilkan UP/ATTN',
  'layoutInvoice.show_detail_contact': 'Tampilkan Detail Kontak',
  'layoutInvoice.show_manufacturing_product_materials': 'Tampilkan Bahan Produk Manufaktur',
  'layoutInvoice.show_manufacturing_product_materials_only':
    'Tampilkan Bahan Produk Manufaktur Saja',
  'layoutInvoice.show_the_calculated_value_of_the_remaining_bill':
    'Tampilkan Nilai Terbilang dari Sisa Tagihan',
  'layoutInvoice.show_total_after_discount': 'Tampilkan Total Setelah Diskon',
  'layoutInvoice.show_coa': 'Tampilkan COA',
  'layoutInvoice.show_reference': 'Tampilkan Reference',
  'layoutInvoice.change_template': 'Ganti template',
  'layoutInvoice.template': 'Template',
  'layoutInvoice.stamp': 'Stempel',
  'layoutInvoice.change_stamp': 'Ganti Stempel',
  'layoutInvoice.edit_header': 'Ubah Header',
  'layoutInvoice.please_choose_template': 'Silahkan pilih template di bawah :',
  'layoutInvoice.please_choose_stamp': 'Silahkan pilih stempel di bawah :',
  'layoutInvoice.select_this_template': 'Gunakan template ini',
  'layoutInvoice.select_this_stamp': 'Gunakan Stempel ini',
  'layoutInvoice.current_template': 'Template saat ini',
  'layoutInvoice.current_stamp': 'Stempel saat ini',
  'layoutInvoice.name': 'Nama',
  'layoutInvoice.transfer_money': 'Transfer Dana',
  'layoutInvoice.label_delivery': 'Label Pengiriman',
  'layoutInvoice.proof_of_goods_receipt': 'Bukti Terima Barang',
  'layoutInvoice.payment_recap': 'Rekap Pembayaran',
  'layoutInvoice.production_orders': 'Perintah Produksi',
  'layoutInvoice.show_due_date': 'Tampilkan Tanggal Jatuh Tempo',
  'layoutInvoice.show_number': 'Tampilkan Nomor',
  'layoutInvoice.show_expedition': 'Tampilkan Ekspedisi',
  'layoutInvoice.show_message': 'Tampilkan Pesan',
  'layoutInvoice.show_signature': 'Tampilkan Tanda Tangan',
  'layoutInvoice.show_signature_detail': 'Tampilkan TTd Pengirim & Penerima',
  'layoutInvoice.font_size': 'Ukuran Font',
  'layoutInvoice.additional_message': 'Tambahan Pesan',
  'layoutInvoice.additional_message_desc':
    'Pesan pada tambahan pesan ini akan ditempelkan secara otomatis di bagian bawah dari pesan dari transaksi.',
  'layoutInvoice.default_message': 'Pesan Default',
  'layoutInvoice.default_message_desc':
    'Pesan default akan diload secara otomatis pada input pesan saat menambahkan transaksi baru.',
  'layoutInvoice.account': 'Rekening',
  'layoutInvoice.show_due': 'Tampilkan Sisa Tagihan',
  'layoutInvoice.show_payments_received': 'Tampilkan Pembayaran Diterima',
  'layoutInvoice.invoice_show_coa_payment_detail': 'Tampilkan Informasi Kode Akun',
  'layoutInvoice.date_format': 'Format Tanggal',
  'layoutInvoice.show_percentage_number_on_additional_number':
    'Tampilkan Angka Persentase Tambahan Diskon',
  'layoutInvoice.discount_price': 'Harga Diskon',
  'layoutInvoice.show_discount_price': 'Tampilkan Harga Diskon',
  'layoutInvoice.show_total_discount': 'Tampilkan Total Diskon',
  'layoutInvoice.show_qty_actual': 'Tampilkan Kuantitas Aktual',
  'layoutInvoice.show_avg_price': 'Tampilkan Harga Rata - Rata',

  'layoutReport.layout_report': 'Layout Laporan',
  'layoutReport.manage_your_finance_report_print_pdf_layout_here':
    'Atur tampilan print pdf laporan keuangan Anda di sini',
  'layoutReport.report_logo': 'Logo Laporan',
  'layoutReport.report_setting': 'Setting Laporan',
  'layoutReport.show_logo': 'Tampilkan Logo',
  'layoutReport.logo_position': 'Posisi Logo',
  'layoutReport.logo_position_left': 'Kiri',
  'layoutReport.logo_position_center': 'Tengah',
  'layoutReport.logo_position_right': 'Kanan',
  'layoutReport.show_comp_name': 'Tampilkan Nama Perusahaan',
  'layoutReport.font_family': 'Font Family',
  'layoutReport.font_size': 'Ukuran Font',
  'layoutReport.margin_size': 'Ukuran Margin',
  'layoutReport.margin_top': 'Margin Atas',
  'layoutReport.margin_right': 'Margin Kanan',
  'layoutReport.margin_bottom': 'Margin Bawah',
  'layoutReport.margin_left': 'Margin Kiri',
  'layoutReport.report_text': 'Teks Laporan',
  'layoutReport.comp_name': 'Nama Perusahaan',
  'layoutReport.report_title': 'Judul Laporan',
  'layoutReport.report_date': 'Tanggal Laporan',
  'layoutReport.click_here_to_reset_layout':
    'Klik di sini untuk mengembalikan layout laporan ke default',
  'layoutReport.show_comp_sign': 'Tampilkan Tanda Tangan',
  'layoutReport.show_comp_sign_left': 'Tampilkan Tanda Tangan Bagian Kiri',
  'layoutReport.show_comp_sign_center': 'Tampilkan Tanda Tangan Bagian Tengah',
  'layoutReport.show_comp_sign_right': 'Tampilkan Tanda Tangan Bagian Kanan',
  'layoutReport.signature': 'Tanda Tangan',

  'financeTag.add_tag': 'Tambah Tag',
  'financeTag.financeTag': 'Tag',
  'financeTag.all_tags': 'Semua tag',
  'financeTag.successfully_added': 'berhasil ditambahkan.',
  'financeTag.error_cannot_add': 'Tidak dapat menambah tag.',
  'financeTag.error_cannot_update': 'Tidak dapat mengubah tag.',
  'financeTag.edit_tag': 'Ubah Tag',
  'financeTag.please_input_the_tag_name': 'Silakan masukkan nama tag.',
  'financeTag.name': 'Nama',
  'financeTag.sale': 'Penjualan',
  'financeTag.purchase': 'Pembelian',
  'financeTag.expense': 'Biaya',
  'financeTag.fixed_asset': 'Aset Tetap',
  'financeTag.other': 'Lain-lain',
  'financeTag.date': 'Tanggal',
  'financeTag.transaction': 'Transaksi',
  'financeTag.desc': 'Deskripsi',
  'financeTag.total': 'Total',
  'financeTag.max_characters': 'Maks {value} karakter.',
  'financeTag.min_characters': 'Min {value} karakter.',
  'financeTag.only_alphanumeric_allowed': 'Hanya huruf dan angka yang diperkenankan.',
  'financeTag.error_cannot_search': 'Tidak dapat mencari tag.',
  'financeTag.select_tag': 'Pilih tag',
  'financeTag.reference': 'Referensi',

  'financeOrder.default_label_term': 'Order Termin I',
  'financeOrder.message': 'Pesan',
  'financeOrder.add_order': 'Tambah Pemesanan',
  'financeOrder.financeOrder': 'Pemesanan',
  'financeOrder.open_order': 'Pesanan belum selesai',
  'financeOrder.order_waiting_approval': 'Pesanan tunggu approval',
  'financeOrder.order_partial_shipped': 'Pesanan kirim sebagian',
  'financeOrder.error_cannot_print_pdf': 'Tidak dapat membuat pdf pemesanan.',
  'financeOrder.successfully_added': 'berhasil ditambahkan.',
  'financeOrder.error_cannot_add': 'Tidak dapat menambah pemesanan.',
  'financeOrder.error_cannot_update': 'Tidak dapat mengubah pemesanan.',
  'financeOrder.error_cannot_move': 'Tidak dapat memindah pemesanan.',
  'financeOrder.error_cannot_change_status': 'Gagal mengubah status pemesanan.',
  'financeOrder.edit_order': 'Ubah pemesanan',
  'financeOrder.max_characters': 'Maks {value} karakter.',
  'financeOrder.min_characters': 'Min {value} karakter.',
  'financeOrder.only_number_allowed': 'Hanya angka yang diperkenankan.',
  'financeOrder.description': 'Deskripsi',
  'financeOrder.tax': 'Pajak',
  'financeOrder.select_tax': 'Pilih pajak',
  'financeOrder.please_select_a_contact': 'Silakan pilih kontak',
  'financeOrder.select_contact': 'Pilih kontak',
  'financeOrder.add_contact': 'Tambah kontak',
  'financeOrder.all_contacts': 'Semua kontak',
  'financeOrder.transaction_date': 'Tgl Transaksi',
  'financeOrder.please_select_transaction_date': 'Silakan pilih tanggal transaksi',
  'financeOrder.due_date': 'Tanggal Jatuh Tempo',
  'financeOrder.please_select_due_date': 'Silakan pilih tanggal jatuh tempo',
  'financeOrder.term': 'Termin',
  'financeOrder.select_term': 'Pilih termin',
  'financeOrder.add_term': 'Tambah termin',
  'financeOrder.price_include_tax': 'Harga termasuk pajak',
  'financeOrder.add_more': 'Tambah baris',
  'financeOrder.number': 'Nomor',
  'financeOrder.please_input_the_number': 'Silakan isikan nomor.',
  'financeOrder.memo': 'Reference',
  'financeOrder.date': 'Tanggal',
  'financeOrder.status': 'Status',
  'financeOrder.balance_due': 'Sisa Tagihan',
  'financeOrder.total': 'Total',
  'financeOrder.please_select_a_term': 'Silakan pilih termin.',
  'financeOrder.search_by_memo': 'Cari',
  'financeOrder.the_due_date_max_higger_than_transaction_date':
    'Tanggal jatuh tempo harus sama atau setelah tanggal transaksi',
  'financeOrder.detail_title': ' Tagihan {number}',
  'financeOrder.customer': 'Pelanggan',
  'financeOrder.reference': 'Reference',
  'financeOrder.product': 'Produk',
  'financeOrder.qty': 'Kuantitas',
  'financeOrder.not_sent_yet': 'Belum Dikirim',
  'financeOrder.price': 'Harga',
  'financeOrder.add_product': 'Tambah Produk',
  'financeOrder.select_product': 'Pilih produk',
  'financeOrder.attachments': 'Attachment',
  'financeOrder.save_&_approve': 'Simpan & setujui',
  'financeOrder.save_as_draft': 'Simpan sbg draft',
  'financeOrder.save_&_submit_for_approval': 'Simpan & ajukan approval',
  'financeOrder.please_select_a_product': 'Silakan pilih produk.',
  'financeOrder.please_input_the_qty': 'Silakan masukkan kuantitas.',
  'financeOrder.please_input_the_price': 'Silakan masukkan harga.',
  'financeOrder.please_input_the_amount': 'Silakan masukkan total.',
  'financeOrder.approve': 'Setujui',
  'financeOrder.are_you_sure_to_approve': 'Apakah Anda yakin untuk menyutujui?',
  'financeOrder.submit_for_approval': 'Ajukan approval',
  'financeOrder.are_you_sure_to_submit_for_approval':
    'Apakah Anda yakin untuk mengajukan approval?',
  'financeOrder.create_order': 'Buat Tagihan',
  'financeOrder.amount_paid': 'Total Dibayar',
  'financeOrder.paid_from': 'Terima pembayaran ke',
  'financeOrder.add_payment': 'Terima Pembayaran',
  'financeOrder.make_a_payment': 'Terima pembayaran',
  'financeOrder.select_paid_from': 'Pilih tujuan pembayaran',
  'financeOrder.please_select_a_paid_from': 'Silakan pilih terima pembayaran ke.',
  'financeOrder.error_cannot_add_payment': 'Tidak bisa menambah pembayaran.',
  'financeOrder.payment': 'Pembayaran',
  'financeOrder.detail_order': 'Detil Tagihan',
  'financeOrder.number_quotes': 'Nomor Penawaran',
  'financeOrder.amount': 'Jumlah',
  'financeOrder.dp': 'DP',
  'financeOrder.create_delivery': 'Buat Pengiriman',
  'financeOrder.follow_these_steps_to_import_your_order':
    'Ikuti langkah-langkah berikut untuk meng-import pemesanan',
  'financeOrder.1_download_your_order_template_file': '1. Download file template pemesanan',
  'financeOrder.start_by_downloading_our_order_csv':
    'Mulai dengan mengunduh file template Excel pemesanan. File ini mempunyai kolom header yang yang dibutuhkan Kledo untuk mengimport detail dari pemesanan-mu.',
  'financeOrder.download_template_file': 'Download File Template',
  'financeOrder.download_template_data_example_file': 'Download File Contoh',
  'financeOrder.import': 'Import Pemesanan',
  'financeOrder.2_copy_your_order_into_the_template': '2. Salin detail pemesanan ke dalam template',
  'financeOrder.using_excel_or_another_spreadsheet_editor':
    'Menggunakan Excel atau software spreadsheeet lainnya, masukkan detail pemesanan ke dalam file template dari Kledo. Pastikan detail pemesanan sesuai dengan kolom header yang ada di template.',
  'financeOrder.important': 'PENTING',
  'financeOrder.3_upload_the_updated_template_file_here':
    '3. Upload template yang sudah diupdate ke sini',
  'financeOrder.upload': 'Upload',
  'financeOrder.save_your_updated_template_as_.csv_or_.txt_and_you_are_ready_to_go':
    'Simpan file template yang sudah diupdate',
  'financeOrder.a_maximum_of_500_order_can_be_imported_at_a_time':
    'Maksimal 1.000 pemesanan dapat diimport dalam satu waktu.',
  'financeOrder.import_actions_only_insert_new_order_cannot_update_or_delete':
    'Saat meng-import, Kledo hanya akan memasukkan data baru, tidak ada data yang dihapus atau diupdate.',
  'financeOrder.dont_change_the_column_heading_in_the_template_file':
    'Jangan mengubah header kolom di dalam template. Ini dibutuhkan agar import dapat berjalan.',
  'financeOrder.order_successfully_imported': '{rowCount} baris pemesanan berhasil diimport.',
  'financeOrder.go_to_order_list': 'Ke halaman daftar pemesanan',
  'financeOrder.print_order': 'Cetak Pemesanan',
  'financeOrder.copy_link': 'Salin Link Pemesanan',
  'financeOrder.send_email': 'Kirim Email Pemesanan',
  'financeOrder.send_sms': 'Kirim SMS Pemesanan',
  'financeOrder.send_wa': 'Kirim Whatsapp Pemesanan',
  'financeOrder.insufficient_stock_warning':
    'Tidak dapat menambah pemesanan, karena terdapat produk yang stok nya kurang.',

  'financeDelivery.message': 'Pesan',
  'financeDelivery.add_delivery': 'Tambah Pengiriman',
  'financeDelivery.financeDelivery': 'Pengiriman',
  'financeDelivery.open_delivery': 'Pengiriman belum selesai',
  'financeDelivery.error_cannot_print_pdf': 'Tidak dapat membuat pdf pengiriman.',
  'financeDelivery.successfully_added': 'berhasil ditambahkan.',
  'financeDelivery.error_cannot_add': 'Tidak dapat menambah pengiriman.',
  'financeDelivery.error_cannot_update': 'Tidak dapat mengubah pengiriman.',
  'financeDelivery.error_cannot_move': 'Tidak dapat memindah pengiriman.',
  'financeDelivery.error_cannot_change_status': 'Gagal mengubah status pengiriman.',
  'financeDelivery.edit_delivery': 'Ubah pengiriman',
  'financeDelivery.max_characters': 'Maks {value} karakter.',
  'financeDelivery.min_characters': 'Min {value} karakter.',
  'financeDelivery.only_number_allowed': 'Hanya angka yang diperkenankan.',
  'financeDelivery.description': 'Deskripsi',
  'financeDelivery.tax': 'Pajak',
  'financeDelivery.select_tax': 'Pilih pajak',
  'financeDelivery.please_select_a_contact': 'Silakan pilih kontak',
  'financeDelivery.select_contact': 'Pilih kontak',
  'financeDelivery.add_contact': 'Tambah kontak',
  'financeDelivery.all_contacts': 'Semua kontak',
  'financeDelivery.transaction_date': 'Tgl Transaksi',
  'financeDelivery.please_select_transaction_date': 'Silakan pilih tanggal transaksi',
  'financeDelivery.due_date': 'Tanggal Jatuh Tempo',
  'financeDelivery.please_select_due_date': 'Silakan pilih tanggal jatuh tempo',
  'financeDelivery.term': 'Termin',
  'financeDelivery.select_term': 'Pilih termin',
  'financeDelivery.add_term': 'Tambah termin',
  'financeDelivery.price_include_tax': 'Harga termasuk pajak',
  'financeDelivery.add_more': 'Tambah baris',
  'financeDelivery.number': 'Nomor',
  'financeDelivery.please_input_the_number': 'Silakan isikan nomor.',
  'financeDelivery.memo': 'Reference',
  'financeDelivery.date': 'Tanggal',
  'financeDelivery.status': 'Status',
  'financeDelivery.balance_due': 'Sisa Tagihan',
  'financeDelivery.total': 'Total',
  'financeDelivery.please_select_a_term': 'Silakan pilih termin.',
  'financeDelivery.search_by_memo': 'Cari',
  'financeDelivery.the_due_date_max_higger_than_transaction_date':
    'Tanggal jatuh tempo harus sama atau setelah tanggal transaksi',
  'financeDelivery.detail_title': ' Tagihan {number}',
  'financeDelivery.customer': 'Pelanggan',
  'financeDelivery.reference': 'Reference',
  'financeDelivery.product': 'Produk',
  'financeDelivery.qty': 'Kuantitas',
  'financeDelivery.price': 'Harga',
  'financeDelivery.add_product': 'Tambah Produk',
  'financeDelivery.select_product': 'Pilih produk',
  'financeDelivery.attachments': 'Attachment',
  'financeDelivery.save_&_approve': 'Simpan & setujui',
  'financeDelivery.save_as_draft': 'Simpan sbg draft',
  'financeDelivery.save_&_submit_for_approval': 'Simpan & ajukan approval',
  'financeDelivery.please_select_a_product': 'Silakan pilih produk.',
  'financeDelivery.please_input_the_qty': 'Silakan masukkan kuantitas.',
  'financeDelivery.please_input_the_price': 'Silakan masukkan harga.',
  'financeDelivery.please_input_the_amount': 'Silakan masukkan total.',
  'financeDelivery.approve': 'Setujui',
  'financeDelivery.are_you_sure_to_approve': 'Apakah Anda yakin untuk menyutujui?',
  'financeDelivery.submit_for_approval': 'Ajukan approval',
  'financeDelivery.are_you_sure_to_submit_for_approval':
    'Apakah Anda yakin untuk mengajukan approval?',
  'financeDelivery.create_delivery': 'Buat Tagihan',
  'financeDelivery.amount_paid': 'Total Dibayar',
  'financeDelivery.paid_from': 'Terima pembayaran ke',
  'financeDelivery.add_payment': 'Terima Pembayaran',
  'financeDelivery.make_a_payment': 'Terima pembayaran',
  'financeDelivery.select_paid_from': 'Pilih tujuan pembayaran',
  'financeDelivery.please_select_a_paid_from': 'Silakan pilih terima pembayaran ke.',
  'financeDelivery.error_cannot_add_payment': 'Tidak bisa menambah pembayaran.',
  'financeDelivery.payment': 'Pembayaran',
  'financeDelivery.detail_delivery': 'Detil Tagihan',
  'financeDelivery.number_quotes': 'Nomor Penawaran',
  'financeDelivery.amount': 'Jumlah',
  'financeDelivery.shipping_delivery': 'Biaya Pengiriman',
  'financeDelivery.print_delivery': 'Cetak Surat Jalan',
  'financeDelivery.print_delivery_label': 'Cetak Label Pengiriman',
  'financeDelivery.copy_link': 'Salin Link Surat Jalan',
  'financeDelivery.send_email': 'Kirim Email Surat Jalan',
  'financeDelivery.send_sms': 'Kirim SMS Surat Jalan',
  'financeDelivery.send_wa': 'Kirim Whatsapp Surat Jalan',

  'shipping.show_shipping_information': 'Tampilkan Informasi Pengiriman',
  'shipping.hide_shipping_information': 'Sembunyikan Informasi Pengiriman',
  'shipping.shipping_date': 'Tanggal Pengiriman',
  'shipping.shipping_expedition': 'Ekspedisi',
  'shipping.shipping_tracking': 'No. Resi',
  'shipping.select_expedition': 'Pilih ekspedisi',
  'shipping.add_expedition': 'Tambah ekspedisi',
  'shipping.shipping_cost': 'Biaya pengiriman',
  'shipping.please_select_shipping_date': 'Silahkan pilih tanggal pengiriman.',
  'shipping.shipping_information': 'Informasi pengiriman',

  'creditMemo.add_credit_memo': 'Tambah Hutang',
  'creditMemo.credit_memo': 'Hutang',
  'creditMemo.add_transactions': 'Tambah Transaksi',
  'creditMemo.successfully_added': 'Berhasil ditambahkan',
  'creditMemo.error_cannot_add': 'Tidak dapat menambah hutang',
  'creditMemo.error_cannot_update': 'Hutang gagal diperbarui',
  'creditMemo.edit_credit_memo': 'Ubah Hutang',
  'creditMemo.max_characters': 'Maks. {value} karakter',
  'creditMemo.min_characters': 'Min. {value} karakter',
  'creditMemo.only_number_allowed': 'Hanya angka yang diperbolehkan',
  'creditMemo.description': 'Deskripsi',
  'creditMemo.message': 'Pesan',
  'creditMemo.amount': 'Jumlah',
  'creditMemo.transaction_date': 'Tanggal Transaksi',
  'creditMemo.please_select_transaction_date': 'Silakan pilih tanggal transaksi',
  'creditMemo.number': 'Nomor',
  'creditMemo.please_input_the_number': 'Silakan masukkan nomor.',
  'creditMemo.memo': 'Reference',
  'creditMemo.date': 'Tanggal',
  'creditMemo.total': 'Total',
  'creditMemo.credit_balance': 'Sisa Hutang',
  'creditMemo.detail_title': 'Hutang {number}',
  'creditMemo.reference': 'Reference',
  'creditMemo.attachments': 'Attachment',
  'creditMemo.please_input_the_amount': 'Silakan masukkan jumlah',
  'creditMemo.status': 'Status',
  'creditMemo.balance_due': 'Sisa Tagihan',
  'creditMemo.search_by_memo': 'Cari',
  'creditMemo.customer': 'Pelanggan',
  'creditMemo.detail_credit_memo': 'Detil Hutang',
  'creditMemo.account': 'Akun',
  'creditMemo.select_account': 'Pilih akun',
  'creditMemo.make_a_payment': 'Buat pembayaran',
  'creditMemo.return_number': 'Nomor Retur',

  'return.add_return': 'Tambah Retur',
  'return.return': 'Retur',
  'return.detail_return': 'Detail Retur',
  'return.vendor': 'Vendor',
  'return.add_transactions': 'Tambah Transaksi',
  'return.successfully_added': 'Berhasil ditambahkan',
  'return.error_cannot_add': 'Tidak dapat menambah retur',
  'return.error_cannot_update': 'Retur gagal diperbarui',
  'return.edit_return': 'Ubah Retur',
  'return.max_characters': 'Maks. {value} karakter',
  'return.min_characters': 'Min. {value} karakter',
  'return.only_number_allowed': 'Hanya angka yang diperbolehkan',
  'return.description': 'Deskripsi',
  'return.message': 'Pesan',
  'return.amount': 'Jumlah',
  'return.transaction_date': 'Tanggal Retur',
  'return.please_select_transaction_date': 'Silakan pilih tanggal transaksi',
  'return.number': 'Nomor',
  'return.please_input_the_number': 'Silakan masukkan nomor.',
  'return.memo': 'Reference',
  'return.date': 'Tanggal',
  'return.total': 'Total',
  'return.credit_balance': 'Sisa Retur',
  'return.detail_title': 'Retur {number}',
  'return.reference': 'Reference',
  'return.attachments': 'Attachment',
  'return.please_input_the_amount': 'Silakan masukkan jumlah',
  'return.status': 'Status',
  'return.balance_due': 'Sisa Tagihan',
  'return.search_by_memo': 'Cari',
  'return.customer': 'Pelanggan',
  'return.detail_credit_memo': 'Detil Retur',
  'return.account': 'Akun',
  'return.select_account': 'Pilih akun',
  'return.make_a_payment': 'Buat pembayaran',
  'return.invoice': 'Tagihan',
  'return.faktur_balance': 'Jumlah Faktur',
  'return.qty_faktur': 'Qty Faktur',
  'return.available_return': 'Yang Bisa Diretur',
  'return.product': 'Produk',
  'return.discount_percent': 'Diskon',
  'return.tax': 'Pajak',
  'return.qty_return': 'Qty Retur',
  'return.price': 'Price',
  'return.please_input_the_qty': 'Silakan masukkan kuantitas.',
  'return.price_include_tax': 'Harga termasuk pajak',
  'return.cannot_greather_than_available': 'Tidak dapat melebihi jumlah yang tersedia.',
  'return.total_return': 'Total Retur',

  'financePurchaseQuotes.message': 'Pesan',
  'financePurchaseQuotes.error_cannot_print_pdf': 'Tidak dapat membuat pdf penawaran.',
  'financePurchaseQuotes.add_quote': 'Tambah Penawaran',
  'financePurchaseQuotes.financePurchaseQuotes': 'Penawaran',
  'financePurchaseQuotes.financePurchaseQuotes2': 'Penawaran Pembelian',
  'financePurchaseQuotes.successfully_added': 'berhasil ditambahkan.',
  'financePurchaseQuotes.error_cannot_add': 'Tidak dapat menambah penawaran.',
  'financePurchaseQuotes.error_cannot_update': 'Tidak dapat mengubah penawaran.',
  'financePurchaseQuotes.error_cannot_move': 'Tidak dapat memindah penawaran.',
  'financePurchaseQuotes.error_cannot_change_status': 'Tidak dapat mengubah status penawaran.',
  'financePurchaseQuotes.edit_quote': 'Ubah Penawaran',
  'financePurchaseQuotes.max_characters': 'Maks {value} karakter.',
  'financePurchaseQuotes.min_characters': 'Min {value} karakter.',
  'financePurchaseQuotes.only_number_allowed': 'Hanya angka yang diperkenankan.',
  'financePurchaseQuotes.description': 'Deskripsi',
  'financePurchaseQuotes.tax': 'Pajak',
  'financePurchaseQuotes.select_tax': 'Pilih pajak',
  'financePurchaseQuotes.amount': 'Total',
  'financePurchaseQuotes.please_select_a_contact': 'Silakan pilih kontak',
  'financePurchaseQuotes.select_contact': 'Pilih kontak',
  'financePurchaseQuotes.add_contact': 'Tambah kontak',
  'financePurchaseQuotes.all_contacts': 'Semua kontak',
  'financePurchaseQuotes.transaction_date': 'Tgl Transaksi',
  'financePurchaseQuotes.please_select_transaction_date': 'Silakan pilih tanggal transaksi',
  'financePurchaseQuotes.due_date': 'Kadaluarsa',
  'financePurchaseQuotes.please_select_due_date': 'Silakan pilih tanggal kadaluarsa',
  'financePurchaseQuotes.term': 'Termin',
  'financePurchaseQuotes.select_term': 'Pilih termin',
  'financePurchaseQuotes.add_term': 'Tambah termin',
  'financePurchaseQuotes.price_include_tax': 'Harga termasuk pajak',
  'financePurchaseQuotes.add_more': 'Tambah baris',
  'financePurchaseQuotes.number': 'Nomor',
  'financePurchaseQuotes.please_input_the_number': 'Silakan isikan nomor.',
  'financePurchaseQuotes.memo': 'Reference',
  'financePurchaseQuotes.date': 'Tanggal',
  'financePurchaseQuotes.status': 'Status',
  'financePurchaseQuotes.balance_due': 'Sisa Tagihan',
  'financePurchaseQuotes.please_select_a_term': 'Silakan pilih termin.',
  'financePurchaseQuotes.search_by_memo': 'Cari',
  'financePurchaseQuotes.the_due_date_max_higger_than_transaction_date':
    'Tanggal kadaluarsa harus sama atau setelah tanggal transaksi',
  'financePurchaseQuotes.detail_title': 'Penawaran {number}',
  'financePurchaseQuotes.customer': 'Pelanggan',
  'financePurchaseQuotes.reference': 'Reference',
  'financePurchaseQuotes.product': 'Produk',
  'financePurchaseQuotes.qty': 'Kuantitas',
  'financePurchaseQuotes.price': 'Harga',
  'financePurchaseQuotes.add_product': 'Tambah Produk',
  'financePurchaseQuotes.select_product': 'Pilih produk',
  'financePurchaseQuotes.attachments': 'Attachment',
  'financePurchaseQuotes.save_as_draft': 'Simpan sbg draft',
  'financePurchaseQuotes.save_&_submit_for_approval': 'Simpan & ajukan approval',
  'financePurchaseQuotes.please_select_a_product': 'Silakan pilih produk.',
  'financePurchaseQuotes.please_input_the_qty': 'Silakan masukkan kuantitas.',
  'financePurchaseQuotes.please_input_the_price': 'Silakan masukkan harga.',
  'financePurchaseQuotes.please_input_the_amount': 'Silakan masukkan total.',
  'financePurchaseQuotes.mark_as_sent': 'Tandai sbg terkirim',
  'financePurchaseQuotes.are_you_sure_to_mark_as_sent':
    'Apakah Anda yakin menandai sebagai terkirim?',
  'financePurchaseQuotes.mark_as_accepted': 'Tandai sbg diterima',
  'financePurchaseQuotes.are_you_sure_to_mark_as_accepted':
    'Apakah Anda yakin menandai sebagai diterima?',
  'financePurchaseQuotes.mark_as_declined': 'Tandai sbg ditolak',
  'financePurchaseQuotes.are_you_sure_to_mark_as_declined':
    'Apakah Anda yakin menandai sebagai ditolak?',
  'financePurchaseQuotes.create_invoice': 'Buat Tagihan',
  'financePurchaseQuotes.send': 'Kirim',
  'financePurchaseQuotes.detail_quote': 'Detil Penawaran',
  'financePurchaseQuotes.create_order': 'Buat Pemesanan',

  'financePurchaseQuotes.follow_these_steps_to_import_your_quotes':
    'Ikuti langkah berikut untuk mengimport penawaranmu',
  'financePurchaseQuotes.1_download_your_quotes_template_file':
    '1. Download file template penawaran',
  'financePurchaseQuotes.start_by_downloading_our_quotes_csv':
    'Mulai dengan mengunduh file template Excel penawaran. File ini mempunyai kolom header yang yang dibutuhkan Kledo untuk mengimport detail dari penawaran-penawaranmu.',
  'financePurchaseQuotes.download_template_file': 'Download File Template',
  'financePurchaseQuotes.download_template_data_example_file': 'Download File Contoh',
  'financePurchaseQuotes.import': 'Import Penawaran',
  'financePurchaseQuotes.2_copy_your_quotes_into_the_template':
    '2. Salin detail penawaran ke dalam template',
  'financePurchaseQuotes.using_excel_or_another_spreadsheet_editor':
    'Menggunakan Excel atau software spreadsheeet lainnya, masukkan detail penawaran ke dalam file template dari Kledo. Pastikan detail penawaran sesuai dengan kolom header yang ada di template.',
  'financePurchaseQuotes.important': 'PENTING',
  'financePurchaseQuotes.3_upload_the_updated_template_file_here':
    '3. Upload template yang sudah diupdate ke sini',
  'financePurchaseQuotes.upload': 'Upload',
  'financePurchaseQuotes.save_your_updated_template_as_.csv_or_.txt_and_you_are_ready_to_go':
    'Simpan file template yang sudah diupdate',
  'financePurchaseQuotes.a_maximum_of_500_quotes_can_be_imported_at_a_time':
    'Maksimal 1.000 penawaran dapat diimport dalam satu waktu.',
  'financePurchaseQuotes.contact_name_that_not_exist_will_be_inserted_as_a_new_contact':
    'Nama kontak yang yang belum ada di Kledo akan dimasukkan sebagai kontak baru.',
  'financePurchaseQuotes.import_actions_only_insert_new_quotes_cannot_update_or_delete':
    'Saat meng-import, Kledo hanya akan memasukkan data baru, tidak ada data yang dihapus atau diupdate.',
  'financePurchaseQuotes.dont_change_the_column_heading_in_the_template_file':
    'Jangan mengubah header kolom di dalam template. Ini dibutuhkan agar import dapat berjalan.',
  'financePurchaseQuotes.you_can_only_upload_csv_file':
    'Anda hanya dapat mengunggah file csv atau excel',
  'financePurchaseQuotes.upload_file': 'Upload File',
  'financePurchaseQuotes.check_the_imported_data': 'Cek Data',
  'financePurchaseQuotes.please_check_the_imported_data': 'Silakan cek data yang diimport',
  'financePurchaseQuotes.import_quotes_done': 'Import Berhasil',
  'financePurchaseQuotes.error_upload_file': 'Gagal mengunggah file.',
  'financePurchaseQuotes.file_not_found': 'File tidak ditemukan.',
  'financePurchaseQuotes.if_you_are_happy_to_continue_with_the_import_please_click_the_import_now_button': `Jika data yang diimport sesuai dengan yang kamu harapkan, silakan klik tombol "Import Sekarang".`,
  'financePurchaseQuotes.valid': 'Valid',
  'financePurchaseQuotes.invalid': 'Invalid',
  'financePurchaseQuotes.total': 'Total',
  'financePurchaseQuotes.import_now': 'Import Sekarang',
  'financePurchaseQuotes.contact_name': 'Nama Kontak',
  'financePurchaseQuotes.email': 'Email',
  'financePurchaseQuotes.address': 'Alamat',
  'financePurchaseQuotes.phone': 'Phone',
  'financePurchaseQuotes.quote_date': 'Tanggal Penawaran',
  'financePurchaseQuotes.quote_number': 'Nomor Penawaran',
  'financePurchaseQuotes.product_name': 'Nama Produk',
  'financePurchaseQuotes.code_sku': 'Kode/SKU',
  'financePurchaseQuotes.tax_inclusive': 'Termasuk Pajak',

  'financePurchaseQuotes.inventory_account': 'Akun Penyimpanan',
  'financePurchaseQuotes.quotes_successfully_imported': '{rowCount} quote berhasil diimport.',
  'financePurchaseQuotes.view_detail': 'Tampilkan detil',
  'financePurchaseQuotes.import_another_file': 'Import file lain',
  'financePurchaseQuotes.go_to_quote_list': 'Ke halaman daftar quote',
  'financePurchaseQuotes.success': 'Berhasil',
  'financePurchaseQuotes.failed': 'Gagal',
  'financePurchaseQuotes.error_cannot_import': 'Gagal import.',
  'financePurchaseQuotes.error': 'Error',
  'financePurchaseQuotes.vendor': 'Vendor',
  'debitMemo.add_debit_memo': 'Tambah Piutang',
  'debitMemo.debit_memo': 'Piutang',
  'debitMemo.add_transactions': 'Tambah Transaksi',
  'debitMemo.successfully_added': 'Berhasil ditambahkan',
  'debitMemo.error_cannot_add': 'Tidak dapat menambah hutang',
  'debitMemo.error_cannot_update': 'Piutang gagal diperbarui',
  'debitMemo.edit_debit_memo': 'Ubah Piutang',
  'debitMemo.max_characters': 'Maks. {value} karakter',
  'debitMemo.min_characters': 'Min. {value} karakter',
  'debitMemo.only_number_allowed': 'Hanya angka yang diperbolehkan',
  'debitMemo.description': 'Deskripsi',
  'debitMemo.message': 'Pesan',
  'debitMemo.amount': 'Jumlah',
  'debitMemo.transaction_date': 'Tanggal Transaksi',
  'debitMemo.please_select_transaction_date': 'Silakan pilih tanggal transaksi',
  'debitMemo.number': 'Nomor',
  'debitMemo.please_input_the_number': 'Silakan masukkan nomor.',
  'debitMemo.memo': 'Reference',
  'debitMemo.date': 'Tanggal',
  'debitMemo.total': 'Total',
  'debitMemo.debit_balance': 'Sisa Piutang',
  'debitMemo.detail_title': 'Piutang {number}',
  'debitMemo.reference': 'Reference',
  'debitMemo.attachments': 'Attachment',
  'debitMemo.please_input_the_amount': 'Silakan masukkan jumlah',
  'debitMemo.status': 'Status',
  'debitMemo.balance_due': 'Sisa Tagihan',
  'debitMemo.search_by_memo': 'Cari',
  'debitMemo.customer': 'Pelanggan',
  'debitMemo.detail_debit_memo': 'Detil Piutang',
  'debitMemo.account': 'Akun',
  'debitMemo.select_account': 'Pilih akun',
  'debitMemo.make_a_payment': 'Terima Pembayaran',
  'debitMemo.return_number': 'Nomor Retur',

  'financePurchaseDelivery.message': 'Pesan',
  'financePurchaseDelivery.add_delivery': 'Tambah Pengiriman',
  'financePurchaseDelivery.financePurchaseDelivery': 'Pengiriman',
  'financePurchaseDelivery.error_cannot_print_pdf': 'Tidak dapat membuat pdf pengiriman.',
  'financePurchaseDelivery.successfully_added': 'berhasil ditambahkan.',
  'financePurchaseDelivery.error_cannot_add': 'Tidak dapat menambah pengiriman.',
  'financePurchaseDelivery.error_cannot_update': 'Tidak dapat mengubah pengiriman.',
  'financePurchaseDelivery.error_cannot_move': 'Tidak dapat memindah pengiriman.',
  'financePurchaseDelivery.error_cannot_change_status': 'Gagal mengubah status pengiriman.',
  'financePurchaseDelivery.edit_delivery': 'Ubah pengiriman',
  'financePurchaseDelivery.max_characters': 'Maks {value} karakter.',
  'financePurchaseDelivery.min_characters': 'Min {value} karakter.',
  'financePurchaseDelivery.only_number_allowed': 'Hanya angka yang diperkenankan.',
  'financePurchaseDelivery.description': 'Deskripsi',
  'financePurchaseDelivery.tax': 'Pajak',
  'financePurchaseDelivery.select_tax': 'Pilih pajak',
  'financePurchaseDelivery.please_select_a_contact': 'Silakan pilih kontak',
  'financePurchaseDelivery.select_contact': 'Pilih kontak',
  'financePurchaseDelivery.add_contact': 'Tambah kontak',
  'financePurchaseDelivery.all_contacts': 'Semua kontak',
  'financePurchaseDelivery.transaction_date': 'Tgl Transaksi',
  'financePurchaseDelivery.please_select_transaction_date': 'Silakan pilih tanggal transaksi',
  'financePurchaseDelivery.due_date': 'Tanggal Jatuh Tempo',
  'financePurchaseDelivery.please_select_due_date': 'Silakan pilih tanggal jatuh tempo',
  'financePurchaseDelivery.term': 'Termin',
  'financePurchaseDelivery.select_term': 'Pilih termin',
  'financePurchaseDelivery.add_term': 'Tambah termin',
  'financePurchaseDelivery.price_include_tax': 'Harga termasuk pajak',
  'financePurchaseDelivery.add_more': 'Tambah baris',
  'financePurchaseDelivery.number': 'Nomor',
  'financePurchaseDelivery.please_input_the_number': 'Silakan isikan nomor.',
  'financePurchaseDelivery.memo': 'Reference',
  'financePurchaseDelivery.date': 'Tanggal',
  'financePurchaseDelivery.status': 'Status',
  'financePurchaseDelivery.balance_due': 'Sisa Tagihan',
  'financePurchaseDelivery.total': 'Total',
  'financePurchaseDelivery.please_select_a_term': 'Silakan pilih termin.',
  'financePurchaseDelivery.search_by_memo': 'Cari',
  'financePurchaseDelivery.the_due_date_max_higger_than_transaction_date':
    'Tanggal jatuh tempo harus sama atau setelah tanggal transaksi',
  'financePurchaseDelivery.detail_title': ' Tagihan {number}',
  'financePurchaseDelivery.customer': 'Pelanggan',
  'financePurchaseDelivery.reference': 'Reference',
  'financePurchaseDelivery.product': 'Produk',
  'financePurchaseDelivery.qty': 'Kuantitas',
  'financePurchaseDelivery.price': 'Harga',
  'financePurchaseDelivery.add_product': 'Tambah Produk',
  'financePurchaseDelivery.select_product': 'Pilih produk',
  'financePurchaseDelivery.attachments': 'Attachment',
  'financePurchaseDelivery.save_&_approve': 'Simpan & setujui',
  'financePurchaseDelivery.save_as_draft': 'Simpan sbg draft',
  'financePurchaseDelivery.save_&_submit_for_approval': 'Simpan & ajukan approval',
  'financePurchaseDelivery.please_select_a_product': 'Silakan pilih produk.',
  'financePurchaseDelivery.please_input_the_qty': 'Silakan masukkan kuantitas.',
  'financePurchaseDelivery.please_input_the_price': 'Silakan masukkan harga.',
  'financePurchaseDelivery.please_input_the_amount': 'Silakan masukkan total.',
  'financePurchaseDelivery.approve': 'Setujui',
  'financePurchaseDelivery.are_you_sure_to_approve': 'Apakah Anda yakin untuk menyutujui?',
  'financePurchaseDelivery.submit_for_approval': 'Ajukan approval',
  'financePurchaseDelivery.are_you_sure_to_submit_for_approval':
    'Apakah Anda yakin untuk mengajukan approval?',
  'financePurchaseDelivery.create_delivery': 'Buat Tagihan',
  'financePurchaseDelivery.amount_paid': 'Total Dibayar',
  'financePurchaseDelivery.paid_from': 'Terima pembayaran ke',
  'financePurchaseDelivery.add_payment': 'Terima Pembayaran',
  'financePurchaseDelivery.make_a_payment': 'Terima pembayaran',
  'financePurchaseDelivery.select_paid_from': 'Pilih tujuan pembayaran',
  'financePurchaseDelivery.please_select_a_paid_from': 'Silakan pilih terima pembayaran ke.',
  'financePurchaseDelivery.error_cannot_add_payment': 'Tidak bisa menambah pembayaran.',
  'financePurchaseDelivery.payment': 'Pembayaran',
  'financePurchaseDelivery.detail_delivery': 'Detil Tagihan',
  'financePurchaseDelivery.number_quotes': 'Nomor Penawaran',
  'financePurchaseDelivery.amount': 'Jumlah',
  'financePurchaseDelivery.shipping_delivery': 'Biaya Pengiriman',
  'financePurchaseDelivery.vendor': 'Vendor',
  'financePurchaseDelivery.print_receipt_of_goods': 'Cetak Bukti Terima Barang',

  'businessFlow.businessFlow': 'Pengaturan Alur Bisnis',
  'businessFlow.sales_discount': 'Diskon penjualan',
  'businessFlow.sales_discount_desc': 'Apakah perusahaan Anda dapat memberikan diskon penjualan?',
  'businessFlow.delivery_desc': 'Apakah perusahaan Anda membuat surat jalan saat pengiriman?',
  'businessFlow.order_desc': 'Apakah perusahaan Anda menerima pemesanan penjualan?',
  'businessFlow.quote_desc': 'Apakah perusahaan Anda mengirimkan penawaran penjualan?',
  'businessFlow.down_payment': 'Uang muka',
  'businessFlow.down_payment_desc': 'Apakah perusahaan Anda dapat menerima uang muka penjualan?',
  'businessFlow.purchase_down_payment_desc':
    'Apakah perusahaan Anda dapat membayarkan uang muka pembelian?',
  'businessFlow.purchase_discount': 'Diskon pembelian',
  'businessFlow.purchase_discount_desc': 'Apakah perusahaan Anda dapat menerima diskon pembelian?',
  'businessFlow.purchase_delivery': 'Pengiriman pembelian',
  'businessFlow.purchase_delivery_desc': 'Apakah fitur pengiriman pembelian diaktifkan?',
  'businessFlow.purchase_order': 'Pesanan pembelian',
  'businessFlow.purchase_order_desc': 'Apakah perusahaan Anda melakukan pemesanan pembelian?',
  'businessFlow.purchase_quote': 'Penawaran pembelian',
  'businessFlow.purchase_quote_desc': 'Apakah perusahaan Anda menerima pemesanan pembelian?',
  'businessFlow.reconciliation': 'Rekonsiliasi',
  'businessFlow.reconciliation_desc':
    'Apakah Anda merekonsiliasi transaksi di Kledo dengan mutasi di rekening bank?',
  'businessFlow.tracking_stock': 'Lacak inventori/stok secara default',
  'businessFlow.tracking_stock_desc':
    'Saat menambah produk, secara default set produk untuk melacak stok',
  'businessFlow.error_cannot_update': 'Tidak dapat mengubah pengaturan.',
  'businessFlow.decline_new_sales_if_stock_not_enough': 'Tolak tagihan baru jika stok kurang',
  'businessFlow.decline_new_order_if_stock_not_enough': 'Tolak pemesanan baru jika stok kurang',
  'businessFlow.decline_new_delivery_if_stock_not_enough': 'Tolak pengiriman baru jika stok kurang',
  'businessFlow.show_available_stock_when_making_a_sale':
    'Tampilkan stok tersedia saat membuat penjualan',
  'businessFlow.show_sales_description': 'Tampilkan deskripsi di penjualan',
  'businessFlow.show_sales_description_desc': 'Apakah kolom deskripsi di penjualan ditampilkan?',
  'businessFlow.show_purchases_description': 'Tampilkan deskripsi di pembelian',
  'businessFlow.show_purchases_description_desc':
    'Apakah kolom deskripsi di pembelian ditampilkan?',
  'businessFlow.hide_products_that_are_out_of_stock': 'Sembunyikan produk yang stok habis',
  'businessFlow.hide_products_that_are_out_of_stock_description':
    'Apakah produk yang stok habis disembunyikan saat menambah tagihan?',
  'businessFlow.show_profit_margin_when_making_transaction':
    'Tampilkan profit margin saat membuat transaksi',
  'businessFlow.show_details_of_average_price_and_profit_margin_when_making_a_new_transaction':
    'Menampilkan detail harga rata-rata dan profit margin saat membuat transaksi baru.',
  'businessFlow.whether_stock_is_in_the_warehouse':
    'Apakah stok yang ada di gudang perlu ditampilkan saat membuat penjualan?',
  'businessFlow.show_available_stock_per_warehouse':
    'Perhitungan stok berdasarkan gudang yang dipilih',
  'businessFlow.whether_displayed_stock_needs_per_warehouse':
    'Jika diaktifkan, maka perhitungan stok berdasarkan gudang yang dipilih, bukan berdasarkan kesuluruhan stok',
  'businessFlow.decline_to_add_sales_if_the_total_stock_not_enough':
    'Tolak tambah tagihan jika total stok di gudang tidak mencukupi?',
  'businessFlow.decline_to_add_order_if_the_total_stock_not_enough':
    'Tolak tambah pemesanan jika total stok di gudang tidak mencukupi?',
  'businessFlow.decline_to_add_delivery_if_the_total_stock_not_enough':
    'Tolak tambah pengiriman jika total stok di gudang tidak mencukupi?',
  'businessFlow.discount_in_nominal': 'Input diskon dalam nominal',
  'businessFlow.wether_discount_input_when_add_invoice_in_nominal_format_or_percent_format':
    'Jika aktif maka input diskon saat menambah tagihan akan dalam format nominal, bukan dalam format persen.',
  'businessFlow.product_price_dont_change': 'Harga produk tidak bisa diubah',
  'businessFlow.wether_product_price_can_be_changed_when_add_invoice':
    'Jika aktif maka harga produk saat menambah tagihan tidak dapat diubah.',
  'businessFlow.sales_person': 'Sales person',
  'businessFlow.default_sale_include_tax': 'Default harga penjualan termasuk pajak',
  'businessFlow.wether_create_sale_invoice_include_tax':
    'Apakah saat membuat tagihan defaultnya harga penjualan termasuk pajak?',
  'businessFlow.default_purchase_include_tax': 'Default harga pembelian termasuk pajak',
  'businessFlow.wether_create_purchase_invoice_include_tax':
    'Apakah saat membuat tagihan defaultnya harga pembelian termasuk pajak?',
  'businessFlow.min_stock': 'Stok minimal',
  'businessFlow.when_product_below_min_stock_email_notification':
    'Saat stok produk di bawah stok minimal, akan dikirimkan email notifikasi',
  'businessFlow.decline_product_conv_if_stock_not_enough': 'Tolak konversi produk jika stok kurang',
  'businessFlow.wether_decline_add_product_conv_if_stock_not_enough':
    'Apakah tolak penambahan konversi produk jika stok bahan baku tidak mencukupi?',
  'businessFlow.lang_date_currency': 'Bahasa, Tanggal, & Mata Uang',
  'businessFlow.app_lang': 'Bahasa Invoice',
  'businessFlow.app_lang_desc':
    'Bahasa yang digunakan secara default untuk seluruh staff perusahaan Anda',
  'businessFlow.time_zone': 'Zona waktu',
  'businessFlow.time_zone_desc': 'Zona waktu lokasi perusahaan Anda',
  'businessFlow.date_format': 'Format tanggal',
  'businessFlow.date_format_desc': 'Tampilan tanggal di aplikasi Kledo',
  'businessFlow.time_format': 'Format waktu',
  'businessFlow.time_format_desc': 'Tampilan jam dan menit di aplikasi Kledo',
  'businessFlow.number_after_comma': 'Jumlah angka di belakang koma',
  'businessFlow.number_after_comma_desc':
    'Berapa jumlah angka di belakang koma yang ditampilkan di aplikasi Kledo?',
  'businessFlow.currency_format': 'Format mata uang',
  'businessFlow.currency_format_desc': 'Tampilan mata uang di aplikasi Kledo',
  'businessFlow.activate_multi_currency': 'Aktifkan fitur multi mata uang',
  'businessFlow.activate_multi_currency_desc':
    'Apakah perusahaan Anda menggunakan lebih dari satu mata uang?',
  'businessFlow.base_currency': 'Mata uang dasar',
  'businessFlow.base_currency_desc':
    'Mata uang yang digunakan sebagai mata uang utama di perusahaan Anda',
  'businessFlow.show_coa': 'Tampilkan COA',
  'businessFlow.show_coa_desc': 'Tampilkan COA di laporan laba rugi',
  'businessFlow.show_currency_symbol': 'Tampilkan simbol mata uang',
  'businessFlow.show_currency_symbol_desc':
    'Apakah simbol mata uang akan ditampilkan di tampilan antar muka?',
  'businessFlow.show_reference': 'Tampilkan reference',
  'businessFlow.show_reference_desc': 'Apakah input reference ditampilkan saat membuat invoice?',
  'businessFlow.show_tag': 'Tampilkan tag',
  'businessFlow.show_tag_desc': 'Apakah input tag ditampilkan saat membuat invoice?',
  'businessFlow.input_tag_is_required': 'Input tag wajib diisi',
  'businessFlow.input_tag_is_required_desc':
    'Apakah tag wajib diiisi saat menambahkan transaksi penjualan?',
  'businessFlow.show_delivery_info': 'Tampilkan informasi pengiriman',
  'businessFlow.show_delivery_info_desc':
    'Apakah input informasi pengiriman ditampilkan saat membuat invoice?',
  'businessFlow.lock_price_from_quote': 'Kunci harga dari penawaran',
  'businessFlow.lock_price_from_quote_desc':
    'Apakah saat membuat tagihan dari penawaran, harganya dibuat tidak bisa diubah?',
  'businessFlow.lock_price_from_purchase_quote': 'Kunci harga dari penawaran pembelian',
  'businessFlow.lock_price_from_purchase_quote_desc':
    'Apakah saat membuat tagihan pembelian dari penawaran, harganya dibuat tidak bisa diubah?',
  'businessFlow.use_fifo_method': 'Gunakan metode FIFO untuk perhitungan biaya inventori',
  'businessFlow.use_fifo_method_desc':
    'Metode yang digunakan untuk menghitung HPP. Jika aktif akan menggunakan metode FIFO, jika tidak aktif akan menggunakan metode Averaging. Pengaturan ini tidak dapat diubah jika telah terdapat produk yang aktif lacak stok nya. Pengaturan hanya tersedia di paket Elite.',
  'businessFlow.auto_update_product_buy_price': 'Auto update harga beli produk',
  'businessFlow.auto_update_product_buy_price_desc':
    'Saat melakukan pembelian produk, otomatis harga beli-nya dicatatkan ke produk sebagai harga beli default',
  'businessFlow.auto_update_product_sell_price': 'Auto update harga jual produk',
  'businessFlow.auto_update_product_sell_price_desc':
    'Saat melakukan penjualan produk, otomatis harga jual-nya dicatatkan ke produk sebagai harga jual default',
  'businessFlow.reference_multiline': 'Multiline reference',
  'businessFlow.reference_multiline_desc': 'Apakah reference bisa banyak baris?',
  'businessFlow.show_due_date': 'Tampilkan jatuh tempo',
  'businessFlow.is_the_due_date_shown_on_the_purchase_invoice_form':
    'Apakah tanggal jatuh tempo ditampilkan di form tagihan pembelian ?',
  'businessFlow.is_the_due_date_shown_on_the_invoice_form':
    'Apakah tanggal jatuh tempo ditampilkan di form tagihan ?',
  'businessFlow.show_witholding': 'Tampilkan pemotongan',
  'businessFlow.show_witholding_desc': 'Apakah pemotongan ditampilkan saat membuat tagihan?',
  'businessFlow.show_sequence': 'Tampilkan nomor urut',
  'businessFlow.show_sequence_desc': 'Apakah tampilkan kolom urutan item transaksi?',
  'businessFlow.auto_update_selling_price_from_orders_and_quotes':
    'Auto update harga jual dari pemesanan & penawaran',
  'businessFlow.auto_update_selling_price_from_orders_and_quotes_desc':
    'Jika aktif maka harga produk akan diupdate jika ada pemesanan atau penawaran. Jika tidak aktif maka hanya update harga saat di tagihan saja.',
  'businessFlow.auto_update_purchase_price_from_purchases_orders_and_purchases_quotes':
    'Auto update harga beli dari pembelian pemesanan & pembelian penawaran',
  'businessFlow.auto_update_purchase_price_from_purchases_orders_and_purchases_quotes_desc':
    'Jika aktif maka harga produk akan diupdate jika ada pembelian pemesanan atau pembelian penawaran. Jika tidak aktif maka hanya update harga saat di tagihan pembelian saja.',
  'businessFlow.default_message': 'Gunakan pesan yang sama dengan transaksi asal',
  'businessFlow.default_message_desc':
    'Saat membuat tagihan dari pemesanan, maka isi pesan di tagihan otomatis disamakan dengan pesan di pemesanan.',
  'businessFlow.default_message_delivery':
    'Gunakan pesan pengiriman yang sama dengan transaksi pemesanan',
  'businessFlow.default_message_delivery_desc':
    'Saat membuat pengiriman, maka isi pesan disamakan dengan transaksi pemesanan.',
  'businessFlow.delivery_items_default_zero':
    'Default kuantitas produk dari pemesanan ke pengiriman',
  'businessFlow.delivery_items_default_zero_desc':
    'Kuantitas produk akan diubah ke 0 ketika membuat pengiriman dari pemesanan',
  'businessFlow.input_tax_is_required': 'Input pajak wajib diisi',
  'businessFlow.input_tax_is_required_desc': 'Apakah input pajak wajib diisi saat membuat invoice?',
  'businessFlow.invoice_create_from_shipment_only':
    'Tagihan hanya bisa dibuat dari transaksi pengiriman',
  'businessFlow.invoice_create_from_shipment_only_desc':
    'Apakah untuk membuat tagihan, diwajibkan membuat transaksi pengirimannya terlebih dahulu?',
  'businessFlow.order_create_from_quotation_only':
    'Pemesanan hanya bisa dibuat dari transaksi penawaran',
  'businessFlow.order_create_from_quotation_only_desc':
    'Apakah untuk membuat pemesanan, diwajibkan membuat transaksi penawarannya terlebih dahulu?',
  'businessFlow.invoice_profit_margin_not_minus': 'Tolak tagihan baru jika margin laba minus',
  'businessFlow.invoice_profit_margin_not_minus_desc':
    'Tolak penjualan jika produk dijual dengan margin laba dibawah 0',
  'businessFlow.purchase_invoice_create_from_shipment_only':
    'Tagihan pembelian hanya bisa dibuat dari transaksi pengiriman pembelian',
  'businessFlow.purchase_invoice_create_from_shipment_only_desc':
    'Apakah untuk membuat tagihan pembelian, diwajibkan membuat transaksi pengiriman pembeliannya terlebih dahulu?',
  'businessFlow.purchase_order_create_from_quotation_only':
    'Pemesanan pembelian hanya bisa dibuat dari transaksi penawaran pembelian',
  'businessFlow.purchase_order_create_from_quotation_only_desc':
    'Apakah untuk membuat pemesanan pembelian, diwajibkan membuat transaksi penawaran pembeliannya terlebih dahulu?',

  'salesPerson.sales_person': 'Sales Person',
  'salesPerson.show_sales_person': 'Tampilkan Sales Person',
  'salesPerson.hide_sales_person': 'Sembunyikan Sales Person',
  'salesPerson.select_sales_person': 'Pilih sales person',
  'salesPerson.sales_name': 'Nama Sales',
  'salesPerson.sales_amount': 'Jumlah Penjualan',
  'salesPerson.sales_value': 'Nilai Penjualan',
  'salesPerson.sales_total': 'Total Penjualan',
  'salesPerson.customer': 'Pelanggan',
  'salesPerson.sales_incentive': 'Insentif Sales',
  'salesPerson.sales_commission': 'Komisi Sales',
  'salesPerson.manage_sales_commission': 'Atur Komisi Sales',
  'salesPerson.commission_rule': 'Aturan Komisi',
  'salesPerson.faq_question_commission_calculated':
    'Bagaimana perhitungan komisi pada laporan ini?',
  'salesPerson.faq_answer_commission_calculated':
    'Komisi dihitung dari total tagihan setelah didiskon. Komponen pajak dan ongkos kirim tidak digunakan untuk menghitung komisi.',
  'salesPerson.faq_question_give_different_commission':
    'Bagaimana untuk memberikan komisi yang berbeda untuk masing-masing sales?',
  'salesPerson.faq_answer_give_different_commission_menu_here': 'menu disini',
  'salesPerson.faq_question_give_commission_progressively':
    'Bagaimana untuk memberikan komisi secara progresif, semakin banyak penjualan maka komisi semakin besar?',
  'salesPerson.faq_answer_give_commission_progressively':
    'Anda dapat mengatur komisi bertingkat dari pengaturan, silakan {here}.',

  'massEmail.mass_email': 'Kirim Email Massal',
  'massEmail.cannot_mass_email': 'Tidak dapat kirim email massal',
  'massEmail.can_be_sent': 'Dapat dikirim',
  'massEmail.cannot_be_sent': 'Tidak dapat dikirim',
  'massEmail.are_you_sure': 'Apakah Anda yakin akan mengirim {count} email {title}?',
  'massEmail.data_progress_sent': '{count} email dari total {total} telah dikirim',
  'massEmail.data_has_been_sent': 'Total {count} email telah berhasil dikirim',

  'massDelete.mass_delete': 'Hapus Massal',
  'massDelete.cannot_mass_delete': 'Tidak dapat menghapus massal',
  'massDelete.can_be_deleted': 'Bisa dihapus',
  'massDelete.cannot_be_deleted': 'Tidak bisa dihapus',
  'massDelete.archived': 'Diarsipkan',
  'massDelete.are_you_sure': 'Apakah Anda yakin akan menghapus {count} {title}?',
  'massDelete.are_you_sure_archive':
    'Apakah Anda yakin akan menghapus/mengarsipkan {count} {title}?',
  'massDelete.deleted_data_is_irreversible': 'Data yang dihapus tidak dapat dikembalikan',
  'massDelete.data_progress_deleted': '{count} data dari total {total} telah terhapus',
  'massDelete.data_has_been_deleted': 'Total {count} data telah berhasil dihapus',
  'massDelete.transaction_before_lock_date_and_reconciled_cannot_be_deleted':
    '**Transaksi sebelum tanggal penguncian dan transaksi yang sudah direkonsiliasi tidak dapat dihapus',
  'massDelete.transaction_before_lock_date_reconciled_and_related_cannot_be_deleted':
    '**Transaksi sebelum tanggal penguncian, transaksi yang telah direkonsiliasi, dan transaksi yang terkait dengan transaksi lainnya tidak dapat dihapus',

  'massPrint.cannot_mass_print': 'Tidak dapat mencetak massal',

  'massApproval.mass_approve': 'Approve Massal',
  'massApproval.mass_reject': 'Tolak Massal',
  'massApproval.cannot_mass_approve': 'Tidak dapat approve massal',
  'massApproval.cannot_mass_reject': 'Tidak dapat tolak massal',
  'massApproval.can_be_approved': 'Bisa approve',
  'massApproval.can_be_rejected': 'Bisa ditolak',
  'massApproval.are_you_sure_will_approved':
    'Apakah Anda yakin akan approve {count} transaksi ini?',
  'massApproval.are_you_sure_will_rejected':
    'Apakah Anda yakin akan menolak {count} transaksi ini?',
  'massApproval.approved_data_is_irreversible':
    'Data yang telah di approve tidak dapat dikembalikan',
  'massApproval.rejected_data_is_irreversible': 'Data yang telah ditolak tidak dapat dikembalikan',
  'massApproval.data_progress_approved': '{count} data dari total {total} telah approve',
  'massApproval.data_progress_rejected': '{count} data dari total {total} telah ditolak',
  'massApproval.data_has_been_approved': 'Total {count} data telah berhasil di approve',
  'massApproval.data_has_been_rejected': 'Total {count} data telah berhasil ditolak',

  'onBoarding.greeting': 'Hallo {name},',
  'onBoarding.welcome_to_kledo': 'Selamat datang di Kledo!',
  'onBoarding.setup_kledo_account': 'Yuk kita setup dulu akun Kledo nya :)',
  'onBoarding.start_to_setup': 'Mulai setup',
  'onBoarding.inventory_question':
    'Apakah Anda ingin mengatur stok dan inventori produk yang dijual ?',
  'onBoarding.quote_question': 'Apakah Anda memerlukan surat penawaran ke pelanggan ?',
  'onBoarding.order_question': 'Apakah Anda melakukan purchase order ke supplier ?',
  'onBoarding.delivery_question': 'Apakah Anda memerlukan surat jalan ?',
  'onBoarding.horee': 'Horee!',
  'onBoarding.checklist_greeting': 'Yuk mulai menggunakan Kledo',
  'onBoarding.setup_complete': 'Setup selesai, sekarang kamu bisa mulai menggunakan Kledo.',
  'onBoarding.go_to_dashboard': 'Masuk ke Dashboard',
  'onBoarding.cannot_access_onboarding': 'Tidak dapat mengakses onboarding',
  'onBoarding.next_step': 'Selanjutnya',
  'onBoarding.prev_step': 'Sebelumnya',
  'onBoarding.step_question': 'Pertanyaan - {step}',
  'onBoarding.company_location_question': 'Dimana lokasi perusahaan {company}?',
  'onBoarding.company_location_question_province': 'Provinsi wajib diisi!',
  'onBoarding.company_location_question_city': 'Kota wajib diisi',
  'onBoarding.role_question': 'Sebagai apakah anda di perusahaan {company}?',
  'onBoarding.role_question_error':
    'Silakan pilih peran atau masukkan peran khusus sebelum melanjutkan',
  'onBoarding.employee_question': 'Berapakah jumlah karyawan di perusahaan Anda?',

  'recurring.add': 'Tambah {title} Berulang',
  'recurring.edit': 'Ubah {title} Berulang',
  'recurring.add_invoice': 'Tambah tagihan berulang',
  'recurring.edit_invoice': 'Ubah tagihan berulang',
  'recurring.add_order': 'Tambah pemesanan berulang',
  'recurring.edit_order': 'Ubah pemesanan berulang',
  'recurring.add_purchaseinvoice': 'Tambah tagihan pembelian berulang',
  'recurring.edit_purchaseinvoice': 'Ubah tagihan pembelian berulang',
  'recurring.add_purchaseorder': 'Tambah pemesanan pembelian berulang',
  'recurring.edit_purchaseorder': 'Ubah pemesanan pembelian berulang',
  'recurring.add_expense': 'Tambah biaya berulang',
  'recurring.edit_expense': 'Ubah biaya berulang',
  'recurring.add_manualjournal': 'Tambah jurnal manual berulang',
  'recurring.edit_manualjournal': 'Ubah jurnal manual berulang',
  'recurring.add_cashbank': 'Tambah {title} berulang',
  'recurring.edit_cashbank': 'Edit kas & bank berulang',
  'recurring.recurring_transaction_periode': 'Ulang transaksi ini setiap',
  'recurring.start_date': 'Tanggal mulai',
  'recurring.end_date': 'Tanggal selesai (Optional)',
  'recurring.end_date_column': 'Tanggal selesai',
  'recurring.next_date_transaction': 'Tanggal transaksi berikutnya',
  'recurring.automatic_send_email_on_created_transaction':
    'Kirim email ke customer setiap transaksi dibuat',
  'recurring.day': 'Hari',
  'recurring.week': 'Minggu',
  'recurring.month': 'Bulan',
  'recurring.please_input_the_recurring': 'Silahkan isi jumlah pengulangan',
  'recurring.please_select_start_date': 'Silahkan pilih tanggal mulai',
  'recurring.please_select_end_date': 'Silahkan pilih tanggal selesai',
  'recurring.title': 'Transaksi Berulang {name}',
  'recurring.cannot_select_before_start_date': 'Tidak dapat memilih sebelum tanggal mulai',
  'recurring.recurring': 'Transaksi Berulang',
  'recurring.number': 'Nomor',
  'recurring.repeat_every': 'Ulang Setiap',
  'recurring.next_date': 'Transaksi Berikutnya',
  'recurring.total': 'Total',
  'recurring.error_load_data': 'Error saat mengambil data',
  'recurring.error_cannot_recurring': 'Error tidak dapat membuat transaksi berulang',
  'recurring.error_cannot_delete': 'Error tidak dapat membuat transaksi berulang',
  'recurring.error_load_detail': 'Error saat mengambil data detail',
  'recurring.new_transaction': 'Transaksi Berulang Baru',
  'recurring.cannot_select_before_current_date': 'Tidak dapat memilih sebelum tanggal sekarang',
  'recurring.repeat': 'Berulang',

  'tutorial.video_tutorial': 'Tutorial Video',

  'report.the_total_number_of_bills_issued_during_this_period':
    'Total jumlah tagihan yang diterbitkan pada periode ini.',
  'report.average_value_of_invoices_issued_during_this_period':
    'Rata-rata nilai tagihan yang diterbitkan pada periode ini.',
  'report.gross_profit_margin_is_the_ratio_of_gross_profit_to_total_revenue':
    'Margin laba kotor merupakan rasio laba kotor terhadap total pendapatan.',
  'report.gross_profit_margin_is_the_ratio_of_net_income_to_total_revenue':
    'Margin laba kotor merupakan rasio dari laba bersih terhadap total pendapatan.',
  'report.roi_is_the_ratio_of_net_income_to_net_assets_per_year':
    'ROI merupakan rasio laba bersih terhadap aset bersih per tahun. Dimana aset bersih merupakan total aset dikurangi liabilitas.<br/>ROI = (Net profit / Net asset) x 12 bulan x 100%',
  'report.the_average_number_of_days_it_takes_your_customer_to_pay_the_bill':
    'Rata-rata jumlah hari yang dibutuhkan pelanggan Anda untuk membayar tagihan.',
  'report.the_average_number_of_days_it_take_you_to_pay_a_bill_to_a_vendor':
    'Rata-rata jumlah hari yang Anda butuhkan untuk membayar tagihan kepada vendor.',
  'report.is_the_percentage_of_liabilities_divided_by_equity':
    'Merupakan persentase liabilitas dibagi ekuitas.',
  'report.is_the_percentage_of_current_assets_divided_by_labilities':
    'Merupakan persentase aset lancar dibagi liabilitas.',
  'report.percentage': 'Persentase',
  'invoicePaymentCreditMemo.invoicePaymentCreditMemo': 'Pembayaran Tagihan dengan Hutang',
  'purchaseInvoicePaymentDebitMemo.purchaseInvoicePaymentDebitMemo':
    'Pembayaran Tagihan Pembelian dengan Piutang',

  'unrealizedGain.unrealizedGain': 'Laba/Rugi Belum Terealisasi',
  'financeProduct.profit_margin': 'Margin laba',
  'financeProduct.average_purchase_price': 'Harga beli rata-rata',
  'financeProduct.last_purchase_price': 'Harga beli terakhir',
  'financeProduct.this_customer_selling_price': 'Harga jual pelanggan ini',
  'financeProduct.last_sell_price': 'Harga jual terakhir',
  'financeProduct.insufficient_payment': 'Kurang Bayar',
  'settings.menu_cannot_be_found': 'Menu Pengaturan Yang Anda Cari Tidak Ditemukan',
  'report.avg': 'Rata-Rata',
  'reports.stock_adjustment': 'Laporan Penyesuaian Stok',
  'bussinesFlow.default_tracking_stock': 'Default lacak stok',
  'bussinesFlow.desc_default_tracking_stock':
    'Secara default fitur lacak stock aktif saat membuat produk baru',
  'settings.reset': 'Reset',
  'financeContacts.taxable': 'Kena pajak',
  'financeContacts.not_taxable': 'Tidak Kena Pajak',
  'tooltip.next': '{title} Selanjutnya',
  'tooltip.prev': '{title} Sebelumnya',
  'financeBanks.bank_transaction': 'Transaksi Bank',
  'picker.select_year': 'Pilih tahun',
  'picker.select_month': 'Pilih bulan',
  'picker.select_date': 'Pilih tanggal',
  'chart.from_param_to_param': 'dari {from} s/d {to}',

  'gajihub.hash_tag': '#jadilebihbaik, mudah, dan praktis.',
  'gajihub.head_title_1': 'Proses',
  'gajihub.head_title_2': 'Payroll',
  'gajihub.head_title_3': 'Absensi',
  'gajihub.head_title_4': 'dan',
  'gajihub.head_title_5': 'Akuntansi',
  'gajihub.head_title_6': 'Bisnis Anda Kini Saling Terintegrasi',
  'gajihub.head_desc_1': 'Kini Anda bisa melakukan',
  'gajihub.head_desc_2': 'penghitungan',
  'gajihub.head_desc_3': 'pencatatan gaji',
  'gajihub.head_desc_4': 'tunjangan',
  'gajihub.head_desc_5': 'sampai',
  'gajihub.head_desc_6': 'pajak',
  'gajihub.head_desc_7':
    'seluruh karyawan secara otomatis dalam pembukuan perusahaan dengan integrasi GajiHub dan Kledo.',
  'gajihub.integrate': 'Integrasikan Sekarang',
  'gajihub.footer_note':
    'Dapatkan solusi manajemen SDM dan kemudahan pengelolaan akuntansi perusahaan yang saling terintegrasi secara sempurna.',
  'gajihub.feat_title_1': 'Apa yang Akan',
  'gajihub.feat_title_2': 'Anda Dapatkan',
  'gajihub.feat_title_3': 'Dari Integrasi GajiHub dan Kledo?',
  'gajihub.feat_1_sub_title': 'Integrasi sistem payroll dan akuntansi',
  'gajihub.feat_1_description':
    'Proses pencatatan gaji dan seluruh tunjangan seluruh karyawan bisa tercatat pada sistem pembukuan perusahaan Anda secara otomatis dalam satu platform terintegrasi.',
  'gajihub.feat_2_sub_title': 'Penghitungan PPh 21 otomatis',
  'gajihub.feat_2_description':
    'Tidak perlu pusing menghitung pajak penghasilan seluruh karyawan. Dengan integrasi ini, Kledo akan secara otomatis mencatat PPh 21 karyawan sesuai data yang tersimpan di GajiHub.',
  'gajihub.feat_3_sub_title': 'Melakukan pencatatan presensi melalui smartphone',
  'gajihub.feat_3_description':
    'Seluruh karyawan Anda juga akan mendapatkan kemudahan dalam melakukan presensi kehadiran dan pengajuan cuti dari smartphone melalui aplikasi ESS GajiHub.',
  'gajihub.feat_4_sub_title': 'Pencatatan kasbon dan reimburse karyawan langsung ke pembukuan',
  'gajihub.feat_4_description':
    'Anda dapat mencatat semua nilai kasbon dan reimburse karyawan dalam sistem GajiHub. Kemudian, data tersebut akan otomatis tercatat dalam sistem akuntansi Kledo.',
  'gajihub.feat_5_sub_title': 'Buat dan kirim slip gaji karyawan real time',
  'gajihub.feat_5_description':
    'Buat slip gaji hitungan detik dan kirim langsung ke seluruh karyawan Anda. Slip gaji ini juga bisa diakses melalui aplikasi ESS GajiHub di smartphone karyawan Anda.',
  'gajihub.feat_6_sub_title': 'Iuran BPJS Ketenagakerjaan tercatat otomatis',
  'gajihub.feat_6_description':
    'Anda bisa mengelola BPJS Ketenagakerjaan dan Kesehatan seluruh karyawan Anda di GajiHub. Nantinya, pembayaran iuran akan otomatis tercatat di sistem akuntansi Kledo.',
  'payroll.goto_gajihub': 'Pergi ke GajiHub',
  'payroll.integrate_with_gajihub': 'Integrasikan dengan GajiHub',
  'payroll.payroll': 'Payroll',
  'payroll.perform_employee_payroll_quickly_and_neatly':
    'Melakukan penggajian karyawan dengan cepat dan rapi.',
  'payroll.employee_payroll_is_automatically_recorded_in_kledo_accounting_software':
    'Penggajian karyawan otomatis tercatat pada software akuntansi Kledo.',
  'payroll.automatically_calculating_PPH_21_Tax': 'Otomatis penghitungan Pajak PPH 21.',
  'payroll.automatic_employee_BPJS_calculation': 'Perhitungan BPJS karyawan otomatis.',
  'payroll.employee_attendance_via_mobile_app': 'Absensi karyawan via mobile app.',
  'payroll.application_for_leave_and_permission': 'Pengajuan cuti dan izin.',
  'payroll.employee_cash_account': 'Kasbon Karyawan.',
  'payroll.employee_reimbursement': 'Reimburse Karyawan.',
  'payroll.and_many_more': 'Dan masih banyak lagi.',
  'payroll.click_the_button_below_to_start_the_integration':
    'Klik tombol dibawah untuk memulai integrasi.',
  'payroll.what_benefits_will_you_get_with_this_integration':
    'Apa saja keuntungan yang akan kamu dapatkan dengan integrasi ini ?',
  'payroll.this_feature_is_avilable_for_free': 'Fitur ini tersedia gratis lho.',
  'payroll.kledo_is_now_automatically_integrated_with_gajihub_payroll_software':
    'Kledo kini terintegrasi secara otomatis dengan Software Payroll GajiHub.',
  'payroll.please_wait': 'Mohon tunggu ya!',
  'payroll.integrating_kledo_with_gajihub': 'Sedang mengintegrasikan Kledo dengan GajiHub...',
  'payroll.integrating_success_1': 'Horee!, Akun',
  'payroll.integrating_success_2': 'Kledo',
  'payroll.integrating_success_3': 'sudah berhasil dikoneksikan dengan Software Payroll',
  'payroll.integrating_success_4': 'GajiHub.',

  'companies.success_message_title': 'Perusahaanmu berhasil dibuat.',
  'companies.success_message_subtitle': 'Silahkan klik tombol di bawah ini untuk melanjutkan.',
  'companies.go_to_dashboard': 'Pergi ke dashboard',
  'message.sorry_you_are_not_authorized_to_access_this_page':
    'Maaf, Anda tidak mempunya akses untuk halaman ini.',
  'message.you_have_some_unsaved_transaction_entries':
    'Anda mempunyai {countTransaction} draft isian transaksi yang belum disimpan, {clickHere} untuk melihat.',
  'message.click_here': 'klik disini',
  'message.click_here_2': 'Klik disini',
  'message.image_is_being_uploaded': 'Gambar sedang diunggah...',
  'message.for_the_detail': 'untuk penjelasan detail.',

  'emailTemplate.invoice_due_reminder_email': 'Email Pengingat {transType} Jatuh Tempo',
  'emailTemplate.send_email_to_customers_who_have_invoices_due':
    'Kirim email ke pelanggan yang mempunyai {transType} jatuh tempo',
  'emailTemplate.send_email': 'Kirim email',
  'emailTemplate.days_before_due': 'hari sebelum jatuh tempo',
  'emailTemplate.days_after_due': 'hari setelah jatuh tempo',
  'emailTemplate.send_a_due_reminder_email_to_the_group':
    'Kirim email pengingat jatuh tempo ke group kontak pelanggan sebagai berikut',
  'emailTemplate.send_reminder': 'Kirim Pengingat',

  'report.stock_tracking_product': 'Produk Lacak Stok',
  'report.non_stock_tracking_product': 'Produk Tanpa Lacak Stok',
  'report.package_product': 'Produk Paket',

  'return.product_not_found_in_this_transaction': 'Product tidak ditemukan di transaksi ini',
  'return.maximum_qty_that_can_be_returned': 'Jumlah maksimal yang dapat di retur {max}',
  'return.the_product_has_met_the_maximum_return_limit':
    'Produk telah memenuhi batas pengembalian maksimum',
  'financeWareHouses.the_product_is_already_on_the_list': 'Produk sudah ada di daftar',
  'report.minimum_stock': 'Stok Minimal',
  'report.control_stock': 'Stok Kontrol',
  'report.control_stock_tooltip': 'Selisih dari stok akhir dan stok minimal',

  'businessFlow.reject_the_transaction_if_the_balance_is_insufficient':
    'Tolak transaksi jika kurang saldo',
  'businessFlow.reject_the_transaction_if_the_balance_is_insufficient_desc':
    'Tolak tambah transaksi kas dan bank jika saldo kas dan bank tidak mencukupi',

  'settings.finance_approval_setting': 'Atur alur approval transaksi',
  'businessFlow.journal_desc_include_contact': 'Tampilkan kontak pada deskripsi jurnal',
  'businessFlow.journal_desc_include_contact_desc':
    'Apakah nama kontak akan ditampilkan di deskripsi jurnal?',
  'financeProduct.mass_unarchive_info':
    '**Hanya produk yang berstatus arsip yang dapat dibatalkan pengarsipannya.',
  'financeProduct.mass_unarchive': 'Batalkan Archive Massal',
  'financeProduct.can_be_unarchive': 'Bisa diunarchive',
  'financeProduct.cannot_be_unarchive': 'Tidak bisa diunarchive',
  'financeProduct.mass_unarchive_confirm_message':
    'Apakah Anda yakin akan mengunarchivekan {count} produk ?',
  'massDelete.cannot_mass_unarchive': 'Tidak dapat mengunarchivekan massal',
  'massDelete.data_progress_unarchived': '{count} data dari total {total} telah diunarchive',
  'massDelete.data_has_been_unarchived': 'Total {count} data telah berhasil diunarchive',
  'financeContacts.salutation': 'Sapaan',
  'financeContacts.choose_salutation': 'Pilih sapaan',
  'product.the_maximum_number_of_uploads_is_5_photos': 'Jumlah upload maksimal 5 gambar',
  'reports.profitloss_hide_sub_account': 'Sembunyikan Sub Akun',
  'reports.account_view': 'Tampilan Akun',
  'reports.change_account_view_loading': 'Mengubah format tampilan akun...',
  'reports.change_account_view_success': 'Format tampilan akun berhasil diubah',
  'reports.format_account_profit_loss_1': 'Kode - Nama Akun',
  'reports.format_account_profit_loss_2': '(Kode) Nama Akun',
  'reports.format_account_profit_loss_3': 'Nama Akun - Kode',
  'reports.format_account_profit_loss_4': 'Nama Akun (Kode)',
  'reports.format_account_profit_loss_5': 'Nama Akun',
  'reports.separate_others_income_and_expenses': 'Pisah Pendapatan & Biaya Lain-Lain',
  'financeWarehouse.qty_per_unit': 'Qty per Satuan',

  'faqs.whats_your_question': 'Apa Pertanyaan Anda?',
  'faqs.most_popular': 'Terpopuler',
  'faqs.most_help': 'Paling Membantu',
  'faqs.search_result': 'Hasil Pencarian {value}',
  'faqs.title_by_category': 'Berdasarkan Kategori {value}',
  'faqs.title_by_tag': 'Berdasarkan Tag {value}',
  'faqs.was_this_answer_helpful': 'Apakah jawaban ini membantu?',
  'faqs.last_update': 'Update Terakhir',
  'faqs.copy_raw_text': 'FAQ berhasil disalin',
  'financeProduct.total_cost_summary': 'Total Biaya',
  'financeWareHouses.production_warehouse': 'Gudang produk hasil',
  'financeWareHouses.raw_material_warehouse_is_the_same_as_product_warehouse':
    'Gudang bahan baku sama dengan gudang produk hasil',
  'conversionProduct.avg_price': 'HPP',
  'financeProduct.product_conversion_cost_per_unit_help_text':
    'Total biaya produk untuk setiap unitnya (Biaya bahan baku + Biaya produksi)',
  'layoutInvoice.show_the_print_delivery_on_the_invoice_with_title':
    'Tampilkan surat jalan di {title}',
  'queue.data_is_waiting_to_be_processed': '{value} data sedang menunggu diproses...',
  'billing.using_the_invoicing_feature': 'Penggunaan fitur tagihan',
  'billing.this_month': 'Bulan ini',
  'billing.last_month': 'Bulan lalu',
  'businessFlow.reject_warehouse_transfer_if_stock_is_less_with_title':
    'Tolak {title} Jika Stok Kurang',
  'businessFlow.reject_warehouse_transfer_if_stock_is_less_desc_with_title':
    'Jika ya, maka {title} akan ditolak jika stok kurang',

  'financeProduct.products_with_stock_tracking_with_title': '{title} dengan lacak stok',
  'financeFixedAssets.run_automatic_deprecation': 'Jalankan Penyusutan Sekarang',
  'financeFixedAssets.automatic_deprecation': 'Penyusutan Otomatis',
  'financeFixedAssets.automatic_depreciation_every_month': 'Penyusutan otomatis setiap bulan',
  'financeFixedAssets.automatic_depreciation_every_month_desc_with_title':
    'Jika fitur ini aktif, maka {title} akan otomatis disusutkan pada tanggal 1 setiap bulannya.',
  'financeProduct.regular_product_with_title': '{title} reguler',
  'financeProduct.package_product_with_title': '{title} paket',
  'financeProduct.manufacturing_product_with_title': '{title} manufaktur',
  'button.void': 'Void',
  'button.unvoid': 'Unvoid',

  'financeBanks.spend_money_and_receive_money': 'Kirim Dana dan Terima Dana',
  'financeBanks.receive_transfers': 'Terima Transfer',
  'financeBanks.send_transfers': 'Kirim Transfer',
  'financeBanks.expense_payment': 'Pembayaran Biaya',
  'financeBanks.invoice_payment': 'Pembayaran Tagihan',
  'financeBanks.purchase_invoice_payment': 'Pembayaran Tagihan Pembelian',
  'financeBanks.purchase_order_payment': 'Pembayaran Pemesanan Pembelian',
  'financeBanks.credit_memo_payment': 'Pembayaran Hutang',
  'financeBanks.debit_memo_payment': 'Pembayaran Piutang',
  'financeBanks.fixed_asset_payment': 'Pembayaran Aset Tetap',
  'financeBanks.sales_shipping_costs': 'Biaya Pengiriman Penjualan',
  'financeBanks.purchase_shipping_costs': 'Biaya Pengiriman Pembelian',
  'financeBanks.invoice_withholding': 'Pemotongan Penjualan',
  'financeBanks.purchase_invoice_withholding': 'Pemotongan Pembelian',
  'financeBanks.reversal': 'Reversal',
  'financeProduct.product_list_with_title': 'Daftar {title}',
  'financeProduct.product_detail_with_title': 'Detil {title}',
  'financeProduct.print_a_number': 'Print sejumlah',
  'layoutBarcode.label_size': 'Ukuran kertas',
  'layoutBarcode.number_of_columns': 'Jumlah Kolom',
  'layoutBarcode.show_product_name': 'Tampilkan nama produk',
  'layoutBarcode.barcode_show_text': 'Tampilkan angka barcode',
  'layoutBarcode.barcode_show_product_price': 'Tampilkan harga',
  'layoutBarcode.click_here_to_reset_layout':
    'Klik di sini untuk mengembalikan layout barcode ke default',
  'settings.layout_barcode': 'Atur tampilan print barcode',
  'financeContacts.date_of_birth': 'Tanggal Lahir',
  'financeInvoices.witholding_total': 'Total Pemotongan',
  'financeContacts.total_from_value': 'Total dari {value}',

  'pos.choose_product_favorites_with_title':
    'Pilih {title} yang akan ditampilkan dihalaman depan POS',
  'pos.page_note':
    'Halaman ini untuk mengatur produk yang dijadikan favorit di POS. Jika Anda ingin menambah produk baru, silahkan {clickHere}!',
  'pos.add_product_favorite_with_title': 'Tambah {title} Favorit',
  'pos.save_to_favorite': 'Simpan ke Dalam Favorit',
  'pos.choose_product_to_become_favorite': 'Pilih {title} untuk dijadikan favorit',
  'pos.choose_product_to_become_favorite_with_title': 'Pilih {title} untuk dijadikan favorit',
  'pos.add_outlet': 'Tambah Outlet',
  'pos.edit_outlet': 'Ubah Outlet',
  'pos.name_is_required': 'Nama wajib diisi',
  'pos.name_at_least_3_characters': 'Nama minimal 3 karakter',
  'pos.code_is_required': 'Kode wajib diisi',
  'pos.code_at_least_3_characters': 'Kode minimal 3 karakter',
  'pos.not_yet_connected_to_the_warehouse': 'Belum terhubung ke gudang',
  'pos.connect_now': 'Hubungkan sekarang!',
  'pos.connect_outlets_and_warehouses': 'Hubungkan outlet dan gudang',
  'kledoPos.hash_tag': '#jadilebihbaik, mudah, dan praktis.',
  'kledoPos.head_title_1': 'Tetap Bisa Catat Transaksi Meski',
  'kledoPos.head_title_2': 'Internet Mati',
  'kledoPos.head_title_3': 'dengan Kledo POS',
  'kledoPos.head_title_description':
    'Aplikasi kasir yang terintegrasi secara sempurna dengan software akuntansi Kledo. Solusi terbaik untuk pencatatan transaksi dari berbagai outlet, toko, dan cabang usaha dalam satu platform.',
  'kledoPos.download': 'Download Kledo POS',
  'kledoPos.sales_contact': 'Hubungi Sales',
  'kledoPos.footer_note':
    'Catat setiap transaksi dan lakukan analisa keuangan bisnis lebih mudah menggunakan integrasi Kledo POS dan software akuntansi Kledo sekarang juga.',
  'kledoPos.feat_title_1': 'Apa yang Akan',
  'kledoPos.feat_title_2': 'Anda Dapatkan',
  'kledoPos.feat_title_3': 'Jika Menggunakan Kledo POS?',
  'kledoPos.feat_1_sub_title': 'Menerima berbagai jenis pembayaran',
  'kledoPos.feat_1_description':
    'Dengan Kledo POS, Anda bisa mencatat pembayaran dari berbagai metode pembayaran mulai dari pembayaran tunai, non tunai, e-wallet, dan dari mesin EDC.',
  'kledoPos.feat_2_sub_title': 'Aplikasi kasir yang mudah digunakan',
  'kledoPos.feat_2_description':
    'Kledo POS bisa Anda install di smartphone atau tablet Android dengan mudah dan bisa digunakan secara online dan offline.',
  'kledoPos.feat_3_sub_title': 'Pengaturan promo',
  'kledoPos.feat_3_description':
    'Buat promo yang memanjakan pelanggan Anda dan catat setiap transaksi yang menggunakan promo tersebut tanpa ada satupun yang terlewat.',
  'kledoPos.feat_4_sub_title': 'Implementasi gratis',
  'kledoPos.feat_4_description':
    'Sudah beli software POS mahal tapi masih harus bayar training? Di Kledo POS kami menjanjikan implementasi dan training gratis sampai Anda mahir.',
  'kledoPos.feat_5_sub_title': 'Profil pelanggan',
  'kledoPos.feat_5_description':
    'Data pelanggan merupakan hal penting yang harus dimiliki setiap bisnis. Dengan menggunakan Kledo POS, Anda bisa menyimpan semuanya dengan lebih praktis.',
  'kledoPos.feat_6_sub_title': 'Integrasi dengan sistem akuntansi',
  'kledoPos.feat_6_description':
    'Terintegrasi sempurna dengan ekosistem Kledo sehingga memudahkan Anda dalam mengelola data keuangan dalam satu platform yang optimal.',
  'kledoPos.setting': 'Pengaturan POS',
  'kledoPos.setting_custom_product': 'Custom Produk',
  'kledoPos.setting_custom_product_desc':
    'Anda dapat membuat transaksi di POS untuk produk yang belum tercatat di data, atau disebut custom produk. Untuk transaksi custom produk, sistem Kledo akan mencatatkannya sebagai penjualan untuk produk sesuai pada pengaturan ini.',
  'kledoPos.setting_default_customer': 'Default Pelanggan',
  'kledoPos.setting_default_customer_desc':
    'Anda dapat mencatatkan penjualan di POS dengan tanpa mencatatkan pelanggannya. Apabila transaksi tidak dicatatkan pelanggannya, maka sistem Kledo akan mencatatkannya sebagai transaksi milik `default Pelanggan` pada pengaturan ini.',
  'kledoPos.setting_product_placeholder': 'Pilih Produk',
  'kledoPos.setting_customer_placeholder': 'Pilih Pelanggan',
  'kledoPos.error_cannot_update': 'Gagal simpan pengaturan',
  'kledoPos.setting_default_outlet_placeholder': 'Pilih Outlet',
  'kledoPos.setting_default_spend_account': 'Default Akun Kirim Dana',
  'kledoPos.setting_default_spend_account_placeholder': 'Pilih Akun',
  'kledoPos.setting_default_spend_account_desc':
    'Saat mencatatkan keluar masuk kas di POS, maka di akunting akan dicatatkan pada akun COA ini.',
  'kledoPos.setting_default_receive_account': 'Default Akun Terima Dana',
  'billing.pos_add_outlet_label': 'Berapa Jumlah Outlet yang akan ditambahkan?',
  'billing.pos_current_quota': 'Kuota POS saat ini: {outlet} Outlet',
  'billing.pos_add_outlet': 'Tambah Outlet',
  'billing.pos_please_add_addon': 'Silakan tambah satu addon POS',
  'financeBanks.import_bank_transfer': 'Import Transfer Bank',
  'financeBanks.follow_these_steps_to_import_your_bank_transfer':
    'Ikuti langkah-langkah berikut untuk meng-import transfer bank',
  'financeBanks.1_download_your_bank_transfer_template_file':
    '1. Download file template transfer bank',
  'financeBanks.start_by_downloading_our_bank_transfer_csv':
    'Mulai dengan mengunduh file template dalam format Excel. File ini mempunyai kolom header yang yang dibutuhkan Kledo untuk mengimport detail dari transfer bank-mu.',
  'financeBanks.2_copy_your_bank_transfer_into_the_template':
    '2. Salin detail transfer bank ke dalam template',
  'financeBanks.bank_transfer_import_using_excel_or_another_spreadsheet_editor':
    'Menggunakan Excel atau software spreadsheeet lainnya, masukkan detail transfer bank ke dalam file template dari Kledo. Pastikan detail transfer bank sesuai dengan kolom header yang ada di template.',
  'financeBanks.a_maximum_of_500_bank_transfer_can_be_imported_at_a_time':
    'Maksimal 1.000 transfer bank dapat diimport dalam satu waktu.',
  'financeBanks.bank_transfer_import_from_account': 'Dari Rekening (Kode Akun)',
  'financeBanks.bank_transfer_import_to_account': 'Ke Rekening (Kode Akun)',
  'financeBanks.bank_transfer_import_transfer_amount': 'Jumlah Transfer',
  'financeBanks.in': 'Masuk',
  'financeBanks.out': 'Keluar',
  'financeAccounts.balance_per_category': 'Saldo Per Kategori',
  'financeAccounts.balance_per_account': 'Saldo Per Akun',
  'reports.show_graph': 'Tampilkan Grafik',
  'reports.hide_graph': 'Sembunyikan Grafik',
  'reports.number_of_transactions_per_source': 'Jumlah transaksi per sumber',
  'massRevertReconcile.mass_unreconciled': 'Batalkan Rekonsiliasi Massal',
  'massRevertReconcile.cannot_mass_unreconciled': 'Tidak dapat membatalkan rekonsiliasi',
  'massRevertReconcile.can_be_unreconciled': 'Bisa membatalkan rekonsiliasi',
  'massRevertReconcile.are_you_sure_want_to_unreconciled':
    'Apakah Anda yakin akan membatalkan {count} rekonsiliasi?',
  'massRevertReconcile.data_progress_unreconciled':
    '{count} data dari total {total} telah dibatalkan dari rekonsiliasi',
  'massRevertReconcile.data_has_been_unreconciled':
    'Total {count} data telah berhasil dibatalkan dari rekonsiliasi',
  'billing.downgrade_warning_title': 'Perhatian!!!',
  'billing.downgrade_warning_description_1':
    'Akun yang telah di downgrade tidak dapat dibatalkan. Diperlukan melakukan upgrade dan pembayaran untuk mengembalikan ke paket sebelumnya.',
  'billing.downgrade_warning_description_2':
    'Akun yang telah di downgrade, akan mengikuti harga paket terbaru dan batasan fitur terbaru.',
  'billing.downgrade_warning_description_3':
    'Akun yang lebih lama di Kledo, biasanya mendapatkan harga yang lebih murah, dan fitur yang lebih lengkap dibandingkan dengan akun baru di Kledo.',
  'billing.what_package_will_you_downgrade_to': 'Akan di downgrade ke paket apa?',
  'billing.downgrade_now': 'Downgrade Sekarang',
  'billing.button_confirm_title':
    'Aksi ini tidak dapat dibatalkan, dan Anda akan kehilangan benefit sebagai pemilik akun lama. Apakah Anda yakin untuk mendowngrade paket Anda?',
  'billing.button_downgrade_now': 'Ya, Downgrade Sekarang',
  'financeFixedAssets.asset_value': 'Nilai Aset',
  'financeFixedAssets.asset_depreciation': 'Depresiasi Aset',
  'financeFixedAssets.asset_disposal': 'Laba/Rugi Pelepasan Aset',
  'financeFixedAssets.new_assets': 'Aset Baru',
  'financeFixedAssets.depreciation_chart': 'Depresiasi',
  'financeFixedAssets.profit_and_loss_on_asset_disposal': 'Untung Rugi Pelepasan Aset',
  'financeFixedAssets.highest_value_assets': '{count} Nilai Asset Tertinggi',
  'financeFixedAssets.the_highest_acquisition_costs': '{count} Biaya Akuisisi Tertinggi',
  'financeFixedAssets.profit': 'Untung',
  'financeFixedAssets.loss': 'Rugi',
  'financeProduct.this_product_is_a_package_material_from_with_title':
    '{title} ini bahan paket dari',
  'financeProduct.this_product_is_raw_material_from_with_title': '{title} ini bahan baku dari',
  'settings.due_reminder': 'Kirim email ke pelanggan yang mempunyai tagihan jatuh tempo',
  'businessFlow.system': 'Sistem',
  'businessFlow.enable_white_label': 'Aktifkan whitelabel?',
  'businessFlow.enable_white_label_desc':
    'Jika whitelabel aktif, maka web app, konten whatsapp, dan konten email akan dihilangkan teks kledo pada bagian footernya.',
  'financeOrder.amount_unbilled': 'Nominal Belum Ditagih',
  'settings.download_description':
    'Anda dapat mengunduh database Kledo Anda untuk disimpan secara offline di komputer Anda. Data yang diunduh dalam format excel (.xls), dan diperlukan beberapa menit untuk menyiapkan file backupnya.',
  'settings.download_button': 'Mulai Siapkan File Backup Sekarang',
  'settings.please_wait_download_backup':
    'Mohon menunggu beberapa saat, file download database sedang disiapkan.',
  'settings.preparing_data': 'Menyiapkan data {dataName}',
  'settings.backup_auto_delete': '* File backup akan dihapus otomatis dalam 1-4 hari',
  'settings.download_backup_finish':
    'File backup database telah siap, klik tombol dibawah untuk mendownload:',
  'settings.finance_backup_database': 'Mengunduh database Kledo Anda',
  'settings.backup_date': 'Tanggal Backup',
  // delete
  'sales_overview.vs_same_date_with_another_date': 'vs tanggal sama {date}',
  'sales_overview.invoices_this_month_with_title': '{title} {date}',
  'sales_overview.of_the_total_unpaid_invoices_with_title': 'dari total {title} belum dibayar',
  'sales_overview.invoices_paid_vs_total_invoices_for_the_month_with_title':
    '{title} lunas vs total {title} {date}',
  'sales_overview.paid_ratio': 'Rasio Lunas',
  'sales_overview.sales_flow_not_completed_with_title': 'Alur {title} Belum Selesai',
  'sales_overview.quote_accepted_with_title': '{title} disetujui',
  'sales_overview.order_not_finished_with_title': '{title} belum selesai',
  'sales_overview.delivery_has_not_been_invoiced_with_title': '{title} belum ditagih',
  'sales_overview.invoices_due_with_title': '{title} jatuh tempo',
  'purchases_overview.purchase_payment_sent_with_title': 'Pembayaran {title} dikirim',
  'purchases_overview.payment_purchases_pending_with_title': 'Menunggu Pembayaran {title}',
  'financeProduct.qty_per_warehouse': 'Stok per Gudang',
  'financeProduct.product_value_per_warehouse_with_title': 'Nilai {title} per Gudang',
  'billing.most_chosen': 'Paling Banyak Dipilih',
  'billing.see_more': 'Lihat Selengkapnya',
  'billing.show_less': 'Tampilkan Lebih Sedikit',
  'billing.product_manufacture': 'Produk Manufaktur',
  'billing.contract_length': 'Pilih Lama Kontrak',
  'billing.please_choose_contract_length': 'Silakan Pilih Lama Kontrak',
  'billing.saving': 'Hemat',
  'billing.addon_user_description': 'Tambahkan user untuk terlibat membantu mengelola akun Anda.',
  'billing.addon_warehouse_description':
    'Makin banyak gudang, makin mudah mengkategorikan item atau produk.',
  'billing.addon_marketplace_description':
    'Memberikan Anda untuk melakukan integrasi toko Anda dengan Marketplace',
  'billing.addon_invoice_description':
    'Menambahkan jumlah invoice di akunmu, 1 addons invoice = 5.000 invoice.',
  'billing.addon_product_description':
    'Menambahkan jumlah {products} di akunmu, 1 addons {product} = 1.000 {products}.',
  'billing.addon_bank_connect_description':
    'Mengintegrasikan bisnis yang Anda miliki dengan layanan bank yang Anda punya.',
  'billing.invoices': 'Invoice',
  'billing.employee': 'Karyawan ',
  'billing.select_add_on_hris': 'Pilih Paket Addon Payroll & HRIS',
  'billing.hris_elite': 'Paket Elite',
  'billing.hris_pro': 'Paket Pro',
  'billing.active_package': 'Paket Aktif',
  'billing.expired_date': 'Tanggal expired',
  'billing.at_the_moment': 'Saat Ini',
  'billing.after_extension': 'Setelah Perpanjangan',
  'billing.extend_now': 'Perpanjang Sekarang',
  'billing.upgrade_plan_to': `Upgrade Paket {planName}`,
  'billing.contract': 'Kontrak',
  'billing.per_month': 'Perbulan',
  'billing.calculation_details': 'Rincian Perhitungan',
  'billing.1_year_subs_with_x_discount':
    'Dengan berlangganan 1 tahun, Anda akan mendapatkan diskon sebesar {discount}.',
  'billing.1_year_subs_with_x_discount_warning':
    'Apakah Anda yakin berlangganan {term} bulan dan tidak mendapatkan diskon {discount}?',
  'billing.i_want_discount': 'Saya mau diskon {discount}',
  'billing.i_dont_want_discount': 'Ya, saya tidak mau diskon',
  'financeBanks.bank_connect_less': 'Lebih Sedikit',
  'financeBanks.error_cannot_export': 'Tidak dapat ekspor {bankName}',
  'report.total_due': 'Total Jatuh Tempo',
  'settings.set_roles': 'Atur Peran',
  'settings.set_users': 'Atur Pengguna',
  'report.aged_receivable_from_until_less_than_equal_months':
    '{firstMonth} s/d <= {secondMonth} bulan',
  'report.aged_receivable_greater_than_months': '> {month} bulan',
  'financeFixedAssets.last_automatic_depreciation': 'Penyusutan otomatis terakhir pada {dateTime}',
  'financeFixedAssets.see_automatic_depreciation_history': 'Lihat histori penyusutan otomatis',
  'report.downloading_data': 'Sedang mengunduh data...',
  'contacts.info_is_as_employee':
    'Beberapa data tidak bisa diubah karena kontak juga sebagai pegawai.',
  'report.balance_sheet_previous_month': 'bulan sebelumnya',
  'report.balance_sheet_previous_year': 'tahun sebelumnya',
  'report.balance_sheet_vs_1_month_ago': 'vs 1 bulan lalu',
  'report.balance_sheet_structure': 'Struktur Neraca',
  'report.balance_sheet_trend': 'Tren Neraca',
  'report.balance_sheet_component_trend': 'Tren Komponen Neraca',
  'report.balance_sheet_component': 'Komponen Neraca',
  'report.balance_sheet_equity': 'Ekuitas',
  'report.balance_sheet_asset': 'Aset',
  'report.balance_sheet_liability': 'Liabilitas',
  'report.balance_sheet_trend_net_position': 'Tren Posisi Net Neraca',
  'report.quick_ratio_help_1':
    'Quick ratio berfungsi sebagai indikator likuiditas jangka pendek perusahaan, atau kemampuannya untuk memenuhi kewajiban jangka pendeknya. Dengan kata lain, ini menguji seberapa banyak perusahaan memiliki aset untuk melunasi semua kewajibannya.',
  'report.quick_ratio_help_2':
    'Saat nilai dari quick ratio di atas 1, artinya aset lancar perusahaan atau bisnis mampu memenuhi segala kewajiban lancarnya. Hal ini mengindikasikan kondisi keuangan bisnis yang sehat karena mampu melunasi seluruh tanggungannya jika diperlukan.',
  'report.quick_ratio_help_3':
    'Sebaliknya, jika nilai quick ratio kurang dari 1, artinya entitas tersebut tidak mampu melunasi kewajiban lancarnya menggunakan semua aset lancar yang dimiliki.',
  'report.quick_ratio_help_4':
    'Rumus quick ratio : (Aset Lancar – Stok atau Persediaan) / Kewajiban Lancar',
  'report.current_ratio_help_1':
    'Current ratio digunakan untuk mengevaluasi kemampuan perusahaan agar bisa membayar kewajiban jangka pendek, seperti utang dan upah. Current ratio ini dihitung dengan membagi aset lancar dengan kewajiban lancar. Semakin tinggi hasilnya, semakin kuat posisi keuangan perusahaan.',
  'report.current_ratio_help_2':
    'Namun, perlu diingat bahwa rasio lancar yang terlalu tinggi juga dapat menunjukkan bahwa perusahaan tidak mengoptimalkan penggunaan asetnya.',
  'report.current_ratio_help_3': 'Rumus Current Ratio = Total Aset Lancar / Total Kewajiban Lancar',
  'report.debt_equity_ratio_help_1':
    'Debt to Equity Ratio adalah rasio keuangan yang membandingkan jumlah hutang dengan ekuitas.',
  'report.debt_equity_ratio_help_2':
    'Ekuitas dan jumlah hutang piutang yang digunakan untuk operasional perusahaan harus berada dalam jumlah yang proporsional. Debt to Equity Ratio juga sering dikenal sebagai rasio leverage atau rasio pengungkit.',
  'report.debt_equity_ratio_help_3': 'Rumus Debt to Equity Ratio (DER) = Total Hutang / Ekuitas',
  'report.debt_equity_ratio_help_4':
    'Nilai debt to equity ratio di atas 2 atau 200% menunjukkan bahwa kondisi keuangan perusahaan sudah sangat rawan terhadap berbagai jenis risiko.',
  'report.equity_ratio_help_1':
    'Equity Ratio menunjukkan nilai aset yang dibiayai menggunakan ekuitas pemilik. Semakin tinggi proporsi dana dari pemilik, maka dianggap semakin rendah resikonya.',
  'report.equity_ratio_help_2':
    'Rasio ini dihitung dengan membagi total ekuitas perusahaan dengan total asetnya.',
  'report.equity_ratio_help_3': 'Rumus Equity Ratio  = Total Ekuitas / Total Aset',
  'birthday.please_input_the_birthday_template_subject':
    'Silakan masukkan judul untuk template ulang tahun.',
  'birthday.send_an_email_to_a_customer_having_a_birthday':
    'Kirim email ke pelanggan yang berulang tahun',
  'birthday.when_email_birthday_sent':
    'Pada hari ulang tahun, pelanggan akan secara otomatis dikirim email ucapan selamat ulang tahun. Pastikan telah mengisi tanggal lahir pelanggan dari masing-masing data kontak pelanggan Anda!',
  'settings.customer_birthday': 'Kirim email ke pelanggan yang sedang berulang tahun',
  'businessFlow.add_format_code': 'Tambahkan kode format',
  'financeInvoices.profit_margin_minus': 'Profit margin minus',
  'financeInvoices.select_name': 'Pilih {name}',
  'financeWareHouses.stock_adjustment_price': 'Harga Penyesuaian Stok',
  'financeWareHouses.recorded_average_price': 'Harga Rata-Rata Tercatat',
  'financeApproval.cannot_approve_transaction': 'Gagal melakukan approve pada transaksi ini.',
  'report.equity_movement_capital_balance': 'Saldo Modal',
  'report.equity_movement_capital_increase': 'Penambahan Modal',
  'report.equity_movement_capital_reduction': 'Pengurangan Modal',
  'report.equity_movement_capital_component_trends': 'Tren Komponen Modal',
  'report.equity_movement_capital_component': 'Komponen Modal',
  'report.receivable_payable_debt_widget': 'Hutang',
  'report.receivable_payable_receivable_widget': 'Piutang',
  'report.receivable_payable_net_debt_receivable_widget': 'Net Hutang Piutang',
  'report.receivable_payable_debt_receivable_contact_widget': 'Kontak',
  'report.receivable_payable_debt': 'Hutang Terbanyak',
  'report.receivable_payable_receivable': 'Piutang Terbanyak',
  'report.receivable_payable_ratio_debt_receivable': 'Rasio Hutang Piutang',
  'report.receivable_payable_trend_debt_receivable': 'Tren Hutang Piutang',
  'report.receivable_payable_transaction': 'transaksi',
  'report.receivable_payable_this_month': 'bulan ini',
  'report.receivable_payable_this_year': 'tahun ini',
  'report.receivable_payable_contact_debt_with_title': 'kontak punya {title}',
  'report.receivable_payable_contact_receivable_with_title': 'kontak punya {title}',
  'report.executive_summary_equity_movement': 'Perubahan Modal',
  'financeProduct.change_column_display': 'Mengubah tampilan kolom...',
  'financeProduct.column_display_changed_successfully': 'Tampilan kolom berhasil diubah',
  'reports.add_menu_to_favorites_menu': 'Tambahkan menu ke menu favorit',
  'reports.remove_from_favorites_menu': 'Hilangkan dari menu favorit',
  'page404.title': 'Halaman tidak ditemukan',
  'page404.description':
    'Halaman tersebut tidak digunakan lagi, dihapus, atau tidak ada sama sekali',
  'page404.fallback': 'Kembali ke halaman beranda',
  'page404.custom_detail_title': 'Halaman detail {title} tidak ditemukan',
  'page404.custom_import_title': 'Halaman import {title} tidak ditemukan',
  'page404.custom_detail_description': '{title} ini telah dihapus atau tidak ada sama sekali',
  'page404.custom_fallback': 'Kembali ke halaman {title}',
  'report.cash_change': 'Perubahan Kas',
  'report.end_period': 'Saldo Penutupan',
  'report.cash_out': 'Kas Keluar',
  'report.cash_in': 'Kas Masuk',
  'report.trend_cash_flow': 'Tren Arus Kas',
  'report.chart_cash_flow': 'Saldo Kas',
  'report.funding_components': 'Komponen Pendanaan',
  'report.investment_components': 'Komponen Investasi',
  'report.operational_components': 'Komponen Operasional',
  'report.vs_1_year_ago': 'vs 1 tahun lalu',
  'report.bank_summary_balance_per_bank': 'Saldo per Bank',
  'report.bank_summary_trend_balance_per_bank': 'Trend Saldo per Bank',
  'report.bank_summary_trend_received_per_bank': 'Trend Kas Masuk per Bank',
  'report.bank_summary_balance_all_bank': 'Saldo Seluruh Bank',
  'report.bank_summary_trend_spent_per_bank': 'Trend Kas Keluar per Bank',
  'report.bank_summary_trend_net_per_bank': 'Trend Net Pergerakan per Bank',

  'financeContacts.contact_information': 'Informasi Kontak',
  'financeInvoices.void_reason_label': 'Silahkan masukkan alasan void transaksi',
  'financeInvoices.void_reason_placeholder': 'Silahkan masukkan alasan anda',
  'report.profit_loss_net_profit_margin': 'Margin Laba Bersih',
  'report.profit_loss_gross_profit_margin': 'Margin Laba Kotor',
  'report.profit_loss_operating_expense_ratio': 'Rasio Biaya Operasional',
  'report.profit_loss_profit_loss_trend': 'Tren Laba Rugi',
  'report.profit_loss_flow': 'Alur Laba Rugi',
  'report.profit_loss_income_details': 'Rincian Pendapatan',
  'report.profit_loss_expense_details': 'Rincian Biaya',
  'report.profit_loss_component_trends': 'Tren Komponen Laba Rugi',
  'reports.sales_value_per_product_category_graph': 'Nilai Penjualan per Kategori Produk',
  'reports.total_sales_per_product_category_graph': 'Jumlah Penjualan per Kategori Produk',
  'transactionFees.total_transaction_fees': 'Total Biaya Transaksi',
  'settings.fees': 'Atur biaya transaksi penjualan dan pembelian',
  'transactionFees.fees': 'Biaya Transaksi',
  'transactionFees.nominal': 'Nominal',
  'transactionFees.please_input_the_nominal': 'Silahkan masukkan nominal',
  'transactionFees.please_select_transaction_fee': 'Silahkan pilih biaya transaksi',
  'transactionFees.select_transaction_fee': 'Pilih biaya transaksi',
  'reports.total_of_transactions_per_customer': 'Jumlah Transaksi per Pelanggan',
  'reports.total_of_transactions_per_vendor': 'Jumlah Transaksi per Vendor',
  'reports.expense_claim_per_term': 'Klaim Biaya Per Tempo',
  'reports.term_expense_claim': 'Tempo Klaim Biaya',
  'reports.total_due_expense_claim': 'Total Klaim Biaya Jatuh Tempo',
  'financeBanks.bank_statements_empty_title': 'Anda belum mempunyai data transaksi bank',
  'financeBanks.bank_statements_empty_description':
    'Data transaksi bank dapat diimport otomatis dari rekening Bank Anda atau import secara <br/> manual dengan upload filex Excel.',
  'financeBanks.bank_statements_automatic_import': 'Import Otomatis',
  'financeBanks.bank_statements_manual_import': 'Import Manual',
  'financeInvoices.enter_the_number_of_shipping_items': 'Masukkan jumlah item pengiriman',
  'billing.pos_renew_outlet': 'Perpanjang Outlet',
  'billing.pos_length_of_contract': 'Lama Kontrak',
  'billing.add_pos_addon': 'Tambah Addon POS',
  'billing.renew_pos': 'Perpanjang POS',
  'hideFeature.add_pos_addon_or_upgrade':
    'Addon POS anda telah habis masa aktifnya, silahkan order<br/>perpanjangan untuk kembali dapat mengakses POS',
  'report.journal_transaction_value': 'Nilai Transaksi',
  'report.journal_transaction_amount': 'Jumlah Transaksi',
  'report.journal_journal_row': 'Baris Jurnal',
  'report.journal_value_per_transaction_type': 'Nilai Per Tipe Transaksi',
  'report.journal_total_transaction_per_type': 'Jumlah Transaksi Per Tipe',
  'report.journal_total_transaction_value': 'Total Nilai Transaksi',
  'report.journal_total_transaction_amount': 'Total Jumlah Transaksi',
  'report.journal_total_journal_row': 'Total Baris Jurnal',

  'bankConnect.hash_tag': '#bankconnect',
  'bankConnect.head_title_1': 'Otomatis Download',
  'bankConnect.head_title_2': 'Mutasi Rekening',
  'bankConnect.head_title_3': 'Bank ke Kledo',
  'bankConnect.head_title_description':
    'Kini setiap transaksi di rekening bank mu bisa otomatis masuk ke Kledo dan direkonsiliasi. Tidak ada lagi deh selisih laporan keuangan dengan transaksi riil di bank.',
  'bankConnect.sales_contact': 'Hubungi Tim Hebat Kledo',
  'bankConnect.add_bank_connect': 'Tambah Bank Connect',
  'bankConnect.set_bank_connect': 'Atur Bank Connect',
  'bankConnect.feat_title_1': 'Apa yang',
  'bankConnect.feat_title_2': 'Anda Dapatkan',
  'bankConnect.feat_title_3': 'Dengan Bank Connect',
  'bankConnect.feat_1_sub_title': 'Otomatis Download Mutasi',
  'bankConnect.feat_1_description':
    'Setiap hari, data transaksi mutasi bank akan otomatis terdownload ke aplikasi Kledo. Tak perlu lagi repot export transaksi dari ibanking dan import ke Kledo.',
  'bankConnect.feat_2_sub_title': 'Gratis, Tanpa Biaya',
  'bankConnect.feat_2_description':
    'Kledo tidak mengenakan biaya sepeserpun untuk menggunakan fitur Bank Connect. Tetapi bank akan mengenakan biaya untuk koneksi API, biaya bervariasi untuk masing-masing bank.',
  'bankConnect.feat_3_sub_title': 'Koneksi Langsung via Bank API',
  'bankConnect.feat_3_description':
    'Tanpa menggunakan pihak ke 3, Kledo akan langsung terhubung dengan bank Anda melalui API.',
  'bankConnect.feat_4_sub_title': 'Aman dan Terjamin',
  'bankConnect.feat_4_description':
    'Kerjasama Kledo dan Bank hanya dibatasi pada pengambilan mutasi rekening saja, sehingga tidak memungkinkan adanya transaksi uang keluar dari rekening Anda.',
  'bankConnect.feat_5_sub_title': 'Unlimited',
  'bankConnect.feat_5_description':
    'Tanpa batasan jumlah transaksi tiap harinya, dan tanpa batasan jumlah rekening yang Anda hubungkan. Semuanya gratis!',
  'bankConnect.footer_note': 'Yuk mudahkan proses rekonsiliasi bank mu sekarang juga!',
  'report.number_of_shipments_per_expedition': 'Jumlah Pengiriman per Ekspedisi',
  'report.sales_value_per_expedition': 'Nilai Penjualan per Ekspedisi',
  'reports.product_sales_per_customer': 'Penjualan Produk Per Pelanggan',
  'reports.product_sales_per_customer_group': 'Penjualan Produk Per Grup Pelanggan',
  'reports.product_purchases_per_vendor': 'Pembelian Produk Per Vendor',
  'reports.product_purchases_per_vendor_group': 'Pembelian Produk Per Grup Vendor',
  'reports.cash_movement': 'Pergerakan Kas',
  'reports.account_component': 'Komponen Akun',
  'reports.excluded_accounts': 'Akun yang dikecualikan',
  'reports.select_excluded_accounts': 'Pilih akun yang ingin dikecualikan',
  'kledoPos.setting_default_account_receive_payment': 'Default Akun Terima Pembayaran',
  'kledoPos.setting_default_account_receive_payment_desc':
    'Saat melakukan pembayaran transaksi di POS, maka di akunting akan dicatatkan pada akun COA ini.',
  'billing.show_coupon': 'Tambah Kupon',
  'billing.hide_coupon': 'Sembunyikan Kupon',
  'billing.input_coupon': 'Masukkan Kupon',
  'reports.purchase_per_period_value': 'Nilai Pembelian',
  'reports.purchase_per_period_amount': 'Jumlah Pembelian',
  'layoutInvoice.invoice_show_witholding_detail': 'Tampilkan Detail Pemotongan',
  'financeContacts.new_contact': 'Kontak Baru',
  'financeContacts.total_contact': 'Total Kontak',
  'financeContacts.contact_per_group': 'Kontak Per Grup',
  'financeContacts.contact_per_province': 'Kontak Per Provinsi',
  'reportsImport.date_and_time': 'Tanggal & Waktu',
  'reportsImport.total': 'Jumlah Import',
  'reportsImport.valid': 'Berhasil',
  'reportsImport.invalid': 'Gagal',
  'pos.pos_role': 'Peran POS',
  'approval.financeBanks': 'Kas & Bank',
  'financeWareHouses.change_access_per_contact': 'Ubah Akses Per Kontak',
  'financeWareHouses.per_contact_warehouse_access': 'Akses Gudang Per Kontak',
  'financeWareHouses.per_contact_warehouse_access_desc':
    'Gudang dapat dikhususkan hanya dapat digunakan untuk grup kontak yang terpilih. <br /> Misalnya, Gudang Surabaya hanya bisa digunakan untuk pelanggan dalam grup Surabaya. Sedangkan Gudang Nasional bisa digunakan untuk semua pelanggan',
  'financeWareHouses.warehouse_per_contact_type':
    'Gudang dapat digunakan oleh grup kontak mana saja?',
  'financeWareHouses.all_contact_groups': 'Semua Grup Kontak',
  'financeWareHouses.select_contact_group': 'Pilih Grup Kontak',
  'reports.using_new_table_view': 'Anda sedang menggunakan tampilan tabel baru',
  'reports.using_legacy_table_view': 'Anda sedang menggunakan tampilan tabel lama',
  'reports.switch_to_new_table_view': 'Ganti ke tampilan tabel baru',
  'reports.switch_to_old_table_view': 'Ganti ke tampilan tabel lama',
  'settings.finance_payment_connect': 'Terima pembayaran dari ewallet, kartu kredit, VA',
  'paymentConnect.connection_test': 'Tes Koneksi',
  'paymentConnect.activate_payment_via_provider_name': 'Aktifkan pembayaran melalui {providerName}',
  'paymentConnect.coa_cash_bank_accounts': 'Akun COA Kas & Bank',
  'paymentConnect.coa_cash_bank_accounts_help_text':
    'Pembayaran dari payment gateway akan dicatatkan pada akun bank ini',
  'paymentConnect.coa_expense_account': 'Akun COA Beban',
  'paymentConnect.coa_expense_account_help_text':
    'Biaya Transaksi atas pembayaran akan dicatatkan pada akun COA ini',
  'paymentConnect.tax_coa_account': 'Akun COA Pajak',
  'paymentConnect.tax_coa_account_help_text':
    'Pengenaan pajak transaksi atas pembayaran akan dicatatkan pada akun COA ini',
  'paymentConnect.midtrans_api_key_help_text':
    'Salin API Key dari akun Midtrans Anda, melalui menu Pengaturan >> Access Keys >> Server Key',
  'paymentConnect.xendit_api_key_help_text':
    'Salin API Key dari akun Xendit Anda, melalui menu Pengaturan >> Developer >> API keys >> Buat secret key baru >> Secret API Key',
  'paymentConnect.doku_api_key_help_text':
    'Salin API Key dari akun DOKU Anda, melalui menu Integration >> API Keys >> Secret Key',
  'paymentConnect.token_callback_help_text':
    'Klik tombol salin URL dibawah, kemudian masukkan ke akun {providerName}',
  'invoices.payment_link': 'Link Pembayaran {providerName}',
  'invoices.payment_connect_not_activated':
    'Anda belum mengaktifkan fitur {title}. Dengan {title}, pelanggan Anda dapat melakukan pembayaran melalui berbagai channel pembayaran, seperti GoPay, Kartu Kredit, Virtual Account, Alfamart, Kredivo dan banyak lagi.',
  'invoices.activate': 'Aktifkan',
  'paymentConnect.please_input_api_key': 'Silahkan masukkan API Key',
  'paymentConnect.please_input_tax_coa_account': 'Silahkan masukkan Akun COA Pajak',
  'paymentConnect.please_input_coa_expense_account': 'Silahkan masukkan Akun COA Beban',
  'paymentConnect.please_input_coa_cash_bank_accounts': 'Silahkan masukkan Akun COA Kas & Bank',
  'layoutInvoice.show_reference_as_message_with_title': 'Tampilkan {reference} Sebagai Pesan',
  'tags.choose_a_color': 'Pilih warna',
  'report.profitloss_delta_tooltip':
    'Perbedaan antara pendapatan periode sebelumnya dan pendapatan periode ini.',
  'report.profitloss_delta_percentage_tooltip':
    'Pertumbuhan atau penurunan dari pendapatan periode sebelumnya.',
  'kledoPos.setting_sales_tags': 'Tags Penjualan',
  'kledoPos.setting_sales_tags_description':
    'Penjualan melalui POS akan otomatis menggunakan tags berikut.',
  'sso.please_input_your_code': 'Silakan masukkan kode Anda',
  'sso.verified': 'Diverifikasi',
  'sso.pending_verification': 'Verifikasi Tertunda',
  'sso.domain_verification': 'Verifikasi Domain',
  'sso.domain_verification_successful': 'Verifikasi Domain Berhasil',
  'sso.add_domain': 'Tambah Domain',
  'sso.add_domain_desc':
    'Silahkan masukkan alamat email Anda yang mengandung nama domain perusahaan Anda. Misalnya namaku@namaperusahaan.com',
  'sso.domain_successfully_verified': 'Selamat! Domain {domainName} berhasil diverifikasi.',
  'sso.verify_domain_desc':
    'Kode verifikasi telah dikirim ke alamat email Anda. Silahkan masukkan kode verifikasi pada form di bawah',
  'sso.verification': 'Verifikasi',
  'sso.resend_code': 'Kirim ulang kode',
  'sso.sso_configuration_service_provider_desc':
    'Silahkan masukkan data SP Entity ID dan SP ACS URL di bawah ke dalam halaman administrasi identity provider anda',
  'sso.sso_configuration_identity_provider_desc':
    'Ambil data IDP Entity ID dan SAML Login URL dari identity provider Anda, kemudian masukkan ke form di bawah',
  'sso.sso_please_enter_idp_entity_id': 'Silahkan masukkan IDP Entity ID',
  'sso.sso_please_enter_saml_login_url': 'Silahkan masukkan SAML Login URL',
  'sso.sso_configuration': 'Konfigurasi SSO',
  'settings.sso_setting': 'Login Kledo dengan hanya satu akun',
  'sso.continue_with_sso': 'Lanjutkan dengan SSO',
  'sso.login_with_sso': 'Login dengan SSO',
  'sso.login_sso_desc':
    'Akun Anda telah mengaktifkan Single Sign-On. Lanjutkan dengan SSO <br /> untuk mengakses akun Kledo Anda',
  'sso.return_to_login': 'Kembali ke login',
  'businessFlow.enable_login_with_sso': 'Aktifkan login dengan SSO?',
  'businessFlow.enable_login_with_sso_desc': 'Apakah Anda menggunakan SSO untuk login?',
  'contacts.group_with_title': 'Grup {title}',
  'contacts.who_can_access_this_contact_group': 'Siapa yang bisa mengakses grup ini?',
  'settings.approval_setting': 'Pengaturan Approval',
  'financeExpenseTerms.select_terms': 'Pilih Termin',
  'approval.transfer_money': 'Transfer Dana',
  'dashboard.the_maximum_number_that_can_be_displayed_on_the_dashboard':
    'Jumlah maksimal yang dapat ditampilkan di dashboard adalah 30',
  'sso.faq_question_what_is_sso': 'Apa itu Single Sign On (SSO)?',
  'sso.faq_answer_what_is_sso':
    'SSO memungkinkan pengguna untuk login ke banyak aplikasi dan website menggunakan satu identity provider. Sehingga pengguna tidak perlu menghafal banyak user dan password untuk masing-masing aplikasi atau website.',
  'sso.faq_question_how_does_sso_work': 'Bagaimana cara kerja SSO?',
  'sso.faq_answer_1_how_does_sso_work': 'Pengguna berusaha login ke Kledo menggunakan SSO',
  'sso.faq_answer_2_how_does_sso_work': 'Kledo mengirimkan data pengguna ke Identity Provider',
  'sso.faq_answer_3_how_does_sso_work':
    'Identity Provider melakukan pengecekan kredensial pengguna',
  'sso.faq_answer_4_how_does_sso_work':
    'Identity Provider mengirimkan respon ke Kledo, memberitahukan bahwa pengguna diperbolehkan login',
  'sso.faq_answer_5_how_does_sso_work':
    'Kledo menerima info tersebut dari Identity Provider, dan meloginkan pengguna tersebut ke Kledo',
  'sso.faq_question_how_to_setup_sso_in_kledo': 'Bagaimana cara setup SSO di Kledo?',
  'sso.faq_answer_how_to_setup_sso_in_kledo': 'Klik {here} untuk tutorial cara setup SSO di Kledo.',
  'sso.here': 'di sini',
  'report.average_unit_price': 'Harga Satuan Rata-Rata',
  'report.average_product_value': 'Nilai Produk Rata-Rata',
  'report.total_product_value': 'Total Nilai Produk',
  'billing.payment_balance': 'Saldo Pembayaran',
  'roles.time_access_limit': 'Terapkan batas waktu akses',
  'roles.access_limit': 'Batas waktu akses',
  'roles.restricted_access_type_monday_friday': 'Senin - Jumat Jam Kerja',
  'roles.restricted_access_type_monday_saturday': 'Senin - Sabtu Jam Kerja',
  'roles.restricted_access_type_custom': 'Custom',
  'roles.active_days_monday': 'Senin',
  'roles.active_days_tuesday': 'Selasa',
  'roles.active_days_wednesday': 'Rabu',
  'roles.active_days_thursday': 'Kamis',
  'roles.active_days_friday': 'Jumat',
  'roles.active_days_saturday': 'Sabtu',
  'roles.active_days_sunday': 'Minggu',
  'businessFlow.enable_contact_group_is_required': 'Grup wajib diisi',
  'businessFlow.enable_contact_group_is_required_desc':
    'Apakah saat menambah dan mengubah kontak harus ada grup?',
  'report.unit_price_help':
    'Harga Satuan yang ditampilkan merupakan harga pembelian terakhir (bukan harga rata-rata)',
  'report.product_value_help':
    'Nilai Produk yang ditampilkan merupakan harga satuan terakhir dikalikan kuantitas (bukan nilai produk rata-rata)',

  'scheduler_lock_date.monday': 'Senin',
  'scheduler_lock_date.tuesday': 'Selasa',
  'scheduler_lock_date.wednesday': 'Rabu',
  'scheduler_lock_date.thursday': 'Kamis',
  'scheduler_lock_date.friday': 'Jumat',
  'scheduler_lock_date.saturday': 'Sabtu',
  'scheduler_lock_date.sunday': 'Minggu',
  'scheduler_lock_date.days': 'Hari',
  'scheduler_lock_date.weeks': 'Minggu',
  'scheduler_lock_date.months': 'Bulan',
  'scheduler_lock_date.create_successfully': 'Opsi berhasil diperbarui',
  'scheduler_lock_date.create_failed': 'Tidak dapat memperbarui opsi',
  'scheduler_lock_date.subtitle_1': 'Kini Anda bisa secara otomatis mengubah tanggal penguncian.',
  'scheduler_lock_date.subtitle_2':
    'Tentukan aturan otomatis perubahannya pada form di bawah, dan nantinya tanggal penguncian akan otomatis terupdate sesuai pengaturanmu.',
  'scheduler_lock_date.is_automatically_changed':
    'Apakah tanggal penguncian otomatis diubah berkala?',
  'scheduler_lock_date.label1': 'Setiap kapan tanggal penguncian diubah?',
  'scheduler_lock_date.label2': 'Setiap tanggal berapa tanggal penguncian diubah?',
  'scheduler_lock_date.label3': 'Setiap hari apa tanggal penguncian diubah?',
  'scheduler_lock_date.label4': 'Ubah tanggal penguncian ke tanggal kapan?',
  'scheduler_lock_date.daily': 'Harian',
  'scheduler_lock_date.weekly': 'Mingguan',
  'scheduler_lock_date.monthly': 'Bulanan',
  'scheduler_lock_date.example': 'Contoh:',
  'scheduler_lock_date.example_sub':
    'Jika tanggal penguncian otomatis diubah pada 1 Maret, dan tanggal penguncian diubah ke 2 bulan sebelumnya, maka tanggal penguncian akan berubah menjadi 1 Januari',
  'scheduler_lock_date.earlier': 'Sebelumnya',
  'scheduler_lock_date.update_successfully': 'Schedule Tanggal Penguncian berhasil diaktifkan.',
  'scheduler_lock_date.update_failed': 'Tidak dapat mengaktifkan Schedule Tanggal Penguncian.',
  'scheduler_lock_date.update_successfully_inactive':
    'Schedule Tanggal Penguncian berhasil dinonaktifkan.',
  'scheduler_lock_date.update_failed_inactive':
    'Tidak dapat menonaktifkan Schedule Tanggal Penguncian.',
  'financeFixedAssets.effective_life_title': 'Masa Manfaat',
  'paymentConnect.faq_question_payment_gateway': 'Apa itu Payment Gateway?',
  'paymentConnect.faq_answer_payment_gateway':
    'Dengan menggunakan payment gateway, pelanggan Anda dapat melakukan pembayaran tagihan yang Anda terbitkan melalui berbagai channel pembayaran, seperti kartu kredit, gopay, virtual account dan sebagainya. \n\nKode QR akan disematkan pada tagihan Anda, sehingga pelanggan dapat membayar dengan mudah melalui scan QR Code.',
  'paymentConnect.faq_question_when_customer_pays_through_payment_gateway':
    'Apa yang terjadi saat pelanggan saya membayar melalui Payment Gateway?',
  'paymentConnect.faq_answer_when_customer_pays_through_payment_gateway':
    'Saat pembayaran dilakukan, maka tagihan Anda akan otomatis terupdate status pembayarannya. Sehingga Anda tidak perlu lagi repot mencatatkan setiap pembayaran yang masuk.',
  'paymentConnect.faq_question_payment_gateway_supported_by_kledo':
    'Payment Gateway apa saja yang didukung oleh Kledo?',
  'paymentConnect.faq_answer_payment_gateway_supported_by_kledo':
    'Anda dapat menggunakan Midtrans, Xendit maupun Doku.',
  'paymentConnect.faq_question_charge_a_fee':
    'Apakah Kledo memungut biaya untuk penggunaan Payment Gateway?',
  'paymentConnect.faq_answer_charge_a_fee':
    'Tidak. Namun pihak payment gateway provider akan mengenakan biaya per transaksi. Untuk informasi lebih detailnya, bisa ditanyakan langsung ke pihak Midtrans, Xendit maupun Doku.',
  'paymentConnect.faq_question_how_to_setup': 'Bagaimana cara setup nya?',
  'paymentConnect.faq_answer_how_to_setup': 'Klik tautan berikut:',
  'report.see_report': 'Lihat Laporan',
  'paymentConnect.click_here_to_go_to_tutorial':
    '{clickHere} untuk detail cara setup payment connect {providerName}.',
  'kledoPos.service_charge': 'Service Charge Pada Biaya Tagihan',
  'kledoPos.service_charge_desc':
    'Pada penjualan yang terdapat service charge, biaya dicatatkan pada master biaya tagihan ini.',
  'paymentConnect.success_copy_url_callback': 'Url callback berhasil disalin',
  'financeContacts.search_on_google_map': 'Cari di Google Map',
  'users.check_user_quota_availability': 'Cek ketersediaan kuota {title}',
  'businessFlow.duplicate_contact_email': 'Duplikat email kontak',
  'businessFlow.duplicate_contact_email_desc': 'Jika aktif maka email kontak bisa duplikat.',
  'audit.ip_address': 'Alamat IP',
  'audit.enter_ip_address': 'Masukkan alamat IP',
  'topBar.show_dark_mode': 'Tampilkan mode gelap',
  'topBar.show_light_mode': 'Tampilkan mode terang',
  'financeInvoices.reject_reason_label': 'Silahkan masukkan alasan Anda menolak transaksi',
  'massDownload.data_progress_processed': '{count} data dari total {total} telah diproses',
  'billing.enter_coupon_code': 'Masukkan Kode Kupon',
  'approval.receive_invoice_payment': 'Terima Pembayaran Tagihan',
  'approval.receive_sales_order_payment': 'Terima DP Pemesanan Penjualan',
  'approval.purchase_payment': 'Pembayaran Pembelian',
  'approval.purchase_order_payment': 'Pembayaran DP Pemesanan Pembelian',
  'financeInvoices.detail_something': 'Detail {something}',
  'financeInvoices.logistic': 'Logistik',
  'financeInvoices.location': 'Lokasi',

  'placeholder.attachment': 'Pilih Attachment',
  'option.with_attachment': 'Punya Attachment',
  'option.without_attachment': 'Tanpa Attachment',
  'placeholder.attachment_photo': 'Pilih Foto',
  'option.with_attachment_photo': 'Punya Foto',
  'option.without_attachment_photo': 'Tanpa Foto',
  'title.attachment_photo': 'Foto',
  'layoutInvoice.show_transaction_fee_details': 'Tampilkan Detail Biaya Transaksi',
  'massPrint.data_progress_processed': '{count} data dari total {total} telah diproses',
  'massPrint.mass_print': 'Cetak Massal',
  reload_window: 'Muat ulang halaman',
  welcome_to_company: 'Selamat datang di {value}',
  thankyou_you_have_successfully_joined_to_company:
    'Terimakasih, Anda berhasil bergabung di {value}',
  please_click_next_to_start_the_application:
    'Silahkan klik lanjut, untuk mulai menggunakan aplikasi',
  'uploadImage.image_with_title': 'Gambar {title}',
  'uploadImage.show_image_with_title': 'Tampilkan Gambar {title}',
  'uploadImage.hide_image_with_title': 'Sembunyikan Gambar {title}',
  'financeInvoices.this_customer': '{customer} Ini',
  'financeInvoices.all_customer': 'Seluruh {customer}',
  'financeInvoices.sales_price_history': 'Riwayat Harga Jual',
  'financeInvoices.sales_price_history_of_all_customers': 'Riwayat Harga Jual Seluruh {customer}',
  'financeInvoices.something_has_been_applied': '{something} telah diterapkan',

  'financeBanks.total_of_selected_rows_reconcile': 'total nominal rekonsiliasi : {count}',
  'financePurchaseInvoices.transaction_date_must_be_gt_date':
    'Tgl Transaksi harus lebih dari {date}',
  'financeInvoices.failed_to_get_sales_price_history':
    'Gagal mendapatkan riwayat harga jual terakhir',
  'financePurchaseInvoices.recurring_purchase_invoice': 'Tagihan Pembelian Berulang',
  'financePurchaseInvoices.1_download_your_purchase_invoices_recurring_template_file':
    '1. Download file template tagihan pembelian berulang',
  'financePurchaseInvoices.start_by_downloading_our_purchase_invoices_recurring_csv':
    'Mulai dengan mengunduh file template Excel tagihan pembelian berulang. File ini mempunyai kolom header yang yang dibutuhkan Kledo untuk mengimport detail dari tagihan-tagihan pembelian berulangmu.',
  'financePurchaseInvoices.2_copy_your_invoices_purchase_recurring_into_the_template':
    '2. Salin detail tagihan pembelian berulang ke dalam template',
  'financePurchaseInvoices.purchase_invoice_recurring_using_excel_or_another_spreadsheet_editor':
    'Menggunakan Excel atau software spreadsheeet lainnya, masukkan detail tagihan pembelian berulang ke dalam file template dari Kledo. Pastikan detail tagihan pembelian berulang sesuai dengan kolom header yang ada di template.',

  'financeWarehouse.upload_photo': 'Unggah Gambar Gudang Anda',
  'financeWarehouse.upload_photo_maxsize': '(Maks Ukuran Attachment 10 MB)',
  'billing.term_and_conditions':
    'Dengan klik tombol di atas, Anda setuju dengan {termOfUse} kami dan mengonfirmasi bahwa Anda telah membaca {privacyPolicy} kami.',
  'billing.terms_of_use': 'Ketentuan Penggunaan',
  'billing.privacy_policy': 'Kebijakan Privasi',
  'report.mass_export': 'Ekspor Massal',
  'massExport.data_has_been_exported': 'Total {count} data telah berhasil diekspor',
  'massExport.its_ok_to_close_this_process':
    'Tidak apa-apa untuk meninggalkan proses ini. Ketika ekspor selesai, Anda akan diberitahu melalui email.',
  'massExport.cannot_mass_export': 'Tidak dapat mengekspor massal',
  'massExport.part': 'Sebagian',
  'massExport.data_progress_exported': '{count} data dari total {total} telah terekspor',
  'massExport.retrieving_the_necessary_data': 'Sedang mengambil data yang diperlukan',
  'financeProduct.all_row': 'Semua Baris',

  'financeBanks.manual_get_data': 'Ambil Data Manual',
  'button.get_data': 'Ambil data',
  'financeBanks.schedule_get_data': 'Jadwal Pengambilan Mutasi',
  'users.login_with_facebook': 'Sign in dengan Facebook',
  'warehouses.avg_cogs': 'Rata-Rata HPP',
  'report.diff_qty_help': 'Selisih kuantitas bahan baku dan barang jadi',
  'roles.start_time_must_be_before_end_time': 'Waktu awal harus sebelum waktu akhir',
  'roles.end_time_must_be_after_start_time': 'Waktu akhir harus setelah waktu awal',
  'roles.custom_options_require_minimum_1_active_day':
    'Opsi custom membutuhkan minimal 1 hari aktif',
  'financeWareHouses.approval_status': 'Status Approval',

  'billing.addon_stamp_description': 'Bubuhkan E-Meterai ke transaksimu',
  'billing.stamp': 'Meterai',
  'financeInvoices.stamp': 'E-Meterai',
  'stamp.billing_title': 'Billing E-Meterai',
  'stamp.balance': 'Saldo E-Meterai',
  'stamp.yes': 'Ya',
  'stamp.no': 'Tidak',
  'stamp.affix_description': 'Yuk bubuhkan E-meterai ke dokumen tagihanmu dalam satu klik! E-meterai telah dijamin legalitasnya berdasarkan Undang Undang No.10 Tahun 2020.',
  'stamp.zero_quota_description': 'Tak perlu repot lagi menempelkan manual meterai pada dokumen. Cukup dengan E-meterai, dan legalitas telah dijamin Undang Undang!',
  'stamp.add_stamp_quota_description': 'Yuk tambahkan saldo E-meterai mu terlebih dahulu, supaya dapat mulai membubuhkan E-meterai pada tagihanmu',
  'stamp.total_billing' : 'Total Tagihan',
  'stamp.user' : 'Pembubuh',
  'stamp.document' : 'Dokumen',
  'stamp.affix_stamp' : 'Bubuhkan E-Meterai',
  'stamp.affix' : 'Bubuhkan',
  'stamp.add_stamp_balance' : 'Tambah Saldo E-meterai',
  'stamp.sheets' : 'Lembar',
  'stamp.your_stamp_quota' : 'Kuota E-meterai Anda',
  'stamp.bill_preview' : 'Preview Tagihan',
  'stamp.balance_left': 'Sisa Saldo',
  'stamp.amount': 'Jumlah',
  'stamp.description' : 'Deskripsi',
  'stamp.date' : 'Tanggal',
  'stamp.confirm_message' : 'Apakah Anda membubuhkan E-meterai pada dokumen {value}? Pembubuhan E-meterai akan mengurangi saldo, dan tidak dapat dibatalkan.',
  'stamp.cancel' : 'Batal',
  'stamp.usage' : 'Penggunaan',
  'stamp.add_balance' : 'Tambah Saldo',
  'stamp.loading_document' : 'Memuat dokumen...',
  'stamp.drag_help_text' : 'Seret untuk mengubah posisi e-meterai',
  'stamp.page' : 'Halaman {page} dari {total}',
  'stamp.select_user' : 'Pilih Pembubuh',
}

export default {
  locale: 'id-ID',
  antdData,
  localeData,
  messages,
}
