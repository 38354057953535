/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useMemo } from 'react'
import { Card, Col, ConfigProvider, Row, Skeleton, Table, Typography } from 'antd'
import { connect, useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import { darkModeSelector } from "redux/selectors";

import Empty from './Empty'

const { Text } = Typography

const ResponsiveTableSummary = ({
  title,
  titleColSpan,
  datas = [],
  isMobileView,
  isTabView,
  className,
  disableResponsive,
}) => {
  const darkMode = useSelector(darkModeSelector)

  const getContent = useCallback(() => {
    const content = []

    if (!disableResponsive && (isMobileView || isTabView)) {
      content.push(
        <Table.Summary.Cell key="title" colSpan={2}>
          <Card
            title={
              <div style={{ fontSize: 18, fontWeight: 'bold', padding: '4px 0' }}>{title}</div>
            }
            size="small"
            bodyStyle={{ backgroundColor: darkMode ? '#141414' : '#f1f4f8' }}
            headStyle={{ backgroundColor: darkMode ? '#141414' : '#f1f4f8' }}
          >
            <Row>
              {datas.map((data, i) => {
                if (data.hide) return <React.Fragment key={data.title ? data.title : i} />
                return (
                  <Col xs={24} sm={12} key={data.title ? data.title : i} className="mt-3 px-1">
                    {data.title}
                    <h5>{data.value}</h5>
                  </Col>
                )
              })}
            </Row>
          </Card>
        </Table.Summary.Cell>,
      )
    } else {
      content.push(
        <Table.Summary.Cell colSpan={titleColSpan} key="title">
          <Text strong>{title}</Text>
        </Table.Summary.Cell>,
      )
      datas.forEach((data, i) => {
        if (data.hide) return
        content.push(
          <Table.Summary.Cell
            align={data.align ? data.align : 'right'}
            colSpan={data.colSpan}
            key={data.title ? data.title : i}
            className={data?.className}
          >
            {typeof data.render === 'function' ? data.render() : <Text strong>{data.value}</Text>}
          </Table.Summary.Cell>,
        )
      })
    }
    return content
  }, [datas, disableResponsive, isMobileView, isTabView, title, titleColSpan, darkMode])

  return (
    <Table.Summary.Row style={{ backgroundColor: darkMode ? '#424242' : '#f1f4f8' }} className={className}>
      {getContent()}
    </Table.Summary.Row>
  )
}

// Responsive table component
const ResponsiveTable = ({
  intl,
  name,
  onAdd,
  withTitle = true,
  isMobileView,
  isTabView,
  rowKey,
  columns,
  dataSource,
  pagination,
  footer,
  loading,
  rowClassName,
  onChange,
  onRow,
  sortDirections,
  summary,
  rowSelection,
  className,
  style,
  showHeader,
  components,
  scroll,
  expandedRowKeys,
  expandedRowRender,
  onExpandedRowsChange,
  onExpand,
  expandRowByClick,
  bordered,
  mobileTableByPassEmptyValue,
  emptyDescription,
  emptyTitle,
  appendEmptyDescription,
  ...props
}) => {
  const formattedColumns = useMemo(() => {
    const newColumns = columns.map((column) => {
      return {
        ...column,
        dataIndex:
          typeof column.dataIndex === 'string' ? column.dataIndex.split('.') : column.dataIndex,
        responsive: isMobileView || isTabView ? [] : null,
        render: loading ? () => null : column.render,
      }
    })

    return newColumns
  }, [columns, isMobileView, isTabView, loading])

  const renderMobileRow = useCallback(
    (row) => {
      return formattedColumns.map((column, index) => {
        if (withTitle && index === 0) return null

        let val = ''
        let colVal = ''

        if (column && column.dataIndex && column.dataIndex.length > 0) {
          colVal = row
          for (let i = 0; i < column.dataIndex.length; i += 1) {
            if (column && (colVal[column.dataIndex[i]] || colVal[column.dataIndex[i]] === 0)) {
              colVal = colVal[column.dataIndex[i]]
            } else {
              colVal = null
              break
            }
          }
        }

        val = column.render && column.key !== 'mobileView' ? column.render(colVal, row) : colVal

        if (!val && !mobileTableByPassEmptyValue) return null

        return (
          <Col xs={24} sm={12} key={column.key ? column.key : index} className="mt-3 px-1">
            {column.title}
            <h5>{val}</h5>
          </Col>
        )
      })
    },
    [mobileTableByPassEmptyValue, formattedColumns, withTitle],
  )

  const responsiveColumns = useMemo(() => {
    const newColumns = [...formattedColumns]
    if (isMobileView || isTabView) {
      newColumns.push({
        key: 'mobileView',
        render: loading
          ? () => null
          : (text, row) => {
              let val = ''

              if (
                withTitle &&
                newColumns[0] &&
                newColumns[0].dataIndex &&
                newColumns[0].dataIndex.length > 0
              ) {
                val = row
                for (let i = 0; i < newColumns[0].dataIndex.length; i += 1) {
                  if (newColumns[0] && val[newColumns[0].dataIndex[i]]) {
                    val = val[newColumns[0].dataIndex[i]]
                  } else {
                    val = null
                    break
                  }
                }

                val =
                  newColumns[0].render && newColumns[0].key !== 'mobileView'
                    ? newColumns[0].render(val, row)
                    : val
                if (val)
                  val = (
                    <div style={{ fontSize: 18, fontWeight: 'bold', padding: '4px 0' }}>{val}</div>
                  )
              }

              return (
                <Card title={val} size="small">
                  <Row>{renderMobileRow(row)}</Row>
                </Card>
              )
            },
      })
    }

    return newColumns
  }, [formattedColumns, isMobileView, isTabView, loading, renderMobileRow, withTitle])

  const emptyMessage = useMemo(() => {
    return name
      ? intl.formatMessage({ id: 'table.data_x_empty' }, { name })
      : intl.formatMessage({ id: 'table.data_empty' })
  }, [intl, name])

  return (
    <ConfigProvider
      renderEmpty={() =>
        loading ? (
          <div style={{ height: 140 }} />
        ) : (
          <Empty
            title={emptyTitle ?? emptyMessage}
            description={emptyDescription}
            appendDescription={appendEmptyDescription}
            name={name}
            onAdd={onAdd}
          />
        )
      }
    >
      <Table
        style={{ minHeight: loading ? 200 : undefined, ...style }}
        className={className}
        showHeader={showHeader}
        rowKey={rowKey}
        columns={responsiveColumns}
        components={components}
        scroll={scroll}
        dataSource={dataSource}
        pagination={pagination}
        expandable={{
          expandedRowRender,
          expandedRowKeys,
          onExpandedRowsChange,
          onExpand,
          expandRowByClick,
        }}
        footer={footer}
        loading={
          loading && {
            indicator: (
              <div className="w-100">
                <Skeleton
                  paragraph={{ rows: 4 }}
                  className="ml-2 pr-2"
                  active
                />
              </div>
            ),
            style: { left: '-50%', top: 'calc(-50% + 80px)' },
          }
        }
        rowClassName={rowClassName}
        onChange={onChange}
        onRow={onRow}
        sortDirections={sortDirections}
        summary={loading ? null : summary}
        rowSelection={
          rowSelection
            ? {
                ...rowSelection,
                renderCell: (checked, record, index, originNode) => (
                  <div
                    onClick={(e) => e.stopPropagation()}
                    style={{
                      position: 'absolute',
                      height: '100%',
                      width: '100%',
                      left: 0,
                      top: 0,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'default',
                    }}
                  >
                    {originNode}
                  </div>
                ),
              }
            : undefined
        }
        // expandedRowKeys={expandedRowKeys}
        // expandedRowRender={expandedRowRender}
        // onExpandedRowsChange={onExpandedRowsChange}
        // onExpand={onExpand}
        // expandRowByClick={expandRowByClick}
        bordered={bordered}
        {...props}
      />
    </ConfigProvider>
  )
}

const mapStateToProps = (state) => {
  return {
    isMobileView: state.settings.isMobileView,
    isTabView: state.settings.isTabView,
  }
}

export default connect(mapStateToProps, {})(injectIntl(ResponsiveTable))

const Summary = connect(mapStateToProps, {})(ResponsiveTableSummary)

export { Summary, Empty }
