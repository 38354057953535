import React, { useCallback, useEffect } from 'react'
import { addLocaleData, IntlProvider } from 'react-intl'
import { connect, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import 'dayjs/locale/id'
import store from 'store'
import { ThemeProvider } from 'styled-components'
import { ConfigProvider as AntdProvider, theme } from 'antd'

import english from 'locales/en-US'
import indonesia from 'locales/id-ID'
import { styledComponentsTheme } from 'styledComponentsTheme'
import { optionsSelector } from 'redux/selectors'
import { allowedLocales } from 'utils/static'

const { defaultAlgorithm, darkAlgorithm } = theme

addLocaleData(english.localeData)
addLocaleData(indonesia.localeData)

const locales = {
  'en-US': english,
  'id-ID': indonesia,
}

const ConfigProvider = ({ children, settings }) => {
  const options = useSelector(optionsSelector)

  const setLocale = useCallback(() => {
    if (settings.locale === 'id-ID') {
      dayjs.locale('id')
    } else {
      dayjs.locale('en')
    }
  }, [settings.locale])

  const setDarkMode = useCallback(() => {
    if (options && options?.dark_mode === 1) {
      store.set('app.darkMode', 1)
      document.documentElement.classList.add('dark')
      document.documentElement.setAttribute('data-color-scheme', 'dark')
    } else if (options && options?.dark_mode === 0) {
      store.remove('app.darkMode')
      document.documentElement.classList.remove('dark')
      document.documentElement.setAttribute('data-color-scheme', 'light')
    }
  }, [options])

  useEffect(() => {
    setLocale()
  }, [setLocale])

  useEffect(() => {
    if (options) {
      setDarkMode()
    }
  }, [options, setDarkMode])

  const currentLocale =
    locales[allowedLocales.includes(settings.locale) ? settings.locale : 'id-ID']

  const config = Object.freeze({
    algorithm: options && options?.dark_mode === 1 ? darkAlgorithm : defaultAlgorithm,
    token: {
      colorPrimary: '#4677f3',
      fontSize: 13,
    },
    components: {
      Table: {
        headerBg: options && options?.dark_mode === 1 ? '#424242' : '#F2F4F8',
        rowHoverBg: options && options?.dark_mode === 1 ? '#424242' : '#F2F4F8',
      },
      Tooltip: {
        zIndexPopup: 2071,
        colorBgSpotlight: 'rgba(0, 0, 0, 0.75)',
      },
      DatePicker: {
        cellHoverWithRangeBg: options && options?.dark_mode === 1 ? '#424242' : '',
      },
    },
  })

  return (
    <AntdProvider locale={currentLocale.antdData} theme={config}>
      <IntlProvider locale={currentLocale.locale} messages={currentLocale.messages}>
        <ThemeProvider theme={styledComponentsTheme}>{children}</ThemeProvider>
      </IntlProvider>
    </AntdProvider>
  )
}

export default connect(({ settings }) => ({ settings }))(ConfigProvider)
