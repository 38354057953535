import { useSelector } from 'react-redux'

import { termTitleSelector, userSelector } from 'redux/selectors'

/**
 * @description use https://www.npmjs.com/package/pluralize
 * if the english term is can be change from user
 *
 * @param intl
 * @returns {{reference: string, product: string, vendor: string, tag: string, customer: string}}
 */
export const getPluralize = ({ intl }) => ({
  customer: `${intl.formatMessage({ id: 'report.customer' })}s`,
  tag: `${intl.formatMessage({ id: 'financeTag.financeTag' })}s`,
  reference: `${intl.formatMessage({ id: 'report.reference' })}s`,
  vendor: `${intl.formatMessage({ id: 'report.vendor' })}s`,
  product: `${intl.formatMessage({ id: 'report.product' })}s`,
  sales_person: intl.formatMessage({ id: 'salesPerson.sales_person' }),
  payable: intl.formatMessage({ id: 'creditMemo.credit_memo' }),
  receivable: intl.formatMessage({ id: 'debitMemo.debit_memo' }),
})

export const getTerm = ({ intl, term, plural }) => {
  if (plural) {
    return getPluralize({ intl })
  }

  return {
    customer: term?.customer ?? intl.formatMessage({ id: 'report.customer' }),
    tag: term?.tag ?? intl.formatMessage({ id: 'financeTag.financeTag' }),
    reference: term?.reference ?? intl.formatMessage({ id: 'report.reference' }),
    vendor: term?.vendor ?? intl.formatMessage({ id: 'report.vendor' }),
    product: term?.product ?? intl.formatMessage({ id: 'report.product' }),
    sales_person: term?.sales_person ?? intl.formatMessage({ id: 'salesPerson.sales_person' }),
    payable: term?.payable ?? intl.formatMessage({ id: 'creditMemo.credit_memo' }),
    receivable: term?.receivable ?? intl.formatMessage({ id: 'debitMemo.debit_memo' }),
  }
}

const useTerm = ({ intl, plural }) => {
  const term = useSelector(termTitleSelector)
  const user = useSelector(userSelector)

  return getTerm({ intl, term, plural: plural && user?.lang === 'en-US' })
}

export default useTerm
