import { Link } from 'react-router-dom'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showAccountTransactionReport } from 'redux/finance/actions'
import { checkPermission } from 'redux/user/selectors'

const DetailAccountModal = ({ data, ...props }) => {
  const dispatch = useDispatch()
  const hasAccountPermission = useSelector((state) => checkPermission(state, 'accounts'))

  const showAccountTransHandler = (e, account) => {
    e.preventDefault()
    if (account) {
      dispatch(
        showAccountTransactionReport({
          id: account?.id,
        }),
      )
    }
  }

  const getAccountTransUrl = (account) =>
    account ? `/reports/account-transaction/${account.id}` : ''

  return (
    <>
      {hasAccountPermission ? (
        <Link
          onClick={(e) => showAccountTransHandler(e, data)}
          className="text-primary"
          to={getAccountTransUrl(data)}
          {...props}
        >
          {data && `${data?.ref_code} ${data?.name}`}
        </Link>
      ) : (
        data && `${data?.ref_code} ${data?.name}`
      )}
    </>
  )
}

export default DetailAccountModal
