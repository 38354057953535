import React, { useCallback, useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import { Button, Form, Modal, Progress, Result } from 'antd'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import { transType } from 'utils/static'
import { titleSelector } from 'redux/selectors'
import { Cancel } from 'components/UI'
import { useNotificationProgress } from 'utils/hooks'
import MassMinTransDateForm from 'components/UI/MassCreateTransModal/MassMinTransDateForm'
import InfoTable from 'components/UI/MassCreateTransModal/InfoTable'

const MassCreateTransModal = ({
  intl,
  show,
  onCancel,
  loadingTransDate,
  loadingInfo,
  getMinTransDate,
  getInfo,
  onExecute,
  onCompleted,
  info,
  type,
  originTransType,
  minTransDate,
}) => {
  const [formValues, setFormValues] = useState(null)

  const [form] = Form.useForm()

  const {
    onSetChannel,
    progress: { percentage, total, imported, data: progressData },
    onDisconnect,
    onResetProgress,
    statusImported,
  } = useNotificationProgress()

  const titleName = useCallback((currentType) => {
    switch (currentType) {
      case transType.INVOICE:
        return 'invoices'
      case transType.DELIVERY:
        return 'deliveries'
      case transType.ORDER:
        return 'orders'
      case transType.PURCHASE_INVOICE:
        return 'purchase_invoices'
      case transType.PURCHASE_DELIVERY:
        return 'purchase_deliveries'
      case transType.PURCHASE_ORDER:
        return 'purchase_orders'
      default:
        return 'invoices'
    }
  }, [])

  const title = useSelector((state) => titleSelector(state, titleName(type)))
  const originTitle = useSelector((state) => titleSelector(state, titleName(originTransType)))
  const [step, setStep] = useState(0)
  const [loadingExecute, setLoadingExecute] = useState(false)

  useEffect(() => {
    if (statusImported) {
      setStep(3)
      setLoadingExecute(false)
      onCompleted()
      onDisconnect()
    }
  }, [statusImported, onCompleted, onDisconnect])

  useEffect(() => {
    if (show && step === 0) {
      getMinTransDate()
    }
    // don't include getMinTransDate or it will infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, step])

  const setChannel = (data) => {
    setLoadingExecute(false)
    if (data) {
      onSetChannel(data.channel, 'App\\Events\\MassInvoiceProgress')
      setLoadingExecute(false)
      setStep(2)
    }
  }

  const submitHandler = (values) => {
    if (!values.trans_date_from_parent_tran) {
      values.trans_date = values.trans_date.format('YYYY-MM-DD')
    } else {
      delete values.trans_date
    }
    values.trans_date_from_parent_tran = Number(values.trans_date_from_parent_tran)
    setFormValues(values)
    getInfo({
      values,
      callback: () => {
        form.resetFields()
        setStep(1)
      },
    })
  }

  const onClose = () => {
    onCancel()
    setStep(0)
    if (form) {
      form.resetFields()
    }
    if (statusImported) {
      setLoadingExecute(false)
      onResetProgress()
    }
  }

  const handleExecute = () => {
    setLoadingExecute(true)
    onExecute(formValues, setChannel)
  }

  const errors = _.isArray(info.errors) ? info.errors : []

  return (
    <Modal
      title={intl.formatMessage({ id: 'financeInvoices.create_mass_trans' }, { title })}
      open={show}
      onCancel={onClose}
      destroyOnClose
      maskClosable={(info.valid ?? 0) <= 0}
      maskTransitionName=""
      width={600}
      footer={
        (info.valid > 0 && step === 1) || step === 0
          ? [
              <Cancel key={0} onClick={onClose} />,
              <Button
                key="submit"
                form="massPaymentForm"
                htmlType="submit"
                type="primary"
                loading={loadingInfo || loadingExecute}
                onClick={step === 1 ? handleExecute : () => form.submit()}
              >
                {intl.formatMessage({ id: 'button.next' })}
              </Button>,
            ]
          : null
      }
    >
      {step === 0 && (
        <MassMinTransDateForm
          loading={loadingTransDate}
          minTransDate={minTransDate}
          onSubmit={submitHandler}
          form={form}
        />
      )}
      {step === 1 && (
        <InfoTable
          loading={loadingInfo}
          info={info}
          errors={errors}
          title={title}
          originTransType={originTransType}
          originTitle={originTitle}
        />
      )}
      {step === 2 && (
        <>
          <Progress percent={percentage} status="active" className="mb-3" />
          <p className="text-center">
            {intl.formatMessage(
              { id: 'financeInvoices.data_progress_create_trans' },
              { count: imported, total, title: title.toLowerCase() },
            )}
          </p>
        </>
      )}
      {step === 3 && (
        <>
          <Result
            status="success"
            title={
              <h5>
                {intl.formatMessage(
                  {
                    id: 'financeInvoices.data_has_been_create_trans',
                  },
                  { count: progressData.valid, title: title.toLowerCase() },
                )}
              </h5>
            }
          />
          <div className="d-flex justify-content-center">
            <Button onClick={onClose}>Ok</Button>
          </div>
        </>
      )}
    </Modal>
  )
}

export default injectIntl(MassCreateTransModal)
