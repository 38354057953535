import axios from 'axios'
import { stringify } from 'query-string'

import { cleanBlankValue, getOnce } from 'utils/helper'

export const getFinancePurchaseQuotes = (params) => {
  const query = stringify(params)
  return getOnce(`/finance/purchaseQuotes?${query}`)
}

export const addFinancePurchaseQuote = (data) => {
  return axios.post('/finance/purchaseQuotes', data)
}

export const editFinancePurchaseQuote = (data) => {
  return axios.put(`/finance/purchaseQuotes/${data.id}`, data)
}

export const deleteOnAddFinancePurchaseQuoteAttachment = (url) => {
  return axios.delete('/finance/purchaseQuotes/attachments', { data: { url } })
}

export const deleteOnEditFinancePurchaseQuoteAttachment = (id, url) => {
  return axios.delete(`/finance/purchaseQuotes/${id}/attachments`, { data: { url } })
}

export const getFinancePurchaseQuote = (id) => {
  return axios.get(`/finance/purchaseQuotes/${id}`)
}

export const getFinancePurchaseQuoteLog = (id, params) => {
  const query = params ? `?${stringify(params)}` : ''
  return axios.get(`/finance/purchaseQuotes/${id}/logs${query}`)
}

export const changeFinancePurchaseQuoteStatus = (id, data) => {
  return axios.patch(`/finance/purchaseQuotes/${id}/status`, data)
}

export const deleteFinancePurchaseQuote = (id) => {
  return axios.delete(`/finance/purchaseQuotes/${id}`)
}

export const uploadImportFinancePurchaseQuote = (data) => {
  return axios.post('/finance/purchaseQuotes/uploadImport', data)
}

export const executeImportFinancePurchaseQuote = (data) => {
  return axios.post('/finance/purchaseQuotes/executeImport', data)
}

export const getFinancePurchaseQuotePublicUrl = (id) => {
  return axios.get(`/finance/purchaseQuotes/${id}/publicUrl`)
}

export const exportFinancePurchaseQuote = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/finance/purchaseQuotes/export?${query}`, { responseType: 'arraybuffer' })
}

export const getFinancePurchaseQuoteWaTemplate = (id) => {
  return axios.get(`/finance/purchaseQuotes/${id}/whatsapp`)
}

export const getFinancePurchaseQuoteEmailTemplate = (id) => {
  return axios.get(`/finance/purchaseQuotes/${id}/email`)
}

export const sendFinancePurchaseQuoteEmail = (id, data) => {
  return axios.post(`/finance/purchaseQuotes/${id}/email`, data)
}

export const getFinancePurchaseQuoteSmsTemplate = (id) => {
  return axios.get(`/finance/purchaseQuotes/${id}/sms`)
}

export const sendFinancePurchaseQuoteSms = (id, data) => {
  return axios.post(`/finance/purchaseQuotes/${id}/sms`, data)
}

export const inputMassDeleteFinancePurchaseQuote = (data) => {
  return axios.post('/finance/purchaseQuotes/inputMassDelete', data)
}

export const executeMassDeleteFinancePurchaseQuote = (data) => {
  return axios.delete('/finance/purchaseQuotes/executeMassDelete', { data })
}

export const inputMassSendEmailFinancePurchaseQuote = (data) => {
  return axios.post('/finance/purchaseQuotes/inputMassEmail', data)
}

export const executeMassSendEmailFinancePurchaseQuote = (data) => {
  return axios.post('/finance/purchaseQuotes/executeMassEmail', data)
}

export const getMassDownloadFinancePurchaseQuote = (params) => {
  return axios.get('/finance/purchaseQuotes/massDownload', { params })
}

export const approveFinancePurchaseQuote = ({ id }) => {
  return axios.post(`/finance/purchaseQuotes/${id}/approve`)
}

export const rejectFinancePurchaseQuote = ({ id, body }) => {
  return axios.post(`/finance/purchaseQuotes/${id}/reject`, body)
}

export const revertApprovalFinancePurchaseQuote = ({ id }) => {
  return axios.post(`/finance/purchaseQuotes/${id}/revert`)
}
