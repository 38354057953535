import React from 'react'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { PrinterOutlined } from '@ant-design/icons'

function Print(props) {
  const title =
    props.title === 'button.print' ? props.intl.formatMessage({ id: props.title }) : props.title
  return (
    <Button {...props} title={title}>
      {title}
    </Button>
  )
}

Print.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.object,
  title: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
}

Print.defaultProps = {
  type: 'default',
  icon: <PrinterOutlined />,
  title: 'button.print',
  onClick: () => {},
  id: 'btn-print',
}

export default injectIntl(Print)
