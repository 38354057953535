import React from 'react'
import { FormattedHTMLMessage, injectIntl } from 'react-intl'
import { Button, Form, Input, message, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'

import { useLoginWithSsoMutation } from 'utils/queries'
import { loginFormSelector } from 'redux/selectors'
import styles from './sso.module.scss'

const Sso = ({ intl, onBackLogin }) => {
  const [form] = Form.useForm()

  const loginForm = useSelector(loginFormSelector)
  const dispatch = useDispatch()
  const onPush = (payload) => dispatch(push(payload))

  const loginWithSsoMutation = useLoginWithSsoMutation()

  const onSubmit = async () => {
    try {
      const values = await form.validateFields()

      loginWithSsoMutation.mutate(values, {
        onSuccess: (response) => {
          if (response.data.success) {
            window.location.href = response.data.data
          } else {
            message.error(response.data.message)
          }
        },
        onError: (error) => {
          if (error?.data?.message) {
            message.error(error.data.message)
          }
        },
      })
    } catch {
      //
    }
  }

  const backToLogin = () => onPush('/user/login')

  return (
    <>
      <Typography.Title className={`text-center ${styles.title}`} level={1}>
        {intl.formatMessage({ id: 'sso.continue_with_sso' })}
      </Typography.Title>
      <Typography.Paragraph className={`text-center ${styles.desc}`}>
        <FormattedHTMLMessage id="sso.login_sso_desc" />
      </Typography.Paragraph>
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        initialValues={{
          email: '',
        }}
      >
        <Form.Item
          label={intl.formatMessage({ id: 'users.email' })}
          name="email"
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: 'users.please_input_your_email_address',
              }),
            },
            {
              type: 'email',
              message: intl.formatMessage({
                id: 'users.the_input_is_not_valid_email_',
              }),
            },
          ]}
        >
          <Input placeholder="Email" size="default" type="email" autoFocus />
        </Form.Item>
        <Button
          block
          type="primary"
          className="mt-2"
          htmlType="submit"
          loading={loginWithSsoMutation.status === 'loading'}
          disabled={loginWithSsoMutation.status === 'loading'}
        >
          {intl.formatMessage({ id: 'sso.continue_with_sso' })}
        </Button>
        <Button
          block
          className="mt-2"
          htmlType="button"
          onClick={loginForm.showLoginForm ? onBackLogin : backToLogin}
        >
          {intl.formatMessage({ id: 'sso.return_to_login' })}
        </Button>
      </Form>
    </>
  )
}

export default injectIntl(Sso)
