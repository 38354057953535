import {
  getInitsFinance,
  watchlistsDashboardDelete,
  watchlistsDashboardMove,
  watchlistsDashboardPost,
} from 'utils/apis'
import * as actionTypes from 'redux/actionTypes'
import { setFinanceExpenseTerms } from 'redux/financeExpenseTerms/actions'
import { setFinanceTaxes } from 'redux/financeTaxes/actions'
import { setFinanceWareHouses, setFinanceWareHousesRoles } from 'redux/financeWareHouses/actions'
import { setFinanceUnits } from 'redux/financeUnits/actions'
import { setShippingComps } from 'redux/shippingComps/actions'
import { setTransactionFees } from 'redux/transactionFees/actions'

const setFinanceConfig = (payload, translation) => {
  const contactTypes = []

  ;(payload?.contact_type ?? []).forEach((contactType) => {
    if (contactType.id === 1) {
      contactTypes.push({
        id: 1,
        name: translation?.term?.vendor ?? 'Vendor',
      })
      return null
    }
    if (contactType.id === 3) {
      contactTypes.push({
        id: 3,
        name: translation?.term?.customer ?? 'Customer',
      })
      return null
    }
    contactTypes.push(contactType)
    return null
  })

  return {
    type: actionTypes.SET_FINANCE_CONFIG,
    payload: {
      ...payload,
      contact_type: contactTypes,
    },
  }
}

export const setFinanceConfigDefaultAccount = (payload) => {
  return {
    type: actionTypes.SET_FINANCE_CONFIG_DEFAULT_ACCOUNT,
    payload,
  }
}

export const setFinanceBankAccounts = (payload) => {
  return {
    type: actionTypes.SET_FINANCE_BANK_ACCOUNTS,
    payload,
  }
}

export const addFinanceBankAccount = (payload) => {
  return {
    type: actionTypes.ADD_FINANCE_BANK_ACCOUNT,
    payload,
  }
}

export const editFinanceBankAccount = (payload) => {
  return {
    type: actionTypes.EDIT_FINANCE_BANK_ACCOUNT,
    payload,
  }
}

export const deleteFinanceBankAccount = (payload) => {
  return {
    type: actionTypes.DELETE_FINANCE_BANK_ACCOUNT,
    payload,
  }
}

export const setFinanceAccountCategories = (payload) => {
  return {
    type: actionTypes.SET_FINANCE_ACCOUNT_CATEGORIES,
    payload,
  }
}

export const setFinanceProductCategories = (payload) => {
  return {
    type: actionTypes.SET_FINANCE_PRODUCT_CATEGORIES,
    payload,
  }
}

export const setFinanceProductCategoriesRoles = (payload) => {
  return {
    type: actionTypes.SET_FINANCE_PRODUCT_CATEGORIES_ROLES,
    payload,
  }
}

export const setDashboard = (payload) => {
  return {
    type: actionTypes.SET_DASHBOARD,
    payload,
  }
}

export const setADashboard = (payload) => {
  return {
    type: actionTypes.SET_A_DASHBOARD,
    payload,
  }
}

export const addDashboard = (payload) => {
  return {
    type: actionTypes.ADD_DASHBOARD,
    payload,
  }
}

export const editDashboard = (payload) => {
  return {
    type: actionTypes.EDIT_DASHBOARD,
    payload,
  }
}

export const deleteDashboard = (payload) => {
  return {
    type: actionTypes.DELETE_DASHBOARD,
    payload,
  }
}

export const resetDashboard = () => {
  return {
    type: actionTypes.RESET_DASHBOARD,
  }
}

export const filterAccountWatchList = (payload) => {
  return {
    type: actionTypes.FILTER_ACCOUNT_WATCH_LIST,
    payload,
  }
}

export const addAccountWatchListRedux = (payload) => {
  return {
    type: actionTypes.ADD_ACCOUNT_WATCH_LIST,
    payload,
  }
}

export const addAccountWatchList = (payload) => {
  return (dispatch) => {
    return watchlistsDashboardPost(payload).then((response) => {
      dispatch(addAccountWatchListRedux(response.data.data))
      return response
    })
  }
}

export const moveAccountWatchListRedux = (payload) => {
  return {
    type: actionTypes.MOVE_ACCOUNT_WATCH_LIST,
    payload,
  }
}

export const moveAccountWatchList = (payload) => {
  return (dispatch) => {
    dispatch(moveAccountWatchListRedux(payload))

    return watchlistsDashboardMove(payload.srcId, payload.destId)
      .then((response) => {
        return response
      })
      .catch(() => {
        dispatch(
          moveAccountWatchListRedux({
            dragIndex: payload.hoverIndex,
            hoverIndex: payload.dragIndex,
            srcId: payload.destId,
            destId: payload.srcId,
          }),
        )
      })
  }
}

export const deleteAccountWatchListRedux = (id) => {
  return {
    type: actionTypes.DELETE_ACCOUNT_WATCH_LIST,
    payload: { id },
  }
}

export const deleteAccountWatchList = (id) => {
  return (dispatch) => {
    dispatch(deleteAccountWatchListRedux(id))
    return watchlistsDashboardDelete(id).then((response) => {
      return response
    })
  }
}

export const setIDCardTypes = (payload) => {
  return {
    type: actionTypes.SET_ID_CARD_TYPES,
    payload,
  }
}

export const setFinanceApproval = (payload) => {
  return {
    type: actionTypes.SET_FINANCE_APPROVAL,
    payload,
  }
}

export const setPaymentConnectProviders = (payload) => {
  return {
    type: actionTypes.SET_PAYMENT_CONNECT_PROVIDERS,
    payload,
  }
}

export const setFinance = (payload, translation) => {
  return (dispatch) => {
    dispatch(setFinanceConfig(payload.config, translation))
    dispatch(setFinanceAccountCategories(payload.accountCategories))
    dispatch(setFinanceWareHouses(payload.warehouses))
    dispatch(setFinanceWareHousesRoles(payload.warehouses_has_role))
    dispatch(setFinanceExpenseTerms(payload.expenseTerms))
    dispatch(setFinanceTaxes(payload.taxes))
    dispatch(setFinanceUnits(payload.units))
    dispatch(setShippingComps(payload.shippingComps))
    dispatch(setFinanceProductCategories(payload.productCategories))
    dispatch(setFinanceProductCategoriesRoles(payload.product_categories_has_role))
    dispatch(setFinanceBankAccounts(payload.bankAccounts))
    dispatch(setIDCardTypes(payload.id_card_types || []))
    dispatch(setDashboard(payload.dashboard))
    dispatch(setFinanceApproval(payload.finance_approval))
    if (payload.payment_connect_providers) {
      dispatch(setPaymentConnectProviders(payload.payment_connect_providers))
    }

    if (Array.isArray(payload.fees)) {
      dispatch(setTransactionFees(payload.fees))
    }
  }
}

export const initsFinance = () => {
  return (dispatch, getState) => {
    const state = getState()
    return getInitsFinance().then((response) => {
      if (response.data.success) {
        dispatch(setFinance(response.data.data, state.translation))
      }
      return true
    })
  }
}

export const showAccountTransactionReport = (payload) => {
  return {
    type: actionTypes.SHOW_ACCOUNTTRANSACTION_REPORT,
    payload,
  }
}

export const hideAccountTransactionReport = (payload) => {
  return {
    type: actionTypes.HIDE_ACCOUNTTRANSACTION_REPORT,
    payload,
  }
}

export const showAuditModal = (payload) => {
  return {
    type: actionTypes.SHOW_AUDIT_MODAL,
    payload,
  }
}

export const hideAuditModal = (payload) => {
  return {
    type: actionTypes.HIDE_AUDIT_MODAL,
    payload,
  }
}
