import React from 'react'
import { Pagination as PaginationAntd, Typography } from 'antd'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { settingsSelector } from 'redux/selectors'
import { qtyToCurrencyAutoComma } from 'utils/formatting'
import PageSizeChanger from './PageSizeChanger'

export const DEFAULT_PER_PAGES = [15, 25, 50, 100]

function Pagination({ showPageSizeChangeOnSinglePage, pageSizeOptions, disableFloat, ...props }) {
  const settings = useSelector(settingsSelector)

  const innerPageSizeOptions = pageSizeOptions || DEFAULT_PER_PAGES

  if (props.showSizeChanger && showPageSizeChangeOnSinglePage) {
    return (
      <div
        className="d-flex custom-pagination"
        style={{
          float: !disableFloat && (settings.isMobileView ? 'left' : 'right'),
          width: 'auto',
        }}
      >
        {props.total ? (
          <Typography.Text className="ant-pagination-total-text">
            Total {qtyToCurrencyAutoComma({ value: props.total, maxPrecision: 0 })} data
          </Typography.Text>
        ) : null}
        <PageSizeChanger
          pageSizeOptions={innerPageSizeOptions}
          pageSize={props?.pageSize}
          onShowSizeChange={props.onShowSizeChange}
          current={props.current}
        />
      </div>
    )
  }

  return (
    <PaginationAntd
      pageSizeOptions={innerPageSizeOptions}
      showTotal={(total) => {
        if (typeof total === 'number') {
          return `Total ${qtyToCurrencyAutoComma({ value: total, maxPrecision: 0 })} data`
        }
        return ''
      }}
      {...props}
    />
  )
}

Pagination.propTypes = {
  current: PropTypes.number,
  pageSize: PropTypes.number,
  total: PropTypes.number,
  onChange: PropTypes.func,
  onShowSizeChange: PropTypes.func,
  hideOnSinglePage: PropTypes.bool,
  showSizeChanger: PropTypes.bool,
  showPageSizeChangeOnSinglePage: PropTypes.bool,
}

Pagination.defaultProps = {
  current: 1,
  pageSize: 15,
  onChange: () => {},
  onShowSizeChange: () => {},
  total: 0,
  hideOnSinglePage: true,
  showSizeChanger: false,
  showPageSizeChangeOnSinglePage: false,
}

export default Pagination
