import { Checkbox, Form, Input, Modal, message, Typography } from 'antd'
import Axios from 'axios'
import { Cancel, Reset, Save } from 'components/UI'
import React, { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import localStore from 'store'
import { generateBaseUri } from 'utils/helper'

const ChangeEnvModal = ({ visible, onCancel }) => {
  const queryClient = useQueryClient()

  const initialValues = useMemo(() => {
    const isOverwriteBaseUrl = localStore.get('app.isOverwriteBaseUrl')
    return {
      base_url: localStore.get('app.bypassEndpoint') || process.env.REACT_APP_API_URL,
      overwrite_baseurl: typeof isOverwriteBaseUrl !== 'undefined' ? isOverwriteBaseUrl : true,
    }
  }, [])

  const submitHandler = useCallback(
    (values) => {
      localStore.set('app.bypassEndpoint', values.base_url)
      localStore.set('app.isOverwriteBaseUrl', values.overwrite_baseurl)
      queryClient.clear()
      Axios.defaults.baseURL = values.base_url
      message.success('Base URL berhasil diganti')
      onCancel()
    },
    [onCancel, queryClient],
  )

  const resetHandler = useCallback(() => {
    localStore.set('app.bypassEndpoint', '')
    localStore.set('app.isOverwriteBaseUrl', false)
    queryClient.clear()
    Axios.defaults.baseURL = generateBaseUri()
    message.success('Base URL berhasil direset')
    onCancel()
  }, [onCancel, queryClient])

  return (
    <Modal
      title="Ganti Base Url"
      visible={visible}
      destroyOnClose
      onCancel={onCancel}
      maskTransitionName=""
      maskClosable={false}
      footer={[
        <Cancel key="cancel" onClick={onCancel} />,
        <Reset type="success" key="reset" onClick={resetHandler} />,
        <Save form="changeEnvForm" key="submit" htmlType="submit" />,
      ]}
    >
      <Form
        initialValues={initialValues}
        layout="vertical"
        name="changeEnvForm"
        onFinish={submitHandler}
      >
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Silahkan masukkan base url',
            },
          ]}
          name="base_url"
          label="Base URL"
        >
          <Input placeholder="contoh: https://apipos.jokolodang.com/v1/api" autoFocus />
        </Form.Item>
        <Form.Item name="overwrite_baseurl" valuePropName="checked">
          <Checkbox>
            <Typography.Text strong>
              Jika dicentang, hanya berlaku untuk login yang memiliki 1 perusahaan. Overwrite disini
              artinyaa baseurl yang diinputkan akan mengganti endpoint di response init.
            </Typography.Text>
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ChangeEnvModal
