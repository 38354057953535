import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, message, Modal } from 'antd'
import { injectIntl } from 'react-intl'
import localStore from 'store'
import { useDispatch, useSelector } from 'react-redux'

import { locationSelector, loginFormSelector, userSelector } from 'redux/selectors'
import { afterLoginHandler, hideLoginForm } from 'redux/user/actions'
import { setSelectedEndpoint } from 'redux/companies/actions'
import { singleLogin, verifyGoogleToken } from 'utils/apis'
import { setSavedEmail, setToken } from 'utils/cookies'
import { setErrorLoginForm } from 'redux/loginForm/actions'
import LoginForm from 'components/apps/User/Login'
import Sso from 'containers/Users/Login/Sso'

const LoginModal = ({ intl }) => {
  const [showSso, setShowSso] = useState(false)

  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  const loginForm = useSelector(loginFormSelector)
  const location = useSelector(locationSelector)

  const [loading, setLoading] = useState(false)

  // Untuk pengecekan posisi halaman di form atau tidak
  const isSafeToReload = useMemo(() => {
    const avoidUrl = [
      'add',
      'edit',
      'import',
      'transfer',
      'spend',
      'receive',
      'register',
      'dispose',
    ]
    let safeToReload = true

    avoidUrl.forEach((url) => {
      if (location.pathname.includes(url)) {
        safeToReload = false
      }
    })
    return safeToReload
  }, [location.pathname])

  // Diparsing baseurl nya sesuai di base url axios
  const parseBaseUrl = (url) => {
    let baseURL = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
    baseURL = baseURL.replace(/\/api\/v1+$/, '')
    return baseURL
  }

  useEffect(() => {
    if (!loginForm.showLoginForm) {
      dispatch(setErrorLoginForm(null))
    }
  }, [dispatch, loginForm.showLoginForm])

  const hideHandler = useCallback(() => dispatch(hideLoginForm()), [dispatch])
  const login = useCallback(
    async (values) => {
      try {
        setLoading(true)
        setSavedEmail(values.email)
        // dispatch(setErrorLoginForm(null));
        const response = await singleLogin(values.email, values.password, values.remember_me)
        setLoading(false)
        if (response.data.success) {
          // Jika dari api tidak ada return endpoint, untuk ambil endpoin dari config
          if (!response.data?.data?.init?.endpoint && !localStore.get('app.endpoint')) {
            response.data.data.endpoint = parseBaseUrl(response.config?.baseURL)
          }
          // Sebelum reload dicek dulu apakah posisi input data
          if (isSafeToReload) {
            dispatch(afterLoginHandler(response, {}, true))
            window.location.reload(true)
          } else {
            if (user.email === response.data?.data?.user?.email) {
              setToken(response.data?.data?.data?.access_token, values.remember_me)
              // Jika dapat endpoint dari login dan di localstoragenya kosong
              if (response.data?.data?.init?.endpoint && !localStore.get('app.endpoint')) {
                dispatch(setSelectedEndpoint(response.data?.data?.init.endpoint))
              }
            } else {
              dispatch(afterLoginHandler(response, {}, true))
            }
            hideHandler()
          }
        } else {
          message.error(response.data.message)
        }
      } catch (e) {
        setLoading(false)
        if (e && e.data && e.data.message) {
          dispatch(setErrorLoginForm(e.data.message))
        }
      }
    },
    [dispatch, hideHandler, isSafeToReload, user],
  )

  const onGoogleLogin = useCallback(
    async (payload) => {
      if (payload?.code) {
        try {
          setLoading(true)
          const response = await verifyGoogleToken(payload.code)
          setLoading(false)
          if (response.data.success) {
            // if (user.email === response.data.data.user.email) {
            //   setSavedEmail(response.data.data.user.email);
            //   setToken(response.data.data.data.access_token);
            // } else {
            //   dispatch(afterLoginHandler(response, { email: response.data.data.user.email }, true));
            // }
            // hideHandler();
            // Jika dari api tidak ada return endpoint, untuk ambil endpoin dari config
            if (!response.data?.data?.init?.endpoint && !localStore.get('app.endpoint')) {
              response.data.data.endpoint = parseBaseUrl(response.config?.baseURL)
            }
            // Sebelum reload dicek dulu apakah posisi input data
            if (isSafeToReload) {
              dispatch(afterLoginHandler(response, { email: response.data.data.user.email }, true))
              window.location.reload(true)
            } else {
              if (user.email === response.data?.data?.user?.email) {
                setToken(response.data.data.data.access_token, payload.remember_me)
                // Jika dapat endpoint dari login dan di localstoragenya kosong
                if (response.data?.data?.init?.endpoint && !localStore.get('app.endpoint')) {
                  dispatch(setSelectedEndpoint(response.data?.data?.init.endpoint))
                }
              } else {
                dispatch(
                  afterLoginHandler(response, { email: response.data.data.user.email }, true),
                )
              }
              hideHandler()
            }
          } else {
            message.error(response.data.message)
          }
        } catch (e) {
          setLoading(false)
          if (e && e.data && e.data.message) {
            dispatch(setErrorLoginForm(e.data.message))
          }
        }
      }
    },
    [dispatch, hideHandler, isSafeToReload, user.email],
  )

  return (
    <Modal
      open={loginForm.showLoginForm}
      onCancel={hideHandler}
      footer={null}
      closable={false}
      maskClosable={false}
      destroyOnClose
      maskTransitionName=""
    >
      <h5 className="text-uppercase">
        <strong>
          {loginForm.message ||
            intl.formatMessage({ id: 'users.you_have_logged_out_automatically' })}
        </strong>
      </h5>
      {loginForm.error && (
        <Alert message={loginForm.error} type="error" showIcon className="mt-3" />
      )}
      <br />
      {showSso ? (
        <Sso onBackLogin={() => setShowSso(false)} />
      ) : (
        <LoginForm
          loading={loading}
          onSubmit={login}
          onGoogleLogin={onGoogleLogin}
          onSsoLogin={() => setShowSso(true)}
        />
      )}
    </Modal>
  )
}

export default injectIntl(LoginModal)
