/* eslint-disable react/no-unused-state */
import React from 'react'
import { injectIntl } from 'react-intl'
import { Form, Select, Tag } from 'antd'
import { connect } from 'react-redux'
import uuid from 'uuid'
import _ from 'lodash'

import { SearchInput } from 'components/UI'
import AddFinanceTag from 'components/apps/FinanceTag/AddFinanceTag'
import { getModulePermissions } from 'redux/user/selectors'
import { getTerm } from 'utils/hooks/useTerm'
import { checkContrast, hexToRGB } from 'utils/helper'
import withTagSuggestion from './withTagSuggestion'
import withAddTag from './withAddTag'
import styles from './tag-suggestion.module.scss'

/**
 * @desc Tag suggestion component
 */
class TagSuggestion extends React.PureComponent {
  containerId = `suggestion-tag-${uuid()}`

  popUpClassName = `suggestion-tag-popup-${uuid()}`

  state = {
    defaultTags: [],
    open: false,
    finishFirstSync: false,
  }

  allItem = {
    id: '',
    name: this.props.intl.formatMessage({ id: 'financeTag.all_tags' }),
    color: null,
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.open !== this.state.open) {
      this.afterVisibleChangeHanlder()
    }

    // Sementara dikomen, karena ada masalah mempengaruhi rerender, menyebabkan defaultTag form kereset
    // if (!this.state.finishFirstSync) {
    //   if (_.isArray(this.props.defaultTag)) {
    //     this.setState({
    //       defaultTags: this.props.defaultTag,
    //     })
    //   }
    //   this.setState({ finishFirstSync: true })
    // }

    if (
      typeof this.props.getDefaultTags === 'function' &&
      prevState.defaultTags !== this.state.defaultTags
    ) {
      this.props.getDefaultTags(this.state.defaultTags)
    }
  }

  afterSavedHandler = (data) => {
    if (data.id) {
      const newData = { id: data.id, name: data.name, color: data.color }
      this.setState(
        (prevState) => {
          return {
            defaultTags: [...prevState.defaultTags, newData],
          }
        },
        () => {
          if (this.props.onAfterSaved) {
            this.props.onAfterSaved(newData)
          }
        },
      )
    }
  }

  showAddTagHandler = (e) => {
    e.preventDefault()
    this.props.onToggleTagModal()
  }

  popUpScrollHandler = (e) => {
    if (this.props.infiniteScroll) {
      this.props.onLoadMore(e, this.props.params)
    }
  }

  afterVisibleChangeHanlder = () => {
    if (this.state.open) {
      this.props.onFetchData(
        {
          search: '',
          ...this.props.params,
        },
        this.resetScrollTop,
      )
    } else {
      this.props.onResetSearch()
    }
  }

  dropdownVisibleChangeHandler = (open) => this.setState({ open })

  // Find container scroll element dropdown menu
  scrollToN = (nodes = []) => {
    if (nodes.length > 0) {
      if (nodes[1]) {
        if (nodes[1].scrollTo) {
          nodes[1].scrollTo(0, 32)
        }
      } else {
        this.scrollToN(nodes[0].childNodes)
      }
    }
  }

  resetScrollTop = () => {
    const container = document.getElementById(this.containerId)
    const menu = container.getElementsByClassName(this.popUpClassName)
    if (menu.length > 0) {
      setTimeout(() => {
        if (menu[0]) {
          this.scrollToN(menu[0].childNodes)
        }
      }, 10)
    }
  }

  getPopupContainer = () => document.getElementById(this.containerId)

  getOptions = () => {
    const { tags, defaultTag, execludeTags, withExtra, permissions } = this.props
    const options = []

    if (Array.isArray(defaultTag)) {
      defaultTag.forEach((row) => {
        options.push({ id: row.id, name: row.name, color: row.color })
      })
    }

    this.state.defaultTags.forEach((row) => {
      options.push({ id: row.id, name: row.name, color: row.color })
    })

    if (this.props.asFilter) {
      options.push(this.allItem)
    }

    let data = _.cloneDeep(tags)
    // Filter option is not to displayed
    if (execludeTags.length > 0) {
      data = data.filter(
        (row) =>
          !_.includes(
            execludeTags.map((exclude) => exclude),
            row.id,
          ),
      )
    }

    // set option from default item, usally for update view, add option, filter
    options.forEach((row) => {
      const findItem = data.find((item) => row.id === item.id)
      if (!findItem) {
        data.unshift(row)
      }
    })

    if (this.props.search && withExtra && permissions.isCanAdd) {
      const findItem = data.find((item) => item.name === this.props.search || item.id === 0)
      if (!findItem) {
        data.unshift({ id: 0, name: this.props.search, color: null })
      } else if (findItem.id > 0) {
        data = data.filter((row) => row.id !== findItem.id)
        data.unshift(findItem)
      }
    }

    return data
  }

  selectedHandler = (value, options = []) => {
    const findData = options.find((row) => row.id === value)
    if (findData) {
      if (value) {
        this.setState((prevState) => {
          return {
            defaultTags: [
              ...prevState.defaultTags,
              { name: findData.name, id: findData.id, color: findData.color },
            ],
          }
        })
      } else if (this.props.withExtra && this.props.permissions.isCanAdd && value === 0) {
        this.props.addFinanceTag({ name: findData.name }).then((response) => {
          if (response.data.success) {
            const { data } = response.data
            this.setState(
              (prevState) => {
                return {
                  defaultTags: [
                    ...prevState.defaultTags,
                    { name: data.name, id: data.id, color: data.color },
                  ],
                }
              },
              () => {
                if (this.props.form) {
                  const oldTags = this.props.form.getFieldValue(this.props.name) || []
                  const newTags = [...oldTags.filter((row) => row > 0), data.id]
                  this.props.form.setFieldValue(this.props.name, newTags)
                }
              },
            )
          }
        })
      }
    }
    if (this.props.onSelect) {
      this.props.onSelect(value, options, findData, this.state.defaultTags)
    }
  }

  searchChangeHandler = (value) => {
    if (this.state.open) {
      this.props.onSearchTag(value, this.props.params)
    }
  }

  deselectHandler = (value) => {
    const findData = this.state.defaultTags.find((row) => row.id === value)
    if (findData) {
      this.setState((prevState) => {
        return {
          defaultTags: prevState.defaultTags.filter((row) => row.id !== value),
        }
      })
    }
    if (typeof this.props.onDeselect === 'function') return this.props.onDeselect(value)
    return null
  }

  clearHandler = () => {
    this.setState({ defaultTags: [] })
    if (typeof this.props.onClear === 'function') return this.props.onClear()
    return null
  }

  render() {
    const {
      params,
      form,
      formItemLoaderTag,
      rules,
      tags,
      onSearchTag,
      label,
      withLabel,
      name,
      initialValue,
      defaultTag,
      onSelect,
      styleFormItem,
      loadingSearchTag,
      config,
      showTagModal,
      tagFormRef,
      loadingTag,
      onSubmitTag,
      onToggleTagModal,
      intl,
      onAfterSaved,
      dispatch,
      asFilter,
      onLoadMore,
      infiniteData,
      infiniteScroll,
      onFetchData,
      innerRef,
      onResetSearch,
      execludeTags,
      isListField,
      fieldKey,
      permissions,
      tooltip,
      withTerm,
      term,
      search,
      addFinanceTag,
      getDefaultTags,
      formItemClassName,
      ownerOnly,
      ...props
    } = this.props

    const newInitialValue = initialValue < 0 ? undefined : initialValue
    const options = this.getOptions()

    let _label = label
    let _placeholder = intl.formatMessage({ id: 'financeTag.select_tag' })
    let _extraTitle = intl.formatMessage({ id: 'financeTag.add_tag' })

    if (withTerm || !label) {
      const _term = getTerm({ intl, term })
      _label = _term.tag
      _placeholder = intl.formatMessage({ id: 'text.select' }, { title: _term.tag })
      _extraTitle = intl.formatMessage({ id: 'button.add_with_title' }, { title: _term.tag })
    }

    return (
      <>
        <div className="tag-suggestion-container" id={this.containerId}>
          <Form.Item
            className={formItemClassName}
            {...formItemLoaderTag}
            label={withLabel ? _label : null}
            style={styleFormItem}
            initialValue={newInitialValue}
            rules={rules}
            name={name}
            isListField={isListField}
            tooltip={tooltip}
          >
            <SearchInput
              placeholder={_placeholder}
              extraTitle={_extraTitle}
              {...props}
              withExtra={this.props.withExtra && permissions.isCanAdd}
              onSearch={this.searchChangeHandler}
              onSelect={(value) => this.selectedHandler(value, options)}
              onExtraClick={this.showAddTagHandler}
              onPopupScroll={this.popUpScrollHandler}
              infiniteScroll
              infiniteData={infiniteData}
              onDropdownVisibleChange={this.dropdownVisibleChangeHandler}
              allowClear
              ref={innerRef}
              getPopupContainer={this.getPopupContainer}
              popupClassName={this.popUpClassName}
              mode="multiple"
              open={this.state.open}
              defaultActiveFirstOption={this.props.withExtra && permissions.isCanAdd}
              onDeselect={this.deselectHandler}
              onClear={this.clearHandler}
              tagRender={({ label: tagLabel, value, closable, onClose }) => {
                const onPreventMouseDown = (event) => {
                  event.preventDefault()
                  event.stopPropagation()
                }

                const tag = options.find((item) => item.id === value)

                const backgroundColor = hexToRGB(tag?.color ?? '#F2F4F8')
                const color =
                  checkContrast(backgroundColor, 'rgb(255, 255, 255)') >= 4.5
                    ? 'rgb(255, 255, 255)'
                    : 'rgb(0, 0, 0)'

                return (
                  <Tag
                    color={backgroundColor}
                    onMouseDown={onPreventMouseDown}
                    closable={closable}
                    onClose={onClose}
                    style={{ '--color': color }}
                    className={styles.tag}
                  >
                    {tagLabel}
                  </Tag>
                )
              }}
            >
              {options.map((row) => {
                return (
                  <Select.Option
                    key={row.id}
                    value={row.id}
                    style={{
                      fontWeight: row.id !== '' ? 'normal' : 'bold',
                    }}
                  >
                    {`${row.name}`}
                  </Select.Option>
                )
              })}
            </SearchInput>
          </Form.Item>
        </div>
        <AddFinanceTag
          show={showTagModal}
          ref={tagFormRef}
          confirmLoading={loadingTag}
          submitHandler={() => onSubmitTag(this.afterSavedHandler)}
          hideHandler={onToggleTagModal}
        />
      </>
    )
  }
}

TagSuggestion.defaultProps = {
  onSelect: () => {},
  onAfterSaved: () => {},
  infiniteScroll: true,
  onFocus: () => {},
  execludeTags: [],
  allowClear: true,
  asFilter: false,
  withExtra: true,
  withLabel: true,
  ownerOnly: false,
}

const mapStateToProps = (state) => {
  return {
    permissions: getModulePermissions(state, 'tags'),
    term: state.translation.term,
  }
}

const Component = connect(
  mapStateToProps,
  {},
)(withAddTag(withTagSuggestion(injectIntl(TagSuggestion))))

export default React.forwardRef((props, ref) => <Component innerRef={ref} {...props} />)
