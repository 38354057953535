import axios from 'axios'
import { stringify } from 'query-string'

import { cleanBlankValue } from 'utils/helper'

export const getFinanceConversionBalances = () => {
  return axios.get('/finance/conversionBalances')
}

export const getFinanceConversionBalancesDate = () => {
  return axios.get('/finance/conversionBalances/date')
}

export const getFinanceConversionBalance = (id) => {
  return axios.get(`/finance/conversionBalances/${id}`)
}

export const saveFinanceConversionBalanceDate = (data) => {
  return axios.put('/finance/conversionBalances/date', data)
}

export const saveFinanceConversionBalances = () => {
  return axios.post('/finance/conversionBalances')
}

export const editFinanceConversionBalances = (id, data) => {
  return axios.put(`/finance/conversionBalances/${id}`, data)
}

export const deleteFinanceConversionBalances = (id) => {
  return axios.delete(`/finance/conversionBalances/${id}`)
}

export const getFinanceReversal = (id) => {
  return axios.get(`/finance/reversals/${id}`)
}

export const getFinanceOpeningBalance = (id) => {
  return axios.get(`/finance/openingBalances/${id}`)
}

export const uploadImportFinanceConversionBalances = (data) => {
  return axios.post('/finance/conversionBalances/uploadImport', data)
}

export const executeImportFinanceConversionBalances = (data) => {
  return axios.post('/finance/conversionBalances/executeImport', data)
}

export const exportConversionBalances = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/finance/conversionBalances/export?${query}`, { responseType: 'arraybuffer' })
}
