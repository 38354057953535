import { useDispatch } from 'react-redux'
import { useCallback, useEffect } from 'react'
import _ from 'lodash'
import { stringify } from 'query-string'
import { replace } from 'connected-react-router'

import { cleanBlankValue } from 'utils/helper'
import usePrevious from './usePrevious'
import usePermission from './usePermission'

const useReport = ({
  params,
  setParams,
  parseParamsToUri,
  parseUriToParams,
  reportPermissionName,
  intl,
  filters,
  location,
}) => {
  const { isHasAccess, loadedInit } = usePermission({
    name: reportPermissionName,
    intl,
    parentPermission: 'reports',
  })

  const prevParams = usePrevious(params)

  const dispatch = useDispatch()
  const onReplace = useCallback((path) => dispatch(replace(path)), [dispatch])

  const _parseParamsToUri = useCallback(
    ({ params: __params = {} } = {}) => {
      let currentParams = _.cloneDeep({ ...params, ...__params })
      if (currentParams.page === 1) currentParams.page = null

      if (typeof parseParamsToUri === 'function') {
        currentParams = {
          ...parseParamsToUri({
            params: currentParams,
          }),
        }
      }

      const payload = cleanBlankValue(currentParams)
      const query = stringify(payload, { arrayFormat: 'bracket' })
      onReplace(`${location.pathname}?${query}`)
    },
    [params, parseParamsToUri, onReplace, location.pathname],
  )

  const paramChangeHandler = useCallback(
    (_params) => {
      setParams((prevState) => {
        return {
          ...prevState,
          ..._params,
        }
      })
    },
    [setParams],
  )

  const _parseUriToParams = useCallback(() => {
    if (!_.isEmpty(filters)) {
      let currentFilters = { ...filters }
      if (typeof parseUriToParams === 'function') {
        currentFilters = {
          ...parseUriToParams({ filters: currentFilters }),
        }
      }
      paramChangeHandler(currentFilters)
    }
  }, [filters, paramChangeHandler, parseUriToParams])

  useEffect(() => {
    _parseUriToParams()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!_.isEmpty(prevParams) && !_.isEqual(prevParams, params)) {
      _parseParamsToUri()
    }
  }, [loadedInit, params, _parseParamsToUri, prevParams])

  const pageChangeHandler = useCallback(
    (page, per_page) => paramChangeHandler({ page, per_page }),
    [paramChangeHandler],
  )

  const onTableChange = useCallback(
    (pagination, _filters, sorter) => {
      setParams((prevState) => {
        let orderBy = ''
        if (sorter.order === 'ascend') orderBy = 'asc'
        else if (sorter.order === 'descend') orderBy = 'desc'

        let sortBy

        if (sorter?.field?.[0]) {
          sortBy = typeof sorter?.field === 'string' ? sorter?.field : sorter?.field?.[0]
        } else sortBy = sorter.field ?? null

        return {
          ...prevState,
          sort_by: sortBy,
          order_by: orderBy,
        }
      })
    },
    [setParams],
  )

  return {
    pageChangeHandler,
    onTableChange,
    loadedInit,
    isHasAccess,
    parseParamsToUri: _parseParamsToUri,
  }
}

export default useReport
