import React from 'react'
import { injectIntl } from 'react-intl'

import { Pagination, Table } from 'components/UI'

const DataTable = ({
  data,
  columns,
  loading,
  onTableChange,
  onRow,
  rowClassName,
  onPageChange,
  isMobileView,
  onPageSizeChange,
  ...props
}) => {
  return (
    <div className="row">
      <div className="col">
        <div className="row">
          <div className="col mb-4">
            <Table
              dataSource={data?.data || []}
              columns={columns}
              pagination={false}
              loading={loading}
              onChange={onTableChange}
              onRow={onRow}
              rowClassName={rowClassName}
              {...props}
            />
          </div>
        </div>
        {!loading ? (
          <div className="row">
            <div className="col">
              {data?.last_page > 0 && data?.total > 0 && (
                <Pagination
                  current={data?.current_page || 1}
                  pageSize={data?.per_page ? parseInt(data.per_page, 10) : 10}
                  showSizeChanger
                  showPageSizeChangeOnSinglePage={
                    parseInt(data.per_page ?? '0', 10) >= parseInt(data?.total ?? '0', 10)
                  }
                  total={data?.total || 0}
                  onChange={onPageChange}
                  onShowSizeChange={onPageSizeChange}
                  style={{ float: isMobileView ? 'left' : 'right' }}
                  pageSizeOptions={[10, 15, 25, 50, 100]}
                />
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default injectIntl(DataTable)
