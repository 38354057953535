import React from 'react'
import { Button, Form, Popover, Select, Space } from 'antd'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { CaretDownOutlined } from '@ant-design/icons'

import { formatDateText } from 'utils/formatting'
import { filterOption } from 'utils/helper'
import { Filter as ButtonFilter, RangePicker, SearchWidget } from 'components/UI'

const { Option } = Select

// map with name to make easy what are the ids is
export const excludeTransTypeIdsForAccountTransactions = [
  {
    id: 2,
    name: 'Purchase Order',
  },
  {
    id: 4,
    name: 'Quote',
  },
  {
    id: 6,
    name: 'Order',
  },
  {
    id: 51,
    name: 'Warehouse Transfer',
  },
  {
    id: 63,
    name: 'Purchase Quote',
  },
]

class Filter extends React.Component {
  filterFormRef = React.createRef()

  state = {
    search: '',
  }

  onChange = (value) => this.props.setParams({ date_from: value[0], date_to: value[1] })

  filterVisibleChangeHandler = (visible) => {
    if (!visible) {
      const { setParams } = this.props
      const form = this.filterFormRef.current
      form
        .validateFields()
        .then((values) => setParams(values))
        .catch((info) => {
          form.scrollToField(info.errorFields[0].name)
        })
    }
  }

  renderFilter = () => {
    const { params, intl, transTypeIds } = this.props

    const filteredTransTypeIds = (transTypeIds || []).filter(
      (transTypeId) =>
        !excludeTransTypeIdsForAccountTransactions.find(
          (_transType) => transTypeId.id === _transType.id,
        ),
    )

    const children = filteredTransTypeIds.map((item) => (
      <Option key={item.id} value={item.id}>
        {item.name || '-'}
      </Option>
    ))

    return (
      <Form
        style={{ width: 300 }}
        name="FilterForm"
        ref={this.filterFormRef}
        initialValues={{
          sources_id: params.sources_id || '',
        }}
        layout="vertical"
      >
        <Form.Item name="sources_id" label={intl.formatMessage({ id: 'report.source' })}>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder={intl.formatMessage({ id: 'report.please_select_source' })}
            filterOption={filterOption}
          >
            {children}
          </Select>
        </Form.Item>
      </Form>
    )
  }

  clearFilterHandler = () => {
    this.props.onClear()
    if (this.filterFormRef.current) {
      this.filterFormRef.current.setFieldsValue({
        ...this.props.initialParams,
      })
    }
  }

  render() {
    const {
      isMobileView,
      params,
      showClearBtn,
      intl,
      onToggleGraph,
      onSearch,
      showGraph,
      showSearch,
    } = this.props
    const { date_from: dateFrom, date_to: dateTo } = params

    return (
      <>
        <div className="row">
          <div className="col-md-6 mb-3">
            <Space>
              <Popover
                style={{ alignItems: 'center' }}
                placement="bottomLeft"
                content={this.renderFilter()}
                trigger="click"
                onOpenChange={this.filterVisibleChangeHandler}
              >
                <ButtonFilter onClear={this.clearFilterHandler} showClearBtn={showClearBtn} />
              </Popover>
              <Button
                icon={
                  <CaretDownOutlined
                    style={{
                      transform: `rotate(${showGraph ? 0 : 180}deg)`,
                      transition: 'all 150ms ease-in-out',
                    }}
                  />
                }
                onClick={onToggleGraph}
              >
                {intl.formatMessage({
                  id: showGraph ? 'reports.hide_graph' : 'reports.show_graph',
                })}
              </Button>
            </Space>
          </div>
          <div className="col-md-6">
            <div
              style={{ marginBottom: 8, float: isMobileView ? 'left' : 'right', display: 'flex' }}
            >
              <div className="ml-3">
                <RangePicker
                  format={formatDateText()}
                  value={[dateFrom, dateTo]}
                  placeholder="End"
                  allowClear={false}
                  onChange={this.onChange}
                />
              </div>
            </div>
            {showSearch && (
              <div
                style={{
                  marginBottom: 8,
                  float: isMobileView ? 'left' : 'right',
                  display: 'flex',
                  width: isMobileView ? '100%' : 'auto',
                }}
              >
                <SearchWidget
                  onChange={(event) => {
                    this.setState({ search: event.target.value })
                    onSearch(event)
                  }}
                  placeholder={intl.formatMessage({ id: 'input.placeholder.search' })}
                  value={this.state.search}
                />
              </div>
            )}
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    transTypeIds: state.finance.config.trans_type,
  }
}

const Component = connect(mapStateToProps)(injectIntl(Filter))

export default React.forwardRef((props, ref) => <Component innerRef={ref} {...props} />)
