import React from 'react'
import { injectIntl } from 'react-intl'
import { Card, Col, Row } from 'antd'
import { useSelector } from 'react-redux'

import { useGeneralLedgerStatsReport } from 'utils/queries'
import { mobileViewSelector } from 'redux/selectors'
import { useResponsive } from 'utils/hooks'
import PieChart from './PieChart'
import LineChart from './LineChart'

const NumberOfTransactionPerSource = injectIntl(({ intl, data, status, isPrint }) => {
  const isMobileView = useSelector(mobileViewSelector)

  return (
    <>
      <strong
        className={['text-uppercase font-size-16', isPrint ? 'mb-3' : ''].join(' ')}
        style={{ display: 'block' }}
      >
        {intl.formatMessage({ id: 'reports.number_of_transactions_per_source' })}
      </strong>
      <PieChart
        data={data?.pie_chart ?? {}}
        loading={status === 'loading'}
        isMobileView={isMobileView}
      />
    </>
  )
})

const RunningBalance = injectIntl(({ intl, params, data, status, isPrint }) => {
  const { isMobileView, screenSizes } = useResponsive()

  if (!Object.values(screenSizes).includes(true)) return null

  return (
    <>
      <strong
        className={['text-uppercase font-size-16', isPrint ? 'mb-3' : ''].join(' ')}
        style={{ display: 'block' }}
      >
        {intl.formatMessage({ id: 'report.running_balance' })}
      </strong>
      <LineChart
        params={params}
        data={data?.line_chart ?? {}}
        isMobileView={isMobileView}
        isSmallMobileScreen={screenSizes.xs}
        loading={status === 'loading'}
      />
    </>
  )
})

const Charts = ({ params, isPrint }) => {
  const { data, status } = useGeneralLedgerStatsReport({
    payload: params,
  })

  if (isPrint) {
    return (
      <Row className="mt-3" gutter={[8, 8]}>
        <Col xs={24} xl={12}>
          <NumberOfTransactionPerSource data={data} status={status} isPrint />
        </Col>
        <Col xs={24} xl={12}>
          <RunningBalance data={data} status={status} params={params} isPrint />
        </Col>
      </Row>
    )
  }

  return (
    <Row className="mt-3" gutter={[16, 16]}>
      <Col xs={24} xl={12}>
        <Card>
          <NumberOfTransactionPerSource data={data} status={status} />
        </Card>
      </Col>
      <Col xs={24} xl={12}>
        <Card>
          <RunningBalance data={data} status={status} params={params} />
        </Card>
      </Col>
    </Row>
  )
}

export default injectIntl(Charts)
