import axios from 'axios'
import { stringify } from 'query-string'
import { cleanBlankValue, getOnce, openNewTabBlobFile } from 'utils/helper'

export const getFinanceOrders = (params) => {
  const query = stringify(params)
  return getOnce(`/finance/orders?${query}`)
}

export const addFinanceOrder = (data) => {
  return axios.post('/finance/orders', data)
}

export const editFinanceOrder = (data) => {
  return axios.put(`/finance/orders/${data.id}`, data)
}

export const deleteOnAddFinanceOrderAttachment = (url) => {
  return axios.delete('/finance/orders/attachments', { data: { url } })
}

export const deleteOnEditFinanceOrderAttachment = (id, url) => {
  return axios.delete(`/finance/orders/${id}/attachments`, { data: { url } })
}

export const getFinanceOrder = (id, params) => {
  const query = params ? `?${stringify(params)}` : ''
  return axios.get(`/finance/orders/${id}${query}`)
}

export const getFinanceOrderLog = (id, params) => {
  const query = params ? `?${stringify(params)}` : ''
  return axios.get(`/finance/orders/${id}/logs${query}`)
}

export const changeFinanceOrderStatus = (id, data) => {
  return axios.patch(`/finance/orders/${id}/status`, data)
}

export const deleteFinanceOrder = (id) => {
  return axios.delete(`/finance/orders/${id}`)
}

export const getFinanceOrderOverview = () => {
  return axios.get(`/finance/orders/overview`)
}

export const uploadImportFinanceOrder = (data) => {
  return axios.post('/finance/orders/uploadImport', data)
}

export const executeImportFinanceOrder = (data) => {
  return axios.post('/finance/orders/executeImport', data)
}

export const createPdfFinanceOrder = (id) => {
  return axios
    .get(`/finance/orders/${id}/print`, { responseType: 'blob' })
    .then((response) => {
      openNewTabBlobFile(response)
      return Promise.resolve(true)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export const getFinanceOrderPublicUrl = (id) => {
  return axios.get(`/finance/orders/${id}/publicUrl`)
}

export const exportFinanceOrder = (params) => {
  const URL = params.status_id === 'recurring' ? 'orders/recurring/export' : 'orders/export'
  if (params.status_id === 'recurring') {
    delete params.status_id
  }
  const query = stringify(cleanBlankValue(params))
  return axios.get(`/finance/${URL}?${query}`, { responseType: 'arraybuffer' })
}

export const getFinanceOrderWaTemplate = (id) => {
  return axios.get(`/finance/orders/${id}/whatsapp`)
}

export const getFinanceOrderEmailTemplate = (id) => {
  return axios.get(`/finance/orders/${id}/email`)
}

export const sendFinanceOrderEmail = (id, data) => {
  return axios.post(`/finance/orders/${id}/email`, data)
}

export const addRecurringOrder = (data) => {
  return axios.post('/finance/orders/recurring', data)
}

export const editRecurringOrder = (data) => {
  return axios.put(`/finance/orders/recurring/${data.id}`, data)
}

export const getRecurringOrder = (id) => {
  return axios.get(`/finance/orders/recurring/${id}`)
}

export const deleteRecurringOrder = (id) => {
  return axios.delete(`/finance/orders/recurring/${id}`)
}

export const getRecurringOrders = (params) => {
  const query = stringify(params)
  return getOnce(`/finance/orders/recurring?${query}`)
}

export const getFinanceOrderSmsTemplate = (id) => {
  return axios.get(`/finance/orders/${id}/sms`)
}

export const sendFinanceOrderSms = (id, data) => {
  return axios.post(`/finance/orders/${id}/sms`, data)
}

export const inputMassDeleteFinanceOrder = (data) => {
  return axios.post('/finance/orders/inputMassDelete', data)
}

export const executeMassDeleteFinanceOrder = (data) => {
  return axios.delete('/finance/orders/executeMassDelete', { data })
}

export const inputMassSendEmailFinanceOrder = (data) => {
  return axios.post('/finance/orders/inputMassEmail', data)
}

export const executeMassSendEmailFinanceOrder = (data) => {
  return axios.post('/finance/orders/executeMassEmail', data)
}

export const getMassDownloadFinanceOrder = (params) => {
  return axios.get('/finance/orders/massDownload', { params })
}

export const getMinTransDateFinanceOrder = (data) => {
  return axios.post('/finance/orders/mass/minDate', data)
}

export const inputMassCreateFinanceOrder = (data) => {
  return axios.post('/finance/orders/inputMassOrder', data)
}

export const executeMassCreateFinanceOrder = (data) => {
  return axios.post('/finance/orders/executeMassOrder', data)
}

export const approveFinanceOrder = ({ id }) => {
  return axios.post(`/finance/orders/${id}/approve`)
}

export const rejectFinanceOrder = ({ id, body }) => {
  return axios.post(`/finance/orders/${id}/reject`, body)
}

export const revertApprovalFinanceOrder = ({ id }) => {
  return axios.post(`/finance/orders/${id}/revert`)
}
