import React from 'react'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { goBack } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import { ArrowLeftOutlined } from '@ant-design/icons'

function Back(props) {
  const history = useHistory()
  const dispatch = useDispatch()
  if (history.length < 2 && !props.onClick) {
    return null
  }

  const clickHandler = () => {
    if (props.onClick) {
      props.onClick()
    } else {
      dispatch(goBack())
    }
  }

  const title =
    props.title === 'button.back' ? props.intl.formatMessage({ id: props.title }) : props.title
  return (
    <Button {...props} title={title} onClick={clickHandler}>
      {title}
    </Button>
  )
}

Back.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  id: PropTypes.string,
  style: PropTypes.object,
}

Back.defaultProps = {
  icon: <ArrowLeftOutlined />,
  title: 'button.back',
  style: { backgroundColor: '#eb9234', color: '#ffffff' },
  id: 'btn-back',
}

export default injectIntl(Back)
