import React from 'react'
import { Link } from 'react-router-dom'
import { injectIntl } from 'react-intl'

import { Helmet } from 'components/UI'

const NotFound = ({ intl, title, description, fallbackTitle, fallbackLink }) => {
  const pageTitle = title ?? intl.formatMessage({ id: 'page404.title' })

  return (
    <div
      style={{
        minHeight: 'calc(100vh - 500px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Helmet title={pageTitle} />
      <div
        style={{
          maxWidth: '560px',
          backgroundColor: '#fff',
          padding: '80px 30px',
          margin: '100px auto',
          borderRadius: '10px',
          flex: '1',
        }}
      >
        <div
          style={{
            maxWidth: '400px',
            margin: '0 auto',
          }}
        >
          <h1 className="font-size-36 mb-2">{pageTitle}</h1>
          <p className="mb-3">{description ?? intl.formatMessage({ id: 'page404.description' })}</p>
          <h1 className="font-size-80 mb-4 font-weight-bold">404 —</h1>
          <Link to={fallbackLink ?? '/'} className="btn">
            &larr; {fallbackTitle ?? intl.formatMessage({ id: 'page404.fallback' })}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(NotFound)
