import axios from 'axios'
import { stringify } from 'query-string'
import { cleanBlankValue, getOnce, openNewTabBlobFile } from 'utils/helper'

export const getFinancePurchaseInvoices = (params) => {
  const query = stringify(params)
  return getOnce(`/finance/purchaseInvoices?${query}`)
}

export const addFinancePurchaseInvoice = (data) => {
  return axios.post('/finance/purchaseInvoices', data)
}

export const editFinancePurchaseInvoice = (data) => {
  return axios.put(`/finance/purchaseInvoices/${data.id}`, data)
}

export const deleteOnAddFinancePurchaseInvoiceAttachment = (url) => {
  return axios.delete('/finance/purchaseInvoices/attachments', { data: { url } })
}

export const deleteOnEditFinancePurchaseInvoiceAttachment = (id, url) => {
  return axios.delete(`/finance/purchaseInvoices/${id}/attachments`, { data: { url } })
}

export const getFinancePurchaseInvoice = (id, params) => {
  const query = params ? `?${stringify(params)}` : ''
  return axios.get(`/finance/purchaseInvoices/${id}${query}`)
}

export const getFinancePurchaseInvoiceLog = (id, params) => {
  const query = params ? `?${stringify(params)}` : ''
  return axios.get(`/finance/purchaseInvoices/${id}/logs${query}`)
}

export const changeFinancePurchaseInvoiceStatus = (id, data) => {
  return axios.patch(`/finance/purchaseInvoices/${id}/status`, data)
}

export const deleteFinancePurchaseInvoice = (id) => {
  return axios.delete(`/finance/purchaseInvoices/${id}`)
}

export const getFinancePurchaseInvoiceOverview = (params) => {
  return axios.get(`/finance/purchaseInvoices/overview`, { params })
}

export const uploadImportFinancePurchaseInvoice = (data) => {
  return axios.post('/finance/purchaseInvoices/uploadImport', data)
}

export const executeImportFinancePurchaseInvoice = (data) => {
  return axios.post('/finance/purchaseInvoices/executeImport', data)
}

export const uploadImportStatusFinancePurchaseInvoice = (data) => {
  return axios.post('/finance/purchaseInvoices/payment/uploadImport', data)
}

export const executeImportStatusFinancePurchaseInvoice = (data) => {
  return axios.post('/finance/purchaseInvoices/payment/executeImport', data)
}

export const createPdfFinancePurchaseInvoice = (id) => {
  return axios
    .get(`/finance/purchaseInvoices/${id}/print`, { responseType: 'blob' })
    .then((response) => {
      openNewTabBlobFile(response)

      return Promise.resolve(true)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export const getFinancePurchaseInvoicePublicUrl = (id) => {
  return axios.get(`/finance/purchaseInvoices/${id}/publicUrl`)
}

export const exportFinancePurchaseInvoice = (params) => {
  const URL =
    params.status_id === 'recurring'
      ? 'purchaseInvoices/recurring/export'
      : 'purchaseInvoices/export'
  if (params.status_id === 'recurring') {
    delete params.status_id
  }
  const query = stringify(cleanBlankValue(params))
  return axios.get(`/finance/${URL}?${query}`, { responseType: 'arraybuffer' })
}

export const getFinancePurchaseInvoiceWaTemplate = (id) => {
  return axios.get(`/finance/purchaseInvoices/${id}/whatsapp`)
}

export const getFinancePurchaseInvoiceEmailTemplate = (id) => {
  return axios.get(`/finance/purchaseInvoices/${id}/email`)
}

export const sendFinancePurchaseInvoiceEmail = (id, data) => {
  return axios.post(`/finance/purchaseInvoices/${id}/email`, data)
}

export const addRecurringPurchaseInvoice = (data) => {
  return axios.post('/finance/purchaseInvoices/recurring', data)
}

export const editRecurringPurchaseInvoice = (data) => {
  return axios.put(`/finance/purchaseInvoices/recurring/${data.id}`, data)
}

export const getRecurringPurchaseInvoice = (id) => {
  return axios.get(`/finance/purchaseInvoices/recurring/${id}`)
}

export const deleteRecurringPurchaseInvoice = (id) => {
  return axios.delete(`/finance/purchaseInvoices/recurring/${id}`)
}

export const getRecurringPurchaseInvoices = (params) => {
  const query = stringify(params)
  return getOnce(`/finance/purchaseInvoices/recurring?${query}`)
}

export const getFinancePurchaseInvoiceSmsTemplate = (id) => {
  return axios.get(`/finance/purchaseInvoices/${id}/sms`)
}

export const sendFinancePurchaseInvoiceSms = (id, data) => {
  return axios.post(`/finance/purchaseInvoices/${id}/sms`, data)
}

export const inputMassDeleteFinancePurchaseInvoice = (data) => {
  return axios.post('/finance/purchaseInvoices/inputMassDelete', data)
}

export const executeMassDeleteFinancePurchaseInvoice = (data) => {
  return axios.delete('/finance/purchaseInvoices/executeMassDelete', { data })
}

export const inputMassSendEmailFinancePurchaseInvoice = (data) => {
  return axios.post('/finance/purchaseInvoices/inputMassEmail', data)
}

export const executeMassSendEmailFinancePurchaseInvoice = (data) => {
  return axios.post('/finance/purchaseInvoices/executeMassEmail', data)
}

export const getMassDownloadFinancePurchaseInvoice = (params) => {
  return axios.get('/finance/purchaseInvoices/massDownload', { params })
}

export const getMinTransDateFinancePurchaseInvoice = (data) => {
  return axios.post('/finance/purchaseInvoices/mass/minDate', data)
}

export const inputMassCreateFinancePurchaseInvoice = (data) => {
  return axios.post('/finance/purchaseInvoices/inputMassPurchaseInvoice', data)
}

export const executeMassCreateFinancePurchaseInvoice = (data) => {
  return axios.post('/finance/purchaseInvoices/executeMassPurchaseInvoice', data)
}

export const approveFinancePurchaseInvoice = ({ id }) => {
  return axios.post(`/finance/purchaseInvoices/${id}/approve`)
}

export const rejectFinancePurchaseInvoice = ({ id, body }) => {
  return axios.post(`/finance/purchaseInvoices/${id}/reject`, body)
}

export const revertApprovalFinancePurchaseInvoice = ({ id }) => {
  return axios.post(`/finance/purchaseInvoices/${id}/revert`)
}

export const uploadImportFinancePurchaseInvoiceRecurring = (data) => {
  return axios.post('/finance/purchaseInvoices/import/recurring/uploadImport', data)
}

export const executeImportFinancePurchaseInvoiceRecurring = (data) => {
  return axios.post('/finance/purchaseInvoices/import/recurring/executeImport', data)
}
